import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import React, { useState } from "react";
import Locale from "translations";

export function Tax({ tax, currencys }) {
	const { polices } = Locale;
	const [disabled, setDisabled] = useState(true);

	return (
		<>
			<div className="col-3">
				<TextField
					label={polices.taxName}
					placeholder={polices.taxName}
					value={tax.name}
					name="name"
					id="tax-1"
					disabled={disabled}
				/>
			</div>
			<div className="col-5 text-with-select m-0">
				<TextField
					label={polices.taxRate}
					placeholder={polices.taxRate}
					value={tax.rate}
					name="rate"
					id="tax-2"
					disabled={disabled}
				/>
				<SelectField
					label={polices.type}
					placeholder={polices.type}
					value={{ value: tax.type.id, label: tax.type }}
					id="tax-3"
					disabled={disabled}
				/>
			</div>
			{tax.type &&
			tax.type?.value == "fixed" &&
			currencys &&
			currencys.length > 0
				? currencys.map((res) => {
						return (
							<div className="col-md-2">
								<TextField
									label={polices.taxRate + " (" + res.label + ")"}
									placeholder={polices.taxRate + res.label}
									value={
										tax.rates && tax.rates.length > 0
											? tax.rates.map((resa) => resa.currency == res.label)[0]
													.rate
											: ""
									}
									name="rate"
									id="tax-2"
								/>
							</div>
						);
				  })
				: ""}
		</>
	);
}
