import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { viewProperties } from "services/property";
import Photo from "./Photo";

export default function Photos() {
	const [gallery, setGallery] = useState();
	const { id } = useParams();

	useEffect(async () => {
		const res = await viewProperties(id);
		if (res.status === 200 || res.status === 201) {
			setGallery(res.data.property.photos);
		}
	}, []);

	const allPhotos =
		gallery?.length > 0 ? (
			gallery?.map((photo) => {
				return <Photo photo={photo} />;
			})
		) : (
			<p className="col-12 text-center font-weight-bold">No Photos</p>
		);

	return (
		<div className="container px-3 py-2">
			<div className="header-box my-3">Photo Gallery</div>
			<div className="row m-0 p-0">{allPhotos}</div>
		</div>
	);
}
