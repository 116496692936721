import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoginBanner from "modules/auth/components/LoginBanner";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import Locale from "translations";
import MarketPhoto from "assets/images/mailbox.svg";
import axios from "axios";
import { forgetPass } from "services/auth";

export default function ForgotPasswordSubmitted(props) {
	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	const { forgotPasswordSubmitted, success } = Locale;
	const [counter, setCounter] = React.useState(50);
	const [isResend, setIsResend] = useState(false);
	const resendForgetPassword = async () => {
		// axios
		// 	.post(
		// 		`${process.env.REACT_APP_API_URL}/api/users/resend-activate-token?email=${props.location.state.email}`
		// 	)
		// 	.then((res) => {})
		// 	.catch((err) => {});
		const response = await forgetPass({ email: props.location.state.email });
		//
		if (response.status === 200) {
			setIsResend(true);
		}
	};

	useEffect(() => {
		if (isResend) {
			const timer =
				counter > 0
					? setInterval(() => setCounter(counter - 1), 700)
					: setIsResend(false);
			return () => {
				clearInterval(timer);
			};
		}
	}, [counter, isResend]);
	// ----------------------------------------------------------------------------------------------------
	// Main Component Body UI
	// ----------------------------------------------------------------------------------------------------
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					{/* Left Banner */}
					<div className="col-md-6 p-0">
						<LoginBanner />
					</div>

					{/* Forget Password Submitted */}
					<div className="col-md-6 py-5 py-md-0">
						<div className="d-flex justify-content-center justify-content-md-start flex-md-row-reverse px-4 pt-5">
							<LanguageSwitcher />
						</div>

						<div className="d-flex align-items-center h-75 px-4 px-md-0 pt-5 pt-md-0">
							<div className="col-md-8 mx-auto text-center">
								<h1 className="text-title">
									{forgotPasswordSubmitted.mainTitle}
								</h1>
								<p className="text-headline">
									{forgotPasswordSubmitted.successMessage}
								</p>

								<img
									src={MarketPhoto}
									className="my-3"
									alt="Submitted Success"
									title=""
								/>

								{!isResend ? (
									<>
										<p className="text-headline mt-5">{success.notReceive}</p>
										<p className="text-headline">
											{success.checkSpam}
											<button
												className="btn-clear text-danger"
												onClick={resendForgetPassword}
											>
												{success.resendEmail}
											</button>
										</p>
									</>
								) : (
									<p>
										{success.pleaseWait}{" "}
										<span className="text-danger">00:{counter}</span>{" "}
										{success.receiveMail}
									</p>
								)}

								<Link to="/login" className="my-5 py-5 d-block text-dark-blue">
									{forgotPasswordSubmitted.backLogin}
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
