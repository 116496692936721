import moment from "moment";

const locale = localStorage.getItem("currentLocale") || "en";

export function formatAddPromotionsBasic(info) {
	const {
		promotion_name,
		discount,
		ListRatePlanIds,
		min_stay,
		promotion_duration_start_date,
		promotion_duration_end_date,
		days,
		able_see_promotion,
		rooms,
	} = info;
	return {
		promotion_name: promotion_name,
		discount: discount,
		is_everyone: 0,
		rate_plans: ListRatePlanIds,
		min_stay: min_stay,
		stay_from: moment(promotion_duration_start_date).format("YYYY-MM-DD"),
		stay_to: moment(promotion_duration_end_date).format("YYYY-MM-DD"),
		days: days,
		countries:
			able_see_promotion !== undefined && able_see_promotion.length > 0
				? able_see_promotion.map((country) => +country.value)
				: [],
		rooms:
			rooms !== undefined && rooms.length > 0
				? rooms.map((room) => +room.value)
				: [],
	};
}
export function PromotionsFormatDetails(info) {
	const {
		promotion_name,
		discount,
		rate_plans,
		min_stay,
		stay_from,
		stay_to,
		days,
		countries,
		rooms,
	} = info;
	return {
		promotion_name: promotion_name,
		discount: discount,
		is_everyone: 0,
		promotion_apply:
			rate_plans.length > 0
				? rate_plans.map((res) => {
						return {
							label: res.name,
							value: res.id,
						};
				  })
				: [],
		ListRatePlanIds: rate_plans,
		min_stay: min_stay,
		promotion_duration_start_date: moment(stay_from).format("YYYY-MM-DD"),
		promotion_duration_end_date: moment(stay_to).format("YYYY-MM-DD"),
		days: days,
		able_see_promotion:
			countries.length > 0
				? countries.map((res) => {
						return {
							label: res.name[locale],
							value: res.id,
						};
				  })
				: [],
		rooms:
			rooms.length > 0
				? rooms.map((res) => {
						return {
							label: res.custom_name ? res.custom_name : res.name.name,
							value: res.id,
						};
				  })
				: [],
	};
}

export function PromotionsFormatFree(info) {
	const {
		promotion_name,
		discount,
		rate_plans,
		min_stay,
		stay_from,
		stay_to,
		days,
		rooms,
		free_count,
		paid_count,
		free_type,
	} = info;
	return {
		promotion_name: promotion_name,
		discount: discount,
		is_everyone: 0,
		promotion_apply:
			rate_plans.length > 0
				? rate_plans.map((res) => {
						return {
							label: res.name,
							value: res.id,
						};
				  })
				: [],
		ListRatePlanIds: rate_plans,
		min_stay: min_stay,
		promotion_duration_start_date: moment(stay_from).format("YYYY-MM-DD"),
		promotion_duration_end_date: moment(stay_to).format("YYYY-MM-DD"),
		days: days,
		free_count: free_count,
		paid_count: paid_count,
		Free_type: { label: free_type.name, value: free_type.id },
		rooms:
			rooms.length > 0
				? rooms.map((res) => {
						return {
							label: res.custom_name ? res.custom_name : res.name.name,
							value: res.id,
						};
				  })
				: [],
	};
}

export function formatAddPromotionsFree(info) {
	const {
		promotion_name,
		discount,
		ListRatePlanIds,
		paid_count,
		promotion_duration_start_date,
		promotion_duration_end_date,
		days,
		able_see_promotion,
		rooms,
		free_count,
		Free_type,
	} = info;
	return {
		promotion_name: promotion_name,
		discount: discount,
		rate_plans: ListRatePlanIds,
		stay_from: moment(promotion_duration_start_date).format("YYYY-MM-DD"),
		stay_to: moment(promotion_duration_end_date).format("YYYY-MM-DD"),
		days: days,
		paid_count: paid_count,
		free_count: free_count,
		countries:
			able_see_promotion !== undefined && able_see_promotion.length > 0
				? able_see_promotion.map((country) => +country.value)
				: [],
		rooms:
			rooms !== undefined && rooms.length > 0
				? rooms.map((room) => +room.value)
				: [],
		free_type_id: Free_type?.value,
	};
}
