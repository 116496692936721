import ShowForPermission from "helpers/showForPermission";
import { useState } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { Collapse } from "reactstrap";
import Locale from "translations";
// import "../../scss/modules/Dashboard/_sidenav.scss";
import {
	ActiveIcon,
	AddPropertyIcon,
	ApprovedIcon,
	Arrow,
	GroundServiceIcon,
	GroupsIcon,
	InProgressIcon,
	IssuedVisaIcon,
	PropertiesIcon,
	TeamManagementIcon,
	TransportationIcon,
	UsersIcon,
	VerifyingIcon,
	VisasIcon,
	WaitingForIssuingIcon,
	DashboardIcon,
	SafaLogoMinimal,
	SafaLogoFull,
} from "./SideBarIcons";

export default function SideNav({ show, setShow }) {
	// const { dashboard, marketPlace, inventory } = Locale;
	const history = useHistory();
	const { pathname } = useLocation();
	const [dashboardRoutes, setDashboardRoutes] = useState([
		{
			id: 0,
			icon: <DashboardIcon />,
			text: `Dashboard`,
			route: "/",
			show: false,
		},
		{
			id: 1,
			icon: <PropertiesIcon />,
			text: `Properties`,
			route: "/properties",
			show: false,
			permission: ["view-property", "manage-property"],
			subNavs: [
				{
					id: 11,
					icon: <InProgressIcon />,
					route: "/properties/inprogress",
					text: "In Progress",
					permission: ["view-property", "manage-property"],
				},
				{
					id: 12,
					icon: <VerifyingIcon />,
					route: "/properties/verifying",
					text: "Verifying",
					permission: ["view-property", "manage-property"],
				},
				{
					id: 13,
					icon: <ActiveIcon />,
					route: "/properties/active",
					text: "Active",
					permission: ["view-property", "manage-property"],
				},
				{
					id: 14,
					icon: <AddPropertyIcon />,
					route: "/property",
					text: "Add Property",
					permission: ["manage-property"],
				},
			],
		},
		{
			id: 2,
			icon: <VisasIcon />,
			text: `Visas`,
			route: "/manage-visas",
			show: false,
			permission: ["view-visa", "manage-visa"],
			subNavs: [
				{
					id: 21,
					icon: <WaitingForIssuingIcon />,
					route: "/manage-visas/waiting",
					text: "Waiting For Issuing",
					permission: ["view-visa", "manage-visa"],
				},
				{
					id: 22,
					icon: <IssuedVisaIcon />,
					route: "/manage-visas/issued",
					text: "Issued Visa",
					permission: ["view-visa", "manage-visa"],
				},
			],
		},
		{
			id: 3,
			icon: <TransportationIcon />,
			text: `Transportation`,
			route: "/transportation",
			show: false,
			permission: ["manage-transportation"],
			subNavs: [
				{
					id: 31,
					icon: <ApprovedIcon />,
					route: "/transportation",
					text: "Approved",
				},
			],
		},
		{
			id: 4,
			icon: <GroundServiceIcon />,
			text: `Ground Service`,
			route: "/ground-service",
			show: false,
			permission: ["manage-ground-work"],
			subNavs: [
				{
					id: 41,
					icon: <ApprovedIcon />,
					route: "/ground-service",
					text: "Approved",
				},
			],
		},
		{
			id: 5,
			icon: <TeamManagementIcon />,
			text: `Team Management`,
			route: "/team-management",
			show: false,
			permission: ["manage-team", "view-team"],
			subNavs: [
				{
					id: 51,
					icon: <UsersIcon />,
					route: "/team-management/users",
					text: "Users",
				},
				{
					id: 52,
					icon: <GroupsIcon />,
					route: "/team-management/groups",
					text: "Groups",
				},
			],
		},
	]);

	const dashboardList =
		dashboardRoutes &&
		dashboardRoutes?.map((dashboard, index) => {
			const routeHasSubNav =
				dashboard?.subNavs && dashboard?.subNavs.length > 0 ? true : false;
			return (
				<ShowForPermission
					permission={dashboard?.permission}
					key={dashboard?.id}
				>
					<li className="sidenav_item">
						<NavLink
							exact
							className={`sidenav_link`}
							activeClassName="active"
							to={dashboard?.route ?? ""}
							onClick={(e) => {
								routeHasSubNav && e.preventDefault();
								if (show) {
									setDashboardRoutes(
										dashboardRoutes.map((route) => {
											return {
												...route,
												show:
													route.id === dashboard.id ? !dashboard.show : false,
											};
										})
									);
								}
							}}
						>
							<span className="sidenav_icon">{dashboard?.icon}</span>
							<span
								className={`sidenav_arrow ${
									dashboard.show && routeHasSubNav ? "toggled" : ""
								}`}
							>
								<Arrow />
							</span>
							<p className="dash-text">{dashboard?.text}</p>
						</NavLink>
						{/* dropdown */}
						{routeHasSubNav ? (
							<ul className="sidenav_dropdown">
								{show ? (
									<Collapse isOpen={dashboard.show}>
										{dashboard?.subNavs?.map((subNav) => {
											return subNav?.permission ? (
												<div>
													<ShowForPermission
														permission={subNav?.permission}
														key={subNav?.id}
													>
														<li className="dropdown_item">
															<NavLink
																exact
																to={subNav?.route}
																value={dashboard?.id}
																className="dropdown_link"
																activeClassName="active"
																onClick={() =>
																	pathname !== "/" || show
																		? setShow(false)
																		: false
																}
															>
																{/* <span className="sub_sidenav_icon"> */}
																{subNav?.icon}
																{/* </span> */}
																<p className="sub-dash-text">{subNav?.text}</p>
															</NavLink>
														</li>
													</ShowForPermission>
												</div>
											) : (
												<li className="dropdown_item">
													<NavLink
														exact
														to={subNav?.route}
														value={dashboard?.id}
														className="dropdown_link"
														activeClassName="active"
														onClick={() =>
															pathname !== "/" || show ? setShow(false) : false
														}
													>
														{/* <span className="sub_sidenav_icon"> */}
														{subNav?.icon}
														{/* </span> */}
														<p className="sub-dash-text">{subNav?.text}</p>
													</NavLink>
												</li>
											);
										})}
									</Collapse>
								) : (
									dashboard?.subNavs?.map((subNav) => {
										return subNav?.permission ? (
											<ShowForPermission
												permission={subNav?.permission}
												key={subNav?.id}
											>
												<li className="dropdown_item">
													<NavLink
														exact
														to={subNav?.route}
														value={dashboard?.id}
														className="dropdown_link"
														activeClassName="active"
														onClick={() =>
															pathname !== "/" || show ? setShow(false) : false
														}
													>
														{/* <span className="sub_sidenav_icon"> */}
														{subNav?.icon}
														{/* </span> */}
														<p className="sub-dash-text">{subNav?.text}</p>
													</NavLink>
												</li>
											</ShowForPermission>
										) : (
											<li className="dropdown_item">
												<NavLink
													exact
													to={subNav?.route}
													value={dashboard?.id}
													className="dropdown_link"
													activeClassName="active"
													onClick={() =>
														pathname !== "/" || show ? setShow(false) : false
													}
												>
													{/* <span className="sub_sidenav_icon"> */}
													{subNav?.icon}
													{/* </span> */}
													<p className="sub-dash-text">{subNav?.text}</p>
												</NavLink>
											</li>
										);
									})
								)}
							</ul>
						) : null}
					</li>
				</ShowForPermission>
			);
		});

	return (
		<nav id="sideNav" className={show ? "open" : "closed"}>
			<Link
				className={`navbar-brand ${show ? "w-100 m-0" : "m-0"} mt-3`}
				onClick={() => {
					show && setShow(false);
					history.push("/");
				}}
			>
				<span className={`navbar-brand-icon`}>
					<img
						className={show ? "img-fluid" : ""}
						src={show ? SafaLogoFull : SafaLogoMinimal}
						alt="Logo"
					/>
				</span>
			</Link>
			{/* nav list */}
			<ul className="sidenav_list">
				<h1 className="sidenav_header">{"menu"}</h1>
				{dashboardList}
			</ul>
		</nav>
	);
}
