import { AuthRoute } from "components/AuthRoute";
import { HomeRoute } from "components/HomeRoute";
import { HotelLayoutRoute } from "components/HotelLayoutRoute";
import { PrivateRoute } from "components/PrivateRoute";
import { VerifyHotelRouter } from "components/VerifyHotelRouter";
import Activation from "modules/auth/pages/Activation";
import ForgotPassword from "modules/auth/pages/ForgotPassword";
import ForgotPasswordSubmitted from "modules/auth/pages/ForgotPasswordSubmitted";
import Login from "modules/auth/pages/Login";
import Register from "modules/auth/pages/Register";
import RegistrationSucceed from "modules/auth/pages/RegistrationSucceed";
import ResetPassword from "modules/auth/pages/ResetPassword";
import Home from "modules/Home";
import Calendar from "modules/HotelLayout/Calendar";
import Hotel from "modules/HotelLayout/Dashboard/Hotel";
import Promotions from "modules/HotelLayout/Promotions";
import AddBasicDeal from "modules/HotelLayout/Promotions/AddBasicDeal";
import AddRoomOrNight from "modules/HotelLayout/Promotions/AddRoomOrNight";
import ListPromotionsBasicDeal from "modules/HotelLayout/Promotions/ListPromotionsBasicDeal";
import ListPromotionsRoomOrNight from "modules/HotelLayout/Promotions/ListPromotionsRoomOrNight";
import AddRatePlan from "modules/HotelLayout/RatePlan/AddRatePlan/index";
import ListRatePlan from "modules/HotelLayout/RatePlan/ListRatePlan";
import Property from "modules/Property";
import Verify from "modules/VerifyHotel";
import React from "react";
import { Route, Switch } from "react-router-dom";

// Start Team Management
import { TeamManagementRoute } from "components/TeamManagementRoute";
import TeamManagement from "modules/TeamMangament";
import Groups from "modules/TeamMangament/groups";
import ReservationsLayout from "modules/HotelLayout/Reservations";
import ReservationsList from "modules/HotelLayout/Dashboard/ReservationsList";
import BasicInfo from "modules/HotelLayout/ViewProperty/BasicInfo";
import Facilities from "modules/HotelLayout/ViewProperty/Facilities";
import Rooms from "modules/HotelLayout/ViewProperty/Rooms";
import Amenities from "modules/HotelLayout/ViewProperty/Amenities";
import Polices from "modules/HotelLayout/ViewProperty/Polices";
import Photos from "modules/HotelLayout/ViewProperty/Photos";
import ViewPropertyRoute from "components/ViewPropertyRoute";
import Reservation from "modules/HotelLayout/Reservation/Reservation";
import AllNotifications from "components/layout/Notifications";
import { VisasRoute } from "components/VisasRoute";
import Visa from "modules/visa";
import ViewRequest from "modules/visa/viewRequest/ViewRequest";
import PassangerProfile from "modules/visa/viewRequest/PassangerProfile";
import CompanyAccountsView from "modules/company-profile/indexView";
import Dashboard from "modules/Dashboard";
import { TransportationRoute } from "components/TransportationRoute";
import Transportation from "modules/transpoartation";
import TransportationRequest from "modules/transpoartation/transportationRequest";
import TransportationPassangerProfile from "modules/transpoartation/viewRequest/TransportationPassangerProfile";
import TransportationViewRequest from "modules/transpoartation/viewRequest/TransportationViewRequest";
import { GroundServiceRoute } from "components/GroundServiceRoute";
import GroundService from "modules/groundService";
import GroundViewRequest from "modules/groundService/viewRequest/TransportationViewRequest";
import GroundPassangerProfile from "modules/groundService/viewRequest/TransportationPassangerProfile";
import { NetworkRoute } from "components/NetworkRoute";
import Network from "modules/network";
import WalletNetwork from "modules/network/list/myNetwork/wallet/Wallet";
import ActivityLogs from 'modules/activityLogs';

// Lazy Components
// const App = lazy(() => import("./App"));

// Application Routes
const Routes = (
	<Switch>
		{/* Login Module */}
		<Route path="/login" exact component={Login} />
		<AuthRoute path="/register" exact component={Register} />
		<AuthRoute path="/register/success" exact component={RegistrationSucceed} />
		<AuthRoute path="/forgot-password" exact component={ForgotPassword} />
		<AuthRoute
			path="/forgot-password-submitted"
			exact
			component={ForgotPasswordSubmitted}
		/>
		<AuthRoute
			path="/reset-password/:token/:email"
			exact
			component={ResetPassword}
		/>
		{/* <AuthRoute path="/reset-password" exact component={ResetPassword} /> */}
		{/* <AuthRoute path="/activation/:token/:email" component={Activation} /> */}
		<AuthRoute path="/activation" component={Activation} />
		<AuthRoute path="/activation/resend" component={Activation} />

		{/* <HomeRoute path="/" exact component={Home} /> */}
		<HomeRoute path="/properties/inprogress" exact component={Home} />
		<HomeRoute path="/properties/verifying" exact component={Home} />
		<HomeRoute path="/properties/active" exact component={Home} />
		<HomeRoute exact path="/notifications" component={AllNotifications} />
		<HomeRoute path="/company-profile" component={CompanyAccountsView} />
		<HomeRoute path="/" exact component={Dashboard} />

		<HotelLayoutRoute path="/dashboard/:id" exact component={Hotel} />
		<HotelLayoutRoute path="/reservation/:id" exact component={Reservation} />

		<HotelLayoutRoute path="/calendar/:id" exact component={Calendar} />

		<HotelLayoutRoute
			path="/dashboard/promotions/:id"
			exact
			component={Promotions}
		/>

		<HotelLayoutRoute
			path="/dashboard/list-promotions-basic/:id"
			exact
			component={ListPromotionsBasicDeal}
		/>
		<HotelLayoutRoute
			path="/dashboard/list-promotions-Room/:id"
			exact
			component={ListPromotionsRoomOrNight}
		/>

		<HotelLayoutRoute
			path="/dashboard/add-promotions-basic/:id"
			exact
			component={AddBasicDeal}
		/>
		<HotelLayoutRoute
			path="/dashboard/add-promotions-basic/:id/:promotionId"
			exact
			component={AddBasicDeal}
		/>

		<HotelLayoutRoute
			path="/dashboard/:id/reservation/:reservation_id"
			exact
			component={ReservationsLayout}
		/>

		{/* View Properties Routes */}

		<ViewPropertyRoute
			path="/dashboard/:id/view-property/basic"
			exact
			component={BasicInfo}
		/>

		<ViewPropertyRoute
			path="/dashboard/:id/view-property/facilities"
			exact
			component={Facilities}
		/>

		<ViewPropertyRoute
			path="/dashboard/:id/view-property/rooms"
			exact
			component={Rooms}
		/>

		<ViewPropertyRoute
			path="/dashboard/:id/view-property/amenities"
			exact
			component={Amenities}
		/>

		<ViewPropertyRoute
			path="/dashboard/:id/view-property/polices"
			exact
			component={Polices}
		/>

		<ViewPropertyRoute
			path="/dashboard/:id/view-property/photos"
			exact
			component={Photos}
		/>

		<HotelLayoutRoute
			path="/dashboard/reservations-list/:id"
			exact
			component={ReservationsList}
		/>

		<HotelLayoutRoute
			path="/dashboard/add-Room-Night/:id"
			exact
			component={AddRoomOrNight}
		/>
		<HotelLayoutRoute
			path="/dashboard/add-Room-Night/:id/:promotionId"
			exact
			component={AddRoomOrNight}
		/>

		<HotelLayoutRoute
			path="/dashboard/add-Rate-Plan/:id"
			exact
			component={AddRatePlan}
		/>
		<HotelLayoutRoute
			path="/dashboard/add-Rate-Plan/:id/:rateId"
			exact
			component={AddRatePlan}
		/>

		<HotelLayoutRoute
			path="/dashboard/list-Rate-Plan/:id"
			exact
			component={ListRatePlan}
		/>

		<PrivateRoute exact path="/property" component={Property} />
		{/* <PrivateRoute exact path="/property/basic" component={BasicInfo} /> */}
		<PrivateRoute path="/property/:id" component={Property} />

		{/* verify-hotel */}
		<VerifyHotelRouter path="/verify-hotel/:id" component={Verify} />

		{/* Error404 Routes */}
		{/* <Route path="*" component={NotFound} /> */}

		{/* Start Team Management */}

		<TeamManagementRoute
			path="/team-management/users"
			exact
			component={TeamManagement}
		/>
		<TeamManagementRoute
			path="/team-management/groups"
			exact
			component={Groups}
		/>

		{/* End Team Management */}
		{/* Start Visas */}
		<VisasRoute path="/manage-visas/:status" exact component={Visa} />
		<VisasRoute
			path="/manage-visas/:status/:id"
			exact
			component={ViewRequest}
		/>

		<VisasRoute
			path="/manage-visas/:status/:id/:passenger_id"
			exact
			component={PassangerProfile}
		/>

		{/* End Visas */}
		{/* Start Transportation */}
		<TransportationRoute
			path="/transportation"
			exact
			component={Transportation}
		/>
		<TransportationRoute
			path="/transportation/:id"
			exact
			component={TransportationViewRequest}
		/>

		<TransportationRoute
			path="/transportation/:id/:passenger_id"
			exact
			component={TransportationPassangerProfile}
		/>

		{/* End Transportation */}
		{/* Start GroundService */}
		<GroundServiceRoute
			path="/ground-service"
			exact
			component={GroundService}
		/>
		<GroundServiceRoute
			path="/ground-service/:id"
			exact
			component={GroundViewRequest}
		/>

		<GroundServiceRoute
			path="/ground-service/:id/:passenger_id"
			exact
			component={GroundPassangerProfile}
		/>
		<GroundServiceRoute
			path="/ActivityLogs"
			exact
			component={ActivityLogs}
		/>

		{/* End GroundService */}
		{/* Start Network */}
		<NetworkRoute path="/my-network" exact component={Network} />
		<NetworkRoute
			path="/my-network/:id/wallet"
			exact
			component={WalletNetwork}
		/>

		{/* End Network */}
	</Switch>
);

export default Routes;
