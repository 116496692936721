import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Card, CardBody, Collapse } from "reactstrap";
import { getListRooms } from "services/rateplan";
import Locale from "translations";
export default function RoomBookable({
	ratePlan,
	setRatePlanState,
	errors,
	setErrors,
	MealsPlan,
}) {
	const RatePlan = Locale;
	const { id, rateId } = useParams();
	const [listRooms, setListRooms] = useState([]);
	const [done, setDone] = useState(false);

	useEffect(() => {
		if (
			ratePlan.rooms.length > 0 &&
			listRooms.length > 0 &&
			done === false &&
			rateId
		) {
			let rooms = [...ratePlan.rooms];
			let listRoom = [...listRooms];
			listRoom.forEach((res) => {
				rooms.forEach((ele) => {
					if (+res.id === +ele.room_id) {
						res.checked = true;
					}
				});
			});
			setListRooms(listRoom);

			// to can know if page done
			setDone(true);
			let room = rooms.map((res) => res.room_id);
			setRatePlanState({
				...ratePlan,
				rooms: room,
			});
		}
	}, [done, listRooms, rateId, ratePlan, ratePlan.rooms, setRatePlanState]);

	useEffect(() => {
		if (listRooms.length === 0) {
			const fetchListRooms = async () => {
				const res = await getListRooms(id);

				setListRooms(res.data.data);
			};
			fetchListRooms();
		}
	}, [id, listRooms.length]);

	const addNewRoom = (e, index) => {
		let fix = [...listRooms];
		let rooms = [...ratePlan.rooms];
		let value = +e.target.value;
		let checked = e.target.checked;
		fix[index].checked = checked;
		setListRooms(fix);
		if (checked) {
			rooms.push(value);
		} else {
			rooms = rooms.filter((room) => +room !== value);
		}
		setRatePlanState({
			...ratePlan,
			rooms: rooms,
		});
	};

	return (
		<div className="row m-0">
			<div className="col-md-12 p-0 mt-3">
				<div
					className="rate-plan-header"
					onClick={() =>
						setRatePlanState({
							...ratePlan,
							isOpenRoomBookable: !ratePlan.isOpenRoomBookable,
						})
					}
				>
					{RatePlan.hotelLayout?.ratePlan?.whichRoomTypesWillBookable}

					<i
						className={`fas pos-arrow ${
							ratePlan.isOpenRoomBookable ? "fa-chevron-up " : "fa-chevron-down"
						}`}
					></i>
				</div>

				<Collapse isOpen={ratePlan.isOpenRoomBookable}>
					<Card>
						<CardBody>
							<div className="col-md-12 pt-2">
								{listRooms.length > 0
									? listRooms.map((res, index) => (
											<div class="form-check form-check-inline pl-2 pr-2">
												<input
													class="form-check-input"
													type="checkbox"
													id={res.id + "room"}
													value={res.id}
													checked={res.checked}
													onChange={(e) => addNewRoom(e, index)}
												/>
												<label class="form-check-label" for={res.id + "room"}>
													{res.custom_name != null
														? res.custom_name
														: res.name.name}
												</label>
											</div>
									  ))
									: ""}
							</div>
						</CardBody>
					</Card>
				</Collapse>
			</div>
		</div>
	);
}
