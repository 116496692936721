import moment from "moment";
import React, { useEffect, useState } from "react";
import Filter from "./Filter";
import { fetchCountries } from "services/lookups";
import Pagination from "components/shared/Pagination";
import TableContent from "./TableContent";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { listWaitingVisaRequests } from "services/visa";
import Locale from "translations";
import { getListGround } from "services/ground";

export default function GroundRequest() {
	const { requests } = Locale;
	const { status } = useParams();
	const [groundList, setGroundList] = useState([]);
	const [countries, setCountries] = useState([]);
	const locale = localStorage.getItem("currentLocale") || "en";
	const [meta, setMeta] = useState();
	const [filter, setFilter] = useState({
		destination_id: null,
		company_name: null,
		request_date: null,
		page: 1,
	});
	const [visaType, setvisaType] = useState([]);

	const visaTypeOption = [];
	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchCountries();
			const format = res?.data.map((t) => ({
				value: t.id,
				label: t.names[locale],
			}));
			setCountries(format);

			const formatVisa = visaTypeOption.map((t) => ({
				value: t.id,
				label: t.name[locale],
			}));
			setvisaType(formatVisa);
		}
		fetchLookups();
	}, []);

	useEffect(() => {
		const fetchGroundList = async () => {
			const response = await getListGround({
				...filter,
				destination_id: filter.destination_id?.value,
				//visa_type_id: filter.visa_type_id?.value,
				company_name: filter.company_name,
				request_date: filter.request_date
					? moment(filter.request_date).format("YYYY-MM-DD")
					: "",
				//status: "waiting",
			});
			if (response.status === 200 || response.status === 201) {
				setGroundList(response.data.data);
				setMeta(response.data.meta);
			}
		};
		fetchGroundList();
	}, [filter]);

	const listRequests =
		groundList?.length > 0 ? (
			groundList?.map((item, index) => {
				return <TableContent data={item} key={index} />;
			})
		) : (
			<tr>
				<td colSpan="10" className="text-center my-3">
					<div className="product-build__product-no-data">
						<i className="fas fa-info-circle fa-lg"></i> <h4>No Result</h4>
					</div>
				</td>
			</tr>
		);

	const goTo = (page) => {
		setFilter({ ...filter, page: page });
	};
	return (
		<div>
			<div className="row m-0 p-0 ">
				<div className="col-12 d-flex flex-column align-items-end  justify-content-start">
					<Filter
						filter={filter}
						setFilter={setFilter}
						countries={countries}
						visaType={visaType}
					/>
				</div>
				<div className="w-100">
					<div class="card-body p-0">
						<div class="table-responsive">
							<table className="table mb-0 f-15">
								<thead className="table-light">
									<tr className="">
										<th>ID</th>
										<th>{requests.Destination}</th>
										{/* <th>{requests.cycleType}</th> */}
										<th>
											<i class="fas fa-sort"></i> {requests.Pax}
										</th>
										<th>
											<i class="fas fa-sort"></i>
											{requests.Departure}
										</th>
										<th>
											<i class="fas fa-sort"></i>
											{requests.Return}
										</th>
										<th>
											<i class="fas fa-sort"></i> {requests.RequestDate}
										</th>
										<th>{requests.Status}</th>
										<th scope="col"></th>
									</tr>
								</thead>
								<tbody>{listRequests}</tbody>
							</table>{" "}
						</div>
					</div>
				</div>
				<div className="col-12"> </div>
				<Pagination info={meta} goTo={goTo} />
			</div>
		</div>
	);
}
