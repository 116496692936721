import React, { useEffect, useState } from "react";
import Locale from "../../translations";
import { useHistory } from "react-router-dom";
//import UserNumber from "./userNumber";
// import Filter from "./filter";
import { Link, NavLink } from "react-router-dom";
import FilterGroup from "./filterGroup";
import Model from "components/model";
import TextField from "components/shared/TextField";
import SelectField from "components/shared/SelectField";
import Collaps from "./Collapse";
import {
	addTeam,
	deleteGroup,
	getGroupPermissions,
	getGroups,
	getListGroups,
} from "services/TeamManagement";
import { locale } from "moment";
import validate, { isFormValid } from "helpers/validate";
import { store } from "react-notifications-component";
import Pagination from "components/shared/Pagination";
import generateUniqueID from "helpers/generateUniqueID";
import ConfirmModal from "components/Modals/ConfirmModal";
import { getNotification } from "helpers/makeNotifications";
import ShowForPermission from "helpers/showForPermission";
// import Users from "./users";

function Groups() {
	const { teamManagement } = Locale;
	let history = useHistory();
	const [users, setUsers] = useState([]);
	const [searching, setSearching] = useState(false);
	const [selectCollapse, setSelectCollapse] = useState();

	const [filter, setfilter] = useState({
		search: "",
		paginated: 1,
		page: 1,
	});
	const [modalShow, setModalShow] = useState(false);
	const locale = localStorage.getItem("currentLocale") || "en";
	const [confirmIsOpen, setConfirmIsOpen] = useState(false);
	const toggleConfirm = () => {
		setConfirmIsOpen(!confirmIsOpen);
	};
	const toggleModalShow = (id) => {
		//setSeletedId(id)
		setModalShow(!modalShow);
		setTeamMangament({ permissions: [] });
	};

	const [Groups, setGroups] = useState([]);
	const [deleteState, setDeleteState] = useState(false);
	const [team, setTeam] = useState([]);
	const [teamMangament, setTeamMangament] = useState({ permissions: [] });
	const [meta, setMeta] = useState([]);
	const goTo = async (page) => {
		setfilter({ ...filter, page: page }); //page.length < 3 ? Number(page):page
	};
	useEffect(() => {
		async function fetchListUser() {
			const res = await getGroups({ ...filter });
			setGroups(res?.data?.data);
			setMeta(res?.data?.meta);
		}
		fetchListUser();
	}, [filter, deleteState]);

	const [permissions, setPermissions] = useState([]);
	useEffect(() => {
		if (modalShow) {
			async function fetchGroupPermissions() {
				const res = await getGroupPermissions();
				setPermissions(res?.data?.data);
				//setMeta(res?.data?.meta);
			}
			fetchGroupPermissions();
		}
	}, [modalShow]);

	useEffect(() => {
		async function fetchGroups() {
			const res = await getListGroups();
			const format3 = res?.data?.data?.map((t) => ({
				value: t.id,
				label: t.name,
			}));
			setTeam(format3);
		}
		fetchGroups();
	}, []);

	const AllGroups =
		Groups && Groups?.length > 0
			? Groups?.map((Group, index) => (
					<Collaps
						Group={Group}
						index={index}
						setDeleteState={setDeleteState}
						confirmIsOpen={confirmIsOpen}
						setConfirmIsOpen={setConfirmIsOpen}
						toggleConfirm={toggleConfirm}
						setSelectCollapse={setSelectCollapse}
					/>
			  ))
			: "";

	const handleCheckboxChange = (event) => {
		const name = event.target.name;
		if (event.target.checked === true) {
			setTeamMangament({
				...teamMangament,
				[name]: [...teamMangament[name], Number(event.target.value)],
			});
		} else {
			setTeamMangament({
				[name]: [
					...teamMangament[name].filter(
						(e) => e !== Number(event.target.value)
					),
				],
			});
		}
	};
	/*****Add Team */
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setTeamMangament({ ...teamMangament, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			setTeamMangament({ ...teamMangament, [name]: value });
		}
	};
	const checkFormErrors = () => {
		setErrors({
			...errors,
			// ...validate(
			// 	{
			// 		name: "fullName",
			// 		value: teamMangament.fullName,
			// 	},
			// 	{
			// 		required: true,
			// 	}
			// ),
			...validate(
				{
					name: "name",
					value: teamMangament.name,
				},
				{
					required: true,
				}
			),
		});
	};
	const handleForm = async () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};
	useEffect(() => {
		const addNewTeam = async () => {
			if (isFormValid(errors)) {
				const res = await addTeam({
					...teamMangament,
				});

				if (res.status == 200 || res.status == 201) {
					store.addNotification({
						title: "info!",
						message: res.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
					});
					toggleModalShow(null);
					window.location.reload();
				}
			}
		};
		addNewTeam();
	}, [isErrorLoaded]);
	let IND = 0;

	const removeGroup = async () => {
		let data = {
			id: selectCollapse?.id,
		};
		const res = await deleteGroup(data);
		if (res.status == 200) {
			getNotification(res?.message);
			setDeleteState((prev) => !prev);
			setConfirmIsOpen(false);
		} else {
			setConfirmIsOpen(false);
		}
	};
	function checkAllPermession(event, permissionName) {
		let permissionsIds = permissions[permissionName].map(
			(permission) => permission.id
		);
		if (event.target.checked === true) {
			setTeamMangament({
				...teamMangament,
				permissions: [
					...new Set([...teamMangament.permissions, ...permissionsIds]),
				],
			});
		} else {
			// remove all selected boxes
			setTeamMangament({
				...teamMangament,
				permissions: [
					...teamMangament.permissions.filter(
						(permission) => !permissionsIds.includes(permission)
					),
				],
			});
		}
	}

	function permissionChecked(permissionName) {
		let checked = [];
		checked = permissions[permissionName].map((permission) =>
			teamMangament.permissions.includes(permission.id)
		);
		return checked.includes(true);
	}

	return (
		<section className="group px-5">
			<div className="container-fluid  p-0 team-management-tabs">
				<div className="d-flex align-items-center justify-content-between fl-border bg-white mt-5 p-2 head team-management-tabs">
					<FilterGroup
						filter={filter}
						setfilter={setfilter}
						Groups={Groups}
						setGroups={setGroups}
					/>
					<ShowForPermission permission="manage-team">
						<button
							type="button"
							className="btn btn-primary  add-user-btn "
							onClick={() => {
								setModalShow(!modalShow);
							}}
						>
							<i className="fas fa-plus-circle mr-2"></i>
							{teamManagement.addTeam}
						</button>
					</ShowForPermission>
				</div>
				<div className="grop-box">{AllGroups}</div>
				<Pagination info={meta} goTo={goTo} items={10} />
			</div>
			{/******  Model Add Team ****** */}

			<div>
				<Model
					isOpen={modalShow}
					title={teamManagement.addTeam}
					toggle={() => {
						toggleModalShow(null);
					}}
					hasFooter={false}
					className="bulk-edit team-model"
					// size="lg"
				>
					<div className="py-3  team-model-content ">
						<div className="row">
							{/* <div className="col-md-12 ">
								<TextField
									label={teamManagement.fullName}
									placeholder={teamManagement.enterFullName}
									name="fullName"
									onChange={(e) => {
										setTeamMangament({
											...teamMangament,
											fullName: e.target.value,
										});
									}}
								/>
							</div> */}

							<div className="col-md-12 ">
								{/* <SelectField
									label={teamManagement.team}
									placeholder={teamManagement.enterTeam}
									name="name"
									//multi={true}
									options={team}
									onChange={(e) => {
										handleChange({ name: "name", value: e });
										setErrors({
											...errors,
											...validate(
												{ name: "name", value: e },
												{
													required: true,
												}
											),
										});
									}}
									value={teamMangament?.name?.name}
								/> */}
								<TextField
									label={teamManagement.team}
									placeholder={teamManagement.enterTeam}
									name="name"
									onChange={(e) => {
										setTeamMangament({
											...teamMangament,
											name: e.target.value,
										});
									}}
									color={errors?.name?.required ? "danger" : ""}
									errors={errors?.name}
								/>
							</div>

							<div class="col-md-12">
								<div class="form-group full-input">
									<h6
										class="p-1 font-weight-bold text-uppercase text-caption"
										style={{ textAlign: "start" }}
									>
										{teamManagement.Permission}
									</h6>
									<div class="form-group full-input px-0 pb-1">
										{Object.keys(permissions).map((res) => {
											return (
												<>
													{" "}
													<p
														className="mt-1 mb-1"
														style={{ textAlign: "start" }}
													>
														<input
															type="checkbox"
															name={res}
															id={res}
															className="form-check-input"
															onChange={(event) =>
																checkAllPermession(event, res)
															}
															checked={permissionChecked(res)}
														/>
														<label
															class="form-check-label pointer text-caption mx-2"
															htmlFor={res}
														>
															{res}
														</label>
													</p>
													{permissions[res].map((user, index) => {
														let x = generateUniqueID();
														return (
															<div
																class="form-check"
																key={index}
																style={{ textAlign: "start" }}
															>
																<input
																	type="Checkbox"
																	name="permissions"
																	className="form-check-input"
																	onChange={handleCheckboxChange}
																	value={user?.id}
																	checked={teamMangament.permissions?.includes(
																		user.id
																	)}
																	id={`User-Management-${(index + 1, x)}`}
																	data-parent={res}
																/>
																<label
																	class="form-check-label pointer text-caption mx-2"
																	for="exampleCheck1"
																	htmlFor={`User-Management-${(index + 1, x)}`}
																>
																	{user?.display_name[locale]}
																</label>
															</div>
														);
													})}
												</>
											);
										})}
									</div>
								</div>
							</div>
						</div>

						<div className="d-flex justify-content-end py-2">
							<button className="btn btn-primary mx-1 " onClick={handleForm}>
								{teamManagement.add}
							</button>
							<button
								className="btn btn-secondary mx-1"
								onClick={() => {
									toggleModalShow(null);
								}}
							>
								{" "}
								{teamManagement.cancel}
							</button>
						</div>
					</div>
				</Model>
			</div>
			{/******* Model Add Team ***** */}

			<ConfirmModal
				IsOpen={confirmIsOpen}
				toggle={toggleConfirm}
				onConfirm={removeGroup}
				message={"Confirm deletion of the Group !"}
			/>
		</section>
	);
}

export default Groups;
