import { store } from "react-notifications-component";

export const getNotification = (massage) => {
	store.addNotification({
		title: "Info!",
		message: massage ? massage : "done successfully",
		type: "success",
		insert: "top",
		container: "top-right",
		animationIn: ["animated", "fadeIn"],
		animationOut: ["animated", "fadeOut"],
		dismiss: {
			duration: 3000,
			onScreen: true,
			pauseOnHover: true,
		},
	});
};
