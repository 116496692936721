import reservationError from "assets/images/inventory/error.png";
import { useGlobalState, useGlobalDispatch } from "context/global";
import { Modal, ModalBody } from "reactstrap";
import Locale from "translations";

export default function ErrorModal() {
	const { isError, ErrorMassage } = useGlobalState();
	const { commons, inventory } = Locale;

	const dispatch = useGlobalDispatch();

	const closeModal = () => {
		dispatch({ type: "setError", payload: false });
	};

	return isError ? (
		<>
			<div
				id="myModal"
				class="modal fade show"
				tabindex="-1"
				aria-labelledby="myModalLabel"
				aria-modal="true"
				role="dialog"
				style={{
					display: "block",
					background: "#0000004f",
					zIndex: "999999999999999999999999999999999",
				}}
			>
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content" style={{ border: "none" }}>
						<div
							class="alert alert-danger alert-dismissible
                                    fade show px-4  mb-0 text-center"
							role="alert"
						>
							<i
								class="mdi mdi-block-helper d-block
                                        display-4 mt-2 mb-3 text-danger"
							></i>
							<h5 class="text-danger">
								{" "}
								{ErrorMassage?.title
									? ErrorMassage?.title
									: commons.somethingWentWrong}
							</h5>
							<p>
								{" "}
								{ErrorMassage?.body
									? ErrorMassage?.body
									: commons.somethingWentWrong}
							</p>
							<button
								onClick={() => {
									closeModal();
								}}
								type="button"
								class="btn-close"
								data-bs-dismiss="alert"
								aria-label="Close"
							></button>
						</div>
					</div>
				</div>
			</div>
		</>
	) : (
		""
	);
}
