import TextField from "components/shared/TextField";
import { formatAddRatePlan, formatRatePlan } from "data/rateplan";
import validate, { isFormValid } from "helpers/validate";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Link, useHistory, useParams } from "react-router-dom";
import { addRatePlan, editRatePlan, getRatePlan } from "services/rateplan";
import Locale from "translations";
import CancellationPolicy from "./CancellationPolicy";
import ConfirmationTypes from "./ConfirmationTypes";
import ManageRatePlan from "./ManageRatePlan";
import Meals from "./Meals";
import MinimumLengthRatePlan from "./MinimumLengthRatePlan";
import PaymentPolicy from "./PaymentPolicy";
import RoomBookable from "./RoomBookable";
import Notes from "./Notes";
import { fetchCurrencies, fetchCurrenciesproperty } from "services/lookups";
import SelectField from "components/shared/SelectField";

export default function AddRatePlan() {
	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	const history = useHistory();
	const { id, rateId } = useParams();
	const [CurrencyList, setCurrencyList] = useState([]);
	const { propertyDetails, commons } = Locale;
	const [ratePlan, setRatePlanState] = useState({
		Buffet: [],
		name: "",
		isOpenPolicy: true,
		isOpenMeals: true,
		manage_rate_type: true,
		isOpenNotes: true,
		check_in_day: null,
		cancellation_type: "3",
		cancellation: [{}],
		isOpenPayment: true,
		isOpenMinimumLengthRate: true,
		isOpenManageRate: true,
		isOpenRoomBookable: true,
		isOpenConfirmationTypes: true,
		rooms: [],
		paymentConditionsList: [
			{
				payment_policy_type_id: null,
				days: null,
				charge: null,
			},
		],
		payment_policy: 1,
		minimum_length_night: undefined,
	});
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const handleCreate = async () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	// fetch Currencies
	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchCurrenciesproperty(id);

			const format = res?.data?.data.map((t) => ({
				value: t.currency_code,
				label: t.currency_code,
			}));
			setCurrencyList(format);
		}
		fetchLookups();
	}, []);

	// ----------------------------------------------------------------------------------------------------
	// Component Functions
	// ----------------------------------------------------------------------------------------------------

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "name", value: ratePlan.name },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "cancellation_type", value: ratePlan.cancellation_type },
				{ required: true }
			),

			...validate({ name: "meal", value: ratePlan.meal }, { required: true }),
			...validate(
				{
					name: "minimum_length",
					value: ratePlan.minimum_length == "0" ? 1 : ratePlan.minimum_length,
				},
				{ required: true }
			),
			...validate(
				{
					name: "minimum_length_night",
					value:
						ratePlan.minimum_length === "1"
							? "1"
							: ratePlan.minimum_length_night,
				},
				{ required: ratePlan.minimum_length === "1" ? false : true }
			),
		});
	};

	const OwnsProperty = [
		{
			label: "Management Company",
			value: 1,
		},
		{
			label: "Property Management",
			value: 2,
		},
		{
			label: "Property Owner",
			value: 3,
		},
	];

	const ConditionsPaymentPolicyType = [
		{
			label: "on confirmation",
			value: 1,
		},
		{
			label: "before check in",
			value: 2,
		},
	];

	const MealsPlan = [
		{
			label: "Per Pax",
			value: 1,
		},
		{
			label: "Per Room",
			value: 2,
		},
	];

	const isPaymentPolicyValid = () => {
		if (ratePlan.payment_policy == 1) return true;
		const percentages = ratePlan.paymentConditionsList.map(
			(condition) => condition.charge
		);
		let sum = +percentages.reduce((x, y) => +x + +y);
		if (ratePlan.payment_policy == 2) {
			if (sum === 100) {
				return true;
			}
		}
		store.addNotification({
			title: "Error!",
			message: `The total summation of the percentage of Payment Conditions (on confirmation + before check-in) should equal 100% got ${sum}%`,
			type: "danger",
			insert: "top",
			container: "top-right",
			animationIn: ["animated", "fadeIn"],
			animationOut: ["animated", "fadeOut"],
			dismiss: {
				duration: 3000,
				onScreen: true,
				pauseOnHover: true,
			},
		});
	};

	useEffect(() => {
		const AddRatePlan = async () => {
			if (isFormValid(errors)) {
				const data = formatAddRatePlan(ratePlan);
				// to fix add include meals
				if (ratePlan.meal != 3) {
					delete data.meals["exclude"];
				}

				let response;

				if (rateId && ratePlan.id) {
					response = await editRatePlan(id, ratePlan.id, data);
				} else {
					response = await addRatePlan(id, data);
				}
				if (response.status === 201 || response.status === 200) {
					history.push(`/dashboard/list-Rate-Plan/${id}`);

					store.addNotification({
						title: "Info!",
						message: response.statusText,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			}
		};
		AddRatePlan();
	}, [isErrorLoaded]);

	useEffect(() => {
		if (rateId !== undefined && id !== undefined && ratePlan.name === "") {
			const getRateDetails = async () => {
				const response = await getRatePlan(id, rateId);
				if (response.status === 200) {
					const data = formatRatePlan(response.data);
					data.cancellation.sort(
						(a, b) => b.days_before_arrival - a.days_before_arrival
					);

					setRatePlanState({ ...ratePlan, ...data });
				}
			};
			getRateDetails();
		}
	}, [id, rateId]);

	// ----------------------------------------------------------------------------------------------------
	// Main Component Body UI
	// ----------------------------------------------------------------------------------------------------
	if (true) {
		return (
			<>
				<div className="container">
					<div className="col-md-12 p-0 mt-3 mb-5">
						<div className="row m-0">
							{/* Full Name */}
							<div className="col-8 p-0">
								<TextField
									type="text"
									label={propertyDetails.Whatdoyouwanttocallthisrateplan}
									placeholder={propertyDetails.Whatdoyouwanttocallthisrateplan}
									value={ratePlan.name}
									name="name"
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
											}),
										});
										setRatePlanState({
											...ratePlan,
											name: e.target.value,
										});
									}}
									color={errors?.name?.required ? "danger" : ""}
									errors={errors?.name}
								/>
							</div>
							<div className="col-2">
								<SelectField
									type="text"
									label={"currency"}
									placeholder={"currency"}
									value={ratePlan.currency}
									options={CurrencyList}
									name="name"
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(e, {
												required: true,
											}),
										});
										setRatePlanState({
											...ratePlan,
											currency: e,
										});
									}}
									color={errors?.currency?.required ? "danger" : ""}
									errors={errors?.currency}
								/>
							</div>
						</div>

						<CancellationPolicy
							ratePlan={ratePlan}
							setRatePlanState={setRatePlanState}
							errors={errors}
							setErrors={setErrors}
							OwnsProperty={OwnsProperty}
						/>

						<PaymentPolicy
							ratePlan={ratePlan}
							setRatePlanState={setRatePlanState}
							errors={errors}
							setErrors={setErrors}
							ConditionsPaymentPolicyType={ConditionsPaymentPolicyType}
						/>

						<Meals
							ratePlan={ratePlan}
							setRatePlanState={setRatePlanState}
							errors={errors}
							setErrors={setErrors}
							MealsPlan={MealsPlan}
						/>

						<ConfirmationTypes
							ratePlan={ratePlan}
							setRatePlanState={setRatePlanState}
							errors={errors}
							setErrors={setErrors}
							MealsPlan={MealsPlan}
						/>

						<MinimumLengthRatePlan
							ratePlan={ratePlan}
							setRatePlanState={setRatePlanState}
							errors={errors}
							setErrors={setErrors}
							MealsPlan={MealsPlan}
						/>

						<ManageRatePlan
							ratePlan={ratePlan}
							setRatePlanState={setRatePlanState}
							errors={errors}
							setErrors={setErrors}
							MealsPlan={MealsPlan}
						/>

						<RoomBookable
							ratePlan={ratePlan}
							setRatePlanState={setRatePlanState}
							errors={errors}
							setErrors={setErrors}
							MealsPlan={MealsPlan}
						/>

						<Notes
							ratePlan={ratePlan}
							setRatePlanState={setRatePlanState}
							errors={errors}
							setErrors={setErrors}
							MealsPlan={MealsPlan}
						/>

						<div className="row mt-3 justify-content-between m-0">
							<div className="col-md-6"></div>
							<div className="col-md-2">
								<Link
									to={`/dashboard/list-Rate-Plan/${id}`}
									className="btn btn-light btn-light-new w-100"
								>
									<span className="icx icx-left-solid-arrow text-10"></span>{" "}
									{commons.back}
								</Link>
							</div>
							<div className="col-md-4 p-0">
								<button
									className="btn btn-primary w-100"
									onClick={() => handleCreate()}
								>
									{commons.save}
								</button>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	} else {
		// return <Loader message={loaderMessage} />;
	}
}
