import axios from "axios";
import { cleanEmpty } from "services/general";
const basicUrl = process.env.REACT_APP_API_URL;

export const addPromotionsBasic = async (HotelId, data) => {
	return await axios
		.post(
			`${basicUrl + "/api/promotions/" + HotelId + "/basic"}`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err);
};
export const addPromotionsRoom = async (HotelId, data) => {
	return await axios
		.post(
			`${basicUrl + "/api/promotions/" + HotelId + "/free"}`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err);
};
export const getListRatePlanDp = async (HotelId) => {
	return await axios
		.get(`${basicUrl + "/api/promotions/" + HotelId + "/rate-plans"}`)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const getRoomsDp = async (HotelId, ratePlanIds) => {
	return await axios
		.post(`${basicUrl + "/api/promotions/" + HotelId + "/rate-plans/rooms"}`, {
			rooms: ratePlanIds,
		})
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const listPromotionsBasic = async (HotelId) => {
	return await axios
		.get(`${basicUrl + "/api/promotions/" + HotelId + "/basic"}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listPromotionsRoom = async (HotelId) => {
	return await axios
		.get(`${basicUrl + "/api/promotions/" + HotelId + "/free"}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getPromotionsDetails = async (HotelId, promotionId) => {
	return await axios
		.get(`${basicUrl + "/api/promotions/" + HotelId + "/basic/" + promotionId}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getPromotionsFree = async (HotelId, promotionId) => {
	return await axios
		.get(`${basicUrl + "/api/promotions/" + HotelId + "/free/" + promotionId}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editPromotionsBasic = async (HotelId, promotionId, data) => {
	return await axios
		.post(
			`${basicUrl + "/api/promotions/" + HotelId + "/basic/" + promotionId}`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editPromotionsFree = async (HotelId, promotionId, data) => {
	return await axios
		.post(
			`${basicUrl + "/api/promotions/" + HotelId + "/free/" + promotionId}`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};
