import { useGlobalDispatch } from "context/global";
import { usePropertyState } from "context/property";
import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { store } from "react-notifications-component";
import { useParams } from "react-router";
import { addPhotos } from "services/property";
import CancelModal from "./CancelModal";
import Photo from "./Photo";

function Photos() {
	const { id } = useParams();
	const dispatch = useGlobalDispatch();
	const { propertyDetails } = usePropertyState();
	const slides = useRef(null);
	// local photos
	const [photos, setPhotos] = useState([]);
	const [gallery, setGallery] = useState([]);
	const [disabled, setDisabled] = useState(true);
	const [canceled, setCanceled] = useState(false);

	const handleUpload = (e) => {
		const files = e.target.files;
		const urls = [];
		for (let i = 0; i < files.length; i++) {
			const url = window.URL.createObjectURL(files[i]);
			urls.push({
				id: i + Math.random(),
				image: url,
				uploaded: false,
				fileName: files[i].name,
			});
		}
		// const url = window.URL.createObjectURL(files[0]);
		setGallery([...gallery, ...urls]);
		if (files && files != undefined) {
			setPhotos([...photos, ...files]);
		}
	};

	useEffect(() => {
		if (id && propertyDetails?.property?.photos) {
			const formatted = propertyDetails.property.photos.map(
				(photo) =>
					(photo = {
						id: photo.id,
						image: photo.image,
						uploaded: true,
						is_main: photo.is_main,
					})
			);
			setGallery(formatted);
		}
	}, [propertyDetails]);

	const isPhotosValid = () => {
		return true;
	};

	//

	const upload = async () => {
		if (isPhotosValid()) {
			// if (
			// 	id &&
			// 	propertyDetails.hotel?.photos?.length > 0 &&
			// 	photos.length === 0
			// ) {
			// 	dispatch({ type: "progress", step: "6" });
			// } else {
			if (photos.length > 0) {
				const formData = new FormData();
				for (let i = 0; i < photos.length; i++) {
					formData.append(`photos[${i}]`, photos[i]);
				}
				const res = await addPhotos(id, formData);
				if (res?.status === 201 || res?.status === 200) {
					setPhotos([]);
					setDisabled(false);
					const newGallery = Object.entries(res.data.photos).map(
						([key, value]) => ({
							id: key,
							image: value,
							uploaded: true,
						})
					);
					setGallery(
						[...gallery, ...newGallery].filter((photo) => photo.uploaded)
					);
					// dispatch({ type: "progress", step: "6" });
				}
			}
			// }
		} else {
			store.addNotification({
				title: "info!",
				message: "Invalid",
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	useEffect(() => {
		if (id && photos.length === 0) {
			setDisabled(false);
		} else if (photos.length > 0) {
			setDisabled(true);
			if (gallery.length > 3) {
				slides.current.goToSlide(gallery.length - 3);
			}
		}
	}, [photos]);

	const submit = () => {
		//upload();
		dispatch({ type: "progress", step: "6" });
	};

	const reset = () => {
		setCanceled(true);
	};

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 5,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};

	return (
		<div>
			<CancelModal isOpen={canceled} toggle={setCanceled} />
			<div className="header-box my-3">Photo Gallery</div>
			{gallery.length > 0 ? (
				<div className="p-3 text-center d-flex justify-content-between align-items-center position-relative upload-images">
					<h5 className="text-blue-black font-weight-bold">
						Drag and Drop your photos here
					</h5>
					<div className="d-flex">
						<p className="gray py-1 mx-3">or</p>
						<label
							onChange={handleUpload}
							for="uploadFile"
							className="btn btn-primary text-white px-4"
						>
							<i className="far fa-images px-2 fa-lg"></i>
							Upload Photo
						</label>
						<input
							multiple="multiple"
							onChange={handleUpload}
							type="file"
							id="uploadFile"
							accept=".png, .jpg, .jpeg"
							// className="d-none"
						/>
					</div>
				</div>
			) : (
				<div className="bg-white p-3 text-center  position-relative upload-images">
					<h5 className="text-blue-black font-weight-bold">
						Upload at least 1 photo
					</h5>
					<p className="gray mb-3">
						{" "}
						You will also be able to upload more after registration
					</p>
					<h5 className="text-blue-black font-weight-bold">
						Drag and Drop your photos here
					</h5>
					<p className="gray py-1">or</p>
					<label
						for="uploadFile"
						onChange={handleUpload}
						className="btn btn-primary text-white px-4"
					>
						<i className="far fa-images px-2 fa-lg"></i>
						Upload Photo
					</label>
					<input
						multiple="multiple"
						onChange={handleUpload}
						type="file"
						id="uploadFile"
						accept=".png, .jpg, .jpeg"
					/>
				</div>
			)}

			{/* <div className="gallery d-flex row"> */}
			<Carousel ref={slides} responsive={responsive} className="gallery">
				{gallery.length > 0 &&
					gallery.map((photo, i) => {
						return (
							<div className="col-md-12 my-4">
								<Photo
									key={i}
									photo={photo}
									gallery={gallery}
									setPhotos={setPhotos}
									photos={photos}
									setGallery={setGallery}
								/>
							</div>
						);
					})}
			</Carousel>
			{/* </div> */}
			<div className="row my-5 mx-0 justify-content-between">
				<button
					className="btn btn-primary mx-2"
					onClick={upload}
					id="cancel-photo"
					disabled={photos.length === 0}
				>
					Upload
				</button>
				<div>
					<button className="btn mx-2 cancel" onClick={reset} id="cancel-photo">
						cancel
					</button>
					<button
						onClick={submit}
						className="btn btn-primary px-5"
						id="submit-photo"
						disabled={disabled}
					>
						continue
					</button>
				</div>
			</div>
		</div>
	);
}

export default Photos;
