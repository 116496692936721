import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import validate from "helpers/validate";
import { Card, CardBody, Collapse } from "reactstrap";
import Locale from "translations";

export default function MinimumLengthRatePlan({
	ratePlan,
	setRatePlanState,
	errors,
	setErrors,
}) {
	const RatePlan = Locale;

	return (
		<div className="row m-0">
			<div
				className={`col-md-12 p-0 mt-3 ${
					errors?.minimum_length?.required ? "danger-checkbox" : ""
				}`}
			>
				<div
					className="rate-plan-header"
					onClick={() =>
						setRatePlanState({
							...ratePlan,
							isOpenMinimumLengthRate: !ratePlan.isOpenMinimumLengthRate,
						})
					}
				>
					{RatePlan.hotelLayout?.ratePlan?.WantSetMinimumLength}
					<i
						className={`fas pos-arrow ${
							ratePlan.isOpenMinimumLengthRate
								? "fa-chevron-up "
								: "fa-chevron-down"
						}`}
					></i>
				</div>

				<Collapse isOpen={ratePlan.isOpenMinimumLengthRate}>
					<Card>
						<CardBody>
							<div className="col-md-12 pt-2">
								<div class="form-check form-check-inline pl-2 pr-2">
									<input
										class="form-check-input"
										type="radio"
										name="payment_policy789"
										id="minimum_length"
										value="1"
										checked={ratePlan.minimum_length == "1"}
										onChange={(e) => {
											setRatePlanState({
												...ratePlan,
												minimum_length: e.target.value,
											});
											setErrors({
												...errors,
												...validate(
													{
														name: "minimum_length",
														value: e.target.value,
													},
													{ required: true }
												),
											});
										}}
									/>
									<label class="form-check-label" for="minimum_length">
										No (this rate plan is available for any length of stay)
									</label>
								</div>
								<div class="form-check form-check-inline pl-2 pr-2">
									<input
										class="form-check-input"
										type="radio"
										name="payment_policy789"
										id="minimum_length1"
										value="0"
										checked={ratePlan.minimum_length == "0"}
										onChange={(e) => {
											setRatePlanState({
												...ratePlan,
												minimum_length: e.target.value,
											});
											setErrors({
												...errors,
												...validate(
													{
														name: "minimum_length",
														value: e.target.value == "0" ? 1 : e.target.value,
													},
													{ required: true }
												),
											});
										}}
									/>
									<label class="form-check-label" for="minimum_length1">
										Yes
									</label>
								</div>
								{errors?.minimum_length?.required ? (
									<div className="col-md-12 text-danger danger-massage">
										set a minimum length of stay for this rate plan
									</div>
								) : (
									""
								)}

								{ratePlan?.minimum_length === "0" ? (
									<>
										<div className="row">
											<div className="col-md-6">
												<TextField
													label={"Number of night minimum stay Type Days"}
													placeholder={"Number of night minimum stay Type Days"}
													value={ratePlan.minimum_length_night}
													type={"number"}
													name="minimum_length_night"
													onChange={(e) => {
														setErrors({
															...errors,
															...validate(e.target, {
																required: true,
															}),
														});
														setRatePlanState({
															...ratePlan,
															minimum_length_night: e.target.value,
														});
													}}
													min={3}
													color={
														errors?.minimum_length_night?.required
															? "danger"
															: ""
													}
													errors={errors?.minimum_length_night}
												/>
											</div>

											<div className="col-2">
												<SelectField
													type="text"
													label={"check in day"}
													placeholder={"check in day"}
													value={ratePlan.check_in_day}
													options={[
														{ label: "saturday", value: "saturday" },
														{ label: "sunday", value: "sunday" },
														{ label: "monday", value: "monday" },
														{ label: "tuesday", value: "tuesday" },
														{ label: "thursday", value: "thursday" },
														{ label: "friday", value: "friday" },
													]}
													name="name"
													onChange={(e) => {
														setErrors({
															...errors,
															...validate(e, {
																required: true,
															}),
														});
														setRatePlanState({
															...ratePlan,
															check_in_day: e,
														});
													}}
													isInvalid={true}
													color={errors?.check_in_day?.required ? "danger" : ""}
													errors={errors?.check_in_day}
													MessageHint={"Share on special allotments"}
												/>
											</div>
										</div>
									</>
								) : (
									""
								)}
							</div>
						</CardBody>
					</Card>
				</Collapse>
			</div>
		</div>
	);
}
