import { useEffect, useState } from "react";
import { fetchDashboard } from "services/dashborad";

export default function Dashboard() {
	const [statistics, setStatistics] = useState({});

	useEffect(() => {
		async function getDashborad() {
			const res = await fetchDashboard();
			if (res.status == 200) {
				setStatistics(res.data);
			}
		}
		getDashborad();
	}, []);

	return Object.keys(statistics).length > 0 ? (
		<>
			<div class="container-fluid Dashboard">
				<div class="row">
					<div class="col-12">
						<div
							class="page-title-box d-sm-flex align-items-center
              justify-content-between"
						>
							<h4 class="mb-sm-0 font-size-18">Dashboard</h4>

							<div class="page-title-right">
								<ol class="breadcrumb m-0">
									<li class="breadcrumb-item">
										<a
											href="javascript:
                              void(0);"
										>
											Minia
										</a>
									</li>
									<li class="breadcrumb-item active">Dashboard</li>
								</ol>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-xl-4 col-md-6">
						<div class="card card-h-100">
							<div class="card-body">
								<div class="row align-items-center">
									<div class="text-nowrap col-md-12">
										<p style={{ fontWeight: "900", fontSize: "20px" }}>
											Hotels
										</p>
									</div>
									<div class="col-3 text-center">
										<span
											class="text-muted mb-3 lh-1 d-block
                              text-truncate"
										>
											In progress
										</span>
										<h4 class="mb-3 text-warning">
											<span
												class="counter-value"
												data-target={statistics?.hotels?.in_progress}
											>
												{statistics?.hotels?.in_progress}
											</span>
										</h4>
									</div>
									<div class="col-3 text-center">
										<span
											class="text-muted mb-3 lh-1 d-block
                              text-truncate"
										>
											Verified
										</span>
										<h4 class="mb-3 text-info">
											<span
												class="counter-value"
												data-target={statistics?.hotels?.verified}
											>
												{statistics?.hotels?.verified}
											</span>
										</h4>
									</div>
									<div class="col-3">
										<span
											class="text-muted mb-3 text-center lh-1 d-block
                              text-truncate"
										>
											Active
										</span>
										<h4 class="mb-3 text-primary text-center">
											<span
												class="counter-value"
												data-target={statistics?.hotels?.active}
											>
												{statistics?.hotels?.active}
											</span>
										</h4>
									</div>

									<div class="col-3">
										<span
											class="text-muted mb-3 text-center lh-1 d-block
                              text-truncate"
										>
											Shared
										</span>
										<h4 class="mb-3 text-success text-center">
											<span
												class="counter-value"
												data-target={statistics?.hotels?.shared}
											>
												{statistics?.hotels?.shared}
											</span>
										</h4>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-xl-2 col-md-6">
						<div class="card card-h-100">
							<div class="card-body">
								<div class="row align-items-center">
									<div class="text-nowrap col-md-12">
										<p style={{ fontWeight: "900", fontSize: "20px" }}>Room</p>
									</div>
									<div class="col-6 text-center">
										<span
											class="text-muted mb-3 lh-1 d-block
                              text-truncate"
										>
											Total
										</span>
										<h4 class="mb-3 text-warning">
											<span
												class="counter-value"
												data-target={statistics?.rooms?.total_shares}
											>
												{statistics?.rooms?.total_shares}
											</span>
										</h4>
									</div>
									<div class="col-6 text-center">
										<span
											class="text-muted mb-3 lh-1 d-block
                              text-truncate"
										>
											Active
										</span>
										<h4 class="mb-3 text-success">
											<span
												class="counter-value"
												data-target={statistics?.rooms?.active_shares}
											>
												{statistics?.rooms?.active_shares}
											</span>
										</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-2 col-md-6">
						<div class="card card-h-100">
							<div class="card-body">
								<div class="row align-items-center">
									<div class="text-nowrap col-md-12">
										<p style={{ fontWeight: "900", fontSize: "20px" }}>
											Rate Plan
										</p>
									</div>
									<div class="col-6 text-center">
										<span
											class="text-muted mb-3 lh-1 d-block
                              text-truncate"
										>
											Total
										</span>
										<h4 class="mb-3 text-warning">
											<span
												class="counter-value"
												data-target={statistics?.ratePlans?.total_shares}
											>
												{statistics?.ratePlans?.total_shares}
											</span>
										</h4>
									</div>
									<div class="col-6 text-center">
										<span
											class="text-muted mb-3 lh-1 d-block
                              text-truncate"
										>
											Active
										</span>
										<h4 class="mb-3 text-success">
											<span
												class="counter-value"
												data-target={statistics?.ratePlans?.active_shares}
											>
												{statistics?.ratePlans?.active_shares}
											</span>
										</h4>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-xl-2 col-md-6">
						<div class="card card-h-100">
							<div class="card-body">
								<div class="row align-items-center">
									<div class="text-nowrap col-md-12">
										<p style={{ fontWeight: "900", fontSize: "20px" }}>
											Shared This week
										</p>
									</div>
									<div class="col-6 text-center">
										<span
											class="text-muted mb-3 lh-1 d-block
                              text-truncate"
										>
											Room
										</span>
										<h4 class="mb-3 text-warning">
											<span
												class="counter-value"
												data-target={statistics?.rooms?.this_week_shares}
											>
												{statistics?.rooms?.this_week_shares}
											</span>
										</h4>
									</div>
									<div class="col-6 text-center">
										<span
											class="text-muted mb-3 lh-1 d-block
                              text-truncate"
										>
											Rate Plan
										</span>
										<h4 class="mb-3 text-success">
											<span
												class="counter-value"
												data-target={statistics?.ratePlans?.this_week_shares}
											>
												{statistics?.ratePlans?.this_week_shares}
											</span>
										</h4>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-xl-2 col-md-6">
						<div class="card card-h-100">
							<div class="card-body">
								<div class="row align-items-center">
									<div class="text-nowrap col-md-12">
										<p style={{ fontWeight: "900", fontSize: "20px" }}>
											Reservations
										</p>
									</div>
									<div class="col-6 text-center">
										<span
											class="text-muted mb-3 lh-1 d-block
                              text-truncate"
										>
											Total
										</span>
										<h4 class="mb-3 text-warning">
											<span
												class="counter-value"
												data-target={statistics?.reservations?.total}
											>
												{statistics?.reservations?.total}
											</span>
										</h4>
									</div>
									<div class="col-6 text-center">
										<span
											class="text-muted mb-3 lh-1 d-block
                              text-truncate"
										>
											Active
										</span>
										<h4 class="mb-3 text-success">
											<span
												class="counter-value"
												data-target={statistics?.reservations?.active}
											>
												{statistics?.reservations?.active}
											</span>
										</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* <div class="row">
					<div class="col-xl-5 px-2">
						<div class="card card-h-100">
							<div class="card-body">
								<div class="d-flex flex-wrap align-items-center mb-4">
									<h5 class="card-title me-2">Wallet Balance</h5>
									<div class="ms-auto">
										<div>
											<button
												type="button"
												class="btn
                                  btn-soft-secondary btn-sm"
											>
												ALL
											</button>
											<button
												type="button"
												class="btn
                                  btn-soft-primary btn-sm"
											>
												1M
											</button>
											<button
												type="button"
												class="btn
                                  btn-soft-secondary btn-sm"
											>
												6M
											</button>
											<button
												type="button"
												class="btn
                                  btn-soft-secondary btn-sm active"
											>
												1Y
											</button>
										</div>
									</div>
								</div>

								<div class="row align-items-center">
									<div class="col-sm">
										<div
											id="wallet-balance"
											data-colors='["#777aca" ," #1877f9d4" ," #a8aada" ]'
											class="apex-charts"
										></div>
									</div>
									<div class="col-sm align-self-center">
										<div class="mt-4 mt-sm-0">
											<div>
												<p class="mb-2">
													<i
														class="mdi mdi-circle
                                          align-middle font-size-10 me-2
                                          text-success"
													></i>{" "}
													Bitcoin
												</p>
												<h6>
													0.4412 BTC ={" "}
													<span
														class="text-muted
                                          font-size-14 fw-normal"
													>
														$ 4025.32
													</span>
												</h6>
											</div>

											<div class="mt-4 pt-2">
												<p class="mb-2">
													<i
														class="mdi mdi-circle
                                          align-middle font-size-10 me-2
                                          text-primary"
													></i>{" "}
													Ethereum
												</p>
												<h6>
													4.5701 ETH ={" "}
													<span
														class="text-muted
                                          font-size-14 fw-normal"
													>
														$ 1123.64
													</span>
												</h6>
											</div>

											<div class="mt-4 pt-2">
												<p class="mb-2">
													<i
														class="mdi mdi-circle
                                          align-middle font-size-10 me-2
                                          text-info"
													></i>{" "}
													Litecoin
												</p>
												<h6>
													35.3811 LTC ={" "}
													<span
														class="text-muted font-size-14
                                          fw-normal"
													>
														$ 2263.09
													</span>
												</h6>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-7 px-2">
						<div class="row">
							<div class="col-xl-8">
								<div class="card card-h-100">
									<div class="card-body">
										<div
											class="d-flex flex-wrap align-items-center
                              mb-4"
										>
											<h5 class="card-title me-2">Invested Overview</h5>
											<div class="ms-auto">
												<select
													class="form-select
                                      form-select-sm"
												>
													<option value="MAY" selected="">
														May
													</option>
													<option value="AP">April</option>
													<option value="MA">March</option>
													<option value="FE">February</option>
													<option value="JA">January</option>
													<option value="DE">December</option>
												</select>
											</div>
										</div>

										<div class="row align-items-center">
											<div class="col-sm">
												<div
													id="invested-overview"
													data-colors='[" #1877f9d4" ," #34c38f"]'
													class="apex-charts"
												></div>
											</div>
											<div class="col-sm align-self-center">
												<div class="mt-4 mt-sm-0">
													<p class="mb-1">Invested Amount</p>
													<h4>$ 6134.39</h4>

													<p class="text-muted mb-4">
														{" "}
														+ 0.0012.23 ( 0.2 % ){" "}
														<i
															class="mdi mdi-arrow-up ms-1
                                              text-success"
														></i>
													</p>

													<div class="row g-0">
														<div class="col-6">
															<div>
																<p
																	class="mb-2
                                                      text-muted
                                                      text-uppercase
                                                      font-size-11"
																>
																	Income
																</p>
																<h5 class="fw-medium">$ 2632.46</h5>
															</div>
														</div>
														<div class="col-6">
															<div>
																<p
																	class="mb-2
                                                      text-muted
                                                      text-uppercase
                                                      font-size-11"
																>
																	Expenses
																</p>
																<h5 class="fw-medium">-$ 924.38</h5>
															</div>
														</div>
													</div>

													<div class="mt-2">
														<a
															href="#"
															class="btn
                                              btn-primary btn-sm"
														>
															View more{" "}
															<i
																class="mdi
                                                  mdi-arrow-right ms-1"
															></i>
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-xl-4">
								<div
									class="card bg-primary text-white shadow-primary
                      card-h-100"
								>
									<div class="card-body p-0" style={{ background: "#1777fb" }}>
										<div
											id="carouselExampleCaptions"
											class="carousel slide text-center
                              widget-carousel"
											data-bs-ride="carousel"
										>
											<div class="carousel-inner">
												<div class="carousel-item">
													<div class="text-center p-4">
														<i
															class="mdi mdi-bitcoin
                                              widget-box-1-icon"
														></i>
														<div class="avatar-md m-auto">
															<span
																class="avatar-title
                                                  rounded-circle
                                                  bg-soft-light text-white
                                                  font-size-24"
															>
																<i
																	class="mdi
                                                      mdi-currency-btc"
																></i>
															</span>
														</div>
														<h4
															class="mt-3 lh-base
                                              fw-normal text-white"
														>
															<b>Bitcoin</b>
															News
														</h4>
														<p
															class="text-white-50
                                              font-size-13"
														>
															{" "}
															Bitcoin prices fell sharply amid the global
															sell-off in equities. Negative news over the past
															week has dampened sentiment for bitcoin.{" "}
														</p>
														<button
															type="button"
															class="btn
                                              btn-light btn-sm"
														>
															View details{" "}
															<i
																class="mdi
                                                  mdi-arrow-right ms-1"
															></i>
														</button>
													</div>
												</div>
												<div class="carousel-item">
													<div class="text-center p-4">
														<i
															class="mdi mdi-ethereum
                                              widget-box-1-icon"
														></i>
														<div class="avatar-md m-auto">
															<span
																class="avatar-title
                                                  rounded-circle
                                                  bg-soft-light text-white
                                                  font-size-24"
															>
																<i
																	class="mdi
                                                      mdi-ethereum"
																></i>
															</span>
														</div>
														<h4
															class="mt-3 lh-base
                                              fw-normal text-white"
														>
															<b>ETH</b>
															News
														</h4>
														<p
															class="text-white-50
                                              font-size-13"
														>
															{" "}
															Bitcoin prices fell sharply amid the global
															sell-off in equities. Negative news over the past
															week has dampened sentiment for bitcoin.{" "}
														</p>
														<button
															type="button"
															class="btn
                                              btn-light btn-sm"
														>
															View details{" "}
															<i
																class="mdi
                                                  mdi-arrow-right ms-1"
															></i>
														</button>
													</div>
												</div>
												<div class="carousel-item active">
													<div class="text-center p-4">
														<i
															class="mdi mdi-litecoin
                                              widget-box-1-icon"
														></i>
														<div class="avatar-md m-auto">
															<span
																class="avatar-title
                                                  rounded-circle
                                                  bg-soft-light text-white
                                                  font-size-24"
															>
																<i
																	class="mdi
                                                      mdi-litecoin"
																></i>
															</span>
														</div>
														<h4
															class="mt-3 lh-base
                                              fw-normal text-white"
														>
															<b>Litecoin</b>
															News
														</h4>
														<p
															class="text-white-50
                                              font-size-13"
														>
															{" "}
															Bitcoin prices fell sharply amid the global
															sell-off in equities. Negative news over the past
															week has dampened sentiment for bitcoin.{" "}
														</p>
														<button
															type="button"
															class="btn
                                              btn-light btn-sm"
														>
															View details{" "}
															<i
																class="mdi
                                                  mdi-arrow-right ms-1"
															></i>
														</button>
													</div>
												</div>
											</div>

											<div
												class="carousel-indicators
                                  carousel-indicators-rounded"
											>
												<button
													type="button"
													data-bs-target="#carouselExampleCaptions"
													data-bs-slide-to="0"
													class=""
													aria-label="Slide 1"
												></button>
												<button
													type="button"
													data-bs-target="#carouselExampleCaptions"
													data-bs-slide-to="1"
													aria-label="Slide 2"
													class=""
												></button>
												<button
													type="button"
													data-bs-target="#carouselExampleCaptions"
													data-bs-slide-to="2"
													aria-label="Slide 3"
													class="active"
													aria-current="true"
												></button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> */}
			</div>
		</>
	) : (
		""
	);
}
