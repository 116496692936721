import axios from "axios";
import { cleanEmpty } from "./general";

const safavisaURL = process.env.REACT_APP_API_URL + "/v1/safavisa";
const createVisaURL = process.env.REACT_APP_API_URL;

export const getToken = async (data) => {
	return await axios
		.post(`${createVisaURL}/api/online-visa/auth/login`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const logOutVisa = async (data) => {
	return await axios
		.post(`${safavisaURL}/logout`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewUser = async (data) => {
	return await axios
		.post(`${safavisaURL}/logout/view`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchGroups = async (token, size, page, filter) => {
	let formData = "";
	formData = new FormData();
	formData.append("token", token);
	formData.append("data[size]", size);
	formData.append("data[page]", page);
	return await axios
		.post(`${safavisaURL}/groups`, formData, { params: cleanEmpty(filter) })
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchSearchGroups = async (token, size, page, filter) => {
	let formData = "";
	formData = new FormData();
	formData.append("token", token);

	return await axios
		.post(`${safavisaURL}/search/groups`, formData, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchContractRow = async (token) => {
	return await axios
		.post(`${safavisaURL}/contract`, token)
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewGroup = async (id, token, search) => {
	return await axios
		.post(`${safavisaURL}/members/in/${id}`, token, {
			params: cleanEmpty(search),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const searchMember = async (token, search) => {
	return await axios
		.post(`${safavisaURL}/search/members`, token, {
			params: cleanEmpty(search),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

//member/309055/14369
export const viewMember = async (onlineId, groupId, token) => {
	return await axios
		.post(`${safavisaURL}/member/${onlineId}/${groupId}`, token)
		.then((res) => res)
		.catch((err) => err.response);
};

///search/visa
export const getVisaNumber = async (token, data) => {
	return await axios
		.post(`${safavisaURL}/search/visa`, cleanEmpty(token, data))
		.then((res) => res)
		.catch((err) => err.response);
};
///search/visa
export const printVisaNumber = async (token, data) => {
	return await axios
		.post(`${safavisaURL}/print/visa`, cleanEmpty(token, data))
		.then((res) => res)
		.catch((err) => err.response);
};
//group dropdown
export const fetchGroupsDropdown = async (token) => {
	return await axios
		.get(`${safavisaURL}/status/dropdwn?token=${token}`)
		.then((res) => res)
		.catch((err) => err.response);
};

const visaUrl = process.env.REACT_APP_API_URL + "/api";

export const listWaitingVisaRequests = async (params) => {
	return await axios
		.get(`${visaUrl}/online-visa`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewRequests = async (id) => {
	return await axios
		.get(`${visaUrl}/online-visa/view/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewOnlineVisaPassenger = async (id) => {
	return await axios
		.get(`${visaUrl}/online-visa/view-passenger/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
//create visa
export const createVisa = async (id, data) => {
	return await axios
		.post(`${createVisaURL}/api/online-visa/issue-visa/${id}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
