import React from "react";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Locale from "translations";

export default function PropertyNav() {
	const { property } = Locale;
	const { id } = useParams();
	return (
		<div className="">
			<div className="  d-flex p-0 my-2 border-bottom">
				<NavLink
					exact={true}
					to={`/dashboard/${id}/view-property/basic`}
					activeClassName="view-property-active"
					className=" bg-viewProperty-nav  text-bold "
				>
					{property.basicInfo}
				</NavLink>
				<NavLink
					exact={true}
					to={`/dashboard/${id}/view-property/facilities`}
					activeClassName="view-property-active"
					className="text-bold bg-viewProperty-nav "
				>
					{property.services}
				</NavLink>
				<NavLink
					exact={true}
					to={`/dashboard/${id}/view-property/rooms`}
					activeClassName="view-property-active"
					className="text-bold bg-viewProperty-nav "
				>
					{property.rooms}
				</NavLink>
				<NavLink
					exact={true}
					to={`/dashboard/${id}/view-property/amenities`}
					activeClassName="view-property-active"
					className="text-bold bg-viewProperty-nav "
				>
					{property.amenities}
				</NavLink>
				<NavLink
					exact={true}
					to={`/dashboard/${id}/view-property/photos`}
					activeClassName="view-property-active"
					className="text-bold bg-viewProperty-nav "
				>
					{property.photos}
				</NavLink>
				<NavLink
					exact={true}
					to={`/dashboard/${id}/view-property/polices`}
					activeClassName="view-property-active"
					className="text-bold bg-viewProperty-nav "
				>
					{property.polices}
				</NavLink>
			</div>
		</div>
	);
}
