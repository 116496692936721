import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import validate from "helpers/validate";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Card, CardBody, Collapse } from "reactstrap";
import { getListStandalone } from "services/rateplan";
import Locale from "translations";

export default function ManageRatePlan({
	ratePlan,
	match,
	setRatePlanState,
	errors,
	setErrors,
	MealsPlan,
}) {
	const RatePlan = Locale;
	const [standaloneRate, setStandaloneRate] = useState([]);
	const { id } = useParams();
	useEffect(() => {
		async function fetchListStandalone() {
			const res = await getListStandalone(id);
			const format =
				res.data?.data?.length > 0
					? res.data?.data?.map((t) => ({
							value: t.id,
							label: t.name,
					  }))
					: [];
			setStandaloneRate(format);
		}
		fetchListStandalone();
	}, []);

	return (
		<div className="row m-0" style={{ display: "none" }}>
			<div
				className={`col-md-12 p-0 mt-3 ${
					errors?.manage_rate_type?.required ? "danger-checkbox" : ""
				}`}
			>
				<div
					className="rate-plan-header"
					onClick={() =>
						setRatePlanState({
							...ratePlan,
							isOpenManageRate: !ratePlan.isOpenManageRate,
						})
					}
				>
					{RatePlan.hotelLayout?.ratePlan?.howDoYouWantToManage}
					<i
						className={`fas pos-arrow ${
							ratePlan.isOpenManageRate ? "fa-chevron-up " : "fa-chevron-down"
						}`}
					></i>
				</div>

				<Collapse isOpen={ratePlan.isOpenManageRate}>
					<Card>
						<CardBody>
							<div className="col-md-12 pt-2">
								<div class="form-check form-check-inline pl-2 pr-2">
									<input
										class="form-check-input"
										type="radio"
										name="payment_policy11"
										id="manage_rate_type2"
										value="1"
										checked={ratePlan.manage_rate_type == "1"}
										onChange={(e) => {
											setRatePlanState({
												...ratePlan,
												manage_rate_type: e.target.value,
											});
											setErrors({
												...errors,
												...validate(
													{
														name: "manage_rate_type",
														value: e.target.value,
													},
													{ required: true }
												),
												...validate(
													{
														name: "rate_plan_child",
														value: ratePlan.rate_plan_child,
													},
													{ required: false }
												),
											});
										}}
									/>
									<label class="form-check-label" for="manage_rate_type2">
										Set up as a new rate plan
									</label>
								</div>
								{/* <div class="form-check form-check-inline pl-2 pr-2">
									<input
										class="form-check-input"
										type="radio"
										name="payment_policy11"
										id="manage_rate_type1"
										value="0"
										checked={ratePlan.manage_rate_type == "0"}
										onChange={(e) => {
											setRatePlanState({
												...ratePlan,
												manage_rate_type: e.target.value,
											});
											setErrors({
												...errors,
												...validate(
													{
														name: "manage_rate_type",
														value: e.target.value == "0" ? 1 : e.target.value,
													},
													{ required: true }
												),
											});
										}}
									/>
									<label class="form-check-label" for="manage_rate_type1">
										Based on one of my current rate plans
									</label>
								</div> */}
								{errors?.manage_rate_type?.required ? (
									<div className="col-md-12 text-danger danger-massage">
										should choose manage this rate plan
									</div>
								) : (
									""
								)}
								{ratePlan.manage_rate_type === "0" ? (
									<div className="col-md-12 p-0">
										<div className="row m-0">
											<div className="col-md-3">
												<SelectField
													label={"Rate plan"}
													value={ratePlan.rate_plan_child}
													name="rate_plan_child"
													options={standaloneRate}
													onChange={(e) => {
														setErrors({
															...errors,
															...validate(
																{
																	name: "rate_plan_child",
																	value: e,
																},
																{ required: true }
															),
														});
														setRatePlanState({
															...ratePlan,
															rate_plan_child: e,
														});
													}}
													onBlur={() =>
														setErrors({
															...errors,
															...validate(
																{
																	name: "rate_plan_child",
																	value: ratePlan.rate_plan_child,
																},
																{ required: true }
															),
														})
													}
													color={
														errors?.rate_plan_child?.required ? "danger" : ""
													}
													errors={errors?.rate_plan_child}
												/>
											</div>
											<div className="col-md-7">
												<TextField
													type="text"
													label={
														"How much more expensive than Fully flexible would you like to set this rate plan?"
													}
													placeholder={
														"How much more expensive than Fully flexible would you like to set this rate plan?"
													}
													value={ratePlan.flexible_rate_plan}
													name="flexible_rate_plan"
													onChange={(e) => {
														setErrors({
															...errors,
															...validate(e.target, {
																required: true,
															}),
														});
														setRatePlanState({
															...ratePlan,
															flexible_rate_plan: e.target.value,
														});
													}}
													min={3}
													color={
														errors?.flexible_rate_plan?.required ? "danger" : ""
													}
													errors={errors?.flexible_rate_plan}
												/>
											</div>
											<div className="percentage">
												<p className="font-label">Percentage</p>
											</div>
										</div>
									</div>
								) : (
									""
								)}
							</div>
						</CardBody>
					</Card>
				</Collapse>
			</div>
		</div>
	);
}
