import moment from "moment";
import React, { useEffect, useState } from "react";
import Filter from "./Filter";
import { fetchCountries } from "services/lookups";
import Pagination from "components/shared/Pagination";
import TableContent from "./TableContent";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { listWaitingVisaRequests } from "services/visa";
import Locale from "translations";
import { useMemo } from "react";
import { listTransportaionRequests } from "services/transportation";

export default function TransportationRequest() {
	const { requests } = Locale;
	const [transportationList, setTransportationList] = useState([
		{
			id: 1,
			destination: "egypt",
			visa_type: "Full Cycle",
			request_date: "22-05-2010",
			status: "Approved",
		},
	]);
	const [countries, setCountries] = useState([]);
	const locale = localStorage.getItem("currentLocale") || "en";
	const [meta, setMeta] = useState();
	const [filter, setFilter] = useState({
		destination_id: null,
		cycle_type: null,
		request_date: null,
		company_name: undefined,
		page: null,
	});
	const [visaType, setvisaType] = useState([
		{
			id: 1,
			name: {
				ar: "كاملة",
				en: "Full",
			},
			status: 1,
			created_at: null,
			updated_at: null,
		},
		{
			id: 2,
			name: {
				ar: "نصف",
				en: "Half",
			},
			status: 1,
			created_at: null,
			updated_at: null,
		},
	]);

	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchCountries();
			const format = res?.data.map((t) => ({
				value: t.id,
				label: t.names[locale],
			}));
			setCountries(format);

			const formatVisa = visaType?.map((t) => ({
				value: t?.id,
				label: t?.name[locale],
			}));
			setvisaType(formatVisa);
		}
		fetchLookups();
	}, []);
	console.log("fffff", filter);
	useEffect(() => {
		const fetchWaitingVisaList = async () => {
			const response = await listTransportaionRequests({
				...filter,
				destination_id: filter.destination_id?.id,
				cycle_type: filter.cycle_type?.name,
				request_date: filter.request_date
					? moment(filter.request_date).format("YYYY-MM-DD")
					: "",
				page: 1,
			});
			debugger;
			if (response.status === 200 || response.status === 201) {
				setTransportationList(response.data.data);
				setMeta(response.data.meta);
			}
		};

		fetchWaitingVisaList();
	}, [filter]);

	const listRequests =
		transportationList?.length > 0 ? (
			transportationList?.map((item, index) => {
				return <TableContent data={item} key={index} />;
			})
		) : (
			<tr>
				<td colSpan="10" className="text-center my-3">
					<div className="product-build__product-no-data">
						<i className="fas fa-info-circle fa-lg"></i> <h4>No Result</h4>
					</div>
				</td>
			</tr>
		);

	const goTo = (page) => {
		setFilter({ ...filter, page: page });
	};
	return (
		<div>
			<div className="row m-0 p-0 ">
				<div className="col-12 d-flex flex-column align-items-end  justify-content-start">
					<Filter
						filter={filter}
						setFilter={setFilter}
						countries={countries}
						visaType={visaType}
					/>
				</div>
				<div className="w-100">
					<div class="card-body p-0">
						<div class="table-responsive">
							<table className="table mb-0 f-15">
								<thead className="table-light">
									<tr className="">
										<th>ID</th>
										<th>{requests.Destination}</th>
										<th>{requests.cycleType}</th>
										<th>
											<i class="fas fa-sort"></i> {requests.Pax}
										</th>
										<th>
											<i class="fas fa-sort"></i>
											{requests.Depature}Depature
										</th>
										<th>
											<i class="fas fa-sort"></i>
											{requests.Return}
										</th>
										<th>
											<i class="fas fa-sort"></i> {requests.RequestDate}
										</th>
										<th>{requests.Status}</th>
										<th scope="col"></th>
									</tr>
								</thead>
								<tbody>{listRequests}</tbody>
							</table>{" "}
						</div>
					</div>
				</div>
				<div className="col-12"> </div>
				<Pagination info={meta} goTo={goTo} />
			</div>
		</div>
	);
}
