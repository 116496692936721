import axios from "axios";
import { cleanEmpty } from "./general";
const groundUrl = process.env.REACT_APP_API_URL + "/api";

export const getListGround = async (params) => {
	return await axios
		.get(`${groundUrl}/ground-work`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewGroundRequests = async (id) => {
	return await axios
		.get(`${groundUrl}/ground-work/view/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewGroundPassenger = async (id) => {
	return await axios
		.get(`${groundUrl}/ground-work/view-passenger/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const exportToExcel = async (id) => {
	return await axios
		.get(`${groundUrl}/ground-work/export-passengers/${id}`, {
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};
