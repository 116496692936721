import walletBlue from "assets/images/walletBlue.svg";
import moment from "moment";
import Locale from "translations";

export default function ViewRequestHeader({ header }) {
	const { requests } = Locale;

	return (
		<>
			<div className="rounded row m-0  justify-content-between bg-white border p-0">
				<div className="rounded m-0 row col-10  justify-content-between flex-nowrap  p-0">
					<div className="col-3 py-5 bg-gray text-center">
						<p className="m-0 font-weight-bold">{requests.groundService}</p>
						<p className="m-0 font-weight-bold"> {requests.details}</p>
					</div>

					<div className="row col-5 py-4">
						<div className="col-5 text-visa-header font-weight-bold">
							<p className="m-0 ">{requests.Destination}</p>
							<p className="m-0 ">{requests.Pax}</p>
							<p className="m-0 ">{requests.Departure}</p>
							<p className="m-0 ">{requests.Return}</p>
							{/* <p className="m-0 ">{requests.cycleType}</p> */}
						</div>

						<div className="col-7 ">
							<p className="m-0">{header?.destination_name}</p>
							<p className="m-0">{header?.pax}</p>
							<p className="m-0">
								{" "}
								<p className="m-0">
									{moment(header?.departure_date).format("DD/MM/YYYY")}
								</p>
							</p>
							<p className="m-0">
								{" "}
								<p className="m-0">
									{moment(header?.return_date).format("DD/MM/YYYY")}
								</p>
							</p>
							{/* <p className="m-0">-</p> */}
						</div>
					</div>

					<div className="row col-4 py-4 ">
						<div className="col-5  text-visa-header font-weight-bold">
							<p className="m-0 ">{requests.priceOne}</p>
							<p className="m-0 ">{requests.RequestDate}</p>
							<p className="m-0 ">{requests.Status}</p>
						</div>

						<div className="col-7  ">
							<p className="m-0">
								{header?.price_per_one} {header.currency}
							</p>

							<p className="m-0 ">{header?.request_date}</p>
							<p
								className={`m-0 ${
									header.status === "waiting" ? "text-warning" : "text-success"
								}`}
							>
								{header.status}
							</p>

							<p className="m-0">
								{header?.visaType?.price}{" "}
								{header?.visaType?.currency_name?.toLocaleUpperCase()}
							</p>
						</div>
					</div>
				</div>

				<div className="d-flex justify-content-between align-items-end col-2 p-0 flex-column">
					<div></div>
					<div className="d-flex  bg-gray px-3 py-1 text-center rounded justify-content-center ">
						<img src={walletBlue} alt="" className="online-vise-img" />
						<div className="mx-3">
							<p className="m-0 txt-blue">{requests.TotalPrice}</p>
							<p className="m-0 text-success font-weight-bold">
								{header?.total} {header?.currency?.toLocaleUpperCase()}
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
