import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Locale from "translations";

export default function TableContent({ data }) {
	const { reservations } = Locale;

	return (
		<tr className=" text-black">
			<td>{data.id}</td>
			<td>{data.destination}</td>
			<td>{data.cycle_type}</td>
			<td>{data.pax}</td>
			<td>{data.departure_date}</td>
			<td>{data.return_date}</td>
			<td>{data.request_date}</td>

			<td
				className={`${
					data?.status.toLowerCase() === "waiting"
						? "text-warning"
						: "text-success"
				}`}
			>
				{data.status}
				{/* {data.status} */}
			</td>
			<td className="" width={"3%"}>
				<Link to={`/transportation/${data.id}`}>
					<i class="fas fa-angle-right text-dark"></i>
				</Link>
			</td>
		</tr>
	);
}
