import React, { useEffect, useState } from "react";
import Locale from "../../translations";
import { useHistory } from "react-router-dom";
import Model from "components/model";

import { Link, NavLink } from "react-router-dom";
import Filter from "../TeamMangament/filter";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import Users from "./users";
import moment from "moment";
import { addUser, getListGroups, getListUser } from "services/TeamManagement";
import validate, { isFormValid } from "helpers/validate";
import { store } from "react-notifications-component";
//import { fetchCountries } from "services/lookups";
import {
	fetchCategories,
	fetchCountries,
	fetchServices,
} from "services/lookups";
import { useGlobalState } from "context/global";
function TeamManagement() {
	const { teamManagement } = Locale;
	let history = useHistory();
	const [users, setUsers] = useState([]);
	const [meta, setMeta] = useState([]);
	const [searching, setSearching] = useState(false);
	const [filter, setfilter] = useState({
		q: "",
		email: "",
		status: "",
		page: 1,
	});

	const [modalShow, setModalShow] = useState(false);

	const toggleModalShow = (id) => {
		//setSeletedId(id)
		setModalShow(!modalShow);
	};
	/***Api */

	useEffect(() => {
		async function fetchListUser() {
			const res = await getListUser({
				...filter,
				status: filter?.status?.title,
				page:
					filter?.q === "" && filter?.email === "" && filter?.status === ""
						? filter.page
						: 1,
			});
			//debugger;
			setUsers(res?.data?.users?.data);
			setMeta(res?.data?.users);

		}
		fetchListUser();
	}, [filter]);

	//*******Add User ************/

	const [errors, setErrors] = useState({});
	const [data, setData] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [countries, setCountries] = useState([]);
	const [services, setServices] = useState([]);
	const [catagories, setCatagories] = useState([]);
	const [groups, setGroups] = useState([]);
	const [admins, setAdmins] = useState([]);

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setData({ ...data, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			setData({ ...data, [name]: value });
		}
	};

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{
					name: "supplier_name",
					value: data.supplier_name,
				},
				{
					required: true,
				}
			),
			// ...validate(
			// 	{
			// 		name: "owner_name",
			// 		value: data.owner_name,
			// 	},
			// 	{
			// 		required: true,
			// 	}
			// ),
			...validate(
				{
					name: "email",
					value: data.email,
				},
				{
					required: true,
					email: true,
				}
			),
			...validate(
				{
					name: "password",
					value: data.password,
				},
				{
					required: true,
				}
			),
			...validate(
				{
					name: "password_confirmation",
					value: data.password_confirmation,
				},
				{
					required: true,
					confirm: true,
				},
				data.password
			),

			...validate(
				{
					name: "phone_number",
					value: data.phone_number,
				},
				{
					required: true,
					number: true,
				}
			),
			...validate(
				{
					name: "group_id",
					value: data.group_id,
				},
				{
					//( data?.is_admin?.value == 1)?({required: false}):({required: true})
					required: false,
				}
			),

			...validate(
				{
					name: "country_id",
					value: data.country_id,
				},
				{
					required: true,
				}
			),
			// ...validate(
			// 	{
			// 		name: "category_id",
			// 		value: data.category_id,
			// 	},
			// 	{
			// 		required: true,
			// 	}
			// ),
			// ...validate(
			// 	{
			// 		name: "services",
			// 		value: data.services,
			// 	},
			// 	{
			// 		required: true,
			// 	}
			// ),
			// ...validate(
			// 	{
			// 		name: "is_admin",
			// 		value: data.is_admin,
			// 	},
			// 	{
			// 		required: true,
			// 	}
			// ),
		});
	};
	const handleForm = async () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};
	/*****getCountries */
	const locale = localStorage.getItem("currentLocale") || "en";

	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchCountries();
			const format = res?.data?.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}

		async function fetchLookupServices() {
			const res = await fetchServices();
			const format1 = res?.data?.map((t) => ({
				value: t.id,
				label: t.name,
			}));
			setServices(format1);
		}

		async function fetchLookupCatagories() {
			const res = await fetchCategories();
			const format2 = res?.map((t) => ({
				value: t.id,
				label: t.name,
			}));
			setCatagories(format2);
		}

		async function fetchGroups() {
			const res = await getListGroups();
			const format3 = res?.data?.data?.map((t) => ({
				value: t.id,
				label: t.name,
			}));
			setGroups(format3);
		}

		async function fetchAdmin() {
			//const res = await getListGroups();
			const format4 = [
				{ value: 0, label: teamManagement.NotAdmin },
				{ value: 1, label: teamManagement.IsAdmin },
			];
			setAdmins(format4);
		}

		fetchLookups();
		fetchLookupServices();
		fetchLookupCatagories();
		fetchGroups();
		fetchAdmin();
	}, []);

	useEffect(() => {
		const addNewUser = async () => {
			if (isFormValid(errors)) {
				const res = await addUser({
					...data,
					group_id: data.group_id?.value,
					country_id: data.country_id?.value,
					//services: [data.services?.value],
					//services: data.services?.map((ser) => +ser.value),
					//category_id: data.category_id?.value,
					//is_admin: data.is_admin?.value,
					is_admin: 0,
					phone_number: "+" + data?.country_id?.value + data?.phone_number,
					name: data?.supplier_name,
				});

				if (res.status == 200 || res.status == 201) {
					store.addNotification({
						title: "info!",
						message: res.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
					});
					//const usersData = await getListUser();
					//	if (usersData.status === 200) {
					//setUsers(usersData?.data?.data);
					//}
					toggleModalShow(null);
					window.location.reload();
				}
			}
		};
		addNewUser();
	}, [isErrorLoaded]);
	//******End Add User */

	/***Api */
	return (
		<section className="teams px-5">
			<div className="container-fluid  p-0 team-management-tabs">
				<div className="d-flex align-items-center justify-content-between mt-5 bg-white fl-border head team-management-tabs">
					<Filter
						filter={filter}
						setfilter={setfilter}
						users={users}
						setUsers={setUsers}
					//searching={searching}
					//setSearching={setSearching}
					/>

					<button
						type="button"
						className="btn btn-primary  add-user-btn "
						onClick={() => {
							setModalShow(!modalShow);
						}}
					>
						<i className="fas fa-plus-circle mr-2"></i>
						{teamManagement.addMember}
					</button>
				</div>
				<Users
					users={users}
					countries={countries}
					services={services}
					catagories={catagories}
					groups={groups}
					admins={admins}
					filter={filter}
					setfilter={setfilter}
					meta={meta}
					setMeta={setMeta}
				/>
			</div>

			{/******  Model Add User ****** */}
			<Model
				isOpen={modalShow}
				title={teamManagement.addMember}
				toggle={() => {
					toggleModalShow(null);
				}}
				hasFooter={false}
				className="bulk-edit user-model"
			// size="lg"
			>
				<div className="py-3  user-model-content ">
					<div className="row">
						<div className="col-md-12 ">
							<TextField
								label={teamManagement.fullName}
								placeholder={teamManagement.enterFullName}
								name="supplier_name"
								onChange={(e) => {
									setData({
										...data,
										supplier_name: e.target.value,
									});
								}}
								color={errors?.supplier_name?.required ? "danger" : ""}
								errors={errors?.supplier_name}
							/>
						</div>
						{/* <div className="col-md-6 ">
							<TextField
								label={teamManagement.ownerName}
								placeholder={teamManagement.ownerName}
								name="owner_name"
								onChange={(e) => {
									setData({
										...data,
										owner_name: e.target.value,
									});
								}}
								color={errors?.owner_name?.required ? "danger" : ""}
								errors={errors?.owner_name}
							/>
						</div> */}
						<div className="col-md-12 ">
							<TextField
								label={teamManagement.emailAddress}
								placeholder={teamManagement.enterEmailAddress}
								name="email"
								onChange={(e) => {
									setData({
										...data,
										email: e.target.value,
									});
								}}
								color={
									errors?.email?.required || errors?.email?.email
										? "danger"
										: ""
								}
								errors={errors?.email}
							/>
						</div>
						<div className="col-md-6 ">
							<TextField
								type="password"
								label={teamManagement.password}
								placeholder={teamManagement.enterPassword}
								name="password"
								onChange={(e) => {
									setData({
										...data,
										password: e.target.value,
									});
								}}
								color={errors?.password?.required ? "danger" : ""}
								errors={errors?.password}
							/>
						</div>
						<div className="col-md-6 ">
							<TextField
								type="password"
								label={teamManagement.password_confirmation}
								placeholder={teamManagement.enterConfirmPassword}
								name="password_confirmation"
								onChange={(e) => {
									setData({
										...data,
										password_confirmation: e.target.value,
									});
								}}
								color={
									errors?.password_confirmation?.required ||
										errors?.password_confirmation?.confirm
										? "danger"
										: ""
								}
								errors={errors?.password_confirmation}
							/>
						</div>

						<div className="col-md-6 ">
							<SelectField
								label={teamManagement.country}
								placeholder={teamManagement.enterCountry}
								name="country_id"
								errors={errors.country_id}
								color={errors.country_id?.required ? "danger" : ""}
								options={countries}
								onChange={(e) => {
									handleChange({ name: "country_id", value: e });

									setErrors({
										...errors,
										...validate(
											{ name: "country_id", value: e },
											{
												required: true,
											}
										),
									});
								}}
								value={data.country?.label}
							/>
						</div>
						{locale == "en" ? (
							<div
								className="col-md-6 pl-md-2 phone-supplier"
								style={{ direction: "ltr" }}
							>
								<TextField
									label={teamManagement.phoneNumber}
									placeholder={teamManagement.enterPhoneNumber}
									name="phone_number"
									extraText={
										data?.country_id?.value
											? "+" + data?.country_id?.value
											: "---"
									}
									extraTextPosition="prepend"
									onChange={(e) => {
										setData({
											...data,
											phone_number: data?.country_id?.value + e.target.value,
										});
									}}
									color={
										errors?.phone_number?.required ||
											errors?.phone_number?.number
											? "danger"
											: ""
									}
									errors={errors?.phone_number}
								/>
							</div>
						) : (
							<div
								className="col-md-6 pl-md-2 phone-supplier"
								style={{ direction: "ltr" }}
							>
								<TextField
									label={teamManagement.phoneNumber}
									placeholder={teamManagement.enterPhoneNumber}
									name="phone_number"
									extraText={
										data?.country_id?.value
											? "+" + data?.country_id?.value
											: "---"
									}
									extraTextPosition="prepend"
									onChange={(e) => {
										setData({
											...data,
											phone_number: data?.country_id?.value + e.target.value,
										});
									}}
									color={errors?.phone_number?.required ? "danger" : ""}
									errors={errors?.phone_number}
								/>
							</div>
						)}

						{/* <div className="col-md-6 ">
							<SelectField
								label={teamManagement.catagory}
								placeholder={teamManagement.enterCatagory}
								name="category_id"
								errors={errors.category_id}
								color={errors.category_id?.required ? "danger" : ""}
								options={catagories}
								onChange={(e) => {
									handleChange({ name: "category_id", value: e });

									setErrors({
										...errors,
										...validate(
											{ name: "category_id", value: e },
											{
												required: true,
											}
										),
									});
								}}
								value={data.category_id?.name}
							/>
						</div>
						<div className="col-md-6 ">
							<SelectField
								label={teamManagement.services}
								placeholder={teamManagement.enterServices}
								name="services"
								//multi={true}
								errors={errors.services}
								color={errors.services?.required ? "danger" : ""}
								options={services}
								onChange={(e) => {
									handleChange({ name: "services", value: e });

									setErrors({
										...errors,
										...validate(
											{ name: "services", value: e },
											{
												required: true,
											}
										),
									});
								}}
								value={data.services?.name}
							/>
						</div> */}

						{/* <div className="col-md-6 ">
							<SelectField
								label={teamManagement.admin}
								placeholder={teamManagement.admin}
								name="is_admin"
								//multi={true}
								errors={errors.is_admin}
								color={errors.is_admin?.required ? "danger" : ""}
								options={admins}
								onChange={(e) => {
									handleChange({ name: "is_admin", value: e });
									setErrors({
										...errors,
										...validate(
											{ name: "is_admin", value: e },
											{
												required: true,
											}
										),
									});
								}}
								value={data?.is_admin?.name}
							/>
						</div> */}

						<div className="col-md-12 ">
							<SelectField
								disabled={data?.is_admin?.value == 1}
								label={teamManagement.team}
								placeholder={teamManagement.enterTeam}
								name="group_id"
								//multi={true}
								errors={errors.group_id}
								color={errors.group_id?.required ? "danger" : ""}
								options={groups}
								onChange={(e) => {
									handleChange({ name: "group_id", value: e });
									setErrors({
										...errors,
										...validate(
											{ name: "group_id", value: e },
											{
												required: true,
											}
										),
									});
								}}
								value={data?.group_id?.name}
							/>
						</div>
					</div>

					<div className="d-flex justify-content-end ">
						<button className="btn btn-primary mx-1" onClick={handleForm}>
							{teamManagement.add}
						</button>
						<button
							className="btn btn-secondary mx-1"
							onClick={() => {
								toggleModalShow(null);
							}}
						>
							{" "}
							{teamManagement.cancel}
						</button>
					</div>
				</div>
			</Model>
			{/******* MOdel ***** */}
		</section>
	);
}

export default TeamManagement;
