import axios from "axios";
import { useGlobalDispatch, useGlobalState } from "context/global";
import { store } from "react-notifications-component";
import Locale from "translations";

let axiosInterceptor = null;
let axiosRequestInterceptor = null;
const AxiosConfig = () => {
	let currentLocale = localStorage.getItem("currentLocale") ?? "en";
	const { commons } = Locale;

	let { token } = useGlobalState();
	const dispatch = useGlobalDispatch();

	axios.defaults.headers.common["X-Locale"] = currentLocale;
	axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	let counter = 0;
	if (!!axiosInterceptor || axiosInterceptor === 0) {
		axios.interceptors.response.eject(axiosInterceptor);
	}

	if (!!axiosRequestInterceptor || axiosRequestInterceptor === 0) {
		axios.interceptors.request.eject(axiosRequestInterceptor);
	}
	axiosRequestInterceptor = axios.interceptors.request.use(
		(request) => {
			counter++;
			if (request.data && request.data.hasOwnProperty("search")) {
				return request;
			} else {
				dispatch({ type: "setLoading", payload: true });
				return request;
			}
		},
		(error) => {
			counter--;
		}
	);

	axiosInterceptor = axios.interceptors.response.use(
		(response) => {
			counter--;
			// if (!response.config.url.includes("view_user_profile")) {
			if (counter === 0) {
				dispatch({ type: "setLoading", payload: false });
				return response;
			} else {
				return response;
			}
		},

		(error) => {
			counter--;
			// Show Alert FOr Error
			if (counter === 0) {
				dispatch({ type: "setLoading", payload: false });
			}

			if (error.response && error.response.status === 400) {
				dispatch({
					type: "setError",
					payload: true,
					message: {
						title: "some thing Went Wrong",
						body: error?.response?.data?.message,
					},
				});
			} else if (error.response && error.response.status === 401) {
				store.addNotification({
					title: "Unauthorized!",
					message: "Go Back to Login",
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
				localStorage.removeItem("isAuth");
				localStorage.removeItem("token");
				localStorage.removeItem("currentLocale");
				window.location.href = "/login";
			} else if (error.response && error.response.status === 422) {
				let errorsMsgs = ``;

				if (typeof error?.response?.data.errors === "object") {
					Object.values(error?.response?.data?.errors).map(
						(msg) => (errorsMsgs += msg + "\n")
					);
				} else {
					errorsMsgs = error.response.data.errors;
				}

				dispatch({
					type: "setError",
					payload: true,
					message: {
						title: error.response.data.message,
						body: errorsMsgs,
					},
				});
			} else if (error.response && error.response.status === 500) {
				dispatch({
					type: "setError",
					payload: true,
					message: {
						title: "Something Went Wrong!",
						body: "Internal Server Error, Please try again later",
					},
				});
			} else if (error.response && error.response.status === 404) {
				dispatch({
					type: "setError",
					payload: true,
					message: {
						title: "Something Went Wrong!",
						body: "Page not found 404",
					},
				});
			}
			return Promise.reject(error);
		}
	);
};

export default AxiosConfig;
