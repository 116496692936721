export function formatAddRatePlan(info) {
	const {
		name,
		cancellation_type,
		days_before_arrival,
		long_before_arrival,
		payment_policy,
		meal,
		Meals,
		meals_plan,
		listPrice,
		Buffet,
		minimum_length_night,
		minimum_length,
		manage_rate_type,
		flexible_rate_plan,
		rooms,
		listPaymentConditionsIds,
		rate_plan_child,
		notes,
		ConfirmationTypes,
		check_in_day,
		cancellation,
		currency,
	} = info;

	return {
		currency: currency.value,
		name,
		cancellation_policy_id: cancellation_type,
		cancellation: cancellation,
		payment: {
			payment_policy_id: +payment_policy,
			conditions: listPaymentConditionsIds,
		},
		meals: {
			meal_option_id: +meal,
			exclude: listPrice,
			meal_id: Meals?.value,
			buffet_id: Buffet?.value,
			meal_plan_id: meals_plan?.value,
		},
		check_in_day: check_in_day?.value ?? check_in_day?.label,
		is_available_for_any_length_of_stay: minimum_length,
		minimum_length_of_stay: minimum_length_night,
		is_standalone_rate_plan: manage_rate_type,
		parent_property_rate_id: rate_plan_child?.value,
		rooms: rooms,
		percentage: flexible_rate_plan,
		charge_type: "plus",
		notes: notes,
		booking_type_id: ConfirmationTypes?.value,
	};
}

export function formatRatePlan(info) {
	const {
		name,
		cancellation_policy,
		payment_policy,
		meals,
		is_available_for_any_length_of_stay,
		minimum_length_of_stay,
		is_standalone_rate_plan,
		rooms,
		parent,
		notes,
		bookingType,
		check_in_day,
		cancellation_conditions,
		id,
		currency,
	} = info;

	return {
		id: id,
		currency: { label: currency, value: currency },
		name: name,
		cancellation_type: 3,
		days_before_arrival: cancellation_policy?.days_before_arrival,
		long_before_arrival: cancellation_policy?.charge,
		payment_policy: payment_policy?.payment_policy.id,
		cancellation: cancellation_conditions,
		paymentConditionsList:
			payment_policy.conditions.length > 0
				? payment_policy.conditions.map(
						(res) =>
							(res = {
								...res,
								payment_policy_type_id: res.payment_policy_type.id,
								payment_policy_type: {
									label: res.payment_policy_type.name,
									value: res.payment_policy_type.id,
								},
							})
				  )
				: [],
		meal: meals?.meal_option?.id?.toString(),
		check_in_day: { label: check_in_day, value: check_in_day },
		Buffet:
			meals?.meal_option?.id == 3
				? meals.exclude_meals_buffet_ids
				: { label: meals?.buffet?.name, value: meals?.buffet?.id },
		Meals:
			meals?.meal_option?.id == 3
				? meals.exclude_meals_meal_ids
				: { label: meals?.meal?.name, value: meals?.meal?.id },
		exclude_meals: meals.exclude_meals,
		minimum_length: is_available_for_any_length_of_stay ? "1" : "0",
		minimum_length_night: minimum_length_of_stay,
		manage_rate_type: is_standalone_rate_plan ? "1" : "0",
		rooms: rooms,
		flexible_rate_plan: parent?.percentage.toString(),
		rate_plan_child: {
			value: parent?.parent_property_rate_id,
			label: parent?.parent_property_rate_name,
		},
		meals_plan: { value: meals?.meal_plan?.id, label: meals?.meal_plan?.name },
		notes,
		ConfirmationTypes: {
			label: bookingType?.name["en"],
			value: bookingType?.id,
		},
	};
}
