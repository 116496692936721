import SelectField from "components/shared/SelectField";
import TextAreaField from "components/shared/TextAreaField";
import TextField from "components/shared/TextField";

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FormGroup, Input, Label } from "reactstrap";

import { viewProperties } from "services/property";
import Locale from "translations";

export default function BasicInfo() {
	const currentLocale = localStorage.getItem("currentLocale");

	const { property, commons, supplierDetails } = Locale;
	const [isSameEmail, setIsSameEmail] = useState(false);
	const { id } = useParams();
	const [disabled, setDisabled] = useState(true);
	const [info, setInfo] = useState({
		name: "",
		rating: "",
		description: "",
		country: "",
		city: "",
		area: "",
		address: "",
		postalCode: "",
		phone: "",
		secondPhone: "",
		email: "",
		officialEmail: "",
		latitude: "",
		longitude: "",
		lang: "en",
		distance: "",
		shared: "",
	});

	useEffect(async () => {
		const res = await viewProperties(id);
		if (res.status === 200 || res.status === 201) {

			setInfo({
				...info,
				name: res.data.property.name,
				description: res.data.property.description,
				rating: {
					value: 1,
					label: res.data.rate,
				},
				country: {
					value: res.data.property.location.country.id,
					label: res.data.property.location.country.names[currentLocale],
				},
				city: {
					value: res.data.property.location.city.id,
					label: res.data.property.location.city.names[currentLocale],
				},
				area: {
					value: res.data.property.location.area.id,
					label: res.data.property.location.area.names[currentLocale],
				},
				address: res.data.property.location.address,
				distance: res.data.property.distance_from_haram,
				postalCode: res.data.property.location.post_code,
				phone: res.data.property.primary_phone_number,
				secondPhone: res.data.property.second_phone_number,
				email: res.data.property.reservation_email,
				officialEmail: res.data.property.official_email,
				latitude: res.data.property.location.latitude,
				longitude: res.data.property.location.longitude,
				shared: res.data.status,
			});
		}
	}, []);

	return (
		<div className="container px-3 py-2">
			{" "}
			<div className="content-box">
				<FormGroup tag="fieldset" className="col-12 name-check">
					<FormGroup check>
						<Label htmlFor="basic-search" check>
							<Input
								type="radio"
								name="propertyName"
								id="basic-search"
								checked={info.shared === "Shared"}
								disabled={disabled}
							/>{" "}
							Search
						</Label>
					</FormGroup>
					<FormGroup check>
						<Label htmlFor="basic-insert" check>
							<Input
								type="radio"
								name="propertyName"
								id="basic-insert"
								checked={info.shared != "Shared"}
								disabled={disabled}
							/>{" "}
							Insert
						</Label>
					</FormGroup>
				</FormGroup>
				<div className="row m-0">
					<div className="col-7">
						<TextField
							label={property.name}
							customLabel={property.customName}
							placeholder={property.namePlaceholder}
							value={info.name}
							name="name"
							disabled={disabled}
							id="basic-info"
						/>
					</div>
					{/* rating */}
					<div className="col-5">
						<SelectField
							label={property.rating}
							value={info.rating}
							options={[
								{ label: "1", value: 1 },
								{ label: "2", value: 2 },
								{ label: "3", value: 3 },
								{ label: "4", value: 4 },
								{ label: "5", value: 5 },
							]}
							placeholder={property.ratingPlaceholder}
							name="rating"
							id="basic-rating"
							disabled={disabled}
						/>
					</div>
					{/* description */}
					<div className="col-12">
						<TextAreaField
							label={property.description}
							placeholder={property.descriptionPlaceholder}
							rows={5}
							value={info.description}
							name="description"
							id="basic-description"
							disabled={disabled}
						/>
					</div>
				</div>
			</div>
			<div className="header-box my-3">{property.located}</div>
			<div className="row m-0">
				{/* Country */}
				<div className="col-6">
					<SelectField
						label={commons.country}
						placeholder={commons.countryPlaceholder}
						id="basic-countries"
						value={info.country}
						disabled={disabled}
					/>
				</div>
				{/* City */}
				<div className="col-6">
					<SelectField
						label={commons.city}
						placeholder={commons.cityPlaceholder}
						id="basic-cities"
						value={info.city}
						disabled={disabled}
					/>
				</div>
				{/* Area */}
				<div className="col-6">
					<SelectField
						label={commons.area}
						id="basic-areas"
						value={info.area}
						disabled={disabled}
					/>
				</div>
				{/* address */}
				<div className="col-6">
					<TextField
						label={commons.address}
						placeholder={commons.addressPlaceholder}
						value={info.address}
						name="address"
						id="basic-address"
						disabled={disabled}
					/>
				</div>
				{/* distance */}
				{info.country?.value === 966 && (
					<div className="col-6">
						<TextField
							label={commons.distance}
							placeholder={commons.distancePlaceholder}
							value={info.distance}
							name="distance"
							id="basic-distance"
							disabled={disabled}
						/>
					</div>
				)}

				{/* postalCode */}
				<div className="col-6">
					<TextField
						label={commons.postalCode}
						placeholder={commons.postalCodePlaceholder}
						value={info.postalCode}
						name="postalCode"
						id="basic-postalCode"
						disabled={disabled}
					/>
				</div>
				{/* Phone Number */}
				<div className="col-md-6 pl-md-2">
					<TextField
						label={supplierDetails.phoneNumber}
						placeholder={supplierDetails.phoneNumberPlaceholder}
						name="phone"
						id="basic-phone"
						value={info.phone}
						extraText={info.country.value ? "+" + info.country.value : "---"}
						extraTextPosition="prepend"
						disabled={disabled}
					/>
				</div>
				{/* Phone Number */}
				<div className="col-md-6 pl-md-2">
					<TextField
						label={supplierDetails.phoneNumber}
						placeholder={supplierDetails.phoneNumberPlaceholder}
						name="secondPhone"
						id="basic-secondPhone"
						value={info.secondPhone}
						extraText={info.country.value ? "+" + info.country.value : "---"}
						extraTextPosition="prepend"
						disabled={disabled}
					/>
				</div>
				{/*  Reservations Email address  */}
				<div className="col-6">
					<TextField
						label={supplierDetails.reservationsEmail}
						placeholder={supplierDetails.emailPlaceholder}
						type="text"
						name="email"
						id="basic-email"
						value={info.email}
						disabled={disabled}
					/>
				</div>
				{/*  Business Email */}
				<div className="col-4">
					<TextField
						label={supplierDetails.officialEmail}
						placeholder={supplierDetails.emailPlaceholder}
						type="text"
						name="officialEmail"
						value={info.officialEmail}
						id="basic-officialEmail"
						disabled={disabled}
					/>
				</div>
				{/* isSame */}
				<div className="col-2 d-flex align-items-center">
					<div className="form-group form-check">
						<input
							id="basic-info-officialEmail"
							type="checkbox"
							className="form-check-input"
							defaultChecked={isSameEmail}
							disabled={disabled}
						/>
						<label className="form-check-label text-capitalize">
							{property.isSame}
						</label>
					</div>
				</div>
			</div>
			<div className="header-box my-3">{property.location}</div>
			<div className="col-6">
				<TextField
					label={property.xCord}
					placeholder={property.xCordPlaceholder}
					value={info.latitude}
					name="latitude"
					id="basic-latitude"
					disabled={disabled}
				/>
				<TextField
					label={property.yCord}
					placeholder={property.yCordPlaceholder}
					value={info.longitude}
					name="longitude"
					id="basic-longitude"
					disabled={disabled}
				/>
			</div>
		</div>
	);
}
