import React from "react";

export default function Transaction({ transaction, toggleView }) {
	return (
		<tr>
			<td>{transaction?.transaction_number}</td>
			<td>{transaction?.transaction_type} </td>
			<td>{transaction?.amount} </td>
			<td>{transaction?.action_type}</td>
			<td>{transaction?.created_at}</td>
			<td
				className={`${
					transaction?.status === "approved" ? "text-success" : "text-danger"
				}`}
			>
				{transaction?.status}
			</td>
			<td className="pointer" width={"2%"}>
				{" "}
				<i
					class="fas fa-eye f-18"
					onClick={() =>
						toggleView(transaction.transaction_number, transaction.amount)
					}
				></i>
			</td>
		</tr>
	);
}
