import Pagination from "components/shared/Pagination";
import ShowForPermission from "helpers/showForPermission";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Link, useParams } from "react-router-dom";
import { deleteRatePlan, getListRatePlan } from "services/rateplan";
import Locale from "translations";
const locale = localStorage.getItem("currentLocale") || "en";

function ListRatePlan() {
	const { hotelLayout, home } = Locale;
	const { id } = useParams();
	const [RatePlanList, setRatePlanList] = useState({});
	const fetchData = async (page = 1) => {
		const res = await getListRatePlan(id, page);
		if (res.status === 200) {
			setRatePlanList(res.data);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);
	const goTo = (page) => {
		fetchData(page);
	};

	const deleteRate = async (rateId) => {
		const res = await deleteRatePlan(id, rateId);
		if (res.status === 200) {
			fetchData();
			store.addNotification({
				title: "Info!",
				message: "done",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	return (
		<>
			<div className=" list-rate p-3">
				<div className="col-md-12 p-0">
					<div className="row">
						<div className="col-md-10 p-15">
							<p className="title">{hotelLayout.ratePlan.ratePlan}</p>
							<p className="sub-title">
								{hotelLayout.ratePlan.createAndReview}
							</p>
						</div>
						<div className="col-md-2">
							<ShowForPermission permission="manage-property">
								<Link
									to={`/dashboard/add-Rate-Plan/${id}`}
									className="btn btn-primary btn-add"
								>
									{hotelLayout.ratePlan.addNewRatePlane}
								</Link>
							</ShowForPermission>
						</div>
					</div>
				</div>

				{RatePlanList?.data?.length > 0 && (
					<>
						{" "}
						<div class="table-responsive">
							<table class="table mb-0 f-13">
								<thead className="table-light">
									<tr className="">
										<th scope="col"> {hotelLayout.ratePlan.ratePlanName}</th>
										<th scope="col">{hotelLayout.ratePlan.rooms}</th>
										<th scope="col">{hotelLayout.ratePlan.mealPlan}</th>
										<th scope="col">{hotelLayout.ratePlan.minimumStay}</th>
										<th scope="col">{home.action}</th>
									</tr>
								</thead>
								<tbody>
									{RatePlanList?.data?.length > 0 &&
										RatePlanList?.data?.map((property) => {
											return (
												<tr className=" text-black" key={property.id}>
													<td>{property.name}</td>
													<td>{property.rooms_count}</td>
													<td>{property.meal_option?.name}</td>
													<td>
														{property.is_available_for_any_length_of_stay
															? "No minimum length of stay"
															: property.minimum_length_of_stay}
													</td>

													<td>
														<ShowForPermission permission="manage-property">
															<Link
																className="btn-link-edit"
																to={`/dashboard/add-Rate-Plan/${id}/${property.id}`}
															>
																<i class="far fa-edit"></i>
															</Link>
															<Link
																className="btn-link-trash"
																onClick={() => deleteRate(property.id)}
															>
																<i class="fas fa-trash"></i>
															</Link>
														</ShowForPermission>
													</td>
												</tr>
											);
										})}
								</tbody>
							</table>{" "}
						</div>
						{RatePlanList?.data?.length > 0 ? (
							<Pagination info={RatePlanList?.meta} goTo={goTo} />
						) : null}
					</>
				)}
			</div>
		</>
	);
}

export default ListRatePlan;
