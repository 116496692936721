import SelectField from "components/shared/SelectField";
import TimePickerField from "components/shared/TimePickerField";
import { usePropertyDispatch, usePropertyState } from "context/property";
import { formatGetPolicies, formatPostPolicies } from "data/property";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useParams } from "react-router";
import { FormGroup, Input, Label } from "reactstrap";
import { viewProperties } from "services/property";
import Locale from "translations";
import { Tax } from "./Tax";

export default function Polices() {
	const { id } = useParams();
	const [disabled, setDisabled] = useState(true);

	const [taxes, setTaxes] = useState([
		{
			type: "Polices",
			name: "Polices",
			rate: "5",
		},
		{
			type: "Polices",
			name: "Polices",
			rate: "5",
		},
		{
			type: "Polices",
			name: "Polices",
			rate: "5",
		},
	]);
	const { polices } = Locale;
	const [allPolices, setAllPolices] = useState({
		checkInFrom: "",
		checkInTo: "",
		checkOutFrom: "",
		checkOutTo: "",
		petsCharges: "",
		haveChildren: "no",
		petsAllowed: "",

		taxes: [],
	});

	useEffect(async () => {
		const res = await viewProperties(id);
		if (res.status === 200 || res.status === 201) {
			setAllPolices({
				checkInFrom: res.data.property.arrivalPolicy.check_in_from,
				checkInTo: res.data.property.arrivalPolicy.check_in_to,
				checkOutFrom: res.data.property.arrivalPolicy.check_out_from,
				checkOutTo: res.data.property.arrivalPolicy.check_out_to,
				petsAllowed: res.data.property.otherPolicies.pets_allowed,
				petsCharges: res.data.property.otherPolicies.pets_charges,
				haveChildren: res.data.property.otherPolicies.have_childrens,
				taxes: res.data.property.taxPolicies,
				currencies:
					res.data.property.currencies &&
					res.data.property.currencies.length > 0
						? res.data.property.currencies.map((res) => {
								return { label: res.currency_code, value: res.currency_code };
						  })
						: "",
			});
		}
	}, []);

	return (
		<div className="container px-3 py-2">
			<div className="header-box my-3">{polices.arrivalPolicy}</div>
			<div className="row m-0">
				<div className="col-4">
					<TimePickerField
						label={polices.checkIn}
						id="polices-check-in"
						fromValue={allPolices.checkInFrom}
						toValue={allPolices.checkInTo}
						disabled={disabled}
					/>
				</div>
				<div className="col-4">
					<TimePickerField
						label={polices.checkOut}
						id="polices-check-out"
						name="checkOut"
						fromValue={allPolices.checkOutFrom}
						disabled={disabled}
						toValue={allPolices.checkOutTo}
					/>
				</div>
			</div>

			<div className="header-box my-3">{polices.price}</div>
			<div className="row m-0">
				<div className="col-4">
					<SelectField
						label={"currency"}
						placeholder={"currency"}
						name="petsAllowed"
						id="petsAllowed"
						value={allPolices.currencies}
						multi={true}
						disabled={disabled}
						// options={currencyList}
					/>
				</div>
			</div>

			<div className="header-box my-3">{polices.tax}</div>
			{allPolices.taxes.length > 0 &&
				allPolices.taxes.map((tax, i) => {
					return (
						<>
							<div className="row m-0">
								<Tax
									index={i}
									key={i}
									tax={{ ...tax, currencys: allPolices.currencies }}
								/>
							</div>
						</>
					);
				})}

			<div className="header-box my-3">{polices.children}</div>
			<p>{polices.accommodateChildren}</p>
			<FormGroup tag="fieldset" className="col-12 name-check">
				<FormGroup check>
					<Label check>
						<Input
							type="radio"
							checked={allPolices.haveChildren === "1"}
							name="children"
							disabled={disabled}
							id="children-yes"
						/>
						Yes
					</Label>
				</FormGroup>
				<FormGroup check>
					<Label check>
						<Input
							type="radio"
							checked={allPolices.haveChildren === "0"}
							name="children"
							disabled={disabled}
							id="children-no"
						/>
						No
					</Label>
				</FormGroup>
			</FormGroup>
			<div className="header-box my-3">{polices.pets}</div>
			<p>{polices.petsDetails}</p>
			<div className="row m-0">
				<div className="col-4">
					<SelectField
						label={polices.allowPets}
						placeholder={polices.allowPets}
						name="petsAllowed"
						id="petsAllowed"
						value={{ value: 1, label: allPolices.petsAllowed }}
						options={[
							{ label: "No", value: "no" },
							{ label: "Yes", value: "yes" },
						]}
						disabled={disabled}
					/>
				</div>
				<div className="col-4">
					<SelectField
						label={polices.chargesForPets}
						placeholder={polices.chargesForPets}
						name="petsCharges"
						id="petsCharges"
						options={[
							{ label: "Free", value: "free" },
							{ label: "Paid", value: "paid" },
						]}
						value={{ value: 1, label: allPolices.petsCharges }}
						disabled={disabled}
					/>
				</div>
			</div>
		</div>
	);
}
