import tick from "assets/images/tick.svg";
import {
	useVerifyHotelDispatch,
	useVerifyHotelState,
} from "context/verifyHotel";
import { formatVerifyHotel } from "data/verifyhotel";
import moment from "moment";
import React, { useState } from "react";
import { store } from "react-notifications-component";
import { Link, useHistory, useParams } from "react-router-dom";
import { editContract, verifyHotel } from "services/verify-hotel";
import Locale from "translations";

export default function Confirmation(props) {
	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	const { id } = useParams();
	const { propertyDetails, userDetails, commons } = Locale;
	const VerifyHotel = useVerifyHotelState();
	const history = useHistory();

	//const data = useVerifyHotelState();
	//
	const dispatch = useVerifyHotelDispatch();
	const [done, setDone] = useState(false);
	const [checked, setChecked] = useState(true);
	const canEdit = props?.canEdit;


	const submitStepResq = async () => {
		const data = formatVerifyHotel(VerifyHotel);
		if (VerifyHotel.ManagerDetails.owns_property.value == 2) {
			delete data["management_company"];
		} else if (VerifyHotel.ManagerDetails.owns_property.value == 1) {
			delete data["management_manager"];
		}

		if (canEdit) {
			const res = await editContract(id, data);
			if (res?.status == 200 || res?.status == 201) {
				setDone(true);
				store.addNotification({
					title: "Info!",
					message: res.statusText,
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			}
		} else {
			const res = await verifyHotel(id, data);
			if (res?.status == 200 || res?.status == 201) {
				setDone(true);
				store.addNotification({
					title: "Info!",
					message: res.statusText,
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			}
		}
	};

	const setStep = (step, progress) => {
		dispatch({ type: "setStep", progress: progress, step: step });
	};
	const backStep = () => {
		dispatch({ type: "setStep", progress: 50, step: 2 });
		//history.push("/");
	};

	// ----------------------------------------------------------------------------------------------------
	// Main Component Body UI
	// ----------------------------------------------------------------------------------------------------
	if (true) {
		return (
			<>
				{!done ? (
					<div className="col-md-12 mt-2 mb-5 confirmation">
						<h1 className="text-center text-title text-dark-blue mt-4 mb-3">
							{propertyDetails.managerDetails}
						</h1>

						<div className="bg-white p-2 px-3 border radius-10 mt-3 mb-4">
							<div className="row m-0">
								<div className="col-md-12">
									<p className="title">
										{" "}
										{propertyDetails.reviewSubmitDetails}{" "}
									</p>
									<p className="sub-title">
										{propertyDetails.beforeSubmittingCheck}
									</p>
								</div>
							</div>
						</div>

						{/* <div className="bg-white p-3 px-3 radius-10 mt-3 mb-4">
							<div className="row m-0">
								<div className="col-md-4">
									<p className="section-title">
										{propertyDetails.propertyDetails}
									</p>
								</div>

								<div className="col-md-8 text-right">
									<a
										href={() => false}
										className="section-link "
										onClick={() => {
											setStep(1, 0);
										}}
									>
										Edit Property details
									</a>
								</div>

								<div className="col-md-6">
									<p className="label">
										{" "}
										{propertyDetails.hotelNameAsClassificationCertificate}
									</p>
								</div>
								<div className="col-md-6">
									<p>{VerifyHotel.PropertyDetails?.property_name}</p>
								</div>

								<div className="col-md-6">
									<p className="label"> {propertyDetails.tradeName}</p>
								</div>
								<div className="col-md-6">
									<p>{VerifyHotel.PropertyDetails?.trade_name}</p>
								</div>

								<div className="col-md-6">
									<p className="label">
										{" "}
										{propertyDetails.addressStreetNameHouse}
									</p>
								</div>

								<div className="col-md-6">
									<p>{VerifyHotel.PropertyDetails?.address}</p>
								</div>

								<div className="col-md-6">
									<p className="label"> {propertyDetails.unitNumber}</p>
								</div>
								<div className="col-md-6">
									<p>{VerifyHotel.PropertyDetails?.unit_number}</p>
								</div>

								<div className="col-md-6">
									<p className="label"> {propertyDetails.zipCode}</p>
								</div>
								<div className="col-md-6">
									<p>{VerifyHotel.PropertyDetails?.zip_code}</p>
								</div>

								<div className="col-md-6">
									<p className="label"> {userDetails.country}</p>
								</div>
								<div className="col-md-6">
									<p>{VerifyHotel.PropertyDetails?.country?.label}</p>
								</div>

								<div className="col-md-6">
									<p className="label"> {propertyDetails.townCity}</p>
								</div>
								<div className="col-md-6">
									<p>{VerifyHotel.PropertyDetails?.town_city?.label}</p>
								</div>

								<div className="col-md-6">
									<p className="label"> {propertyDetails.officialDocuments}</p>
								</div>
								<div className="col-md-6">
									{VerifyHotel.PropertyDetails?.photos.map((photo, i) => {
										return <p>{photo.name} </p>;
									})}
								</div>
							</div>
						</div> */}

						<div className="bg-white p-3 px-3 border radius-10 mt-3 mb-4">
							<div className="row m-0">
								<div className="col-md-4">
									<p className="section-title">
										{propertyDetails.ownerDetails}
									</p>
								</div>

								<div className="col-md-8 text-right">
									<i className='fas fa-edit text-primary me-1' ></i>
									<a
										href={() => false}
										className="section-link "
										onClick={() => {
											setStep(1, 0);
										}}
									>
										Edit Property Owner
									</a>
								</div>

								<div className="col-md-6">
									<p className="label"> {propertyDetails.WhoOwnsTheProperty}</p>
								</div>
								<div className="col-md-6">
									<p>{VerifyHotel.OwnerDetails?.owns_property?.label}</p>
								</div>

								<div className="col-md-6">
									<p className="label">
										{" "}
										{propertyDetails.fullNameOfBusinessEntity}
									</p>
								</div>
								<div className="col-md-6">
									<p>{VerifyHotel.OwnerDetails?.full_name_Business}</p>
								</div>

								<div className="col-md-6">
									<p className="label">
										{" "}
										{propertyDetails.addressOfBusinessEntity}
									</p>
								</div>
								<div className="col-md-6">
									<p>{VerifyHotel.OwnerDetails?.address_business}</p>
								</div>

								<div className="col-md-6">
									<p className="label"> {propertyDetails.unitNumber}</p>
								</div>
								<div className="col-md-6">
									<p>{VerifyHotel.OwnerDetails?.unit_number}</p>
								</div>

								<div className="col-md-6">
									<p className="label"> {propertyDetails.zipCode}</p>
								</div>
								<div className="col-md-6">
									<p>{VerifyHotel.OwnerDetails?.zip_code}</p>
								</div>

								<div className="col-md-6">
									<p className="label"> {userDetails.country}</p>
								</div>
								<div className="col-md-6">
									<p>{VerifyHotel.OwnerDetails?.country?.label}</p>
								</div>

								<div className="col-md-6">
									<p className="label"> {propertyDetails.townCity}</p>
								</div>
								<div className="col-md-6">
									<p>{VerifyHotel.OwnerDetails?.town_city?.label}</p>
								</div>

								<div className="col-md-6">
									<p className="label">
										{" "}
										Provide the full names and email of the ultimate beneficial
										owners who own 25% or more of the property management
										company (either directly or through a holding company)
									</p>
								</div>
								<div className="col-md-6">
									<p>
										{VerifyHotel.OwnerDetails?.OwnerNames[0]?.first_name}{" "}
										{VerifyHotel.OwnerDetails?.OwnerNames[0]?.last_name}{" "}
									</p>
								</div>
							</div>
						</div>

						<div className="bg-white p-3 px-3 border radius-10 mt-3 mb-4">
							<div className="row m-0">
								<div className="col-md-4">
									<p className="section-title">
										{propertyDetails.managerDetails}
									</p>
								</div>

								<div className="col-md-8 text-right">
								<i className='fas fa-edit text-primary me-1' ></i>
									<a
										href={() => false}
										className="section-link "
										onClick={() => {
											setStep(2, 50);
										}}
									>
										Edit Property Manager
									</a>
								</div>

								<div className="col-md-6">
									<p className="label">
										{" "}
										{propertyDetails.WhoManagesTheProperty}
									</p>
								</div>
								<div className="col-md-6">
									<p>{VerifyHotel.ManagerDetails?.owns_property?.label}</p>
								</div>
								{VerifyHotel.ManagerDetails?.owns_property?.value === 1 ? (
									<>
										<div className="col-md-6">
											<p className="label">
												{" "}
												{propertyDetails.fullNameOfTheManagementCompany}
											</p>
										</div>
										<div className="col-md-6">
											<p>{VerifyHotel.ManagerDetails.full_name_Management}</p>
										</div>

										<div className="col-md-6">
											<p className="label">
												{" "}
												{
													propertyDetails.alternativeNameYouUseForOperatingYourBusiness
												}
											</p>
										</div>
										<div className="col-md-6">
											<p>
												{VerifyHotel.ManagerDetails.alternative_name_Business}
											</p>
										</div>

										<div className="col-md-6">
											<p className="label"> {propertyDetails.unitNumber}</p>
										</div>
										<div className="col-md-6">
											<p>{VerifyHotel.ManagerDetails.unit_number}</p>
										</div>

										<div className="col-md-6">
											<p className="label"> {userDetails.country}</p>
										</div>
										<div className="col-md-6">
											<p>{VerifyHotel.ManagerDetails?.country?.label}</p>
										</div>

										<div className="col-md-6">
											<p className="label"> {propertyDetails.townCity}</p>
										</div>
										<div className="col-md-6">
											<p>{VerifyHotel.ManagerDetails?.town_city?.label}</p>
										</div>

										<div className="col-md-6">
											<p className="label"> {propertyDetails.zipCode}</p>
										</div>
										<div className="col-md-6">
											<p>{VerifyHotel.ManagerDetails?.zip_code}</p>
										</div>
									</>
								) : (
									<>
										<div className="col-md-6">
											<p className="label">
												{" "}
												{propertyDetails.providePropertyManagerFullName}
											</p>
										</div>
										<div className="col-md-6">
											<p>{VerifyHotel.ManagerDetails?.full_name_Management}</p>
										</div>
										{/* 
										<div className="col-md-6">
											<p className="label"> birth Date</p>
										</div>
										<div className="col-md-6">
											<p>
												{moment(VerifyHotel.ManagerDetails?.birthDate).format(
													"YYYY-MM-DD"
												)}
											</p>
										</div> */}
									</>
								)}
							</div>
						</div>

						{/* <div className="col-md-12">
							<div className="form-check">
								<input
									type="checkbox"
									className="form-check-input"
									id="exampleCheck1"
									onChange={(e) => setChecked(e.target.checked)}
								/>
								<label className="form-check-label" for="exampleCheck1">
									I have READ and AGREE to all the Terms & Conditions
								</label>
							</div>
						</div> */}

						<div className="row mt-3 justify-content-between m-0">
							<div className="col-md-6"></div>
							<div className="col-md-2">
								<button
									onClick={() => {
										backStep();
									}}
									className="btn btn-light btn-light-new w-100"
								>
									<span className="icx icx-left-solid-arrow text-10"></span>{" "}
									{commons.back}
								</button>
							</div>
							<div className="col-md-4 p-0">
								<button
									className="btn btn-primary w-100"
									onClick={() => submitStepResq()}
									disabled={!checked}
								>
									{propertyDetails.submit}
								</button>
							</div>
						</div>
					</div>
				) : (
					<div className="col-md-12 mt-2 mb-5 confirmation">
						<div className="bg-white p-2 px-3 border radius-10 mt-3 mb-4 fix-done">
							<img src={tick} alt="" />
							<h2 className="text-center text-title text-dark-blue mt-4 mb-3">
								Thanks! We’ve received your submission
							</h2>
							<h5 className="text-center text-subtitle text-dark-blue mt-4 mb-3">
								We’ll verify the info submitted and inform you if there are and
								additional requirements.
							</h5>

							<Link to="/" className="btn btn-primary col-md-3 m-auto d-block">
								<span className="icx icx-left-solid-arrow text-10"></span> Back
								To Home
							</Link>
						</div>
					</div>
				)}
			</>
		);
	} else {
		// return <Loader message={loaderMessage} />;
	}
}
