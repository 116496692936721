import TextField from "components/shared/TextField";
import validate from "helpers/validate";
import React from "react";
import Locale from "translations";

export default function OwnerNameInput(props) {
	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	const { propertyDetails, commons } = Locale;
	// ----------------------------------------------------------------------------------------------------
	// Main Component Body UI
	// ----------------------------------------------------------------------------------------------------
	const onInputOwners = (name, value) => {
		let Owners;
		if (props.ownerDetailsState.OwnerNames) {
			Owners = [...props.ownerDetailsState.OwnerNames];
		} else {
			Owners = [...props.ownerDetailsState.ManagerNames];
		}

		Owners[props.index][name] = value;
		props.setOwnerDetailsState({
			...props.ownerDetailsState,
			OwnerNames: Owners,
		});
	};

	return (
		<>
			<div className="bg-white  w-100 radius-10 ">
				<div className="row m-0">
					{/* first Name */}
					<div className="col-4">
						<TextField
							type="text"
							label={propertyDetails.firstName}
							placeholder={propertyDetails.firstName}
							value={props.owner.first_name}
							name="firstName"
							onChange={(e) => {
								props.setErrors({
									...props.errors,
									...validate(
										{ name: `firstName${props.index}`, value: e.target.value },
										{
											required: true,
										}
									),
								});
								onInputOwners("first_name", e.target.value);
							}}
							color={
								props?.errors?.["firstName" + props.index]?.required
									? "danger"
									: ""
							}
							errors={props?.errors?.["firstName" + props.index]}
						/>
					</div>

					{/* first Name */}
					<div className="col-4">
						<TextField
							type="text"
							label={propertyDetails.lastName}
							placeholder={propertyDetails.lastName}
							value={props.owner.last_name}
							name="lastName"
							onChange={(e) => {
								props.setErrors({
									...props.errors,
									...validate(
										{ name: `lastName${props.index}`, value: e.target.value },
										{
											required: true,
										}
									),
								});
								onInputOwners("last_name", e.target.value);
							}}
							color={
								props?.errors?.["lastName" + props.index]?.required
									? "danger"
									: ""
							}
							errors={props?.errors?.["lastName" + props.index]}
						/>
					</div>

					<div className="col-4">
						<TextField
							type="text"
							label={commons.email}
							placeholder={commons.email}
							value={props.owner.email}
							name="email"
							onChange={(e) => {
								props.setErrors({
									...props.errors,
									...validate(
										{ name: `email${props.index}`, value: e.target.value },
										{
											required: true,
											email: true,
										}
									),
								});
								onInputOwners("email", e.target.value);
							}}
							color={
								props?.errors?.["email" + props.index]?.required ||
								props?.errors?.["email" + props.index]?.email
									? "danger"
									: ""
							}
							errors={props?.errors?.["email" + props.index]}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
