import React, { useState } from "react";
import Locale from "translations";
import { Modal, ModalFooter, ModalBody } from "reactstrap";
import DatePickerField from "./DatePickerField";
import moment from "moment";
import { getNotification } from "helpers/makeNotifications";
import { useParams } from "react-router-dom";
import { EditExpirationDate } from "services/reservations";

export default function StatusBar(props) {
	const { reservations } = Locale;
	const { id, reservation_id } = useParams();

	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => {
		setIsOpen(!isOpen);
	};
	//
	const [expiration_date, setExpiration_date] = useState(
		moment(props?.details.payment?.expiration_date)
	);
	const voucherConfigrations = async () => {
		const res = await EditExpirationDate(reservation_id, {
			expiration_date,
		});
		if (res.message) {
			getNotification(res.message);
			toggle();
			props.setSuccessfully((prev) => !prev);
		}
	};
	const allSteps = (
		<ul className="progressbar">
			{props.status.map((item, index) => {
				return (
					<li className={`progressbarItem   `}>
						<div
							className={`step-holder   ${
								item.status === "inactive" ? "" : "active-item"
							}  ${
								index === +props.status.length - 1
									? "remove-after adjust-step-holder"
									: ""
							} `}
						>
							<div
								className={`step-dashes mb-1 ${
									item.status === "inactive" ? "" : "active-status"
								} `}
							>
								{item.status === "inactive" ? (
									<i class="fas fa-ellipsis-h icon-step  "></i>
								) : (
									<i class="fas fa-check text-primary icon-step"></i>
								)}
							</div>
						</div>

						<div
							className={`item-name font-weight-bold text-center   pb-3 ${
								item.status === "inactive" ? "text-muted" : ""
							} `}
						>
							{item.name}
							<p className="item-name text-success m-0 extra-text">
								{" "}
								{item.extraName}
							</p>
						</div>
						{/* reservations.approvedRequest */}
						{item.name == reservations.approvedRequest &&
						props?.details?.payment_status != "paid" &&
						props?.details?.status == "approved" ? (
							<button
								type="button"
								class="btn btn-primary "
								onClick={() => {
									toggle();
								}}
							>
								Edit Expiration Date{" "}
							</button>
						) : (
							<button type="button" class="btn btn-primary invisible ">
								..
							</button>
						)}
					</li>
				);
			})}
		</ul>
	);
	console.log("expiration_date", props?.details.payment?.expiration_date);
	return (
		<div className="bg-white my-2 rounded">
			<div className="bar-header rounded ">
				<h3 className="bar-title  p-3">{props.title}</h3>
			</div>
			<div className="p-3 py-5">{allSteps}</div>

			{/*Modal  */}
			<Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: "600px" }}>
				<div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
					<p className=" text-secondary font-weight-bold w-100">
						Edit Expiration Date
					</p>
					<button
						type="button"
						onClick={toggle}
						class="close"
						aria-label="Close"
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				{/* Modal fields */}
				<ModalBody className="  d-flex flex-column justify-content-center align-items-center ">
					<div className="w-75">
						<div className="w-100">
							<DatePickerField
								label={"Expiration Date"}
								minDate={moment(new Date()).subtract(1, "days")}
								date={expiration_date}
								onChangeDate={(e) => {
									setExpiration_date(e);
								}}
							/>
						</div>
					</div>
				</ModalBody>

				{/* Modal Actions */}
				<div className="pb-5 border-top-0  text-center px-4   p-0 ">
					<button
						className="rounded btn btn-primary w-75 py-2 mx-1 "
						onClick={voucherConfigrations}
					>
						Edit
					</button>
				</div>
			</Modal>
		</div>
	);
}
