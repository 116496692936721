import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import {
	useHistory,
	useParams,
} from "react-router-dom/cjs/react-router-dom.min";
//import { NavLink } from "react-router-dom";
import Locale from "translations";
import VisaRequest from "./visaRequest";

export default function Visa() {
	const { requests, backOffice } = Locale;
	const history = useHistory();
	const { status } = useParams();
	const issuedTab = history.location.pathname.includes("issued");
	const waitingTab = history.location.pathname.includes("waiting");
	let x = waitingTab ? "1" : "2";
	const [activeTab, setActiveTab] = useState(x);

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	useEffect(() => {
		setActiveTab(waitingTab ? "1" : "2");
	}, [status]);



	return (
		<div>
			<div className="container-fluid visa-nav pt-5 px-5">
				<div className="tab-icons product-build__leftside-menu">
					<Nav tabs>
						{waitingTab ? (
							<NavItem className="bg-white">
								<NavLink
									className={classnames({
										active: activeTab === "1" || waitingTab,
									})}
									onClick={() => {
										toggle("1");
										history.push("/manage-visas/waiting");
									}}
								>
									<div className="product-build-list-item">
										<span className="text mx-1">
											<i class="fas fa-hourglass-half mx-1"></i>
											{requests.WaitingForIssuingVisa}
										</span>
									</div>
								</NavLink>
							</NavItem>
						) : (
							<NavItem className="bg-white">
								<NavLink
									className={classnames({
										active: activeTab === "2" || issuedTab,
									})}
									onClick={() => {
										toggle("2");
										history.push("/manage-visas/issued");
									}}
								>
									<div className="product-build-list-item">
										<span className="text mx-1">
											<i class="fas fa-check-circle"></i> {requests.IssuedVisa}
										</span>
									</div>
								</NavLink>
							</NavItem>
						)}
					</Nav>
				</div>
				<div className="tab-content product-build__tabs-content">
					<TabContent activeTab={activeTab} className=" pt-2 pb-4  bg-white">
						<TabPane tabId={waitingTab ? "1" : "2"} className="container-fluid">
							<VisaRequest />
						</TabPane>
						{/* <TabPane tabId="2" className="container">
							{" "}
							<VisaRequest />
						</TabPane> */}
					</TabContent>
				</div>

				<div class="d-flex my-2 flex-row-reverse align-items-center w-100">
					<div class=""></div>
				</div>
			</div>
		</div>
	);
}
