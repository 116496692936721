import React from "react";
import mainLogo from "../../../../assets/images/gallery.jpg";

export default function Photo({ photo }) {
	return (
		<div className="col-4 ">
			<figure className="img-cover m-0 ">
				<img src={photo.image} alt="" className=" " />
			</figure>
		</div>
	);
}
