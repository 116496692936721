import PasswordField from "components/shared/PasswordField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import Loader from "components/Loader";
import { useAuthDispatch, useAuthState } from "context/auth";
import libphonenumber from "google-libphonenumber";
import validate, { isFormValid } from "helpers/validate";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import { emailAvailability, nameAvailability } from "services/auth";
import Locale from "translations";

export default function SupplierDetails(props) {
	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	const { supplierDetails, commons } = Locale;
	const authState = useAuthState();
	const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
	const [supplier, setSupplier] = useState({
		name: authState.supplierDetails?.name ?? "",
		category: authState.supplierDetails?.category ?? "",
		owner: authState.supplierDetails?.owner ?? "",
		email: authState.supplierDetails?.email ?? "",
		country: authState.supplierDetails?.country ?? "",
		countryPhoneCode: authState.supplierDetails?.phoneCode ?? "",
		phone: authState.supplierDetails?.phone ?? "",
		password: authState.supplierDetails?.password ?? "",
		passwordConfirmation: authState.supplierDetails?.confirmPassword ?? "",
		countryCode: null,
		spin: false,
	});

	const dispatch = useAuthDispatch();
	const [errors, setErrors] = useState(authState.supplierDetails?.errors ?? {});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const validatePhone = (phone) => {
		// props.countries.find(
		// 	(country) => country.value === supplier.country.value
		// ).code
		try {
			return phoneUtil.isValidNumberForRegion(
				phoneUtil.parse(phone.toString(), supplier.countryCode),
				supplier.countryCode
			);
		} catch (err) {
			return false;
		}
	};

	const isPasswordMatch = supplier.passwordConfirmation === supplier.password;

	// ----------------------------------------------------------------------------------------------------
	// Component Functions
	// ----------------------------------------------------------------------------------------------------

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "name", value: supplier.name },
				{ required: true, min: 3 }
			),
			// ...validate(
			// 	{ name: "category", value: supplier.category },
			// 	{ required: true }
			// ),
			// ...validate(
			// 	{ name: "owner", value: supplier.owner },
			// 	{ required: true, min: 3 }
			// ),
			...validate(
				{ name: "email", value: supplier.email },
				{ required: true, email: true }
			),
			...validate(
				{ name: "country", value: supplier.country },
				{ required: true }
			),
			...validate(
				{
					name: "phone",
					value: validatePhone(supplier.phone),
				},
				{ required: true, phone: true }
			),
			...validate(
				{ name: "password", value: supplier.password },
				{ required: true, password: true, min: 8 }
			),
			...validate(
				{
					name: "confirm",
					value: isPasswordMatch,
				},
				{ required: true }
			),
		});
	};

	// SUbmit Form
	const submitForm = () => {
		dispatch({
			type: "supplierDetails",
			payload: {
				name: supplier.name,
				email: supplier.email,
				country: supplier.country,
				category: supplier.category,
				phone: supplier.phone,
				owner: supplier.owner,
				phoneCode: supplier.countryPhoneCode,
				password: supplier.password,
				confirmPassword: supplier.passwordConfirmation,
				errors: errors,
			},
		});
		dispatch({ type: "Progress", progress: 50, step: 2 });
	};

	useEffect(() => {
		const checkUniqueness = async () => {
			if (isFormValid(errors) && authState.lastStep === 1) {
				const [emailRes, nameRes] = await Promise.all([
					emailAvailability({ email: supplier.email }),
					nameAvailability({ supplier_name: supplier.name }),
				]);
				if (emailRes.status === 200 && nameRes.status === 200) {
					submitForm();
				}
				// else {
				// 	store.addNotification({
				// 		title: commons.somethingWentWrong,
				// 		message: supplierDetails.businessExistInvalideValidationMessage,
				// 		type: "danger",
				// 		insert: "top",
				// 		container: "top-right",
				// 		animationIn: ["animated", "fadeIn"],
				// 		animationOut: ["animated", "fadeOut"],
				// 		dismiss: {
				// 			duration: 3000,
				// 			onScreen: true,
				// 			pauseOnHover: true
				// 		}
				// 	});
				// }
			} else {
				dispatch({ type: "Progress", progress: 0, step: 1, lastStep: 1 });
			}
			setSupplier({
				...supplier,
				spin: false,
			});
		};
		checkUniqueness();
	}, [isErrorLoaded]);

	const checkAvailability = () => {
		setSupplier({
			...supplier,
			spin: true,
		});
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	// Use Effect for [Country]
	useEffect(() => {
		const selectedCountry = props.countries?.filter(
			(country) => country.value == supplier.country?.value
		);
		setSupplier({
			...supplier,
			countryPhoneCode:
				selectedCountry.length > 0 ? selectedCountry[0].value : "-",
			countryCode:
				selectedCountry.length > 0 ? selectedCountry[0]["code"] : null,
		});
	}, [props.countries, supplier.country?.value]);

	useEffect(() => {
		if (!isPasswordMatch) {
			setErrors({
				...errors,
				...validate(
					{
						name: "confirm",
						value: isPasswordMatch,
					},
					{
						required: true,
						confirm: true,
					}
				),
			});
		} else {
			setErrors({
				...authState.supplierDetails?.errors,
				confirm: {},
			});
		}
	}, [supplier.password, supplier.passwordConfirmation]);
	// ----------------------------------------------------------------------------------------------------
	// Main Component Body UI
	// ----------------------------------------------------------------------------------------------------

	return (
		<>
			<h1 className="text-center text-title text-dark-blue">
				{supplierDetails.title}
			</h1>

			<div className="bg-white p-2 px-3 radius-10 mt-3">
				<div className="row m-0">
					{/* Full Name */}
					<div className="col-6">
						<TextField
							type="text"
							label={supplierDetails.name}
							placeholder={supplierDetails.namePlaceholder}
							value={supplier.name}
							name="name"
							id="supplier-name"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										min: 3,
									}),
								});
								setSupplier({
									...supplier,
									name: e.target.value,
								});
							}}
							min={3}
							color={
								errors?.name?.required || errors?.name?.min ? "danger" : ""
							}
							errors={errors?.name}
						/>
					</div>

					{/* Category */}
					{/* <div className="col-md-6 pr-md-2">
						<SelectField
							label={supplierDetails.category}
							value={supplier.category}
							name="category"
							id="supplier-category"
							options={props.catagories}
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(
										{ name: "category", value: e.value },
										{ required: true }
									),
								});

								setSupplier({
									...supplier,
									category: e,
								});
							}}
							onBlur={() =>
								setErrors({
									...errors,
									...validate(
										{ name: "category", value: supplier.category },
										{ required: true }
									),
								})
							}
							color={errors?.category?.required ? "danger" : ""}
							errors={errors?.category}
						/>
					</div> */}
					{/* Owner name */}
					{/* <div className="col-6">
						<TextField
							type="text"
							label={supplierDetails.owner}
							placeholder={supplierDetails.ownerPlaceholder}
							value={supplier.owner}
							name="owner"
							id="owner-name"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										min: 3,
									}),
								});
								setSupplier({
									...supplier,
									owner: e.target.value,
								});
							}}
							min={3}
							color={
								errors?.owner?.required || errors?.owner?.min ? "danger" : ""
							}
							errors={errors?.owner}
						/>
					</div> */}

					{/* Business Email */}
					<div className="col-6">
						<TextField
							label={supplierDetails.businessEmail}
							placeholder={supplierDetails.businessEmailPlaceholder}
							value={supplier.email}
							name="email"
							id="business-email"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										email: true,
									}),
								});
								setSupplier({
									...supplier,
									email: e.target.value,
								});
							}}
							color={
								errors?.email?.required || errors?.email?.email ? "danger" : ""
							}
							errors={errors?.email}
						/>
					</div>

					{/* Country */}
					<div className="col-md-6 pr-md-2">
						<SelectField
							label={supplierDetails.country}
							value={supplier.country}
							name="country"
							id="supplier-country"
							options={props.countries}
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(
										{ name: "country", value: e },
										{ required: true }
									),
								});
								setSupplier({
									...supplier,
									country: e,
									phone: "",
								});
							}}
							onBlur={() =>
								setErrors({
									...errors,
									...validate(
										{ name: "country", value: supplier.country },
										{ required: true }
									),
								})
							}
							color={errors?.country?.required ? "danger" : ""}
							errors={errors?.country}
						/>
					</div>

					{/* Phone Number */}
					<div className="col-md-6 pl-md-2">
						<TextField
							label={supplierDetails.phoneNumber}
							placeholder={supplierDetails.phoneNumberPlaceholder}
							name="phone"
							id="supplier-phone"
							value={supplier.phone}
							extraText={supplier.countryPhoneCode ?? "---"}
							extraTextPosition="prepend"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(
										{ name: "phone", value: validatePhone(e.target.value) },
										{
											required: true,
											phone: true,
										}
									),
								});
								setSupplier({
									...supplier,
									countryPhoneCode: supplier.countryPhoneCode,
									countryCode: supplier.countryCode,
									phone: e.target.value,
								});
							}}
							color={
								errors?.phone?.required || errors?.phone?.phone ? "danger" : ""
							}
							errors={errors?.phone}
						/>
					</div>

					{/* Password */}
					<div className="col-12">
						<PasswordField
							label={supplierDetails.password}
							placeholder={supplierDetails.passwordPlaceholder}
							name="password"
							id="supplier-password"
							value={supplier.password}
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(
										{ name: "password", value: e.target.value },
										{
											required: true,
											password: true,
											min: 8,
										}
									),
								});
								setSupplier({
									...supplier,
									password: e.target.value,
								});
							}}
							min={8}
							color={
								errors?.password?.required ||
									errors?.password?.password ||
									errors?.password?.min
									? "danger"
									: ""
							}
							errors={errors?.password}
						/>
					</div>

					{/* Repeat Password */}
					<div className="col-12">
						<PasswordField
							label={supplierDetails.repeatPassword}
							placeholder={supplierDetails.repeatPasswordPlaceholder}
							name="confirm"
							id="supplier-confirm"
							value={supplier.passwordConfirmation}
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(
										{ name: "confirm", value: !isPasswordMatch },
										{
											required: true,
											confirm: true,
										}
									),
								});
								setSupplier({
									...supplier,
									passwordConfirmation: e.target.value,
								});
							}}
							min={8}
							color={
								errors?.confirm?.required || errors?.confirm?.confirm
									? "danger"
									: ""
							}
							errors={errors?.confirm}
						/>
					</div>
				</div>
			</div>

			<div className="row mt-3 justify-content-between m-0">
				<div className="col-md-6">
					<Link to="/login" className="mt-2 text-dark-blue" id="supplier-back">
						<span className="icx icx-left-solid-arrow text-10"></span>{" "}
						{commons.backBtn}
					</Link>
				</div>
				<div className="col-md-4 p-0">
					<button
						className="btn btn-primary w-100"
						onClick={() => checkAvailability()}
						disabled={supplier.spin}
						id="supplier-next-1"
					>
						{supplier.spin ? <Spinner color="light" size="sm" /> : null}{" "}
						{commons.next}
					</button>
				</div>
			</div>
		</>
	);
}
