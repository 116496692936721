import React, { useState } from "react";
import { Collapse } from "reactstrap";
import Amenity from "./Amenity";

function OtherAmenity({
	category,
	name,
	allAmenities,
	setAllAmenities,
	roomTypes,
}) {
	const [isOpen, setIsOpen] = useState(false);
	const [selected, setSelected] = useState([]);
	const toggleButton = () => setIsOpen(!isOpen);
	//
	// 	"category",
	// 	category.filter((item) => item.most_requested === 0)
	// );

	const getSelected = () => {
		let selected = [];

		const filtered = category
			.filter((item) => +item.most_requested === 0)
			.map((item) => (item = item.id));
		allAmenities.map((item) => {
			if (filtered.includes(item.id)) {
				selected.push(item);
			}
		});
		return selected;
	};

	//
	return (
		<>
			<div className="button-collapse" onClick={toggleButton}>
				<div className="d-flex p-3 justify-content-between">
					<h6>{name}</h6>

					<div className="icon-items-section d-flex align-items-center">
						<div className="mx-5 text-black">
							{getSelected().length}/
							{category.filter((item) => +item.most_requested === 0).length}{" "}
							selected
						</div>
						<i
							onClick={toggleButton}
							className={`fas fa-fw pointer text-primary fa-lg ${
								isOpen !== true ? "fa-chevron-right " : "fa-chevron-down"
							} `}
						></i>
					</div>
				</div>
			</div>
			<Collapse isOpen={isOpen} className="">
				{category
					.filter((item) => +item.most_requested === 0)
					.map((amenity, i) => (
						<Amenity
							key={i}
							allAmenities={allAmenities}
							setAllAmenities={setAllAmenities}
							amenity={amenity}
							roomTypes={roomTypes}
						/>
					))}
			</Collapse>
		</>
	);
}

export default OtherAmenity;
