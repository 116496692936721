import React, { createContext, useContext, useReducer } from "react";
const verifyHotelContext = createContext();
const verifyHotelDispatchContext = createContext();
const initialState = {};

function verifyHotelReducer(state, action) {
	switch (action.type) {
		case "setPropertyDetails": {
			return { ...state, PropertyDetails: action.payload };
		}
		case "setOwnerDetails": {
			return { ...state, OwnerDetails: action.payload };
		}
		case "setManagerDetails": {
			return { ...state, ManagerDetails: action.payload };
		}
		case "setManagerType": {
			return { ...state, ManagerType: action.payload };
		}
		case "setData": {
			return { ...state, data: action.payload };
		}
		case "setStep": {
			return {
				...state,
				progress: action.progress,
				step: action.step,
				lastStep: action.lastStep,
			};
		}

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function useVerifyHotelState() {
	const context = useContext(verifyHotelContext);
	if (context === undefined) {
		throw new Error(
			"useverifyHotelState must be used within a verifyHotelProvider"
		);
	}
	return context;
}

function useVerifyHotelDispatch() {
	const context = useContext(verifyHotelDispatchContext);
	if (context === undefined) {
		throw new Error(
			"useverifyHotelDispatch must be used within a verifyHotelProvider"
		);
	}
	return context;
}

function VerifyHotelProvider({ children }) {
	const [state, dispatch] = useReducer(verifyHotelReducer, initialState);
	return (
		<verifyHotelContext.Provider value={state}>
			<verifyHotelDispatchContext.Provider value={dispatch}>
				{children}
			</verifyHotelDispatchContext.Provider>
		</verifyHotelContext.Provider>
	);
}

export { VerifyHotelProvider, useVerifyHotelState, useVerifyHotelDispatch };
