import { useVerifyHotelState } from "context/verifyHotel";
import React from "react";
import { Progress } from "reactstrap";

export default function VerifyProgress(props) {
	const { progress } = useVerifyHotelState();

	return (
		<div className="main-bar mt-5">
			<Progress
				className="main-bar__progress mx-3"
				color="primary"
				value={progress}
			/>

			<div className="main-bar__box">
				<div
					className={`main-bar__item ${progress >= 33 ? "active" : ""} ${
						progress === 0 ? "current" : ""
					}`}
				>
					1
				</div>
				<div
					className={`main-bar__item ${progress > 33 ? "active" : ""} ${
						progress === 33 ? "current" : ""
					}`}
				>
					2
				</div>
				<div
					className={`main-bar__item ${progress > 66 ? "active" : ""} ${
						progress === 66 ? "current" : ""
					}`}
				>
					3
				</div>
				{/* <div
					className={`main-bar__item ${progress > 100 ? "active" : ""} ${
						progress === 100 ? "current" : ""
					}`}
				>
					4
				</div> */}
			</div>
		</div>
	);
}
