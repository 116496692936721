import Loader from "components/Loader";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import {
	useVerifyHotelDispatch,
	useVerifyHotelState,
} from "context/verifyHotel";
import validate, { isFormValid } from "helpers/validate";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Link, useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import { fetchCities } from "services/lookups";
import Locale from "translations";
import OwnerNameInput from "./OwnerNameInput";
import uploadIcon from "assets/images/upload.svg";

export default function OwnerDetails(props) {
	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	const { propertyDetails, userDetails, commons } = Locale;
	const { OwnerDetails } = useVerifyHotelState();
	const VerifyHotel = useVerifyHotelState();

	const OwnsProperty = [
		{
			label: "Management Company",
			value: 1,
		},
		{
			label: "Property Management",
			value: 2,
		},
		{
			label: "Property Owner",
			value: 3,
		},
	];
	const { locale } = useGlobalState();
	const history = useHistory();
	const dispatch = useVerifyHotelDispatch();

	const [ownerDetailsState, setOwnerDetailsState] = useState({
		//owns_property: OwnerDetails?.owner_type_id ?? "",
		// owns_property: OwnsProperty.filter((own) => {
		// 	return own.value == OwnerDetails?.owner_type_id;
		// }),

		owns_property: OwnerDetails?.owner_type_id
			? {
				value: OwnerDetails?.owner_type_id,
				label:
					OwnerDetails?.owner_type_id == 1
						? "Management Company"
						: OwnerDetails?.owner_type_id == 2
							? "Property Management"
							: OwnerDetails?.owner_type_id == 3
								? "Property Owner"
								: null,
			}
			: VerifyHotel?.OwnerDetails?.owns_property,
		full_name_Business:
			OwnerDetails?.full_name ?? OwnerDetails?.full_name_Business,
		country: OwnerDetails?.country?.id
			? {
				value: OwnerDetails?.country?.id,
				label: OwnerDetails?.country?.names[locale],
			}
			: OwnerDetails?.country, //OwnerDetails?.country ?? "",
		town_city: OwnerDetails?.city?.id
			? {
				value: OwnerDetails?.city.id,
				label: OwnerDetails?.city.names[locale],
			}
			: OwnerDetails?.town_city, // OwnerDetails?.town_city ?? "",
		//address: OwnerDetails?.address ?? "",
		address_business: OwnerDetails?.address ?? OwnerDetails?.address_business,
		unit_number: OwnerDetails?.unit_number ?? "",
		zip_code: OwnerDetails?.zip ?? OwnerDetails?.zip_code,
		photos: OwnerDetails?.photos ?? [],
		OwnerNames: props?.canEdit
			? OwnerDetails?.beneficial_owner?.map((own) => {
				return {
					email: own.email,
					first_name: own.first_name,
					last_name: own.last_name,
				};
			})
			: VerifyHotel.OwnerDetails?.OwnerNames &&
				VerifyHotel.OwnerDetails?.OwnerNames.length > 0
				? VerifyHotel.OwnerDetails?.OwnerNames?.map((own) => {
					return {
						email: own.email,
						first_name: own.first_name,
						last_name: own.last_name,
					};
				})
				: [{}],

		countryCode: null,
		spin: false,
	});

	const [loaderMessage] = useState(
		"Please be patient and wait, The page will load now."
	);
	const [errors, setErrors] = useState(OwnerDetails?.errors ?? {});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [cities, setCities] = useState([]);

	// ----------------------------------------------------------------------------------------------------
	// Component Functions
	// ----------------------------------------------------------------------------------------------------

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "owns_property", value: ownerDetailsState.owns_property },
				{ required: true }
			),
			...validate(
				{
					name: "full_name_Business",
					value: ownerDetailsState.full_name_Business,
				},
				{ required: true }
			),
			// ...validate(
			// 	{ name: "unit_number", value: ownerDetailsState.unit_number },
			// 	{ required: true }
			// ),
			...validate(
				{ name: "zip_code", value: ownerDetailsState.zip_code },
				{ required: true, min: 5, max: 5 }
			),
			...validate(
				{ name: "address_business", value: ownerDetailsState.address_business },
				{ required: true }
			),
			...validate(
				{ name: "town_city", value: ownerDetailsState.town_city },
				{ required: true }
			),
			...validate(
				{ name: "country", value: ownerDetailsState.country },
				{ required: true }
			),
			...validate(
				{
					name: "firstName0",
					value: ownerDetailsState?.OwnerNames[0].first_name,
				},
				{ required: true }
			),
			...validate(
				{
					name: "lastName0",
					value: ownerDetailsState?.OwnerNames[0].last_name,
				},
				{ required: true }
			),
			...validate(
				{ name: "email0", value: ownerDetailsState?.OwnerNames[0].email },
				{ required: true, email: true }
			),
		});
	};

	const addOwner = () => {
		let ownerDetails = [...ownerDetailsState?.OwnerNames];
		ownerDetails.push({});
		setOwnerDetailsState({ ...ownerDetailsState, OwnerNames: ownerDetails });
	};
	// SUbmit Form
	const submitForm = () => {
		dispatch({
			type: "setOwnerDetails",
			payload: ownerDetailsState,
		});
		dispatch({ type: "setStep", progress: 50, step: 2 });
	};

	const backStep = () => {
		//history.push("/");

		dispatch({ type: "setStep", progress: 0, step: 1 });
	};

	useEffect(async () => {
		// && userRegister.lastStep === 1
		if (isFormValid(errors)) {
			// for check name hotel
			// const response = await emailAvailability(data);
			// response.status === 200
			if (OwnerDetails?.owns_property) {
				dispatch({
					type: "setData",
					payload: {
						...ownerDetailsState,
						country_id: OwnerDetails?.country?.id,
						city_id: OwnerDetails?.city?.id,
					},
				});
			}

			if (true) {
				submitForm();
			} else {
				store.addNotification({
					title: commons.somethingWentWrong,
					message: userDetails.businessExistInvalideValidationMessage,
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			}
		} else {
			// dispatch({ type: "Progress", progress: 0, step: 1, lastStep: 1 });
		}
		setOwnerDetailsState({
			...ownerDetailsState,
			spin: false,
		});
	}, [isErrorLoaded]);

	const handleUpload = (e) => {
		const files = e.target.files;
		if (files && files != undefined) {
			setOwnerDetailsState({
				...ownerDetailsState,
				photos: [...ownerDetailsState.photos, ...files],
			});
		}
	};

	const removePhoto = (photoName) => {
		setOwnerDetailsState({
			...ownerDetailsState,
			photos: ownerDetailsState.photos.filter(
				(photo) => photo.name !== photoName
			),
		});
	};

	const submitStep = () => {
		setOwnerDetailsState({
			...ownerDetailsState,
			spin: true,
		});
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	// Fetch Cities
	useEffect(() => {
		async function fetchLookups() {
			if (ownerDetailsState.country?.value) {
				const res = await fetchCities(ownerDetailsState.country?.value);
				const formatted = res?.data.map((country) => ({
					value: country.id,
					label: country.names[locale],
				}));
				setCities(formatted);
			}
		}
		fetchLookups();
	}, [ownerDetailsState.country]);


	// ----------------------------------------------------------------------------------------------------
	// Main Component Body UI
	// ----------------------------------------------------------------------------------------------------
	if (true) {
		return (
			<>
				<div className="col-md-12 mt-2 mb-5">
					<h1 className="text-center text-title text-dark-blue mt-4 mb-3">
						{propertyDetails.ownerDetails}
					</h1>

					<div className="bg-white p-2 px-3 border radius-10 mt-3">
						<div className="row m-0">
							{/* WhoOwnsTheProperty */}
							<div className="col-md-12 pr-md-2">
								<SelectField
									label={propertyDetails.WhoOwnsTheProperty}
									value={ownerDetailsState.owns_property}
									name="owns_property"
									options={OwnsProperty}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{ name: "owns_property", value: e.value },
												{ required: true }
											),
										});

										setOwnerDetailsState({
											...ownerDetailsState,
											owns_property: e,
										});
									}}
									onBlur={() =>
										setErrors({
											...errors,
											...validate(
												{
													name: "owns_property",
													value: ownerDetailsState.owns_property,
												},
												{ required: true }
											),
										})
									}
									color={errors?.owns_property?.required ? "danger" : ""}
									errors={errors?.owns_property}
								/>
							</div>
							{/* Full name of business entity  */}
							<div className="col-12">
								<TextField
									type="text"
									label={propertyDetails.fullNameOfBusinessEntity}
									placeholder={propertyDetails.fullNameOfBusinessEntity}
									value={ownerDetailsState.full_name_Business}
									name="full_name_Business"
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
											}),
										});
										setOwnerDetailsState({
											...ownerDetailsState,
											full_name_Business: e.target.value,
										});
									}}
									min={3}
									color={errors?.full_name_Business?.required ? "danger" : ""}
									errors={errors?.full_name_Business}
								/>
							</div>
							<div className="col-md-12">
								<div className="border-bottom w-100">
									<p className="title-form">
										{propertyDetails.businessEntityAddress}
									</p>
								</div>
							</div>
							{/* Trade Name */}
							<div className="col-12">
								<TextField
									type="text"
									label={propertyDetails.addressOfBusinessEntity}
									placeholder={propertyDetails.addressOfBusinessEntity}
									value={ownerDetailsState.address_business}
									name="address_business"
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
											}),
										});
										setOwnerDetailsState({
											...ownerDetailsState,
											address_business: e.target.value,
										});
									}}
									color={errors?.address_business?.required ? "danger" : ""}
									errors={errors?.address_business}
								/>
							</div>
							{/* Unit Number (Optional)  */}
							<div className="col-md-6 pl-md-2">
								<TextField
									label={propertyDetails.unitNumber}
									placeholder={propertyDetails.unitNumber}
									name="unit_number"
									type={"number"}
									value={ownerDetailsState.unit_number}
									onChange={(e) => {
										setOwnerDetailsState({
											...ownerDetailsState,
											unit_number: e.target.value,
										});
									}}
								/>
							</div>
							<div className="col-md-6">
								<SelectField
									label={userDetails.country}
									value={ownerDetailsState.country}
									name="country"
									options={props.lookups?.countries}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{ name: "country", value: e.value },
												{ required: true }
											),
										});
										setOwnerDetailsState({
											...ownerDetailsState,
											country: e,
											town_city: "",
										});
									}}
									onBlur={() =>
										setErrors({
											...errors,
											...validate(
												{ name: "country", value: ownerDetailsState.country },
												{ required: true }
											),
										})
									}
									color={errors?.country?.required ? "danger" : ""}
									errors={errors?.country}
								/>
							</div>
							<div className="col-md-6">
								<SelectField
									label={propertyDetails.townCity}
									value={ownerDetailsState.town_city}
									name="town_city"
									options={cities}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{ name: "town_city", value: e.value },
												{ required: true }
											),
										});
										setOwnerDetailsState({
											...ownerDetailsState,
											town_city: e,
										});
									}}
									onBlur={() =>
										setErrors({
											...errors,
											...validate(
												{
													name: "town_city",
													value: ownerDetailsState.town_city,
												},
												{ required: true }
											),
										})
									}
									color={errors?.town_city?.required ? "danger" : ""}
									errors={errors?.town_city}
								/>
							</div>
							{/* zip Code */}
							<div className="col-md-6 pl-md-2">
								<TextField
									label={propertyDetails.zipCode}
									placeholder={propertyDetails.zipCode}
									name="zip_code"
									type={"number"}
									value={ownerDetailsState.zip_code}
									min={5}
									max={5}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{ name: "zip_code", value: e.target.value },
												{
													required: true,
													min: 5,
													max: 5,
												}
											),
										});
										setOwnerDetailsState({
											...ownerDetailsState,
											zip_code: e.target.value,
										});
									}}
									color={
										errors?.zip_code?.required ||
											errors?.zip_code?.min ||
											errors?.zip_code?.max
											? "danger"
											: ""
									}
									errors={errors?.zip_code}
								/>
							</div>
							<div className="col-md-12">
								<div className="border-bottom w-100"></div>
								<p className="title-form">
									Provide the full names of the ultimate beneficial owners who
									own 25% or more of the property management company (either
									directly or through a holding company)
								</p>
							</div>
							{ownerDetailsState.OwnerNames?.map((owner, i) => {
								return (
									<OwnerNameInput
										key={owner.name}
										setErrors={setErrors}
										errors={errors}
										ownerDetailsState={ownerDetailsState}
										setOwnerDetailsState={setOwnerDetailsState}
										owner={owner}
										index={i}
									/>
								);
							})}
							<div className="col-md-12">
								<a className="link-add" onClick={addOwner}>
									<i class="fas fa-plus-circle"></i> Add another
								</a>
							</div>
							<div className="col-md-12">
								<p className="custom-label">
									{propertyDetails.officialDocuments}
								</p>
								<div className="bg-white p-3 text-center w-100  position-relative upload-images">
									<p className="text-center upload-icon">
										<img src={uploadIcon} alt="" srcset="" />
									</p>

									<label
										for="uploadFile"
										onChange={handleUpload}
										className="btn bg-blue-black text-white px-4"
									>
										<i className="far fa-images px-2 fa-lg"></i>
										Upload Photo
									</label>
									<input
										multiple="multiple"
										onChange={handleUpload}
										type="file"
										id="uploadFile"
										accept="image/*"
									/>
								</div>
							</div>

							<div className="col-md-12">
								{ownerDetailsState.photos.map((photo, i) => {
									return (
										<div className="col-md-12 item_box">
											{photo.name}
											<span
												onClick={() => removePhoto(photo.name)}
												className="span-delete"
											>
												<i class="fas fa-times"></i>
											</span>
										</div>
									);
								})}
							</div>
							{/* <div className="col-md-8 pl-md-2">
								<TextField
									label={propertyDetails.ifAnyOwnersGoByAnAlternativeNames}
									placeholder={
										propertyDetails.ifAnyOwnersGoByAnAlternativeNames
									}
									name="alternative_name"
									value={ownerDetailsState.alternative_name}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{ name: "alternative_name", value: e.target.value },
												{
													required: true,
												}
											),
										});
										setOwnerDetailsState({
											...ownerDetailsState,
											alternative_name: e.target.value,
										});
									}}
									color={errors?.alternative_name?.required ? "danger" : ""}
									errors={errors?.alternative_name}
								/>
							</div> */}
						</div>
					</div>

					<div className="row mt-3 justify-content-between m-0">
						<div className="col-md-6"></div>
						<div className="col-md-2">
							<button
								onClick={() => {
									backStep();
								}}
								className="btn btn-light btn-light-new  w-100"
							>
								{/* <span className="icx icx-left-solid-arrow text-10"></span>{" "} */}
								{commons.back}
							</button>
						</div>
						<div className="col-md-4 p-0">
							<button
								className="btn btn-primary w-100"
								onClick={() => submitStep()}
								disabled={ownerDetailsState.spin}
							>
								{ownerDetailsState.spin ? (
									<Spinner color="light" size="sm" />
								) : null}{" "}
								{userDetails.next}
							</button>
						</div>
					</div>
				</div>
			</>
		);
	} else {
		return <Loader message={loaderMessage} />;
	}
}
