import Header from "components/layout/Header";
import { HotelLayoutProvider } from "context/hotelLayout";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import DashboardSubNav from "./layout/DashboardSubNav";
import Layout from "./layout/Layout";
import SideBar from "./layout/SideBar";

// React Component
export function HotelLayoutRoute({ component: Component, ...props }) {
	const isAuth = localStorage.getItem("isAuth");
	if (isAuth) {
		return (
			<Route
				{...props}
				render={(matchProps) => (
					<>
						<Layout>
							<HotelLayoutProvider>
								<DashboardSubNav />
								<Component {...matchProps} />
							</HotelLayoutProvider>
						</Layout>
					</>
				)}
			/>
		);
	} else {
		return <Redirect to="/login" />;
	}
}
