import { formatGetRooms, formatPostRooms } from "data/property";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom";
import { viewProperties } from "services/property";
import Locale from "translations";
import Room from "./Room";

export default function Rooms() {
	const { id } = useParams();

	const { rooms, commons, supplierDetails } = Locale;

	const [allRooms, setAllRooms] = useState();
	useEffect(async () => {
		const res = await viewProperties(id);
		if (res.status === 200 || res.status === 201) {

			setAllRooms(res.data.property.rooms);
		}
	}, []);

	return (
		<div className=" px-3 py-2">
			<div className="room">
				<table class="table mb-0 f-13">
					<thead className="table-light">
						<tr className="">
							<th
								style={{ maxWidth: "150px", width: "150px", minWidth: "150px" }}
							>
								{rooms.type}
							</th>
							{/* <th
							style={{ maxWidth: "150px", width: "150px", minWidth: "150px" }}
						>
							{rooms.name}
						</th> */}
							<th
								style={{ maxWidth: "150px", width: "150px", minWidth: "150px" }}
							>
								{rooms.name}
							</th>
							<th
								style={{ maxWidth: "150px", width: "150px", minWidth: "150px" }}
							>
								{rooms.view}
							</th>
							<th
								style={{ maxWidth: "150px", width: "150px", minWidth: "150px" }}
							>
								{rooms.smokingPolicy}
							</th>
							<th
								style={{ maxWidth: "150px", width: "150px", minWidth: "150px" }}
							>
								{rooms.count}
							</th>
							<th style={{ maxWidth: "80px", width: "80px", minWidth: "80px" }}>
								{rooms.MaxAdults}
							</th>
							<th
								style={{ maxWidth: "100px", width: "100px", minWidth: "100px" }}
							>
								{rooms.MaxChildrens}
							</th>
							<th
								style={{ maxWidth: "150px", width: "150px", minWidth: "150px" }}
							>
								{rooms.MaxAdultWithChild}
							</th>

							<th
								style={{ maxWidth: "50px", width: "50px", minWidth: "50px" }}
							></th>
						</tr>
					</thead>
					<thead className="table-light">
						{allRooms?.map((room, i) => {
							return (
								<tr key={i}>
									<Room index={i} room={room} />
								</tr>
							);
						})}
					</thead>
				</table>
			</div>
		</div>
	);
}
