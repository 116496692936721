import axios from "axios";
import { cleanEmpty } from "services/general";
const basicUrl = process.env.REACT_APP_API_URL;

export const checkHotelClassificationName = async (data) => {
	return await axios
		.post(
			`${basicUrl + "/api/properities/check-property-classification-name"}`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};
export const verifyHotel = async (HotelId, data) => {
	return await axios
		.post(
			`${basicUrl + "/api/properities/" + HotelId + "/verify"}`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err);
};
export const getContract = async (id) => {
	return await axios
		.get(`${basicUrl}/api/contract-requests/property/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const editContract = async (id, data) => {
	return await axios
		.put(`${basicUrl}/api/contract-requests/property/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
