import React, { createContext, useContext, useReducer } from "react";
const PropertyContext = createContext();
const PropertyDispatchContext = createContext();
const initialState = {};

function PropertyReducer(state, action) {
	switch (action.type) {
		case "propertyDetails": {
			return { ...state, propertyDetails: action.payload };
		}

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function usePropertyState() {
	const context = useContext(PropertyContext);
	if (context === undefined) {
		throw new Error("usePropertyState must be used within a PropertyProvider");
	}
	return context;
}

function usePropertyDispatch() {
	const context = useContext(PropertyDispatchContext);
	if (context === undefined) {
		throw new Error(
			"usePropertyDispatch must be used within a PropertyProvider"
		);
	}
	return context;
}

function PropertyProvider({ children }) {
	const [state, dispatch] = useReducer(PropertyReducer, initialState);
	return (
		<PropertyContext.Provider value={state}>
			<PropertyDispatchContext.Provider value={dispatch}>
				{children}
			</PropertyDispatchContext.Provider>
		</PropertyContext.Provider>
	);
}

export { PropertyProvider, usePropertyState, usePropertyDispatch };
