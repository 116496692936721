import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom";
import { viewProperties } from "services/property";
import Locale from "translations";

export default function Facilities() {
	const { services } = Locale;
	const { id } = useParams();
	const [disabled, setDisabled] = useState(true);

	const [service, setService] = useState({
		parkingAvailable: "",
		parkingType: "",
		parkingSite: "",
		parkingReservation: "",
		parkingPrice: "",
		languages: [],
		views: [],
		facilities: [],
	});

	useEffect(async () => {
		const res = await viewProperties(id);
		if (res.status === 200 || res.status === 201) {

			const service = res.data.property.service;
			let lan = res.data.property.languages.map((item) => {
				return { value: item.id, label: item.name };
			});
			setService({
				parkingAvailable: { value: 1, label: service.parking_availabity },
				parkingType: { value: 1, label: service.parking_type },
				parkingSite: { value: 1, label: service.parking_site },
				parkingReservation: {
					value: 1,
					label: service.reservation_parking_space,
				},
				parkingPrice: service.price,
				languages: [...lan],
				facilities: res.data.property.facilities,
				views: res.data.property.views,
			});
		}
	}, []);

	return (
		<div className="container px-3 py-2">
			<div className="header-box my-3">{services.parking}</div>
			<div className="row m-0">
				{/* parkingAvailable */}
				<div className="col-4">
					<SelectField
						id="services-parking"
						label={services.parkingAvailable}
						customLabel={services.parkingAvailability}
						placeholder={services.parkingAvailable}
						options={[
							{ label: "Yes Paid", value: "Yes Paid" },
							{ label: "Yes Not Paid", value: "Yes Not Paid" },
							{ label: "No Availability", value: "No Availability" },
						]}
						disabled={disabled}
						name="parkingAvailable"
						value={service.parkingAvailable}
					/>
				</div>
				{/* parkingType */}
				<div className="col-4">
					<SelectField
						id="services-type"
						label={services.type}
						placeholder={services.type}
						options={[
							{ label: "Public", value: "Public" },
							{ label: "Private", value: "Private" },
						]}
						name="parkingType"
						disabled={disabled}
						value={service.parkingType}
					/>
				</div>
				{/* parkingSite */}
				<div className="col-4">
					<SelectField
						label={services.site}
						placeholder={services.site}
						value={service.parkingSite}
						id="services-site"
						options={[
							{ label: "On Site", value: "On Site" },
							{ label: "External", value: "External" },
						]}
						name="parkingSite"
						disabled={disabled}
					/>
				</div>
				{/* parkingReservation */}
				<div className="col-4">
					<SelectField
						label={services.reservation}
						customLabel={services.parkingSpace}
						placeholder={services.reservation}
						id="services-space"
						options={[
							{ label: "Needed", value: "Needed" },
							{ label: "No Need", value: "No Need" },
						]}
						disabled={disabled}
						name="parkingReservation"
						value={service.parkingReservation}
					/>
				</div>
				<div className="col-4">
					<TextField
						label={services.price}
						placeholder={services.price}
						value={service.parkingPrice}
						name="parkingPrice"
						id="services-price"
						disabled={disabled}
					/>
				</div>
			</div>
			<div className="header-box my-3">{services.languages}</div>
			<p>{services.languagesSpokenTitle}</p>
			<div className="col-md-4">
				<SelectField
					multi
					label={services.languages}
					placeholder={services.languages}
					id="services-languages"
					value={service.languages}
					disabled={disabled}
				/>
			</div>
			<div className="header-box my-3">{services.views}</div>
			<div style={{ columnCount: "3" }}>
				{service.views.length > 0 &&
					service.views.map((view, i) => {
						return (
							<div className="form-group form-check" key={i}>
								<input
									type="checkbox"
									name="views"
									className="form-check-input"
									value={view.value}
									checked={true}
									id={`room-views-${i + 1}`}
									disabled={disabled}
								/>
								<label
									className="form-check-label text-capitalize"
									htmlFor={`room-views-${i + 1}`}
								>
									{view.name}
								</label>
							</div>
						);
					})}
			</div>

			<div className="header-box my-3">{services.popular}</div>
			<p>{services.popularTitle}</p>
			<div style={{ columnCount: "3" }}>
				{service.facilities.length > 0 &&
					service.facilities.map((facility, i) => {
						return (
							<div className="form-group form-check" key={i}>
								<input
									type="checkbox"
									className="form-check-input"
									name="facilities"
									value={facility.value}
									id={`service-check-${i + 1}`}
									checked={true}
									disabled={disabled}
								/>
								<label
									className="form-check-label text-capitalize pointer"
									htmlFor={`service-check-${i + 1}`}
								>
									{facility.name}
								</label>
							</div>
						);
					})}
			</div>
		</div>
	);
}
