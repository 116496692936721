import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Model from "components/model";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import Locale from "../../translations";

import { Collapse, Button, CardBody, Card } from "reactstrap";
import { Table } from "reactstrap";
import {
	addUser,
	deleteGroup,
	editGroup,
	getGroupPermissions,
	getListGroups,
	getOneGroup,
} from "services/TeamManagement";
import validate, { isFormValid } from "helpers/validate";
import { store } from "react-notifications-component";
import {
	fetchCategories,
	fetchCountries,
	fetchServices,
} from "services/lookups";
import generateUniqueID from "helpers/generateUniqueID";
import { getNotification } from "helpers/makeNotifications";
import ConfirmModal from "components/Modals/ConfirmModal";
import ShowForPermission from "helpers/showForPermission";

const Collaps = ({
	Group,
	// setDeleteState,
	// confirmIsOpen,
	// setConfirmIsOpen,
	toggleConfirm,
	setSelectCollapse,
}) => {
	const { teamManagement } = Locale;
	// const { Group } = props;
	//
	let [Id, setId] = useState("");
	const [collapse, setCollapse] = useState(false);
	const [status, setStatus] = useState("Closed");

	const onEntering = () => setStatus("Opening...");

	const onEntered = () => setStatus("Opened");

	const onExiting = () => setStatus("Closing...");

	const onExited = () => setStatus("Closed");

	const toggle = (event) => {
		Id = event.target.id;
		setCollapse(!collapse);
	};

	/******************* */
	const [CurrentUser, setCurrentUser] = useState();
	const [modalShowEdit, setModalShowEdit] = useState(false);
	const [modalShowAdd, setModalShowAdd] = useState(false);
	const locale = localStorage.getItem("currentLocale");

	const toggleModalShowEdit = (id) => {
		//setSeletedId(id)
		setModalShowEdit(!modalShowEdit);
	};

	const toggleModalShowAdd = (id) => {
		//setSeletedId(id)
		setModalShowAdd(!modalShowAdd);
	};

	/****add user */
	const [data, setData] = useState({});
	const [countries, setCountries] = useState([]);
	const [services, setServices] = useState([]);
	const [catagories, setCatagories] = useState([]);
	const [groups, setGroups] = useState([]);
	const [admins, setAdmins] = useState([]);
	const [isErrorLoadedAdd, setIsErrorLoadedAdd] = useState(false);

	useEffect(() => {
		if (modalShowAdd) {
			async function fetchLookups() {
				const res = await fetchCountries();
				const format = res?.data?.map((t) => ({
					value: t.id,
					label: t.names[locale],
					code: t.country_code,
				}));
				setCountries(format);
			}

			async function fetchLookupServices() {
				const res = await fetchServices();
				const format1 = res?.data?.map((t) => ({
					value: t.id,
					label: t.name,
				}));
				setServices(format1);
			}

			async function fetchLookupCatagories() {
				const res = await fetchCategories();
				const format2 = res?.map((t) => ({
					value: t.id,
					label: t.name,
				}));
				setCatagories(format2);
			}
			async function fetchGroups() {
				const res = await getListGroups();
				const format3 = res?.data?.data?.map((t) => ({
					value: t.id,
					label: t.name,
				}));
				setGroups(format3);
			}

			async function fetchAdmin() {
				//const res = await getListGroups();
				const format4 = [
					{ value: 0, label: "notAdmin" },
					{ value: 1, label: "isAdmin" },
				];
				setAdmins(format4);
			}

			fetchLookups();
			fetchLookupServices();
			fetchLookupCatagories();
			fetchGroups();
			fetchAdmin();
		}
	}, [modalShowAdd]);

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setData({ ...data, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			setData({ ...data, [name]: value });
		}
	};
	const checkFormErrorsAdd = () => {
		setErrors({
			...errors,
			...validate(
				{
					name: "supplier_name",
					value: data.supplier_name,
				},
				{
					required: true,
				}
			),
			// ...validate(
			// 	{
			// 		name: "owner_name",
			// 		value: data.owner_name,
			// 	},
			// 	{
			// 		required: true,
			// 	}
			// ),
			...validate(
				{
					name: "email",
					value: data.email,
				},
				{
					required: true,
					email: true,
				}
			),
			...validate(
				{
					name: "password",
					value: data.password,
				},
				{
					required: true,
				}
			),
			...validate(
				{
					name: "password_confirmation",
					value: data.password_confirmation,
				},
				{
					required: true,
					confirm: true,
				},
				data.password
			),
			...validate(
				{
					name: "password_not_match",
					value: data.password_not_match,
				},
				{
					required: false,
				}
			),
			...validate(
				{
					name: "phone_number",
					value: data.phone_number,
				},
				{
					required: true,
				}
			),
			...validate(
				{
					name: "group_id",
					value: data.group_id,
				},
				{
					//( data?.is_admin?.value == 1)?({required: false}):({required: true})
					required: false,
				}
			),

			...validate(
				{
					name: "country_id",
					value: data.country_id,
				},
				{
					required: true,
				}
			),
			// ...validate(
			// 	{
			// 		name: "category_id",
			// 		value: data.category_id,
			// 	},
			// 	{
			// 		required: true,
			// 	}
			// ),
			// ...validate(
			// 	{
			// 		name: "services",
			// 		value: data.services,
			// 	},
			// 	{
			// 		required: true,
			// 	}
			// ),
			// ...validate(
			// 	{
			// 		name: "is_admin",
			// 		value: data.is_admin,
			// 	},
			// 	{
			// 		required: false,
			// 	}
			// ),
		});
	};
	const handleFormAdd = async () => {
		checkFormErrorsAdd();
		if (!isErrorLoadedAdd) {
			setIsErrorLoadedAdd(true);
		} else {
			setIsErrorLoadedAdd(false);
		}
	};

	useEffect(() => {
		if (modalShowAdd) {
			const addNewUser = async () => {
				if (isFormValid(errors)) {
					const res = await addUser({
						...data,
						group_id: Group.id, //data.group_id?.value,
						country_id: data.country_id?.value,
						//services: [data.services?.value],
						//category_id: data.category_id?.value,
						is_admin: 0,
						phone_number: data?.phone_number,
						name: data?.supplier_name,
					});

					if (res.status == 200 || res.status == 201) {
						store.addNotification({
							title: "info!",
							message: res.data.message,
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animated", "fadeIn"],
							animationOut: ["animated", "fadeOut"],
						});

						toggleModalShowAdd(null);
						window.location.reload();
					}
				}
			};
			addNewUser();
		}
	}, [isErrorLoadedAdd]);

	/****end add user */

	/******Edit */
	const [teamMangament, setTeamMangament] = useState({ permissions: [] });
	const [permissions, setPermissions] = useState([]);
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	useEffect(() => {
		if (modalShowEdit) {
			async function fetchGroupPermissions() {
				const res = await getGroupPermissions();
				setPermissions(res?.data?.data);
				//setMeta(res?.data?.meta)
			}
			fetchGroupPermissions();
		}
	}, [modalShowEdit]);

	const handleCheckboxChange = (event) => {
		const name = event.target.name;
		if (event.target.checked === true) {
			setTeamMangament({
				...teamMangament,
				[name]: [...teamMangament[name], Number(event.target.value)],
			});
		} else {
			setTeamMangament({
				...teamMangament,
				[name]: [
					...teamMangament[name].filter(
						(e) => e !== Number(event.target.value)
					),
				],
			});
		}
	};

	function checkAllPermession(event, permissionName) {
		let permissionsIds = permissions[permissionName].map(
			(permission) => permission.id
		);
		if (event.target.checked === true) {
			setTeamMangament({
				...teamMangament,
				permissions: [
					...new Set([...teamMangament.permissions, ...permissionsIds]),
				],
			});
		} else {
			// remove all selected boxes
			setTeamMangament({
				...teamMangament,
				permissions: [
					...teamMangament.permissions.filter(
						(permission) => !permissionsIds.includes(permission)
					),
				],
			});
		}
	}
	function permissionChecked(permissionName) {
		let checked = [];
		checked = permissions[permissionName].map((permission) =>
			teamMangament.permissions.includes(permission.id)
		);
		return checked.includes(true);
	}

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{
					name: "name",
					value: teamMangament.name,
				},
				{
					required: true,
				}
			),
		});
	};
	const handleForm = async () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	if (modalShowEdit) {
	}
	/******** */
	useEffect(() => {
		if (modalShowEdit) {
			async function fetchData() {
				const res = await getOneGroup(Group.id);
				setTeamMangament({
					...teamMangament,

					...res?.data?.data,
				});
			}
			fetchData();
		}
	}, [modalShowEdit]);

	useEffect(() => {
		const editGroup1 = async () => {
			if (isFormValid(errors)) {
				const res = await editGroup(Group.id, {
					...teamMangament,
				});

				if (res?.status == 201 || res?.status == 200) {
					store.addNotification({
						title: "info!",
						message: res?.data[res?.data?.length - 1]
							? res?.data[res?.data?.length - 1]
							: "Updated Successfully", //res?.data?.data?.message
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
					});

					toggleModalShowEdit(null);
					window.location.reload();
				}
			}
		};

		editGroup1();
	}, [isErrorLoaded]);
	// const removeGroup = async () => {
	// 	let data = {
	// 		id: Group?.id,
	// 	};
	// 	const res = await deleteGroup(data);
	// 	if (res.status == 200) {
	// 		getNotification(res?.message);
	// 		setDeleteState((prev) => !prev);
	// 	}
	// };

	/******* */

	/****************** */
	return (
		<div className="group-container">
			{" "}
			{/*onClick={(e) => toggle(e)} style={{ cursor: "pointer" }} */}
			<div className="col-containt">
				<div className=" d-flex  align-items-center">
					<div>
						<Button
							className="CollapsePlusIcon"
							color="none"
							id="btn1"
							onClick={(e) => toggle(e)}
							style={{
								marginBottom: "1rem",
							}}
						>
							<i
								className={
									collapse
										? "fas fa-lg fa-chevron-down blue"
										: "fas fa-lg fa-chevron-right grey"
								}
							></i>
						</Button>
					</div>
					<ShowForPermission permission="manage-team">
						<div className="last editIcon">
							<button
								className="btn btn-link"
								onClick={() => {
									//removeGroup();
									toggleConfirm();
									setSelectCollapse(Group);
								}}
							>
								<i class="fas fa-trash fa-fw fa-lg pointer text-danger"></i>
							</button>
						</div>
						<div className="last editIcon">
							<button
								className="btn btn-link"
								onClick={() => {
									setModalShowEdit(!modalShowEdit);
								}}
							>
								<i
									className={collapse ? "fas fa-edit blue" : "fas fa-edit grey"}
								></i>
							</button>
						</div>

						<div className="last editIcon">
							<button
								className="btn btn-link"
								onClick={() => {
									setModalShowAdd(!modalShowAdd);
								}}
							>
								<i
									className={
										collapse
											? "fas fa-user-plus fa-fw fa-lg pointer blue"
											: "fas fa-user-plus fa-fw fa-lg pointer grey"
									}
								></i>
							</button>
						</div>
					</ShowForPermission>
				</div>

				<div className="num-content">
					<span className={collapse ? " blue" : "grey"}>{Group?.name}</span>
					<span
						className={collapse ? "num bg-blue" : "num bg-grey"}
						style={{ backgroundColor: "grey" }}
					>
						{Group?.users?.length}
					</span>
				</div>
			</div>
			<Collapse
				isOpen={collapse}
				onEntering={onEntering}
				onEntered={onEntered}
				onExiting={onExiting}
				onExited={onExited}
			>
				<Card>
					<CardBody className="card-body">
						<Table className="table">
							<thead className="table-light">
								<tr>
									<th> Name</th>
									<th>{teamManagement.email}</th>
									<th>Mobile</th>
									<th>{teamManagement.status}</th>
								</tr>
							</thead>

							<tbody className="table-stripe ">
								{Group.users && Group.users.length > 0 ? (
									Group.users.map((res) => {
										return (
											<tr>
												<td>{res.name}</td>
												<td>{res.email}</td>
												<td>{res.phone_number}</td>
												<td>
													{res.status && res.status == "active" ? (
														<span className="text-primary">
															{teamManagement.active}
														</span>
													) : (
														<span className="text-danger">
															{" "}
															{teamManagement.inactive}{" "}
														</span>
													)}
												</td>
											</tr>
										);
									})
								) : (
									<tr>
										<td colSpan="10" className="text-center">
											<div className="product-build__product-no-data">
												<i className="fas fa-info-circle fa-lg"></i>{" "}
												<h4>{teamManagement.noResult}</h4>
											</div>
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</CardBody>
				</Card>
			</Collapse>
			{/******  Model edit team ****** */}
			<div>
				<Model
					isOpen={modalShowEdit}
					title={teamManagement.edit}
					toggle={() => {
						toggleModalShowEdit(null);
					}}
					hasFooter={false}
					className="bulk-edit team-model"
					// size="lg"
				>
					<div className="py-3  team-model-content ">
						<div className="row">
							{/* <div className="col-md-12 ">
								<TextField
									label={teamManagement.fullName}
									placeholder={teamManagement.enterFullName}
									name="fullName"
									onChange={(e) => {
										setTeamMangament({
											...teamMangament,
											fullName: e.target.value,
										});
									}}
								/>
							</div> */}

							<div className="col-md-12 ">
								{/* <SelectField
									label={teamManagement.team}
									placeholder={teamManagement.enterTeam}
									name="name"
									//multi={true}
									options={team}
									onChange={(e) => {
										handleChange({ name: "name", value: e });
										setErrors({
											...errors,
											...validate(
												{ name: "name", value: e },
												{
													required: true,
												}
											),
										});
									}}
									value={teamMangament?.name?.name}
								/> */}
								<TextField
									label={teamManagement.team}
									placeholder={teamManagement.enterTeam}
									name="name"
									onChange={(e) => {
										setTeamMangament({
											...teamMangament,
											name: e.target.value,
										});
									}}
									color={errors?.name?.required ? "danger" : ""}
									errors={errors?.name}
									value={teamMangament?.name}
								/>
							</div>

							<div class="col-md-12">
								<div class="form-group full-input">
									<h6
										class="p-1 font-weight-bold text-uppercase text-caption"
										style={{ textAlign: "start" }}
									>
										{teamManagement.Permission}
									</h6>
									<div class="form-group full-input px-0 pb-1">
										{Object.keys(permissions).map((res) => {
											return (
												<>
													{" "}
													<p
														className="mt-1 mb-1"
														style={{ textAlign: "start" }}
													>
														<input
															type="checkbox"
															name={res}
															id={res}
															className="form-check-input"
															onChange={(event) =>
																checkAllPermession(event, res)
															}
															checked={permissionChecked(res)}
														/>
														<label
															class="form-check-label pointer text-caption mx-2"
															htmlFor={res}
														>
															{res}
														</label>
													</p>
													{permissions[res].map((user, index) => {
														let x = generateUniqueID();
														return (
															<div
																class="form-check"
																key={index}
																style={{ textAlign: "start" }}
															>
																<input
																	type="Checkbox"
																	name="permissions"
																	className="form-check-input"
																	onChange={handleCheckboxChange}
																	value={user?.id}
																	checked={teamMangament.permissions?.includes(
																		user.id
																	)}
																	id={`User-Management-${(index + 1, x)}`}
																/>
																<label
																	class="form-check-label pointer text-caption mx-2"
																	for="exampleCheck1"
																	htmlFor={`User-Management-${(index + 1, x)}`}
																>
																	{user?.display_name[locale]}
																</label>
															</div>
														);
													})}
												</>
											);
										})}
									</div>
								</div>
							</div>
						</div>

						<div className="d-flex justify-content-end py-2">
							<button className="btn btn-primary mx-1 " onClick={handleForm}>
								{teamManagement.edit}
							</button>
							<button
								className="btn btn-secondary mx-1"
								onClick={() => {
									toggleModalShowEdit(null);
								}}
							>
								{" "}
								{teamManagement.cancel}
							</button>
						</div>
					</div>
				</Model>
			</div>
			{/*****Add */}
			<div>
				<Model
					isOpen={modalShowAdd}
					title={teamManagement.addMember}
					toggle={() => {
						toggleModalShowAdd(null);
					}}
					hasFooter={false}
					className="bulk-edit user-model"
					// size="lg"
				>
					<div className="py-3  user-model-content ">
						<div className="row">
							<div className="col-md-12 ">
								<TextField
									label={teamManagement.fullName}
									placeholder={teamManagement.enterFullName}
									name="supplier_name"
									onChange={(e) => {
										setData({
											...data,
											supplier_name: e.target.value,
										});
									}}
									color={errors?.supplier_name?.required ? "danger" : ""}
									errors={errors?.supplier_name}
								/>
							</div>
							{/* <div className="col-md-6 ">
								<TextField
									label={teamManagement.ownerName}
									placeholder={teamManagement.ownerName}
									name="owner_name"
									onChange={(e) => {
										setData({
											...data,
											owner_name: e.target.value,
										});
									}}
									color={errors?.owner_name?.required ? "danger" : ""}
									errors={errors?.owner_name}
								/>
							</div> */}
							<div className="col-md-12 ">
								<TextField
									label={teamManagement.emailAddress}
									placeholder={teamManagement.enterEmailAddress}
									name="email"
									onChange={(e) => {
										setData({
											...data,
											email: e.target.value,
										});
									}}
									color={
										errors?.email?.required || errors?.email?.email
											? "danger"
											: ""
									}
									errors={errors?.email}
								/>
							</div>
							<div className="col-md-6 ">
								<TextField
									type="password"
									label={teamManagement.password}
									placeholder={teamManagement.enterPassword}
									name="password"
									onChange={(e) => {
										setData({
											...data,
											password: e.target.value,
										});
									}}
									color={errors?.password?.required ? "danger" : ""}
									errors={errors?.password}
								/>
							</div>
							<div className="col-md-6 ">
								<TextField
									type="password"
									label={teamManagement.password_confirmation}
									placeholder={teamManagement.enterConfirmPassword}
									name="password_confirmation"
									onChange={(e) => {
										setData({
											...data,
											password_confirmation: e.target.value,
										});
									}}
									color={
										errors?.password_confirmation?.required ||
										errors?.password_confirmation?.confirm
											? "danger"
											: ""
									}
									errors={errors?.password_confirmation}
								/>
							</div>

							<div className="col-md-6 ">
								<SelectField
									label={teamManagement.country}
									placeholder={teamManagement.enterCountry}
									name="country_id"
									errors={errors.country_id}
									color={errors.country_id?.required ? "danger" : ""}
									options={countries}
									onChange={(e) => {
										handleChange({ name: "country_id", value: e });

										setErrors({
											...errors,
											...validate(
												{ name: "country_id", value: e },
												{
													required: true,
												}
											),
										});
									}}
									value={data.country?.label}
								/>
							</div>

							{locale == "en" ? (
								<div
									className="col-md-6 pl-md-2 phone-supplier"
									style={{ direction: "ltr" }}
								>
									<TextField
										label={teamManagement.phoneNumber}
										placeholder={teamManagement.enterPhoneNumber}
										name="phone_number"
										extraText={
											data?.country_id?.value
												? "+" + data?.country_id?.value
												: "---"
										}
										extraTextPosition="prepend"
										onChange={(e) => {
											setData({
												...data,
												phone_number:
													"+" + data?.country_id?.value + e.target.value,
											});
										}}
										color={errors?.phone_number?.required ? "danger" : ""}
										errors={errors?.phone_number}
									/>
								</div>
							) : (
								<div
									className="col-md-6 pl-md-2 phone-supplier"
									style={{ direction: "ltr" }}
								>
									<TextField
										label={teamManagement.phoneNumber}
										placeholder={teamManagement.enterPhoneNumber}
										name="phone_number"
										extraText={
											data?.country_id?.value
												? "+" + data?.country_id?.value
												: "---"
										}
										extraTextPosition="prepend"
										onChange={(e) => {
											setData({
												...data,
												phone_number:
													"+" + data?.country_id?.value + e.target.value,
											});
										}}
										color={errors?.phone_number?.required ? "danger" : ""}
										errors={errors?.phone_number}
									/>
								</div>
							)}

							{/* <div className="col-md-6 ">
								<SelectField
									label={teamManagement.catagory}
									placeholder={teamManagement.enterCatagory}
									name="category_id"
									errors={errors.category_id}
									color={errors.category_id?.required ? "danger" : ""}
									options={catagories}
									onChange={(e) => {
										handleChange({ name: "category_id", value: e });

										setErrors({
											...errors,
											...validate(
												{ name: "category_id", value: e },
												{
													required: true,
												}
											),
										});
									}}
									value={data.category_id?.name}
								/>
							</div>
							<div className="col-md-6 ">
								<SelectField
									label={teamManagement.services}
									placeholder={teamManagement.enterServices}
									name="services"
									errors={errors.services}
									color={errors.services?.required ? "danger" : ""}
									options={services}
									onChange={(e) => {
										handleChange({ name: "services", value: e });

										setErrors({
											...errors,
											...validate(
												{ name: "services", value: e },
												{
													required: true,
												}
											),
										});
									}}
									value={data.services?.name}
								/>
							</div> */}

							{/* <div className="col-md-12 ">
								<SelectField
									disabled={data?.is_admin?.value == 1}
									label={teamManagement.team}
									placeholder={teamManagement.enterTeam}
									name="group_id"
									//multi={true}
									errors={errors.group_id}
									color={errors.group_id?.required ? "danger" : ""}
									options={groups}
									onChange={(e) => {
										handleChange({ name: "group_id", value: e });
										setErrors({
											...errors,
											...validate(
												{ name: "group_id", value: e },
												{
													required: true,
												}
											),
										});
									}}
									value={data?.group_id?.name}
								/>
							</div> */}

							{/* <div className="col-md-6 ">
								<SelectField
									label={teamManagement.admin}
									placeholder={teamManagement.admin}
									name="is_admin"
									//multi={true}
									errors={errors.is_admin}
									color={errors.is_admin?.required ? "danger" : ""}
									options={admins}
									onChange={(e) => {
										handleChange({ name: "is_admin", value: e });
										setErrors({
											...errors,
											...validate(
												{ name: "is_admin", value: e },
												{
													required: true,
												}
											),
										});
									}}
									value={data?.is_admin?.name}
								/>
							</div> */}
						</div>

						<div className="d-flex justify-content-end">
							<button className="btn btn-primary mx-1" onClick={handleFormAdd}>
								{teamManagement.add}
							</button>
							<button
								className="btn btn-secondary mx-1"
								onClick={() => {
									toggleModalShowAdd(null);
								}}
							>
								{" "}
								{teamManagement.cancel}
							</button>
						</div>
					</div>
				</Model>

				{/******* MOdel ***** */}
			</div>
		</div>
	);
};

export default Collaps;
