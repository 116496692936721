import React from "react";
import "./style.scss";
function Layout({ children }) {
	return (
		<>
			{/* <Header /> */}
			<main className="main-color">{children}</main>
			{/* <Footer /> */}
		</>
	);
}

export default Layout;
