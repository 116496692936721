import React, { useEffect, useState } from "react";
import { getNotifications } from "services/notifaction";
import Notification from "./Notification";

export default function AllNotifications() {
	const [NotificationsList, setNotificationsList] = useState();

	useEffect(async () => {
		const Notifications = await getNotifications();
		if (Notifications.status == 200) {
			setNotificationsList(Notifications.data.data);
		}
	}, []);

	return (
		<div className="d-flex flex-column  align-items-center ">
			<div className="w-50  p-4">
				{NotificationsList && NotificationsList.length > 0
					? NotificationsList.map((res) => {
							// notification-readed
							return (
								<div className="notification-items mt-3  border">
									<p className="notification-content">
										{" "}
										<strong className="font-weight-bold">
											{res.title}
										</strong>{" "}
									</p>
									<p className="notification-content">{res.body}</p>
									{res.read_at == "" ? <i class="fas fa-circle dot"></i> : ""}
								</div>
							);
					  })
					: ""}
			</div>
		</div>
	);
}
