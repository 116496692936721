import Model from "components/model";
import SelectField from "components/shared/SelectField";
import React, { useState } from "react";
import Locale from "translations";

export default function AddCustomRate({
	customRatemodal,
	setCustomRatemodal,
	countries,
	ratetoggle,
	addCustomRate,
}) {
	const { hotelLayout } = Locale;
	const [customRate, setCustomRate] = useState({
		countries: [],
	});
	const handleSubmit = () => {
		addCustomRate(customRate, customRatemodal.ratePlanId);
		setCustomRatemodal({
			isOpen: !customRatemodal.isOpen,
		});
		setCustomRate({});
	};
	return (
		<>
			<Model
				isOpen={customRatemodal.isOpen}
				title={customRatemodal.title}
				ratetoggle={ratetoggle}
				hasFooter={false}
			>
				<div className="py-3 w-100">
					{/* <div className="col-md-12 mb-4">
						<SelectField
							multi={true}
							label={hotelLayout.calendar.selectCountry}
							options={countries}
							placeholder={hotelLayout.calendar.selectCountry}
							id="services-languages"
							value={customRate.countries}
							onChange={(e) => {
								setCustomRate({ ...customRate, countries: e });
							}}
						/>
					</div> */}

					<div className="col-md-12 mb-4">
						<SelectField
							label={hotelLayout.calendar.RESIDENCE}
							placeholder={hotelLayout.calendar.RESIDENCE}
							value={customRate?.residence}
							name="customRate"
							id="custom-rate"
							options={[{ label: "All", value: null }, ...countries]}
							onChange={(e) => {
								setCustomRate({
									...customRate,
									residence: e,
								});
								// setErrors({
								// 	...errors,
								// 	...validate(
								// 		{ name: "customRate", value: e },
								// 		{ required: true }
								// 	)
								// });
							}}
							// onBlur={() =>
							// 	setErrors({
							// 		...errors,
							// 		...validate(
							// 			{
							// 				name: "customRate",
							// 				value: customRate.customRate
							// 			},
							// 			{ required: true }
							// 		)
							// 	})
							// }
							// color={errors?.customRate?.required ? "danger" : ""}
							// errors={errors?.customRate}
						/>
					</div>

					<div className="col-md-12 mb-4">
						<SelectField
							label={hotelLayout.calendar.nationality}
							placeholder={hotelLayout.calendar.nationality}
							value={customRate?.nationality}
							name="customRate"
							id="custom-rate"
							options={[{ label: "All", value: null }, ...countries]}
							onChange={(e) => {
								setCustomRate({
									...customRate,
									nationality: e,
								});
								// setErrors({
								// 	...errors,
								// 	...validate(
								// 		{ name: "customRate", value: e },
								// 		{ required: true }
								// 	)
								// });
							}}
							// onBlur={() =>
							// 	setErrors({
							// 		...errors,
							// 		...validate(
							// 			{
							// 				name: "customRate",
							// 				value: customRate.customRate
							// 			},
							// 			{ required: true }
							// 		)
							// 	})
							// }
							// color={errors?.customRate?.required ? "danger" : ""}
							// errors={errors?.customRate}
						/>
					</div>

					<div className="submit-btn d-flex flex-row-reverse">
						{customRate?.residence?.value == null &&
						customRate?.nationality?.value == null
							? "Please note that all nationality and all residence is the default one."
							: ""}
						<button
							className="btn btn-primary mx-1 px-5"
							onClick={handleSubmit}
							disabled={
								customRate?.residence?.value == null &&
								customRate?.nationality?.value == null
							}
						>
							{hotelLayout.calendar.add}
						</button>
						<button className="btn btn-secondary mx-1" onClick={ratetoggle}>
							{hotelLayout.calendar.cancel}
						</button>
					</div>
				</div>
			</Model>
		</>
	);
}
