import Header from "components/layout/Header";
import { TeamManagementProvider } from "context/teamManagement";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import Layout from "./layout/Layout";
import SideBar from "./layout/SideBar";
//import DashboardSubNav from "./layout/DashboardSubNav";

// React Component
export function TeamManagementRoute({ component: Component, ...props }) {
	const isAuth = localStorage.getItem("isAuth");
	if (isAuth) {
		return (
			<Route
				{...props}
				render={(matchProps) => (
					<>
						<Layout>
							<TeamManagementProvider>
								{/* <DashboardSubNav /> */}
								<Component {...matchProps} />
							</TeamManagementProvider>
						</Layout>
					</>
				)}
			/>
		);
	} else {
		return <Redirect to="/login" />;
	}
}
