import React from "react";
import Locale from "translations";

export default function Details({ details }) {
	const { reservations } = Locale;

	return (
		<div className="bg-white my-3 rounded">
			<div className="bar-header rounded ">
				<h3 className="bar-title  p-3">{reservations.details}</h3>
			</div>
			<div className="p-3">
				<div className="row m-0 p-0">
					<div className="col-4 p-0">
						<h4 className="second-title">{reservations.hotelName} </h4>
						<p className="text-primiry">{details?.property?.name}</p>
					</div>
					<div className="col-4 p-0">
						<h4 className="second-title">{reservations.bookingDate} </h4>
						<p className="bar-title">{details?.created_at}</p>
					</div>
					{/* <div className="col-3 p-0">
						<h4 className="second-title">{reservations.confirmationNo} </h4>
						<p className="bar-title">{details?.confirm_number}</p>
					</div> */}

					<div className="col-3 p-0">
						<h4 className="second-title">{reservations.confirmationDate} </h4>
						<p className="text-primiry">{details?.confirm_date}</p>
					</div>
				</div>

				<div className="row m-0 p-0 mt-4">
					<div className="col-4 p-0">
						<h4 className="second-title">{reservations.CheckIn} </h4>
						<p className="bar-title">{details?.check_in}</p>
					</div>

					<div className="col-4 p-0">
						<h4 className="second-title">{reservations.CheckOut} </h4>
						<p className="bar-title">{details?.check_out} </p>
					</div>

					<div className="col-3 p-0">
						<h4 className="second-title">{reservations.NoOfNights} </h4>
						<p className="text-primiry">{details?.no_of_nights}</p>
					</div>
				</div>

				<div className="row m-0 p-0 my-4">
					<div className="col-4 p-0">
						<h4 className="second-title">{reservations.referenceNo} </h4>
						<p className="bar-title"> {details?.file_number}</p>
					</div>
					{/* <div className="col-3 p-0">
						<h4 className="second-title">{reservations.source}</h4>
						<p className="bar-title">{details?.source}</p>
					</div> */}

					<div className="col-4 p-0">
						<h4 className="second-title">{reservations.status}</h4>
						<p className="text-success"> {details.status} </p>
					</div>
					<div className="col-4 p-0">
						<h4 className="second-title">{reservations.paymentStatus}</h4>
						<p className="text-success">{details?.payment_type}</p>
					</div>
				</div>

				<div className="row m-0 p-0"></div>
			</div>
		</div>
	);
}
