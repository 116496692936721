import axios from "axios";
import { cleanEmpty } from "services/general";
const reservationsUrl = process.env.REACT_APP_API_URL + "/api";

export const getListReservations = async (id, data) => {
	return await axios
		.get(`${reservationsUrl}/properities/${id}/reservations`, {
			params: cleanEmpty(data),
		})
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const getHotelsRate = async (id) => {
	return await axios
		.get(`${reservationsUrl}/promotions/${id}/rate-plans`)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const askToShare = async (id, data) => {
	return await axios
		.post(`${reservationsUrl}/share/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const askToEditShare = async (id, data) => {
	return await axios
		.post(`${reservationsUrl}/share/${id}/edit`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const askToReject = async (id) => {
	return await axios
		.post(`${reservationsUrl}/property/reservation/reject/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewShare = async (id, data) => {
	return await axios
		.get(`${reservationsUrl}/share/${id}/show`)

		.then((res) => res.data.data)
		.catch((err) => err.response);
};

export const viewReservations = async (property_id, reservation_id) => {
	return await axios
		.get(
			`${reservationsUrl}/properities/${property_id}/reservations/${reservation_id}`
		)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const askToApproved = async (id, data) => {
	return await axios
		.post(
			`${reservationsUrl}/property/reservation/approve/${id}`,
			cleanEmpty(data)
		)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const askToPromo = async (id, data) => {
	return await axios
		.post(
			`${reservationsUrl}/property/reservation/send/promo-code/${id}`,
			cleanEmpty(data)
		)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const askToVoucher = async (id, data) => {
	return await axios({
		method: "post",
		url: `${reservationsUrl}/property/reservation/send/voucher/${id}`,
		data: data,
		headers: { "Content-Type": "multipart/form-data" },
	})
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const SendVoucher = async (id, room_id, data) => {
	return await axios({
		method: "post",
		url: `${reservationsUrl}/property/reservation/${id}/voucher-code/${room_id}`,
		data: data,
	})
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const EditExpirationDate = async (id, data) => {
	return await axios({
		method: "post",
		url: `${reservationsUrl}/property/reservation/edit-expiration-date/${id}`,
		data: data,
	})
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const getRoomsType = async (reservation_id) => {
	return await axios
		.get(
			`${reservationsUrl}/property/reservation/${reservation_id}/lookups/room-type`
		)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const getMealsType = async (reservation_id, room_id) => {
	return await axios
		.get(
			`${reservationsUrl}/property/reservation/${reservation_id}/lookups/meal-type?room_id=${room_id}`
		)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const updateRoom = async (reservation_id, data) => {
	return await axios
		.put(
			`${reservationsUrl}/property/reservation/${reservation_id}/update`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const askToStopShare = async (id, status) => {
	return await axios
		.post(`${reservationsUrl}/share/${id}/update-status`, { status: status })
		.then((res) => res)
		.catch((err) => err.response);
};
