import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Locale from "translations";
import walletBlue from "assets/images/walletBlue.svg";

export default function TableContent({ data }) {
	const { wallet } = Locale;

	return (
		<tr className=" text-black">
			<td>{data.companyName}</td>
			<td>{data.email}</td>
			<td>{data.mobile}</td>
			<td>{data.country}</td>
			<td>{data.city}</td>
			<td>{data.date}</td>
			<td className="text-primary pointer " width={"19%"}>
				<Link to={`/my-network/${data.id}/wallet`}>
					<img src={walletBlue} alt="" width={30} height={25} />{" "}
					{wallet.manageWallet}
				</Link>
			</td>
		</tr>
	);
}
