import emailImg from "assets/images/mailbox.svg";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { activeAccount, reactiveAccount } from "services/auth";
import Locale from "translations";
import AuthLayout from "../layout/AuthLayout";

function Activation() {
	const { login } = Locale;
	const { state } = useLocation();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);
	const [counter, setCounter] = useState(null);
	const useQuery = () => new URLSearchParams(useLocation().search);
	const query = useQuery();

	const [msg, setMsg] = useState(
		"Please click to this link to activate your email"
	);
	const activate = async () => {
		const email = query.get("email");
		const token = query.get("token");
		setLoading(true);
		let response;
		if (state?.action === "resend") {
			response = await reactiveAccount({ email: state.email });
		} else {
			response = await activeAccount({ email, token });
		}
		setLoading(false);
		setCounter(5);
		setMsg(response.data.message);
		setShow(true);
	};

	//

	useEffect(() => {
		if (!loading && show) {
			const timer =
				counter > 0
					? setInterval(() => setCounter(counter - 1), 1000)
					: history.push("/login");
			return () => {
				clearInterval(timer);
			};
		}
	}, [counter, loading, show]);

	return (
		<AuthLayout>
			<div className="bg-white p-2 px-3 radius-10 mt-3">
				<div className="row">
					<div className="col-md-6 mx-auto text-center py-4">
						<img
							src={emailImg}
							width="180"
							alt="email-verification"
							title="email-verification"
						/>
						<p className="text-headline font-medium">{login.emailActivation}</p>
						<p className="text-headline">{msg}</p>
						{show ? (
							<Link to="/login" className="btn btn-success" id="backToLogin">
								{counter} {login.backToLogin}
							</Link>
						) : (
							<button
								onClick={activate}
								className="btn-clear text-danger"
								id="activate"
							>
								{login.activate}
							</button>
						)}
					</div>
				</div>
			</div>
		</AuthLayout>
	);
}

export default Activation;
