import { fetchCalendarFormat } from "data/calendar";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { fetchCalendar } from "services/calendar";
import { getListRooms } from "services/rateplan";
import CalendarRoom from "./CalendarRoom";
import Filter from "./Filter";

export default function Calendar() {
	const { id } = useParams();
	const [roomList, setRoomList] = useState([]);
	const [roomCalender, setRoomCalender] = useState([]);
	const [days, setDays] = useState([]);
	const [filters, setFilters] = useState({
		rooms: [],
		dateStart: moment(new Date()),
		dateEnd: moment(new Date()),
	});

	const getViewCalendar = async (filters) => {
		setRoomCalender([]);
		const { id, date_from, date_to, rooms } = fetchCalendarFormat({
			...filters,
		});
		if (moment(date_to).isAfter(date_from)) {
			const req = await fetchCalendar(id, date_from, date_to, rooms);
			// setRoomCalender([]);

			setTimeout(() => {
				if (req.hasOwnProperty("data")) {
					getDays(date_from, date_to);
					setRoomCalender([...req.data]);

					return req;
				}
			}, 20);
		}
	};



	async function getDays(startDate, endDate) {
		let dates = [];
		let startloob;
		startloob = startDate;
		if (startDate !== "Invalid date" && endDate !== "Invalid date") {
			while (
				moment(startloob._d).add(-1, "days").format("YYYY-MM-DD") !==
				moment(endDate).add(1, "days").format("YYYY-MM-DD")
			) {
				if (
					moment(startloob._d).format("YYYY-MM-DD") !==
					moment().format("YYYY-MM-DD")
				) {
					dates.push(moment(startloob._d).add(-1, "days").format("YYYY-MM-DD"));
				}
				startloob = moment(startloob).add(1, "days");
			}
		}
		setDays(dates);
	}

	useEffect(() => {
		if (roomList.length === 0) {
			const fetchRooms = async () => {
				const req = await getListRooms(id);

				const formatted = req?.data?.data.map((room) => ({
					value: room.id,
					label:
						room.custom_name !== null
							? room.custom_name + " ( " + room?.type?.name + " )"
							: room.name.name,
				}));

				setRoomList(formatted);
			};
			fetchRooms();
		}
	}, [roomList]);

	return (
		<>
			<div className="">
				<Filter
					getViewCalendar={getViewCalendar}
					id={id}
					setFilters={setFilters}
					filters={filters}
					rooms={roomList}
				/>
				{roomCalender.length > 0
					? roomCalender.map((room) => (
						<CalendarRoom
							days={days}
							id={id}
							filters={filters}
							getViewCalendar={getViewCalendar}
							room={room}
							key={room.id + "kry"}
						/>
					))
					: ""}
			</div>
		</>
	);
}
