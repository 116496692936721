import React, { createContext, useContext, useReducer } from "react";
const VisasReducerContext = createContext();
const VisasReducerDispatchContext = createContext();
const initialState = {};

function VisasReducer(state, action) {
	switch (action.type) {
		case "dashboardState": {
			return { ...state, dashboardState: action.payload };
		}

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function useVisasState() {
	const context = useContext(VisasReducerContext);
	if (context === undefined) {
		throw new Error(
			"useVisasReducerState must be used within a VisasReducerProvider"
		);
	}
	return context;
}

function useVisasDispatch() {
	const context = useContext(VisasReducerDispatchContext);
	if (context === undefined) {
		throw new Error(
			"useVisasReducerDispatch must be used within a VisasReducerProvider"
		);
	}
	return context;
}

function VisasProvider({ children }) {
	const [state, dispatch] = useReducer(VisasReducer, initialState);
	return (
		<VisasReducerContext.Provider value={state}>
			<VisasReducerDispatchContext.Provider value={dispatch}>
				{children}
			</VisasReducerDispatchContext.Provider>
		</VisasReducerContext.Provider>
	);
}

export { VisasProvider, useVisasState, useVisasDispatch };
