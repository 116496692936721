// import clearIcon from "assets/images/wallet/clearIcon.svg";
// import correctIcon from "assets/images/wallet/correctIcon.svg";
import DateRangeField from "components/shared/DateRangeField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
// import DateRangeField from "components/Form/DateRangeField/DateRangeField";
// import SelectField from "components/Form/SelectField/SelectField";
// import TextField from "components/Form/TextField/TextField";
import moment from "moment";
import { useEffect, useState } from "react";
//import { getModulesDropdown, getUsersDropdown } from "services/activity";
// import resetIcon from "assets/images/wallet/resetIcon.png";
import { getModulesDropdown, getUsersDropdown } from "services/activity";
import Locale from "translations";

export default function Filter({
	filter,
	setFilter,
	transaction_type,
	search,
}) {
	const { payment, activity, backOffice } = Locale;
	const [usersOptions, setUsersOptions] = useState([]);
	const [modulesOptions, setModulesOptions] = useState([]);

	useEffect(() => {
		async function getUsers() {
			const res = await getUsersDropdown();
			let formated = res?.data?.data.map((item, index) => ({
				value: item.user_id,
				label: item.username,
			}));
			setUsersOptions(formated);
		}

		async function getModules() {
			const res = await getModulesDropdown();

			let formated = res?.data.map((item, index) => ({
				value: "1",
				label: item.log_name,
			}));
			setModulesOptions(formated);
		}

		getUsers();
		getModules();
	}, []);
	return (
		<>
			<div className=" py-2 my-2 px-3 border-bottom">
				<div className="row align-items-center w-100 no-gutter m-0">
					<div className="col-2">
						<TextField
							label={'search'}
							type="text"
							placeholder={"search"}
							onChange={(e) => setFilter({ ...filter, search: e.target.value })}
							value={filter.search}
						/>
					</div>

					<div className="col-2 ">
						<SelectField
							label={activity.user}
							placeholder={activity.enterUsers}
							options={usersOptions}
							value={filter?.user_id}
							onChange={(e) =>
								setFilter({
									...filter,
									user_id: e,
								})
							}
						/>
					</div>

					<div className="col-3">
						<SelectField
							label={activity.Module}
							placeholder={activity.enterModules}
							options={modulesOptions}
							value={filter?.log_name}
							onChange={(e) =>
								setFilter({
									...filter,
									log_name: e,
								})
							}
						/>
					</div>
					<div className="col date-range-input">
						<DateRangeField
							hasLabel={true}
							label={activity.date}
							startDate={filter.date_from}
							endDate={filter.date_to}
							startDatePlaceholderText={activity.date}
							endDatePlaceholderText={activity.date}
							onDatesChange={({ startDate, endDate }) => {
								setFilter({
									...filter,
									date_from: moment(startDate)._d,
									date_to: endDate
										? moment(endDate)._d
										: moment(startDate).add(1, "d")._d,
								});
							}}
							isOutsideRange={(day) => {
								return false;
							}}
						/>
					</div>
						<span
							className="col d-flex justify-content-start align-items-center  mt-2"
							onClick={() => {
								setFilter({
									search: "",
									user_id: "",
									date_from: undefined,
									date_to: undefined,
									log_name: "",
									page: 1,
								});
							}}
						>
						resetFilter <i className="fas fa-retweet"></i>
						</span>
				</div>
			</div>
		</>
	);
}
