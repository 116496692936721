import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { locale } from "moment";
import React from "react";
import Locale from "translations";

export default function Filter({ filter, setFilter, countries, visaType }) {
	const { reservations, requests } = Locale;

	const customStyles = {
		control: () => ({
			// none of react-select's styles are passed to <Control />
			height: "33px",
		}),
		indicatorsContainer: () => ({
			visibility: "hidden",
		}),
	};
	return (
		<div className="row m-0 p-0 w-100 rounded">
			<div className="col">
				<TextField
					value={filter.company_name}
					// label={reservations.bookingNumber}
					hasLabel={false}
					placeholder={reservations.search}
					onChange={(e) => {
						setFilter({
							...filter,
							company_name: e.target.value,
						});
					}}
				/>
			</div>
			<div className="col">
				<SelectField
					hasLabel={false}
					value={filter.destination_id}
					styles={customStyles}
					multi={false}
					placeholder={requests.Destination}
					options={countries}
					onChange={(e) => {
						setFilter({
							...filter,
							destination_id: e,
						});
					}}
				/>
			</div>
			<div className="col">
				<SelectField
					hasLabel={false}
					value={filter.cycle_type}
					styles={customStyles}
					multi={false}
					placeholder={"Cycle Type"}
					options={visaType}
					onChange={(e) => {
						setFilter({
							...filter,
							cycle_type: e,
						});
					}}
				/>
			</div>
			<div className="col">
				<DatePickerField
					hasLabel={false}
					date={filter.request_date}
					placeholder={"DD/MM/YYYY"}
					onChangeDate={(e) => {
						setFilter({
							...filter,
							request_date: e,
						});
					}}
				/>
			</div>

			<div className="col-2 d-flex justify-content-start align-items-center ">
				<span
					className="text-muted btn"
					onClick={() => {
						setFilter({
							company_name: "",
							destination_id: null,
							cycle_type: null,
							request_date: null,
							page: 1,
						});
					}}
				>
					{reservations.cancel} <i class="fas fa-retweet"></i>
				</span>
			</div>
		</div>
	);
}
