import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import AuthLayout from "modules/auth/layout/AuthLayout";
import emailImg from "assets/images/mailbox.svg";
import Locale from "translations";
import { useAuthState } from "context/auth";
import { store } from "react-notifications-component";
// import Locale from "./RegistrationSucceed.locale";
const { success, login } = Locale;

export default function RegistrationSucceed(props) {
	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	const userEmail = useAuthState();
	const [counter, setCounter] = React.useState(60);
	const [isResend, setIsResend] = useState(true);
	const [email, setEmail] = useState(null);
	const [title, setTitle] = useState(success.successTitle);
	const [message, setMessage] = useState(success.successMessage);
	const { pathname, state } = useLocation();
	// ----------------------------------------------------------------------------------------------------
	// Component Functions
	// ----------------------------------------------------------------------------------------------------
	const resendEmailVerification = () => {
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/api/users/resend-activate-token`,
				{ email: state.email }
			)
			.then((res) => {
				if (res.status === 200) {
					store.addNotification({
						title: "Info!",
						message: "Activation code sent!",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			})
			.catch((err) => {});
		setIsResend(true);
		setCounter(60);
	};

	useEffect(() => {
		if (isResend) {
			const timer =
				counter > 0
					? setInterval(() => setCounter(counter - 1), 1000)
					: setIsResend(false);
			return () => {
				clearInterval(timer);
			};
		}
	}, [counter, isResend]);

	useEffect(() => {
		if (pathname.indexOf("activation") >= 0) {
			setEmail(state?.email);
			setTitle("Activation mail");
			setMessage("We just sent you an activation email");
		} else setEmail(userEmail.userDetails?.email);
		if (email != null && pathname.indexOf("activation") >= 0)
			resendEmailVerification();
	}, [email]);

	// ----------------------------------------------------------------------------------------------------
	// Main Component Body UI
	// ----------------------------------------------------------------------------------------------------
	return (
		<>
			<AuthLayout>
				<div className="bg-white p-2 px-3 radius-10 mt-3">
					<div className="row">
						<div className="col-md-6 mx-auto text-center py-4">
							<img
								src={emailImg}
								width="180"
								alt="email-verification"
								title="email-verification"
							/>
							<p className="text-headline font-medium">{title}</p>
							<p className="text-headline">{message}</p>
							<p className="text-headline">{success.successMessage2}</p>
							{!isResend ? (
								<>
									<p className="text-headline mt-5">{success.notReceive}</p>
									<p className="text-headline">
										{success.checkSpam}
										<button
											className="btn-clear text-danger"
											onClick={resendEmailVerification}
											id="resendEmailVerification"
										>
											{success.resendEmail}
										</button>
									</p>
								</>
							) : (
								<p>
									{success.pleaseWait}{" "}
									<span className="text-danger">00:{counter}</span>{" "}
									{success.receiveMail}
								</p>
							)}
						</div>
					</div>
				</div>
				<div className="row mt-3 justify-content-between m-0">
					<div className="col-md-6" />
					<div className="col-md-4">
						<Link to="/login" className="btn btn-primary w-100">
							{login.backToLogin}
						</Link>
					</div>
				</div>
			</AuthLayout>
		</>
	);
}
