import { useHotelLayoutState } from "context/hotelLayout";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Locale from "translations";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import { Modal, ModalBody } from "reactstrap";
import moment from "moment";
import {
	askToEditShare,
	askToShare,
	getHotelsRate,
	viewShare,
} from "services/reservations";
import { getNotification } from "helpers/makeNotifications";
import { useHistory } from "react-router-dom";

export default function Stepper() {
	const { dashboardState } = useHotelLayoutState();
	const [isOpen, setIsOpen] = useState(false);
	const [hotelsRates, setHotelsRates] = useState();
	let history = useHistory();
	const { hotelLayout, reservations } = Locale;
	const { id } = useParams();

	const bookingTypes = [
		{ value: 1, label: reservations.autoConfirmation },
		{ value: 2, label: reservations.manualConfirmation },
	];

	const [share, setShare] = useState({
		publish_from: null,
		publish_to: null,
		booking_type: null,
		rate_plans: [],
	});

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	useEffect(async () => {
		if (dashboardState?.is_shared) {
			const resShare = await viewShare(id);
			if (resShare?.publish_from) {
				setShare({
					...share,
					publish_from: resShare?.publish_from
						? moment(resShare?.publish_from)
						: null,
					publish_to: resShare?.publish_to
						? moment(resShare?.publish_to)
						: null,
					shareEdit: resShare?.publish_from ? true : false,
					rate_plans: resShare?.rate_plans,
					booking_type:
						resShare?.booking_type === "1"
							? { value: 1, label: reservations.autoConfirmation }
							: resShare?.booking_type === "2"
							? { value: 2, label: reservations.manualConfirmation }
							: null,
				});
			}
		}
	}, [dashboardState?.is_shared]);

	/* get hotels rate lookups*/
	useEffect(async () => {
		const res = await getHotelsRate(id);
		const format =
			res?.data?.length > 0
				? res.data.map((item) => {
						return { value: item.id, label: item.name };
				  })
				: null;
		setHotelsRates(format);
	}, []);

	const sendShare = async () => {
		let res;
		let ratesPlans = share.rate_plans.map((item, index) => {
			return item.value;
		});

		if (share.shareEdit === true) {
			res = await askToEditShare(id, {
				publish_from: moment(share.publish_from).format("YYYY-MM-DD"),
				publish_to: moment(share.publish_to).format("YYYY-MM-DD"),
			});
		} else {
			res = await askToShare(id, {
				publish_from: moment(share.publish_from).format("YYYY-MM-DD"),
				publish_to: moment(share.publish_to).format("YYYY-MM-DD"),
				booking_type: share.booking_type.value,
				rate_plans: [...ratesPlans],
			});
		}
		if (res?.status === 200 || res?.status === 201) {
			getNotification(res?.data?.message);
			toggle();
			if (share.shareEdit === false) {
				history.push(`/`);
			}
		}
	};

	return (
		<>
			<div className="stepper">
				<div className="py-3 text-center">
					<h5 className="font-weight-bold text-blue-black">
						4 {hotelLayout.dashboard.steps}
					</h5>
					<p className="text-blue-black">{hotelLayout.dashboard.shareHotel}</p>
				</div>
				<div
					className={`step ${
						dashboardState?.is_has_property_rates ? "success-step" : ""
					} ${dashboardState <= 0 ? "active-step" : ""}`}
				>
					<div className="icon">
						<span className="text-headline">
							{dashboardState?.is_has_property_rates ? (
								<i class="fas fa-check fa-lg"></i>
							) : (
								<i class="fas fa-ellipsis-h"></i>
							)}
						</span>
					</div>
					<Link className="text" to={`/dashboard/list-Rate-Plan/${id}`}>
						{hotelLayout.dashboard.addRate}
					</Link>
				</div>
				<div
					className={`step ${
						dashboardState?.is_has_property_rate_prices ? "success-step" : ""
					} ${dashboardState === 1 ? "active-step" : ""}`}
				>
					<div className="icon">
						<span className="text-headline">
							{dashboardState?.is_has_property_rate_prices ? (
								<i class="fas fa-check fa-lg"></i>
							) : (
								<i class="fas fa-ellipsis-h"></i>
							)}
						</span>
					</div>
					<Link className="text" to={`/calendar/${id}`}>
						{hotelLayout.dashboard.addInventory}
					</Link>
				</div>

				<div
					className={`step  ${
						dashboardState?.is_has_promotions ? "success-step" : ""
					}
			      	${dashboardState === 2 ? "active-step" : ""}`}
				>
					<div className="icon">
						{dashboardState?.is_has_promotions ? (
							<i class="fas fa-check fa-lg"></i>
						) : (
							<i class="fas fa-ellipsis-h"></i>
						)}
					</div>
					<Link className="text" to={`/dashboard/promotions/${id}`}>
						{hotelLayout.dashboard.addPromotion}
					</Link>
				</div>

				<div
					className={`step  ${
						dashboardState?.is_shared ? "success-step" : ""
					}  ${dashboardState === 3 ? "active-step" : ""}`}
				>
					<div className="icon">
						{dashboardState?.is_shared ? (
							<i class="fas fa-check fa-lg"></i>
						) : (
							<i class="fas fa-ellipsis-h"></i>
						)}
					</div>
					<div
						className="text  pointer "
						onClick={() => {
							if (
								hotelsRates?.length > 0 &&
								dashboardState.is_has_property_rate_prices
							) {
								toggle();
							}
						}}
					>
						{hotelLayout.dashboard.shareMarketPlace}
					</div>
				</div>
			</div>

			{/* Modal for share to market place sbs */}
			<Modal isOpen={isOpen} toggle={toggle}>
				<div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
					<p className=" text-secondary font-weight-bold">
						{reservations.ShareatMarketplace}
					</p>
				</div>

				<ModalBody className="  d-flex flex-column justify-content-center align-items-center ">
					<div className="d-flex w-100">
						<div className="w-50 px-2">
							<DatePickerField
								label={reservations.publishFrom}
								onChangeDate={(e) => {
									setShare({ ...share, publish_from: e });
								}}
								date={share.publish_from}
								minDate={moment(new Date()).subtract(1, "days")}
								readOnly={share.publish_from != null && share?.shareEdit}
								disabled={share.publish_from != null && share?.shareEdit}
							/>
						</div>
						<div className="w-50 px-2">
							<DatePickerField
								label={reservations.publishTo}
								onChangeDate={(e) => {
									setShare({ ...share, publish_to: e });
								}}
								date={share.publish_to}
								readOnly={share.publish_from === null}
								disabled={share.publish_from === null}
								minDate={moment(share.publish_from).subtract(1, "days")}
							/>
						</div>
					</div>
					<div className="w-100 px-2">
						<SelectField
							label={reservations.bookingType}
							options={bookingTypes}
							onChange={(e) => {
								setShare({ ...share, booking_type: e });
							}}
							value={share.booking_type}
							readOnly={share.booking_type != null && share?.shareEdit}
							disabled={share.booking_type != null && share?.shareEdit}
						/>
					</div>
					<div className="w-100 px-2">
						<SelectField
							label={reservations.ratePlans}
							multi={true}
							options={hotelsRates}
							value={share.rate_plans}
							onChange={(e) => {
								setShare({ ...share, rate_plans: e === null ? [] : [...e] });
							}}
							disabled={share.rate_plans != null && share?.shareEdit}
						/>
					</div>
				</ModalBody>
				<div className="pb-5 border-top-0  text-center    p-0 ">
					<button
						className="rounded btn btn-primary w-50 py-2 mx-1 "
						onClick={sendShare}
						disabled={
							share?.publish_from === null ||
							share?.publish_to === null ||
							share?.booking_type === null ||
							share?.rate_plans.length === 0
						}
					>
						{reservations.ShareatMarketplace}
					</button>
					<button
						className="rounded btn btn-secondary w-25 py-2 mx-1"
						onClick={toggle}
					>
						{reservations.cancel}
					</button>
				</div>
			</Modal>
		</>
	);
}
