import walletBlue from "assets/images/walletBlue.svg";
import { useParams } from "react-router-dom";
import Locale from "translations";

export default function ViewRequestHeader({ header }) {
	const { requests, backOffice } = Locale;
	const requestsSearch = {
		destination: "United Arab Emirates",
	};
	const { status, id } = useParams();

	return (
		<>
			<div className="rounded row m-0  justify-content-between bg-white border p-0">
				<div className="rounded m-0 row col-12  justify-content-between  flex-nowrap  p-0">
					<div className="col-2   p-5 bg-gray text-center">
						<p className="m-0 font-weight-bold">VISA</p>
						<p className="m-0 font-weight-bold"> REQUESTS</p>
					</div>

					<div className="row col-6 py-2">
						<div className="col-4 text-visa-header font-weight-bold">
							<p className="m-0 ">{requests.Destination}</p>
							<p className="m-0 ">{requests.VisaType}</p>
							<p className="m-0 ">{requests.Pax}</p>
							<p className="m-0 ">{requests.VisaPriceOne}</p>
							<p className="m-0 ">{requests.RequestDate}</p>
							<p className="m-0 ">{requests.Status}</p>
						</div>

						<div className="col-8  ">
							<p className="m-0">{header?.destination_name}</p>
							<p className="m-0">{header?.visa_type_name}</p>
							<p className="m-0">{header?.pax}</p>
							<p className="m-0">
								{header?.price_per_one} {header?.currency}
							</p>
							<p className="m-0">{header?.request_date}</p>
							<p
								className={`m-0 ${
									header?.status === "issued" ? "text-success" : "text-warning"
								}`}
							>
								{header?.status}{" "}
							</p>
						</div>
					</div>

					{/* <div className="row col-4 py-2 ">
						<div className="col-4  text-visa-header font-weight-bold">
							<p className="m-0 ">{requests.Departure}</p>
							<p className="m-0 ">{requests.Return}</p>
							<p className="m-0 ">{requests.RequestDate}</p>
							<p className="m-0 ">{requests.Status}</p>
						</div>

						<div className="col-8  ">
							<p className="m-0">{header?.departure_date}</p>
							<p className="m-0">{header?.return_date}</p>
							<p className="m-0">{header?.request_date}</p>
							<p
								className={`m-0 ${
									header?.status === "issued" ? "text-success" : "text-warning"
								}`}
							>
								{header?.status}{" "}
							</p>
						</div>
					</div> */}

					<div className="col-3 d-flex align-items-center ">
						<div className="d-flex   px-4 text-center ">
							<img src={walletBlue} alt="" className="online-vise-img" />
							<div className="mx-3">
								<p className="m-0 txt-blue">{requests.TotalPrice}</p>
								<p className="m-0 text-success font-weight-bold">
									{header?.total} {header?.currency?.toLocaleUpperCase()}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
