import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import validate from "helpers/validate";
import { useEffect } from "react";
import { useParams } from "react-router";
import { Card, CardBody, Collapse } from "reactstrap";
import Locale from "translations";

export default function PaymentPolicy({
	ratePlan,
	setRatePlanState,
	errors,
	setErrors,
	ConditionsPaymentPolicyType,
}) {
	const RatePlan = Locale;
	const { id, rateId } = useParams();

	const inputPaymentConditionsList = (e, name, index) => {
		let value = e;
		let listPaymentConditions = [...ratePlan.paymentConditionsList];
		let listPaymentConditionsIds = [...ratePlan.paymentConditionsList];

		listPaymentConditions[index][name] = value;
		listPaymentConditionsIds[index][name] = value;
		listPaymentConditionsIds.map((res) => {
			if (res?.payment_policy_type_id?.value) {
				res.payment_policy_type_id = +res.payment_policy_type_id.value;
			}
		});

		setRatePlanState({
			...ratePlan,
			paymentConditionsList: listPaymentConditions,
			listPaymentConditionsIds: listPaymentConditionsIds,
		});
	};

	const removePaymentConditionsList = (index) => {
		setRatePlanState({
			...ratePlan,
			paymentConditionsList: ratePlan.paymentConditionsList.filter(
				(ele, i) => i !== index
			),
			listPaymentConditionsIds: ratePlan.listPaymentConditionsIds.filter(
				(ele, i) => i !== index
			),
		});
	};

	const addPaymentConditionsList = () => {
		let listPaymentConditions = [...ratePlan.paymentConditionsList];
		listPaymentConditions.push({
			payment_policy_type_id: null,
			days: null,
			charge: null,
		});
		setRatePlanState({
			...ratePlan,
			paymentConditionsList: listPaymentConditions,
		});
	};
	useEffect(() => {
		if (
			id !== undefined &&
			rateId !== undefined &&
			ratePlan.paymentConditionsList.length > 0 &&
			ratePlan.listPaymentConditionsIds == undefined
		) {
			let listPaymentConditions = [...ratePlan.paymentConditionsList];
			let list = listPaymentConditions.map(
				(res) =>
					(res = {
						charge: +res.charge,
						days: res.days,
						payment_policy_type_id: res.payment_policy_type?.id,
					})
			);

			setRatePlanState({
				...ratePlan,
				listPaymentConditionsIds: list,
			});
		}
	}, [id, rateId, ratePlan]);

	return (
		<div className="row m-0" style={{ display: "none" }}>
			<div
				className={`col-md-12 p-0 mt-3 ${
					errors?.payment_policy?.required ? "danger-checkbox" : ""
				}`}
			>
				<div
					className="rate-plan-header"
					onClick={() =>
						setRatePlanState({
							...ratePlan,
							isOpenPayment: !ratePlan.isOpenPayment,
						})
					}
				>
					{RatePlan.hotelLayout?.ratePlan?.paymentPolicy}
					<i
						className={`fas pos-arrow ${
							ratePlan.isOpenPayment ? "fa-chevron-up " : "fa-chevron-down"
						}`}
					></i>
				</div>
				<Collapse isOpen={ratePlan.isOpenPayment}>
					<Card>
						<CardBody>
							<div className="col-md-12 pt-2">
								{/* <div class="form-check form-check-inline pl-2 pr-2">
									<input
										class="form-check-input"
										type="radio"
										name="payment_policy123"
										id="payment_policy1"
										value="2"
										checked={ratePlan.payment_policy == "2"}
										onChange={(e) => {
											setRatePlanState({
												...ratePlan,
												listPaymentConditionsIds: undefined,
												paymentConditionsList: [{}],
												payment_policy: e.target.value,
											});
											setErrors({
												...errors,
												...validate(
													{
														name: "payment_policy",
														value: e.target.value,
													},
													{ required: true }
												),
											});
											if (ratePlan.paymentConditionsList.length === 0) {
												addPaymentConditionsList();
											}
										}}
									/>
									<label class="form-check-label" for="payment_policy1">
										Payment conditions
									</label>
								</div> */}
								<div class="form-check form-check-inline pl-2 pr-2">
									<input
										class="form-check-input"
										type="radio"
										name="payment_policy123"
										id="payment_policy2"
										value="1"
										checked={ratePlan.payment_policy == "1"}
										onChange={(e) => {
											setRatePlanState({
												...ratePlan,
												listPaymentConditionsIds: undefined,
												paymentConditionsList: [],
												payment_policy: e.target.value,
											});
											setErrors({
												...errors,
												...validate(
													{
														name: "payment_policy",
														value: e.target.value,
													},
													{ required: true }
												),
											});
										}}
									/>
									<label class="form-check-label" for="payment_policy2">
										Full payment
									</label>
								</div>
								{errors?.payment_policy?.required ? (
									<div className="col-md-12 text-danger danger-massage">
										Which Payment Policy
									</div>
								) : (
									""
								)}
							</div>

							{ratePlan.payment_policy == "2"
								? ratePlan.paymentConditionsList.map((res, i) => {
										return (
											<div className="col-md-12 mt-2" key={i + 123000}>
												<div className="row m-0" key={i + 123000}>
													<div className="col-md-3" key={i + 123000}>
														<SelectField
															label={"Type"}
															value={res.payment_policy_type}
															name="payment_policy_type_id"
															options={
																ratePlan.paymentConditionsList.some(
																	(condition) =>
																		condition.payment_policy_type_id === 1
																)
																	? [
																			{
																				label: "before check in",
																				value: 2,
																			},
																	  ]
																	: ConditionsPaymentPolicyType
															}
															onChange={(e) => {
																inputPaymentConditionsList(
																	e,
																	"payment_policy_type_id",
																	i
																);
															}}
														/>
													</div>
													{ratePlan.paymentConditionsList[i]
														.payment_policy_type_id !== 1 && (
														<div className="col-md-3">
															<TextField
																label={"Days"}
																type={"number"}
																value={res?.days}
																name="days"
																placeholder={"Days"}
																onChange={(e) => {
																	inputPaymentConditionsList(
																		e.target.value,
																		"days",
																		i
																	);
																}}
															/>
														</div>
													)}

													<div className="col-md-3">
														<TextField
															label={"Percentage"}
															value={res?.charge}
															type={"number"}
															name="charge"
															placeholder={"Percentage"}
															onChange={(e) => {
																inputPaymentConditionsList(
																	e.target.value,
																	"charge",
																	i
																);
															}}
														/>
													</div>
													<div className="col-md-3">
														{ratePlan.paymentConditionsList.length > 1 && (
															<a
																href={() => false}
																className="link-delete text-danger"
																onClick={() => {
																	removePaymentConditionsList(i);
																}}
															>
																<i class="fas fa-trash"></i>
															</a>
														)}

														{ratePlan.paymentConditionsList.length == i + 1 ? (
															<a
																href={() => false}
																className="link-delete text-success"
																onClick={() => {
																	addPaymentConditionsList();
																}}
															>
																<i class="fas fa-plus-circle"></i>
															</a>
														) : (
															""
														)}
													</div>
												</div>
											</div>
										);
								  })
								: ""}
						</CardBody>
					</Card>
				</Collapse>
			</div>
		</div>
	);
}
