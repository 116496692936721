import React, { createContext, useContext, useReducer } from "react";
const GroundServiceReducerContext = createContext();
const GroundServiceReducerDispatchContext = createContext();
const initialState = {};

function GroundServiceReducer(state, action) {
	switch (action.type) {
		case "dashboardState": {
			return { ...state, dashboardState: action.payload };
		}

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function useGroundServiceState() {
	const context = useContext(GroundServiceReducerContext);
	if (context === undefined) {
		throw new Error(
			"useGroundServiceReducerState must be used within a GroundServiceReducerProvider"
		);
	}
	return context;
}

function useGroundServiceDispatch() {
	const context = useContext(GroundServiceReducerDispatchContext);
	if (context === undefined) {
		throw new Error(
			"useGroundServiceReducerDispatch must be used within a GroundServiceReducerProvider"
		);
	}
	return context;
}

function GroundServiceProvider({ children }) {
	const [state, dispatch] = useReducer(GroundServiceReducer, initialState);
	return (
		<GroundServiceReducerContext.Provider value={state}>
			<GroundServiceReducerDispatchContext.Provider value={dispatch}>
				{children}
			</GroundServiceReducerDispatchContext.Provider>
		</GroundServiceReducerContext.Provider>
	);
}

export {
	GroundServiceProvider,
	useGroundServiceState,
	useGroundServiceDispatch,
};
