import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalDispatch, useGlobalState } from "context/global";
import { usePropertyDispatch, usePropertyState } from "context/property";
import { formatGetServices, formatPostServices } from "data/property";
import validate, { isFormValid } from "helpers/validate";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom";
import {
	fetchFacilities,
	fetchLanguages,
	fetchRoomViews,
} from "services/lookups";
import { addServices, editServices } from "services/property";
import Locale from "translations";
import CancelModal from "./CancelModal";

function Services() {
	const { id } = useParams();
	const { current } = useGlobalState();
	const { propertyDetails } = usePropertyState();
	const dispatch = useGlobalDispatch();
	const propertyDispatch = usePropertyDispatch();
	const { services } = Locale;
	const locale = localStorage.getItem("currentLocale");
	const [errors, setErrors] = useState({});
	const [canceled, setCanceled] = useState(false);
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [facilities, setFacilities] = useState({});
	const [languages, setLanguages] = useState([]);
	const [views, setViews] = useState([]);
	const [service, setService] = useState({
		parkingAvailable: "",
		parkingType: "",
		parkingSite: "",
		parkingReservation: "",
		parkingPrice: "",
		languages: [],
		views: [],
		facilities: [],
	});

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "parkingAvailable", value: service.parkingAvailable },
				{ required: true }
			),

			...validate(
				{ name: "languages", value: service.languages },
				{ required: true }
			),
			...validate({ name: "views", value: service.views }, { required: true }),
			...validate(
				{ name: "facilities", value: service.facilities },
				{ required: true }
			),
		});
	};

	const formatArray = (array) => {
		return array?.data?.map(
			(item) => (item = { label: item.name, value: item.id })
		);
	};

	useEffect(() => {
		async function fetchLookups() {
			const [languages, roomViews, facilities] = await Promise.all([
				fetchLanguages(locale),
				fetchRoomViews(locale),
				fetchFacilities(locale),
			]);
			setLanguages(formatArray(languages.data));
			setViews(formatArray(roomViews.data));
			setFacilities(formatArray(facilities.data));
		}
		fetchLookups();
	}, []);

	//

	useEffect(() => {
		if (id && propertyDetails?.property.service) {
			const formatted = formatGetServices(propertyDetails.property);
			setService(formatted);
		}
	}, [propertyDetails]);

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setService({ ...service, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			if (name === "country") {
				setService({ ...service, city: "", [name]: value });
			} else setService({ ...service, [name]: value });
		}
	};

	const handleCheckboxChange = (event) => {
		const name = event.target.name;
		if (event.target.checked === true) {
			setService({
				...service,
				[name]: [...service[name], Number(event.target.value)],
			});
		} else {
			setService({
				...service,
				[name]: [
					...service[name].filter((e) => e !== Number(event.target.value)),
				],
			});
		}
	};

	useEffect(async () => {
		if (isFormValid(errors)) {
			if (id && propertyDetails?.property?.service) {
				const data = formatPostServices(service);
				const res = await editServices(id, data);
				if (res.status === 201 || res.status === 200) {
					propertyDispatch({ type: "propertyDetails", payload: res.data });
					dispatch({
						type: "progress",
						step: "3",
						current: "3",
					});
				}
			} else {
				const data = formatPostServices(service);
				const res = await addServices(id, data);
				if (res.status === 201 || res.status === 200) {
					propertyDispatch({ type: "propertyDetails", payload: res.data });

					dispatch({ type: "progress", current: "3", step: "3" });
				}
			}
		} else if (JSON.stringify(errors) !== "{}") {
			store.addNotification({
				title: "info!",
				message: "Please fill all fields",
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}, [isErrorLoaded]);

	const submit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	const reset = () => {
		setCanceled(true);
	};



	return (
		<div>
			<CancelModal isOpen={canceled} toggle={setCanceled} />

			<div className="header-box my-3">{services.parking}</div>
			<div className="row m-0">
				{/* parkingAvailable */}
				<div className="col-4">
					<SelectField
						id="services-parking"
						label={services.parkingAvailable}
						customLabel={services.parkingAvailability}
						placeholder={services.parkingAvailable}
						options={[
							{ label: "Yes Paid", value: "Yes Paid" },
							{ label: "Yes Not Paid", value: "Yes Not Paid" },
							{ label: "No Availability", value: "No Availability" },
						]}
						name="parkingAvailable"
						onChange={(e) => {
							handleChange({ name: "parkingAvailable", value: e });
							setErrors({
								...errors,
								...validate(
									{ name: "parkingAvailable", value: e },
									{ required: true }
								),
							});
						}}
						value={service.parkingAvailable}
						color={errors.parkingAvailable?.required ? "danger" : ""}
						errors={errors.parkingAvailable}
					/>
				</div>
				{service.parkingAvailable?.value == "Yes Paid" ? (
					<div className="col-4">
						<TextField
							label={services.price}
							placeholder={services.price}
							value={service.parkingPrice}
							name="parkingPrice"
							id="services-price"
							min={1}
							onChange={(e) => {
								handleChange(e);
							}}
							placeholderText={"SAR"}
						/>
					</div>
				) : (
					""
				)}
				{service.parkingAvailable?.value == "Yes Paid" ||
					service.parkingAvailable?.value == "Yes Not Paid" ? (
					<>
						<div className="col-4">
							<SelectField
								id="services-type"
								label={services.type}
								placeholder={services.type}
								options={[
									{ label: "Public", value: "Public" },
									{ label: "Private", value: "Private" },
								]}
								name="parkingType"
								onChange={(e) => {
									handleChange({ name: "parkingType", value: e });
								}}
								value={service.parkingType}
							/>
						</div>

						<div className="col-4">
							<SelectField
								label={services.site}
								placeholder={services.site}
								id="services-site"
								options={[
									{ label: "On Site", value: "On Site" },
									{ label: "External", value: "External" },
								]}
								name="parkingSite"
								onChange={(e) => {
									handleChange({ name: "parkingSite", value: e });
								}}
								value={service.parkingSite}
							/>
						</div>

						<div className="col-4">
							<SelectField
								label={services.reservation}
								customLabel={services.parkingSpace}
								placeholder={services.reservation}
								id="services-space"
								options={[
									{ label: "Needed", value: "Needed" },
									{ label: "No Need", value: "No Need" },
								]}
								name="parkingReservation"
								onChange={(e) => {
									handleChange({ name: "parkingReservation", value: e });
								}}
								value={service.parkingReservation}
							/>
						</div>
					</>
				) : (
					""
				)}
			</div>
			<div className="header-box my-3">{services.languages}</div>
			<p>{services.languagesSpokenTitle}</p>
			<div className="col-md-4">
				<SelectField
					multi
					label={services.languages}
					options={languages}
					placeholder={services.languages}
					id="services-languages"
					// placeholder={
					// 	service.languages?.length > 0
					// 		? service.languages
					// 		: services.languages
					// }
					value={service.languages}
					onChange={(values) => {
						//
						setService({ ...service, languages: values });
					}}
				/>
			</div>
			<div className="header-box my-3">{services.views}</div>
			<div style={{ columnCount: "3" }}>
				{views.length > 0 &&
					views.map((view, i) => {
						return (
							<div className="form-group form-check" key={i}>
								<input
									type="checkbox"
									name="views"
									className="form-check-input"
									onChange={handleCheckboxChange}
									value={view.value}
									checked={service.views?.includes(view.value)}
									id={`room-views-${i + 1}`}
								/>
								<label
									className="form-check-label text-capitalize"
									htmlFor={`room-views-${i + 1}`}
								>
									{view.label}
								</label>
							</div>
						);
					})}
			</div>

			<div className="header-box my-3">{services.popular}</div>
			<p>{services.popularTitle}</p>
			<div style={{ columnCount: "3" }}>
				{facilities.length > 0 &&
					facilities.map((facility, i) => {
						return (
							<div className="form-group form-check" key={i}>
								<input
									type="checkbox"
									className="form-check-input"
									name="facilities"
									value={facility.value}
									onChange={handleCheckboxChange}
									checked={service.facilities?.includes(facility.value)}
									id={`service-check-${i + 1}`}
								/>
								<label
									className="form-check-label text-capitalize pointer"
									htmlFor={`service-check-${i + 1}`}
								>
									{facility.label}
								</label>
							</div>
						);
					})}
			</div>
			<div className="row my-5 mx-0 justify-content-start flex-nowrap w-25">
				<button className="btn mx-2 cancel" onClick={reset} id="service-cancel">
					cancel
				</button>
				<button
					className="btn btn-primary px-5"
					onClick={submit}
					id="service-submit"
				>
					continue
				</button>
			</div>
		</div>
	);
}

export default Services;
