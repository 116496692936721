import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import Locale from "translations";
import MyNetwork from "./list/myNetwork";
import BankRequest from "./list/bankRequest";

export default function Network() {
	const { requests, wallet } = Locale;
	const [activeTab, setActiveTab] = useState("1");

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	return (
		<div>
			<div className="container-fluid visa-nav pt-5 px-5">
				<div className="tab-icons product-build__leftside-menu">
					<Nav tabs>
						<NavItem className="bg-white d-flex  w-50">
							<NavLink
								className={classnames({ active: activeTab === "1" })}
								onClick={() => {
									toggle("1");
								}}
							>
								<div className="product-build-list-item w-100">
									<span className="text px-2">
										<i class="fas fa-snowflake"></i> {wallet.myNetwork}
									</span>
								</div>
							</NavLink>
							<NavLink
								className={classnames({ active: activeTab === "2" })}
								onClick={() => {
									toggle("2");
								}}
							>
								<div className="product-build-list-item">
									<span className="text px-2">
										<i class="fas fa-tasks"></i>{" "}
										{wallet.allTransferBankRequests}
									</span>
								</div>
							</NavLink>
						</NavItem>
					</Nav>
				</div>
				<div className="tab-content product-build__tabs-content">
					<TabContent activeTab={activeTab} className=" pt-2 pb-4   bg-white">
						<TabPane tabId={"1"} className="container-fluid">
							<MyNetwork />
						</TabPane>
						<TabPane tabId={"2"} className="container-fluid">
							<BankRequest />
						</TabPane>
					</TabContent>
				</div>

				<div class="d-flex my-2 flex-row-reverse align-items-center w-100">
					<div class=""></div>
				</div>
			</div>
		</div>
	);
}
