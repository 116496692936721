export function SafaLogoFull() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 169.477 38.282">
			<g
				id="Group_898"
				data-name="Group 898"
				transform="translate(-40.219 -456.291)"
			>
				<g
					id="Group_870"
					data-name="Group 870"
					transform="translate(40.219 460.343)"
				>
					<path
						id="Path_2717"
						data-name="Path 2717"
						d="M21.236,29.466a6.276,6.276,0,0,0-2.15.348,5.267,5.267,0,0,0-1.654.943,4.121,4.121,0,0,0-1.075,1.423,4.306,4.306,0,0,0-.38,1.82,2.157,2.157,0,0,0,.711,1.754,5.381,5.381,0,0,0,2.067.96q1.686.464,3.258.877t2.53.679a11.561,11.561,0,0,1,2.051.794,6.407,6.407,0,0,1,1.769,1.307,6.292,6.292,0,0,1,1.257,1.969,7.077,7.077,0,0,1,.479,2.713,8.554,8.554,0,0,1-.6,3.293,7.38,7.38,0,0,1-1.587,2.432,6.745,6.745,0,0,1-2.266,1.506,7.055,7.055,0,0,1-2.663.513H13v-.728a4.752,4.752,0,0,1,1.158-.512,6.733,6.733,0,0,1,1.918-.216H21.1a7.027,7.027,0,0,0,2.447-.414,5.613,5.613,0,0,0,1.935-1.174,5.273,5.273,0,0,0,1.257-1.82,6,6,0,0,0,.446-2.349,3.807,3.807,0,0,0-.876-2.615,6.445,6.445,0,0,0-3.093-1.622q-.265-.1-1.141-.331t-1.835-.479q-.96-.249-1.8-.464T17.4,39.791a6.159,6.159,0,0,1-1.869-.86,6.237,6.237,0,0,1-1.389-1.307,5.171,5.171,0,0,1-.86-1.637A5.94,5.94,0,0,1,13,34.2a5.807,5.807,0,0,1,.678-2.746,6.06,6.06,0,0,1,1.654-1.919,7.091,7.091,0,0,1,2.315-1.142,9.613,9.613,0,0,1,2.7-.381h7.64v.728a4.713,4.713,0,0,1-1.158.513,6.735,6.735,0,0,1-1.918.215Z"
						transform="translate(-13 -28.01)"
						fill="#1a1818"
					/>
					<path
						id="Path_2718"
						data-name="Path 2718"
						d="M71.623,51.63a19.742,19.742,0,0,0-3.241.066,9.119,9.119,0,0,0-2.745.695,4.619,4.619,0,0,0-1.9,1.506,4.3,4.3,0,0,0-.745,2.5,4.124,4.124,0,0,0,.579,2.432A3.984,3.984,0,0,0,65.108,60.2a6.381,6.381,0,0,0,1.951.612,12.821,12.821,0,0,0,1.819.148h2.745ZM66.6,44.284a18.823,18.823,0,0,1,2.382.182,8.821,8.821,0,0,1,2.646.777A5.464,5.464,0,0,1,73.724,47a5.027,5.027,0,0,1,.81,3.111V62.286H68.018q-.662,0-1.538-.066a11.145,11.145,0,0,1-1.769-.281,10.286,10.286,0,0,1-1.753-.6,4.777,4.777,0,0,1-2.547-2.614,5.373,5.373,0,0,1-.364-2.234,5.1,5.1,0,0,1,1.075-3.276A6.782,6.782,0,0,1,63.8,51.283a12.48,12.48,0,0,1,3.688-.86,27.232,27.232,0,0,1,4.134-.05v-.166a4.134,4.134,0,0,0-.578-2.432A3.994,3.994,0,0,0,69.507,46.4a6.387,6.387,0,0,0-1.952-.612,12.822,12.822,0,0,0-1.819-.149H63.52v-.827a4.925,4.925,0,0,1,1.157-.381,9.632,9.632,0,0,1,1.918-.149"
						transform="translate(-40.434 -37.5)"
						fill="#1a1818"
					/>
					<path
						id="Path_2719"
						data-name="Path 2719"
						d="M110.212,29.546a10.316,10.316,0,0,0-1.554.116,2.789,2.789,0,0,0-2.3,1.9,6.441,6.441,0,0,0-.314,2.416v1.092h4.167V35.9a4.9,4.9,0,0,1-1.157.381,9.629,9.629,0,0,1-1.918.149l-1.091-.033V52.843H103.2V36.4h-.86V35.072h.86V33.881a5.366,5.366,0,0,1,.661-3.078,4.938,4.938,0,0,1,1.82-1.754,6.936,6.936,0,0,1,2.348-.778,16.108,16.108,0,0,1,2.281-.182h2.977v.96a4.461,4.461,0,0,1-1.157.364,10.837,10.837,0,0,1-1.919.132"
						transform="translate(-65.097 -28.057)"
						fill="#1a1818"
					/>
					<path
						id="Path_2720"
						data-name="Path 2720"
						d="M139.54,51.63a19.742,19.742,0,0,0-3.241.066,9.119,9.119,0,0,0-2.745.695,4.622,4.622,0,0,0-1.9,1.506,4.3,4.3,0,0,0-.745,2.5,4.127,4.127,0,0,0,.579,2.432,3.985,3.985,0,0,0,1.538,1.373,6.381,6.381,0,0,0,1.951.612,12.821,12.821,0,0,0,1.819.148h2.745Zm-5.027-7.346a18.823,18.823,0,0,1,2.382.182,8.82,8.82,0,0,1,2.646.777,5.464,5.464,0,0,1,2.1,1.754,5.027,5.027,0,0,1,.81,3.111V62.286h-6.516q-.662,0-1.538-.066a11.138,11.138,0,0,1-1.769-.281,10.287,10.287,0,0,1-1.753-.6,4.777,4.777,0,0,1-2.547-2.614,5.373,5.373,0,0,1-.364-2.234,5.1,5.1,0,0,1,1.075-3.276,6.779,6.779,0,0,1,2.679-1.935,12.48,12.48,0,0,1,3.688-.86,27.234,27.234,0,0,1,4.134-.05v-.166a4.135,4.135,0,0,0-.578-2.432,3.994,3.994,0,0,0-1.538-1.373,6.387,6.387,0,0,0-1.952-.612,12.822,12.822,0,0,0-1.819-.149h-2.216v-.827a4.925,4.925,0,0,1,1.157-.381,9.629,9.629,0,0,1,1.918-.149"
						transform="translate(-80.038 -37.5)"
						fill="#1a1818"
					/>
				</g>
				<g
					id="Group_871"
					data-name="Group 871"
					transform="translate(147.603 460.324)"
				>
					<path
						id="Path_2721"
						data-name="Path 2721"
						d="M291.179,30.606a2.327,2.327,0,0,0-.91-1.628l-.5.5c-.055-.056-.1-.111-.16-.168l-1.666,1.666a1.7,1.7,0,0,1,.985,1.591c0,.151-.039.378-.076.681a7.388,7.388,0,0,0,.687-.558c-.01.075-.019.142-.03.229,1.1-.795,1.667-1.552,1.667-2.311m-2.947,8.351a4.381,4.381,0,0,1,1.607-.3,3.933,3.933,0,0,1,2.614,1.023,4.021,4.021,0,0,1,1.477,2.387,13.828,13.828,0,0,1,.152,2.614v5.454a2.916,2.916,0,0,1-.214,1.2,3.4,3.4,0,0,0-2.462-.924h-1.92c.05-.031.1-.066.142-.1a2.249,2.249,0,0,0,1.552-1.718,2.8,2.8,0,0,1-1.477.454,3.447,3.447,0,0,1-2.879-1.78,5.982,5.982,0,0,1-1.1-3.41,5.74,5.74,0,0,1,1.288-3.787,4.644,4.644,0,0,1,1.22-1.118m29.7,2.619a7.56,7.56,0,0,1,3.12-.718,9.05,9.05,0,0,1,6.4,2.8,8.2,8.2,0,0,1,2.8,5.909,3.691,3.691,0,0,1-.371,1.771,3.9,3.9,0,0,0-2.758-.925H309.51a52.088,52.088,0,0,1,5.378-6.554,14.068,14.068,0,0,1,3.041-2.287m14.762,8.082a10.5,10.5,0,0,0-3.371-7.575,10.4,10.4,0,0,0-7.5-3.371,8.4,8.4,0,0,0-3.971,1.122q-3.909,1.793-8.415,7.689V40.909l-.657.657v-.328L307,43.018v7.4h-9.682q-.8,0-.8-.682V37.8l-.657.657v-.329l-.823.824a6.141,6.141,0,0,0-4.731-2.4,5.718,5.718,0,0,0-2.971.782,6.142,6.142,0,0,0-2.307,1.743,7.528,7.528,0,0,0-1.7,4.886,7.875,7.875,0,0,0,1.136,4.128,5.764,5.764,0,0,0,2.413,2.324H273.036V27.965L270.6,30.073V52.525h19.126a5.629,5.629,0,0,1,2.083.265,2.4,2.4,0,0,1,1.4,1.439,4.842,4.842,0,0,0,1.02-.491,3.742,3.742,0,0,0,1.8-1.437.744.744,0,0,0,.516.224h29.2a7.364,7.364,0,0,1,1.931.189,2.8,2.8,0,0,1,1.4,1.213l.076.113.076.114a3.081,3.081,0,0,0,1.35-.486c1.4-.469,2.111-1.81,2.111-4.009"
						transform="translate(-270.599 -27.965)"
						fill="#1a1818"
					/>
				</g>
				<g
					id="Group_872"
					data-name="Group 872"
					transform="translate(108.828 456.291)"
				>
					<path
						id="Path_2722"
						data-name="Path 2722"
						d="M216.617,27.7l4.079-2.363,4.064-2.35L220.7,20.64l-4.079-2.349V27.7Z"
						transform="translate(-200.345 -18.291)"
						fill="#1a1818"
					/>
					<path
						id="Path_2723"
						data-name="Path 2723"
						d="M236.152,29.56l4.064,2.35,4.079,2.363-4.079,2.35-4.064,2.349V29.56Z"
						transform="translate(-211.736 -24.862)"
						fill="#1a1818"
					/>
					<path
						id="Path_2724"
						data-name="Path 2724"
						d="M236.152,63.14l4.064-2.349,4.079-2.349-4.079-2.365-4.064-2.35V63.14Z"
						transform="translate(-211.736 -38.955)"
						fill="#1a1818"
					/>
					<path
						id="Path_2725"
						data-name="Path 2725"
						d="M224.761,63.14,220.7,60.791l-4.079-2.349,4.079-2.365,4.064-2.35V63.14Z"
						transform="translate(-200.345 -38.955)"
						fill="#1a1818"
					/>
					<path
						id="Path_2726"
						data-name="Path 2726"
						d="M236.152,76.306l4.064,2.35,4.079,2.349-4.079,2.35L236.152,85.7v-9.4Z"
						transform="translate(-211.736 -52.122)"
						fill="#1a1818"
					/>
					<path
						id="Path_2727"
						data-name="Path 2727"
						d="M185.71,29.56l-4.064,2.35-4.065,2.363,4.065,2.35,4.064,2.349V29.56Z"
						transform="translate(-177.581 -24.862)"
						fill="#5a5b5e"
					/>
					<path
						id="Path_2728"
						data-name="Path 2728"
						d="M197.081,29.56l4.079,2.35,4.064,2.363-4.064,2.35-4.079,2.349V29.56Z"
						transform="translate(-188.953 -24.862)"
						fill="#5a5b5e"
					/>
					<path
						id="Path_2729"
						data-name="Path 2729"
						d="M185.71,63.14l-4.064-2.349-4.065-2.349,4.065-2.365,4.064-2.35V63.14Z"
						transform="translate(-177.581 -38.955)"
						fill="#5a5b5e"
					/>
					<path
						id="Path_2730"
						data-name="Path 2730"
						d="M185.71,76.306l-4.064,2.35-4.065,2.349,4.065,2.35L185.71,85.7v-9.4Z"
						transform="translate(-177.581 -52.122)"
						fill="#5a5b5e"
					/>
					<path
						id="Path_2731"
						data-name="Path 2731"
						d="M197.081,76.306l4.079,2.35,4.064,2.349-4.064,2.35L197.081,85.7v-9.4Z"
						transform="translate(-188.953 -52.122)"
						fill="#5a5b5e"
					/>
					<path
						id="Path_2732"
						data-name="Path 2732"
						d="M205.225,27.7l-4.064-2.363-4.079-2.35,4.079-2.349,4.064-2.349V27.7Z"
						transform="translate(-188.953 -18.291)"
						fill="#5a5b5e"
					/>
					<path
						id="Path_2733"
						data-name="Path 2733"
						d="M244.3,40.867l-4.079,2.349-4.064,2.349,4.064,2.349,4.079,2.35v-9.4Z"
						transform="translate(-211.736 -31.456)"
						fill="#1a1818"
					/>
					<path
						id="Path_2734"
						data-name="Path 2734"
						d="M244.3,74.434l-4.079-2.349-4.064-2.35,4.064-2.349,4.079-2.349v9.4Z"
						transform="translate(-211.736 -45.55)"
						fill="#1a1818"
					/>
					<path
						id="Path_2735"
						data-name="Path 2735"
						d="M177.581,40.867l4.065,2.349,4.064,2.349-4.064,2.349-4.065,2.35v-9.4Z"
						transform="translate(-177.581 -31.456)"
						fill="#5a5b5e"
					/>
					<path
						id="Path_2736"
						data-name="Path 2736"
						d="M177.581,74.434l4.065-2.349,4.064-2.35-4.064-2.349-4.065-2.349v9.4Z"
						transform="translate(-177.581 -45.55)"
						fill="#5a5b5e"
					/>
					<path
						id="Path_2737"
						data-name="Path 2737"
						d="M224.761,29.56,220.7,31.91l-4.079,2.363,4.079,2.35,4.064,2.349V29.56Z"
						transform="translate(-200.345 -24.862)"
						fill="#1a1818"
					/>
					<path
						id="Path_2738"
						data-name="Path 2738"
						d="M224.761,76.306l-4.064,2.35-4.079,2.349,4.079,2.35,4.064,2.349v-9.4Z"
						transform="translate(-200.345 -52.122)"
						fill="#cb9a51"
					/>
					<path
						id="Path_2739"
						data-name="Path 2739"
						d="M197.081,63.14l4.079-2.349,4.064-2.349-4.064-2.365-4.079-2.35V63.14Z"
						transform="translate(-188.953 -38.955)"
						fill="#eaceaa"
					/>
					<path
						id="Path_2740"
						data-name="Path 2740"
						d="M216.617,42.458l4.079,2.349,4.064,2.349-4.064,2.35-4.079,2.364V42.458Z"
						transform="translate(-200.345 -32.384)"
						fill="#cb9a51"
					/>
					<path
						id="Path_2741"
						data-name="Path 2741"
						d="M205.225,42.458l-4.064,2.349-4.079,2.349,4.079,2.35,4.064,2.364V42.458Z"
						transform="translate(-188.953 -32.384)"
						fill="#eaceaa"
					/>
					<path
						id="Path_2742"
						data-name="Path 2742"
						d="M216.617,65.036v9.4l4.079-2.35,4.064-2.349L220.7,67.386Z"
						transform="translate(-200.345 -45.55)"
						fill="#6f552c"
					/>
					<path
						id="Path_2743"
						data-name="Path 2743"
						d="M205.225,65.036h0l-4.064,2.35-4.079,2.349,4.079,2.349,4.064,2.35v-9.4Z"
						transform="translate(-188.953 -45.55)"
						fill="#a39279"
					/>
					<path
						id="Path_2744"
						data-name="Path 2744"
						d="M216.617,87.579l4.079,2.35,4.064,2.35L220.7,94.628l-4.079,2.349v-9.4Z"
						transform="translate(-200.345 -58.695)"
						fill="#cb9a51"
					/>
					<path
						id="Path_2745"
						data-name="Path 2745"
						d="M205.225,87.579l-4.064,2.35-4.079,2.35,4.079,2.349,4.064,2.349v-9.4Z"
						transform="translate(-188.953 -58.695)"
						fill="#eaceaa"
					/>
				</g>
			</g>
		</svg>
	);
}

export function SafaLogoMinimal() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.56 38.282">
			<g
				id="Group_898"
				data-name="Group 898"
				transform="translate(-108.828 -456.291)"
			>
				<g
					id="Group_872"
					data-name="Group 872"
					transform="translate(108.828 456.291)"
				>
					<path
						id="Path_2722"
						data-name="Path 2722"
						d="M216.617,27.7l4.079-2.363,4.064-2.35L220.7,20.64l-4.079-2.349V27.7Z"
						transform="translate(-200.345 -18.291)"
						fill="#1a1818"
					/>
					<path
						id="Path_2723"
						data-name="Path 2723"
						d="M236.152,29.56l4.064,2.35,4.079,2.363-4.079,2.35-4.064,2.349V29.56Z"
						transform="translate(-211.736 -24.862)"
						fill="#1a1818"
					/>
					<path
						id="Path_2724"
						data-name="Path 2724"
						d="M236.152,63.14l4.064-2.349,4.079-2.349-4.079-2.365-4.064-2.35V63.14Z"
						transform="translate(-211.736 -38.955)"
						fill="#1a1818"
					/>
					<path
						id="Path_2725"
						data-name="Path 2725"
						d="M224.761,63.14,220.7,60.791l-4.079-2.349,4.079-2.365,4.064-2.35V63.14Z"
						transform="translate(-200.345 -38.955)"
						fill="#1a1818"
					/>
					<path
						id="Path_2726"
						data-name="Path 2726"
						d="M236.152,76.306l4.064,2.35,4.079,2.349-4.079,2.35L236.152,85.7v-9.4Z"
						transform="translate(-211.736 -52.122)"
						fill="#1a1818"
					/>
					<path
						id="Path_2727"
						data-name="Path 2727"
						d="M185.71,29.56l-4.064,2.35-4.065,2.363,4.065,2.35,4.064,2.349V29.56Z"
						transform="translate(-177.581 -24.862)"
						fill="#5a5b5e"
					/>
					<path
						id="Path_2728"
						data-name="Path 2728"
						d="M197.081,29.56l4.079,2.35,4.064,2.363-4.064,2.35-4.079,2.349V29.56Z"
						transform="translate(-188.953 -24.862)"
						fill="#5a5b5e"
					/>
					<path
						id="Path_2729"
						data-name="Path 2729"
						d="M185.71,63.14l-4.064-2.349-4.065-2.349,4.065-2.365,4.064-2.35V63.14Z"
						transform="translate(-177.581 -38.955)"
						fill="#5a5b5e"
					/>
					<path
						id="Path_2730"
						data-name="Path 2730"
						d="M185.71,76.306l-4.064,2.35-4.065,2.349,4.065,2.35L185.71,85.7v-9.4Z"
						transform="translate(-177.581 -52.122)"
						fill="#5a5b5e"
					/>
					<path
						id="Path_2731"
						data-name="Path 2731"
						d="M197.081,76.306l4.079,2.35,4.064,2.349-4.064,2.35L197.081,85.7v-9.4Z"
						transform="translate(-188.953 -52.122)"
						fill="#5a5b5e"
					/>
					<path
						id="Path_2732"
						data-name="Path 2732"
						d="M205.225,27.7l-4.064-2.363-4.079-2.35,4.079-2.349,4.064-2.349V27.7Z"
						transform="translate(-188.953 -18.291)"
						fill="#5a5b5e"
					/>
					<path
						id="Path_2733"
						data-name="Path 2733"
						d="M244.3,40.867l-4.079,2.349-4.064,2.349,4.064,2.349,4.079,2.35v-9.4Z"
						transform="translate(-211.736 -31.456)"
						fill="#1a1818"
					/>
					<path
						id="Path_2734"
						data-name="Path 2734"
						d="M244.3,74.434l-4.079-2.349-4.064-2.35,4.064-2.349,4.079-2.349v9.4Z"
						transform="translate(-211.736 -45.55)"
						fill="#1a1818"
					/>
					<path
						id="Path_2735"
						data-name="Path 2735"
						d="M177.581,40.867l4.065,2.349,4.064,2.349-4.064,2.349-4.065,2.35v-9.4Z"
						transform="translate(-177.581 -31.456)"
						fill="#5a5b5e"
					/>
					<path
						id="Path_2736"
						data-name="Path 2736"
						d="M177.581,74.434l4.065-2.349,4.064-2.35-4.064-2.349-4.065-2.349v9.4Z"
						transform="translate(-177.581 -45.55)"
						fill="#5a5b5e"
					/>
					<path
						id="Path_2737"
						data-name="Path 2737"
						d="M224.761,29.56,220.7,31.91l-4.079,2.363,4.079,2.35,4.064,2.349V29.56Z"
						transform="translate(-200.345 -24.862)"
						fill="#1a1818"
					/>
					<path
						id="Path_2738"
						data-name="Path 2738"
						d="M224.761,76.306l-4.064,2.35-4.079,2.349,4.079,2.35,4.064,2.349v-9.4Z"
						transform="translate(-200.345 -52.122)"
						fill="#cb9a51"
					/>
					<path
						id="Path_2739"
						data-name="Path 2739"
						d="M197.081,63.14l4.079-2.349,4.064-2.349-4.064-2.365-4.079-2.35V63.14Z"
						transform="translate(-188.953 -38.955)"
						fill="#eaceaa"
					/>
					<path
						id="Path_2740"
						data-name="Path 2740"
						d="M216.617,42.458l4.079,2.349,4.064,2.349-4.064,2.35-4.079,2.364V42.458Z"
						transform="translate(-200.345 -32.384)"
						fill="#cb9a51"
					/>
					<path
						id="Path_2741"
						data-name="Path 2741"
						d="M205.225,42.458l-4.064,2.349-4.079,2.349,4.079,2.35,4.064,2.364V42.458Z"
						transform="translate(-188.953 -32.384)"
						fill="#eaceaa"
					/>
					<path
						id="Path_2742"
						data-name="Path 2742"
						d="M216.617,65.036v9.4l4.079-2.35,4.064-2.349L220.7,67.386Z"
						transform="translate(-200.345 -45.55)"
						fill="#6f552c"
					/>
					<path
						id="Path_2743"
						data-name="Path 2743"
						d="M205.225,65.036h0l-4.064,2.35-4.079,2.349,4.079,2.349,4.064,2.35v-9.4Z"
						transform="translate(-188.953 -45.55)"
						fill="#a39279"
					/>
					<path
						id="Path_2744"
						data-name="Path 2744"
						d="M216.617,87.579l4.079,2.35,4.064,2.35L220.7,94.628l-4.079,2.349v-9.4Z"
						transform="translate(-200.345 -58.695)"
						fill="#cb9a51"
					/>
					<path
						id="Path_2745"
						data-name="Path 2745"
						d="M205.225,87.579l-4.064,2.35-4.079,2.35,4.079,2.349,4.064,2.349v-9.4Z"
						transform="translate(-188.953 -58.695)"
						fill="#eaceaa"
					/>
				</g>
			</g>
		</svg>
	);
}

export function DashboardIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
			class="feather feather-home"
		>
			<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
			<polyline points="9 22 9 12 15 12 15 22"></polyline>
		</svg>
	);
}

export function PropertiesIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
			class="feather feather-grid"
		>
			<rect x="3" y="3" width="7" height="7"></rect>
			<rect x="14" y="3" width="7" height="7"></rect>
			<rect x="14" y="14" width="7" height="7"></rect>
			<rect x="3" y="14" width="7" height="7"></rect>
		</svg>
	);
}

export function MyTeamIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.898 19.932">
			<g id="Group_2" data-name="Group 2" transform="translate(-2.25 -3)">
				<path
					id="Path_14"
					data-name="Path 14"
					d="M18.2,14.948a.955.955,0,0,0,.4.344,3.65,3.65,0,0,1,2.072,3.293V20.83a.842.842,0,1,0,1.684,0V18.584a5.334,5.334,0,0,0-5.334-5.334.135.135,0,0,0-.085.237A6.771,6.771,0,0,1,18.2,14.948Z"
					transform="translate(1.799 1.26)"
				/>
				<path
					id="Path_15"
					data-name="Path 15"
					d="M7.584,14.934a3.65,3.65,0,0,0-3.65,3.65V20.83a.842.842,0,1,1-1.684,0V18.584A5.334,5.334,0,0,1,7.584,13.25h6.738a5.334,5.334,0,0,1,5.334,5.334V20.83a.842.842,0,1,1-1.684,0V18.584a3.65,3.65,0,0,0-3.65-3.65Z"
					transform="translate(0 1.26)"
					fill-rule="evenodd"
				/>
				<path
					id="Path_16"
					data-name="Path 16"
					d="M14.988,10.363a1.148,1.148,0,0,1,.445-.449,2.806,2.806,0,0,0,0-4.844,1.157,1.157,0,0,1-.445-.449A6.192,6.192,0,0,0,13.94,3.18.1.1,0,0,1,14.011,3a4.492,4.492,0,1,1,0,8.984.1.1,0,0,1-.071-.18A6.128,6.128,0,0,0,14.988,10.363Z"
					transform="translate(1.434)"
				/>
				<path
					id="Path_17"
					data-name="Path 17"
					d="M10.492,10.3A2.807,2.807,0,1,0,7.684,7.492,2.807,2.807,0,0,0,10.492,10.3Zm0,1.684A4.492,4.492,0,1,0,6,7.492,4.492,4.492,0,0,0,10.492,11.984Z"
					transform="translate(0.461)"
					fill-rule="evenodd"
				/>
			</g>
		</svg>
	);
}

export function InProgressIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
			class="feather feather-loader"
		>
			<line x1="12" y1="2" x2="12" y2="6"></line>
			<line x1="12" y1="18" x2="12" y2="22"></line>
			<line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
			<line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
			<line x1="2" y1="12" x2="6" y2="12"></line>
			<line x1="18" y1="12" x2="22" y2="12"></line>
			<line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
			<line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
		</svg>
	);
}

export function Arrow({ className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			viewBox="0 0 6.057 10.114"
		>
			<path
				id="Path_5"
				data-name="Path 5"
				d="M15.785,11.5l-3.643,3.643L8.5,11.5"
				transform="translate(-10.086 17.199) rotate(-90)"
				fill="none"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
			/>
		</svg>
	);
}

export function VerifyingIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
			class="feather feather-settings"
		>
			<circle cx="12" cy="12" r="3"></circle>
			<path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
		</svg>
	);
}

export function ActiveIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
			class="feather feather-check-circle"
		>
			<path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
			<polyline points="22 4 12 14.01 9 11.01"></polyline>
		</svg>
	);
}

export function VisasIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
			class="feather feather-globe"
		>
			<circle cx="12" cy="12" r="10"></circle>
			<line x1="2" y1="12" x2="22" y2="12"></line>
			<path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
		</svg>
	);
}

export function AddPropertyIcon() {
	return <i style={{ fontSize: "16px" }} className="fas fa-plus-circle"></i>;
}

export function WaitingForIssuingIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
			class="feather feather-loader"
		>
			<line x1="12" y1="2" x2="12" y2="6"></line>
			<line x1="12" y1="18" x2="12" y2="22"></line>
			<line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
			<line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
			<line x1="2" y1="12" x2="6" y2="12"></line>
			<line x1="18" y1="12" x2="22" y2="12"></line>
			<line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
			<line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
		</svg>
	);
}

export function IssuedVisaIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
			class="feather feather-check"
		>
			<polyline points="20 6 9 17 4 12"></polyline>
		</svg>
	);
}

export function TransportationIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
			class="feather feather-truck"
		>
			<rect x="1" y="3" width="15" height="13"></rect>
			<polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
			<circle cx="5.5" cy="18.5" r="2.5"></circle>
			<circle cx="18.5" cy="18.5" r="2.5"></circle>
		</svg>
	);
}

export function ApprovedIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
			class="feather feather-check"
		>
			<polyline points="20 6 9 17 4 12"></polyline>
		</svg>
	);
}

export function GroundServiceIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
			class="feather feather-dribbble"
		>
			<circle cx="12" cy="12" r="10"></circle>
			<path d="M8.56 2.75c4.37 6.03 6.02 9.42 8.03 17.72m2.54-15.38c-3.72 4.35-8.94 5.66-16.88 5.85m19.5 1.9c-3.5-.93-6.63-.82-8.94 0-2.58.92-5.01 2.86-7.44 6.32"></path>
		</svg>
	);
}

export function TeamManagementIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
			class="feather feather-users"
		>
			<path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
			<circle cx="9" cy="7" r="4"></circle>
			<path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
			<path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
		</svg>
	);
}

export function UsersIcon() {
	return (
		<i
			class="fa fa-id-badge mr-2"
			style={{ fontSize: "15px", paddingLeft: "4px" }}
			aria-hidden="true"
		></i>
	);
}

export function GroupsIcon() {
	return (
		<i class="fa fa-users" style={{ fontSize: "15px" }} aria-hidden="true"></i>
	);
}
