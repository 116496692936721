import Header from "components/layout/Header";
import { GroundServiceProvider } from "context/groundService";
import { NetworkProvider } from "context/network";
import { TransportationProvider } from "context/transportation";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import DashboardSubNav from "./layout/DashboardSubNav";
import Layout from "./layout/Layout";
import SideBar from "./layout/SideBar";
import SubNav from "./layout/SubNav";
//import DashboardSubNav from "./layout/DashboardSubNav";

// React Component
export function NetworkRoute({ component: Component, ...props }) {
	const isAuth = localStorage.getItem("isAuth");
	if (isAuth) {
		return (
			<Route
				{...props}
				render={(matchProps) => (
					<>
						<Layout>
							<NetworkProvider>
								{/* <DashboardSubNav /> */}
								<Component {...matchProps} />
							</NetworkProvider>
						</Layout>
					</>
				)}
			/>
		);
	} else {
		return <Redirect to="/login" />;
	}
}
