import React from "react";
import TextField from "components/shared/TextField";
import SelectField from "components/shared/SelectField";

import Locale from ".../../translations";

export default function FilterGroup({
	filter,
	setfilter,
	setGroups,
	Groups,
	submit,
}) {
	const { teamManagement } = Locale;

	return (
		<>
			<div
				className="row no-gutters list_header team-filter"
				style={{ width: "80% " }}
			>
				<div className="col-4 mr-2 ">
					<TextField
						label={teamManagement.search}
						placeholder={teamManagement.search}
						name="search"
						value={filter?.search}
						onChange={(e) => {
							setfilter({
								...filter,
								search: e.target.value,
							});
						}}
					/>
				</div>

				<div className="col  pt-3">
					<button
						className="btn text-muted  "
						onClick={() =>
							setfilter({
								search: "",
								paginated: 1,
								page: 1,
							})
						}
					>
						{" "}
						<i className="fas fa-retweet"></i> {teamManagement.restFilter}
					</button>
				</div>
			</div>
		</>
	);
}
