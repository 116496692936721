import { useGlobalDispatch, useGlobalState } from "context/global";
import { usePropertyDispatch, usePropertyState } from "context/property";
import { formatGetRooms, formatPostRooms } from "data/property";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useLocation, useParams } from "react-router-dom";
import {
	fetchRoomNames,
	fetchRoomTypes,
	fetchRoomViews,
} from "services/lookups";
import { addRooms, editRooms, getProperty } from "services/property";
import Locale from "translations";
import CancelModal from "./CancelModal";
import Room from "./Room";

const locale = localStorage.getItem("currentLocale") || "en";

function Rooms() {
	const { id } = useParams();
	const { rooms, commons, supplierDetails } = Locale;
	const { propertyDetails } = usePropertyState();
	const { state } = useLocation();

	const dispatch = useGlobalDispatch();
	const propertyDispatch = usePropertyDispatch();
	const { progress, step } = useGlobalState();
	const [canceled, setCanceled] = useState(false);
	const [lookups, setLookups] = useState({
		types: [],
		names: [],
		views: [],
	});
	const [types, setTypes] = useState([]);

	const [allRooms, setAllRooms] = useState([
		{
			type: "",
			name: "",
			view: "",
			count: 0,
			smokingPolicy: "",
			customName: "",
		},
	]);

	const setRoom = (i, name, value) => {
		let roomsClone = [...allRooms];
		roomsClone[i] = {
			...roomsClone[i],
			[name]: value,
		};
		setAllRooms(roomsClone);
	};

	const formatArray = (array) => {
		return array.data.data.map(
			(item) => (item = { label: item.name, value: item.id })
		);
	};

	const fetchLookups = async () => {
		const [types, names, views] = await Promise.all([
			fetchRoomTypes(locale),
			fetchRoomNames(locale),
			fetchRoomViews(locale),
		]);

		setLookups({
			types: formatArray(types),
			names: formatArray(names),
			views: formatArray(views),
		});

		setTypes(formatArray(types));
	};

	const addRoom = () => {
		setAllRooms([
			...allRooms,
			{
				type: "",
				name: "",
				view: "",
				count: "",
				smokingPolicy: "",
				customName: "",
			},
		]);
	};

	const deleteRoom = (index) => {
		let allRoomsClone = [...allRooms];
		allRoomsClone.splice(index, 1);
		setAllRooms(allRoomsClone);
	};

	const isRoomsValid = () => {
		let isValid = true;
		allRooms.forEach((room) => {
			const { type, customName, view, count, smokingPolicy } = room;
			if (
				Object.values({ type, view, count, smokingPolicy, customName }).some(
					(v) => v === ""
				)
			) {
				isValid = false;
			}
		});
		return isValid;
	};

	useEffect(() => {
		if (id && propertyDetails) {
			const formatted = formatGetRooms(propertyDetails.property.rooms);
			setAllRooms(formatted);
		}
	}, [propertyDetails]);
	const submit = async () => {
		if (isRoomsValid()) {
			if (id && propertyDetails?.property?.rooms?.length > 0) {
				const data = formatPostRooms(allRooms);
				const res = await editRooms(id, { rooms: data });
				if (res.status === 201 || res.status === 200) {
					dispatch({ type: "progress", step: "4", current: "4" });
					propertyDispatch({ type: "propertyDetails", payload: res.data });
				}
			} else {
				const data = formatPostRooms(allRooms);
				const res = await addRooms(id, { rooms: data });
				if (res.status === 201 || res.status === 200) {
					dispatch({ type: "progress", step: "4", current: "4" });
					propertyDispatch({ type: "propertyDetails", payload: res.data });
				}
			}
		} else {
			store.addNotification({
				title: "warning!",
				message: "Please fill all fields",
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	const reset = () => {
		setCanceled(true);
	};

	useEffect(() => {
		fetchLookups();
	}, []);


	//
	useEffect(() => {
		async function fetchProperty() {
			if (propertyDetails && propertyDetails.id) {
				const res = await getProperty(propertyDetails.id);
				if (res.status === 201 || res.status === 200) {
					setTimeout(() => {
						setLookups({
							...lookups,
							views: res.data?.property?.views.map(
								(item) => (item = { label: item.name, value: item.id })
							),
						});
					}, 1200);
				}
			}
		}

		fetchProperty();
	}, [propertyDetails]);

	return (
		<div className="room">
			<CancelModal isOpen={canceled} toggle={setCanceled} />
			<div className="tb-box table-responsive">
			<table class="table mb-0 f-13">
				<thead className="table-light">
					<tr className="">
						<th
							style={{ maxWidth: "150px", width: "150px", minWidth: "150px" }}
						>
							{rooms.type}
						</th>
						{/* <th
							style={{ maxWidth: "150px", width: "150px", minWidth: "150px" }}
						>
							{rooms.name}
						</th> */}
						<th
							style={{ maxWidth: "150px", width: "150px", minWidth: "150px" }}
						>
							{rooms.name}
						</th>
						<th
							style={{ maxWidth: "150px", width: "150px", minWidth: "150px" }}
						>
							{rooms.view}
						</th>
						<th
							style={{ maxWidth: "150px", width: "150px", minWidth: "150px" }}
						>
							{rooms.smokingPolicy}
						</th>
						<th
							style={{ maxWidth: "150px", width: "140px", minWidth: "140px" }}
						>
							{rooms.count}
						</th>
						<th style={{ maxWidth: "80px", width: "90px", minWidth: "90px" }}>
							{rooms.MaxAdults}
						</th>
						<th
							style={{ maxWidth: "100px", width: "100px", minWidth: "100px" }}
						>
							{rooms.MaxChildrens}
						</th>
						<th
							style={{ maxWidth: "150px", width: "150px", minWidth: "150px" }}
						>
							{rooms.MaxAdultWithChild}
						</th>

						<th
							style={{ maxWidth: "50px", width: "50px", minWidth: "50px" }}
						></th>
					</tr>
				</thead>
				<tbody>
				{allRooms.map((room, i) => {
					return (
						<tr key={i}>
							<Room
								types={types}
								index={i}
								room={room}
								setRoom={setRoom}
								lookups={lookups}
								deleteRoom={deleteRoom}
							/>
						</tr>
					);
				})}
				</tbody>
				<tfoot>
				<div className="text-success pointer">
				<button
					className="px-1 mt-2 font14 btn-link text-primary"
					onClick={addRoom}
					id="add-room"
				>
					<i className="fas fa-plus-circle"></i>
					Add Room
				</button>
			</div>
				</tfoot>
			</table>
			</div>
		
			<div className="row my-5 mx-0 justify-content-start flex-nowrap w-25">
				<button className="btn mx-2 cancel" onClick={reset} id="room-cancel">
					Cancel
				</button>
				<button
					className="btn btn-primary px-5"
					id="room-submit"
					onClick={submit}
				>
					Continue
				</button>
			</div>
		</div>
	);
}

export default Rooms;
