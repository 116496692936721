import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import PasswordField from "components/shared/PasswordField";
import TextField from "components/shared/TextField";
import { useGlobalDispatch, useGlobalState } from "context/global";
import validate, { isFormValid } from "helpers/validate";
import LoginBanner from "modules/auth/components/LoginBanner";
import React, { useState } from "react";
import { store } from "react-notifications-component";
import { Link, useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import { LoginEmail, verifyEmail } from "services/auth";
import Locale from "../../../translations";

export default function Login(props) {
	const { login, commons } = Locale;
	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	const { locale } = useGlobalState();
	const dispatch = useGlobalDispatch();
	const [loginState, setLoginState] = useState({
		email: "",
		password: "",
		showPasswordField: false,
		errorPasswordMessage: "",
	});
	const [spin, setSpin] = useState(false);
	const [errors, setErrors] = useState({});
	let location = useHistory();

	// ----------------------------------------------------------------------------------------------------
	// Component Functions
	// ----------------------------------------------------------------------------------------------------
	const checkEmailErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "email", value: loginState.email },
				{ required: true, email: true }
			),
		});
	};
	const checkPasswordErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "password", value: loginState.password },
				{ required: true, password: true, min: 8 }
			),
		});
	};

	// Verify Email
	const emailVerification = async () => {
		checkEmailErrors();
		setSpin(true);
		const requestBody = {
			email: loginState.email,
		};
		if (isFormValid(errors)) {
			const response = await verifyEmail(requestBody);
			if (response?.status === 200) {
				setLoginState({
					...loginState,
					showPasswordField: true,
				});
			} else if (
				response?.status === 422 &&
				response.data.errors.email[0].indexOf("invalid") >= 0
			) {
				//
				// store.addNotification({
				// 	title: commons.somethingWentWrong,
				// 	message: login.loginWithNonRegisteredUser,
				// 	type: "danger",
				// 	insert: "top",
				// 	container: "top-right",
				// 	animationIn: ["animated", "fadeIn"],
				// 	animationOut: ["animated", "fadeOut"],
				// 	dismiss: {
				// 		duration: 3000,
				// 		onScreen: true,
				// 		pauseOnHover: true
				// 	}
				// });
			} else if (
				response.status === 422 &&
				response.data.errors.email[0].indexOf("activate") >= 0
			) {
				location.push("/activation/resend", {
					email: loginState.email,
					action: "resend",
				});
			}
		}
		setSpin(false);
	};

	// Login User
	const loginUser = async () => {
		checkPasswordErrors();
		setSpin(true);

		// var myHeaders = new Headers();
		// myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

		// const params = new URLSearchParams();
		// params.append("client_id", "supplier-manager");
		// params.append("username", loginState.email);
		// params.append("password", loginState.password);
		// params.append("grant_type", "password");
		// params.append("scope", "openid");
		// params.append("client_secret", "91e24cab-2c35-4bcb-9632-c0742bd753ff");

		// var requestOptions = {
		// 	method: "POST",
		// 	headers: myHeaders,
		// 	body: params,
		// 	redirect: "follow"
		// };

		if (isFormValid(errors)) {
			setSpin(true);

			const res = await LoginEmail({
				username: loginState.email,
				password: loginState.password,
			});

			if (res.access_token) {
				debugger;
				dispatch({
					type: "login",
					isAuth: true,
					token: res.access_token,
					refreshToken: res.refresh_token,
					supplierName: res.supplier?.name,
					userName: res.user?.name,
				});
				location.push("/");
			} else if (res.error) {
				store.addNotification({
					title: commons.somethingWentWrong,
					message: res.error_description,
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			}
		}
		setSpin(false);
	};

	// ----------------------------------------------------------------------------------------------------
	// Mini Components UI
	// ----------------------------------------------------------------------------------------------------
	const emailField = (
		<TextField
			placeholder={login.emailPlaceholder}
			label={login.emailAddress}
			value={loginState.email}
			name="email"
			id="login-email"
			onChange={(e) => {
				setErrors({
					...errors,
					...validate(e.target, {
						required: true,
						email: true,
					}),
				});
				setLoginState({
					...loginState,
					email: e.target.value,
					showPasswordField: false,
					password: "",
				});
			}}
			color={errors?.email?.required || errors?.email?.email ? "danger" : ""}
			errors={errors?.email}
			onEnter={(e) => (e.key === "Enter" ? emailVerification() : false)}
		/>
	);

	const passwordField = (
		<PasswordField
			placeholder={login.passwordPlaceholder}
			label={login.password}
			name="password"
			id="login-password"
			value={loginState.password}
			onChange={(e) => {
				setErrors({
					...errors,
					...validate(
						{ name: "password", value: e.target.value },
						{
							required: true,
							password: true,
							min: 8,
						}
					),
				});
				setLoginState({
					...loginState,
					password: e.target.value,
				});
			}}
			min={8}
			color={
				errors?.password?.required ||
				errors?.password?.password ||
				errors?.password?.min
					? "danger"
					: ""
			}
			errors={errors?.password}
			onEnter={(e) => (e.key === "Enter" ? loginUser() : false)}
		/>
	);

	const redirect = () => {
		window.location.reload();
		window.location.href = "/register";
	};
	// ----------------------------------------------------------------------------------------------------
	// Main Component Body UI
	// ----------------------------------------------------------------------------------------------------
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					{/* Left Banner */}
					<div className="col-md-6 p-0">
						<LoginBanner />
					</div>

					{/* Login Form */}
					<div className="col-md-6 py-5 py-md-0">
						<div className="d-flex justify-content-center justify-content-md-start flex-md-row-reverse px-4 pt-5">
							<LanguageSwitcher />
						</div>

						<div className="d-flex align-items-center h-75 px-4 px-md-0 pt-5 pt-md-0">
							<div className="col-md-8 mx-auto text-center">
								<h1 className="text-title">{login.welcomeBack}</h1>
								<p className="text-headline">{login.loginMessage}</p>

								<div className="mt-3">
									{emailField}
									{loginState.showPasswordField ? passwordField : ""}
								</div>

								<div className="text-right mb-2">
									<Link to="/forgot-password" className="my-2 text-dark-blue">
										{login.forgotPassword}
									</Link>
								</div>

								<button
									className="btn btn-primary w-100"
									onClick={
										loginState.showPasswordField ? loginUser : emailVerification
									}
									disabled={spin}
								>
									{spin ? <Spinner color="light" size="sm" /> : null}
									{"  "} {login.continue}
								</button>

								<div className="my-5 py-5 ">
									<Link onClick={redirect} className="d-block text-dark-blue">
										{login.newCompanySignup}
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
