import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import validate from "helpers/validate";
import { Card, CardBody, Collapse } from "reactstrap";
import Locale from "translations";

export default function CancellationPolicy({
	ratePlan,
	setRatePlanState,
	setErrors,
	errors,
}) {
	const RatePlan = Locale;
	const deleteRow = (index) => {
		let x = ratePlan;
		x.cancellation.splice(index, 1);
		setRatePlanState({ ...x });
	};

	const chargeTypeList = [
		{ label: "percent", value: "percent" },
		{ label: "night", value: "night" },
	];

	const handlyCancellation = (index, name, value) => {
		let x = ratePlan;
		x.cancellation[index] = { ...x.cancellation[index], [name]: value };
		setRatePlanState({ ...x });
	};
	return (
		<>
			<div className="row m-0">
				<div
					className={`col-md-12 p-0 mt-3 ${
						errors?.cancellation_type?.required ? "danger-checkbox" : ""
					}`}
				>
					<div
						className="rate-plan-header"
						onClick={() =>
							setRatePlanState({
								...ratePlan,
								isOpenPolicy: !ratePlan.isOpenPolicy,
							})
						}
					>
						{RatePlan.hotelLayout.ratePlan?.whichCancellationPolicy}

						<i
							className={`fas pos-arrow ${
								ratePlan.isOpenPolicy ? "fa-chevron-up " : "fa-chevron-down"
							}`}
						></i>
					</div>

					<Collapse isOpen={ratePlan.isOpenPolicy}>
						<Card>
							<CardBody>
								<div className="col-md-12 pt-2">
									{/* <div class="form-check form-check-inline pl-2 pr-2">
										<input
											class="form-check-input"
											type="radio"
											name="Cancellation"
											id="inlineCheckbox1"
											value="1"
											checked={ratePlan.cancellation_type == "1"}
											onChange={(e) => {
												setRatePlanState({
													...ratePlan,
													cancellation_type: e.target.value,
												});
												setErrors({
													...errors,
													...validate(
														{
															name: "cancellation_type",
															value: e.target.value,
														},
														{ required: true }
													),
													...validate(
														{
															name: "days_before_arrival",
															value: e.target.value,
														},
														{ required: false }
													),
													...validate(
														{
															name: "long_before_arrival",
															value: e.target.value,
														},
														{ required: false }
													),
												});
											}}
										/>
										<label class="form-check-label" for="inlineCheckbox1">
											Free Cancelation
										</label>
									</div> */}
									<div
										class="form-check form-check-inline pl-2 pr-2"
										style={{ display: "none" }}
									>
										<input
											class="form-check-input"
											type="radio"
											name="Cancellation"
											id="inlineCheckbox2"
											value="3"
											checked={ratePlan.cancellation_type == "3"}
											onChange={(e) => {
												setRatePlanState({
													...ratePlan,
													cancellation_type: e.target.value,
												});
												setErrors({
													...errors,
													...validate(
														{
															name: "cancellation_type",
															value: e.target.value,
														},
														{ required: true }
													),
												});
											}}
										/>
										<label class="form-check-label" for="inlineCheckbox2">
											Cancellation conditions
										</label>
									</div>
									{/* <div class="form-check form-check-inline pl-2 pr-2">
										<input
											class="form-check-input"
											type="radio"
											name="Cancellation"
											id="inlineCheckbox3"
											value="2"
											checked={ratePlan.cancellation_type == "2"}
											onChange={(e) => {
												setRatePlanState({
													...ratePlan,
													cancellation_type: e.target.value,
												});
												setErrors({
													...errors,
													...validate(
														{
															name: "cancellation_type",
															value: e.target.value,
														},
														{ required: true }
													),
													...validate(
														{
															name: "days_before_arrival",
															value: e.target.value,
														},
														{ required: false }
													),
													...validate(
														{
															name: "long_before_arrival",
															value: e.target.value,
														},
														{ required: false }
													),
												});
											}}
										/>
										<label class="form-check-label" for="inlineCheckbox3">
											Non-Refundable
										</label>
									</div> */}
									{errors?.cancellation_type?.required ? (
										<div className="col-md-12 text-danger danger-massage">
											Which cancellation policy do you want to use for this rate
											plan
										</div>
									) : (
										""
									)}
								</div>
								<div className="col-md-12 mt-2">
									<div className="row m-0">
										<div className="col-md-12">
											<p className="text-danger " style={{ fontSize: "14px" }}>
												{" "}
												If you not set cancel policy to check-in day, The system
												will restrict the amend and cancel
											</p>
										</div>
									</div>
								</div>
								{ratePlan.cancellation_type == 3
									? ratePlan.cancellation && ratePlan.cancellation.length > 0
										? ratePlan.cancellation.map((res, index) => {
												return (
													<div className="col-md-12 mt-2">
														<div className="row m-0">
															<div className="col-md-2">
																<TextField
																	label={"BEFORE CHECK-IN"}
																	value={res.days_before_arrival}
																	placeholder={"0"}
																	type={"number"}
																	placeholderText={"days"}
																	name="days_before_arrival"
																	onChange={(e) => {
																		handlyCancellation(
																			index,
																			"days_before_arrival",
																			e.target.value
																		);
																	}}
																	color={
																		errors?.days_before_arrival?.required
																			? "danger"
																			: ""
																	}
																	errors={errors?.days_before_arrival}
																/>
															</div>

															<div className="col-md-2">
																<TextField
																	label={`before time`}
																	type={"time"}
																	value={res.before_time}
																	name="before_time"
																	onChange={(e) => {
																		handlyCancellation(
																			index,
																			"before_time",
																			e.target.value
																		);
																	}}
																	color={
																		errors?.long_before_arrival?.required
																			? "danger"
																			: ""
																	}
																	errors={errors?.long_before_arrival}
																/>
															</div>
															<div className="col-md-2 input_icon">
																<SelectField
																	label={"Charge Type"}
																	type={"number"}
																	placeholder={"0"}
																	options={chargeTypeList}
																	value={{
																		label: res.charge_type,
																		value: res.charge_type,
																	}}
																	name="charge_type"
																	onChange={(e) => {
																		handlyCancellation(
																			index,
																			"charge_type",
																			e.value
																		);
																	}}
																	color={
																		errors?.charge_type?.required
																			? "danger"
																			: ""
																	}
																	errors={errors?.charge_type}
																/>
															</div>

															<div className="col-md-2 input_icon">
																<TextField
																	label={`charge`}
																	type={"number"}
																	placeholder={"0"}
																	value={res.charge}
																	name="charge"
																	onChange={(e) => {
																		handlyCancellation(
																			index,
																			"charge",
																			e.target.value
																		);
																	}}
																	color={
																		errors?.long_before_arrival?.required
																			? "danger"
																			: ""
																	}
																	errors={errors?.long_before_arrival}
																	extraTextPosition={"append"}
																	extraText={
																		res.charge_type == "percent" ? "%" : "N"
																	}
																/>
															</div>

															<div className="col-md-2 input_icon">
																<TextField
																	label={`amend charge`}
																	type={"number"}
																	placeholder={"0"}
																	value={res.amend_charge}
																	name="amend_charge"
																	onChange={(e) => {
																		handlyCancellation(
																			index,
																			"amend_charge",
																			e.target.value
																		);
																	}}
																	color={
																		errors?.long_before_arrival?.required
																			? "danger"
																			: ""
																	}
																	errors={errors?.long_before_arrival}
																	extraTextPosition={"append"}
																	extraText={"%"}
																/>
															</div>
															<div className="col-md-1">
																<div
																	className="d-flex justify-item-center align-item-center"
																	style={{
																		height: "100%",
																		alignItems: "center",
																		justifyContent: "center",
																	}}
																>
																	{index != 0 ? (
																		<a
																			href={() => false}
																			onClick={() => deleteRow(index)}
																			className="text-danger mx-1"
																		>
																			<i class="fa fa-trash"></i>
																		</a>
																	) : (
																		""
																	)}
																	{index == ratePlan.cancellation.length - 1 ? (
																		<a
																			href={() => false}
																			onClick={() => {
																				let x = ratePlan;
																				x.cancellation.push({});
																				setRatePlanState({ ...x });
																			}}
																			className="text-success mx-1"
																		>
																			<i class="fa fa-plus"></i>
																		</a>
																	) : (
																		""
																	)}
																</div>
															</div>
														</div>
													</div>
												);
										  })
										: ""
									: ""}
							</CardBody>
						</Card>
					</Collapse>
				</div>
			</div>
		</>
	);
}
