import DateRangeField from "components/shared/DateRangeField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import {
	formatAddPromotionsBasic,
	PromotionsFormatDetails,
} from "data/promotions";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { fetchCountries } from "services/lookups";
import {
	addPromotionsBasic,
	editPromotionsBasic,
	getListRatePlanDp,
	getPromotionsDetails,
	getRoomsDp,
} from "services/Promotions";
function AddBasicDeal() {
	const { locale } = useGlobalState();
	const { id, promotionId } = useParams();
	const [errors, setErrors] = useState({});
	const [basicDeal, setBasicDeal] = useState({
		days: {
			saturday: 0,
			sunday: 0,
			monday: 0,
			tuesday: 0,
			wednesday: 0,
			thursday: 0,
			friday: 0,
		},
	});
	const history = useHistory();
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [lookups, setLookups] = useState({
		countries: [],
		RatePlans: [],
	});
	const [toggle, setToggle] = useState({
		create: false,
		edit: false,
		spin: false,
		disableButton: false,
	});

	useEffect(() => {
		const fetchLookups = async () => {
			const [countries, RatePlanList] = await Promise.all([
				fetchCountries(),
				getListRatePlanDp(id),
			]);

			const format = countries?.data.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));

			const RatePlansFormat =
				RatePlanList?.data.length > 0
					? RatePlanList?.data.map((t) => ({
						value: t.id,
						label: t.name,
						minimum_length_of_stay: t.minimum_length_of_stay,
					}))
					: [];

			setLookups({
				lookups,
				countries: format,
				RatePlans: RatePlansFormat,
			});
		};

		fetchLookups();
	}, []);

	useEffect(() => {
		const fetchPromotionsDetails = async () => {
			if (promotionId !== undefined) {
				const Promotions = await getPromotionsDetails(id, promotionId);
				const data = PromotionsFormatDetails(Promotions.data);
				setBasicDeal(data);
			}
		};
		fetchPromotionsDetails();
	}, [promotionId]);

	useEffect(() => {
		const getRooms = async () => {
			let RatePlan = basicDeal.promotion_apply;
			let max;

			if (RatePlan && RatePlan.length > 0) {
				max = Math.max.apply(
					Math,
					RatePlan.map(function (rate) {
						return rate.value;
					})
				);
			}

			if (
				basicDeal.promotion_apply !== undefined &&
				basicDeal.promotion_apply?.length > 0
			) {
				let list = basicDeal.promotion_apply.map((res) => {
					return res.value;
				});
				const req = await getRoomsDp(id, list);

				let newRoom = req.hasOwnProperty("data")
					? req.data.map((t) => ({
						value: t.room_id,
						label: t.custom_name !== null ? t.custom_name : t.name?.name,
					}))
					: [];

				setBasicDeal({
					...basicDeal,
					ListRatePlanIds: list,
					listRooms: newRoom,
					min_stay: basicDeal.min_stay ? +basicDeal.min_stay : max,
					min_stay_rules: max.toString(),
				});
			}
		};

		getRooms();
	}, [basicDeal.promotion_apply]);

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "promotion_name", value: basicDeal.promotion_name },
				{ required: true, min: 3 }
			),
			...errors,
			...validate(
				{ name: "able_see_promotion", value: basicDeal.able_see_promotion },
				{ required: true }
			),
			...errors,
			...validate(
				{ name: "rooms", value: basicDeal.rooms },
				{ required: true }
			),
			...errors,
			...validate(
				{ name: "discount", value: basicDeal.discount },
				{ required: true }
			),
			...errors,
			...validate(
				{ name: "min_stay", value: basicDeal.min_stay },
				{ required: true }
			),
		});
	};

	const handleSubmit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	useEffect(() => {
		const AddRatePlan = async () => {
			if (isFormValid(errors)) {
				const data = formatAddPromotionsBasic(basicDeal);
				let response;
				if (promotionId !== undefined) {
					response = await editPromotionsBasic(id, promotionId, data);
				} else {
					response = await addPromotionsBasic(id, data);
				}

				if (response.status === 201 || response.status === 200) {
					history.push(`/dashboard/list-promotions-basic/${id}`);

					store.addNotification({
						title: "Info!",
						message: response.statusText,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			}
		};
		AddRatePlan();
	}, [isErrorLoaded]);

	const daysCheck = (e) => {
		let value = e.target.value;
		let checked = e.target.checked;
		let daysList = basicDeal.days;
		if (checked) {
			daysList[value] = checked ? 1 : 0;
		} else {
			daysList[value] = 0;
		}

		setBasicDeal({
			...basicDeal,
			days: daysList,
		});
	};



	return (
		<>
			<div className="container">
				<div className="col-md-12 box-add-promotion">
					<div className="row">
						<div className="col-md-6 m-auto">
							<TextField
								label={"What do you want to name this promotion?"}
								placeholder={"What do you want to name this promotion?"}
								value={basicDeal.promotion_name}
								name="promotion_name"
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(
											{
												name: "promotion_name",
												value: e.target.value,
											},
											{ required: true }
										),
									});
									setBasicDeal({
										...basicDeal,
										promotion_name: e.target.value,
									});
								}}
								onBlur={() =>
									setErrors({
										...errors,
										...validate(
											{
												name: "promotion_name",
												value: basicDeal.promotion_name,
											},
											{ required: true }
										),
									})
								}
								color={errors?.promotion_name?.required ? "danger" : ""}
								errors={errors?.promotion_name}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 m-auto">
							<SelectField
								label={"Who will be able to see this promotion? "}
								value={basicDeal.able_see_promotion}
								name="able_see_promotion"
								options={lookups.countries}
								multi={true}
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(
											{
												name: "able_see_promotion",
												value: e,
											},
											{ required: true }
										),
									});
									setBasicDeal({
										...basicDeal,
										able_see_promotion: e,
									});
								}}
								onBlur={() =>
									setErrors({
										...errors,
										...validate(
											{
												name: "able_see_promotion",
												value: basicDeal.able_see_promotion,
											},
											{ required: true }
										),
									})
								}
								color={errors?.able_see_promotion?.required ? "danger" : ""}
								errors={errors?.able_see_promotion}
							/>
						</div>
					</div>

					<div className="row">
						<div className="col-md-6 m-auto">
							<SelectField
								label={"Which rate plans will this promotion apply to?"}
								value={basicDeal.promotion_apply}
								name="promotion_apply"
								options={lookups.RatePlans}
								multi={true}
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(
											{
												name: "promotion_apply",
												value: e,
											},
											{ required: true }
										),
									});
									setBasicDeal({
										...basicDeal,
										promotion_apply: e,
									});
								}}
								onBlur={() =>
									setErrors({
										...errors,
										...validate(
											{
												name: "promotion_apply",
												value: basicDeal.promotion_apply,
											},
											{ required: true }
										),
									})
								}
								color={errors?.promotion_apply?.required ? "danger" : ""}
								errors={errors?.promotion_apply}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 m-auto">
							<SelectField
								label={"Which rooms?"}
								value={basicDeal.rooms}
								name="rooms"
								multi={true}
								options={basicDeal.listRooms}
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(
											{
												name: "rooms",
												value: e,
											},
											{ required: true }
										),
									});
									setBasicDeal({
										...basicDeal,
										rooms: e,
									});
								}}
								onBlur={() =>
									setErrors({
										...errors,
										...validate(
											{
												name: "rooms",
												value: basicDeal.rooms,
											},
											{ required: true }
										),
									})
								}
								color={errors?.rooms?.required ? "danger" : ""}
								errors={errors?.rooms}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 m-auto">
							<TextField
								label={"How much of a discount do you want to give?"}
								value={basicDeal.discount}
								name="discount"
								placeholder={"How much of a discount do you want to give?"}
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(
											{
												name: "discount",
												value: e.target.value,
											},
											{ required: true }
										),
									});
									setBasicDeal({
										...basicDeal,
										discount: e.target.value,
									});
								}}
								color={errors?.discount?.required ? "danger" : ""}
								errors={errors?.discount}
							/>
						</div>
					</div>

					<div className="row">
						<div className="col-md-6 m-auto">
							<TextField
								label={"How long do guests need to stay to use this promotion?"}
								value={+basicDeal.min_stay}
								name="min_stay"
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(
											{
												name: "min_stay",
												value: e.target.value,
											},
											{ required: true }
										),
									});
									setBasicDeal({
										...basicDeal,
										min_stay: e.target.value,
									});
								}}
								onBlur={() =>
									setErrors({
										...errors,
										...validate(
											{
												name: "min_stay",
												value: basicDeal.min_stay,
											},
											{ required: true }
										),
									})
								}
								color={errors?.min_stay?.required ? "danger" : ""}
								errors={errors?.min_stay}
							/>
						</div>
					</div>

					<div className="row">
						<div className="col-md-6  m-auto">
							<DateRangeField
								label={"When can guests stay using this promotion? "}
								startDate={basicDeal.promotion_duration_start_date}
								endDate={basicDeal.promotion_duration_end_date}
								startDatePlaceholderText={
									"When can guests stay using this promotion?"
								}
								endDatePlaceholderText={
									"When can guests stay using this promotion?"
								}
								minimumNights={1}
								onDatesChange={({ startDate, endDate }) => {
									setBasicDeal({
										...basicDeal,
										promotion_duration_start_date: moment(startDate)._d,
										promotion_duration_end_date: endDate
											? moment(endDate)._d
											: moment(startDate).add(1, "d")._d,
									});
								}}
								disabled={toggle.create}
								readOnly={toggle.create}
							/>
						</div>
					</div>

					<div className="row">
						<div className="col-md-6 m-auto promotions-days">
							<p>
								Which day(s) of the week would you like to include in this
								promotion?
							</p>

							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									type="checkbox"
									id="Mon"
									value="monday"
									onChange={daysCheck}
									checked={+basicDeal.days?.monday}
								/>
								<label class="form-check-label" for="Mon">
									Mon
								</label>
							</div>
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									type="checkbox"
									id="Tue"
									value="tuesday"
									onChange={daysCheck}
									checked={+basicDeal.days?.tuesday}
								/>
								<label class="form-check-label" for="Tue">
									Tue
								</label>
							</div>
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									type="checkbox"
									id="Wed"
									value="wednesday"
									onChange={daysCheck}
									checked={+basicDeal.days?.wednesday}
								/>
								<label class="form-check-label" for="Wed">
									Wed
								</label>
							</div>
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									type="checkbox"
									id="Thu"
									value="thursday"
									onChange={daysCheck}
									checked={+basicDeal.days?.thursday}
								/>
								<label class="form-check-label" for="Thu">
									Thu
								</label>
							</div>
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									type="checkbox"
									id="Fri"
									value="friday"
									checked={basicDeal.days?.friday}
									onChange={daysCheck}
								/>
								<label class="form-check-label" for="Fri">
									Fri
								</label>
							</div>
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									type="checkbox"
									id="Sat"
									value="saturday"
									onChange={daysCheck}
									checked={+basicDeal.days?.saturday}
								/>
								<label class="form-check-label" for="Sat">
									Sat
								</label>
							</div>
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									type="checkbox"
									id="Sun"
									onChange={daysCheck}
									value="sunday"
									checked={+basicDeal.days?.sunday}
								/>
								<label class="form-check-label" for="Sun">
									Sun
								</label>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-6 m-auto">
							<div className="col-md-6 offset-md-6 mt-3">
								<div className="row">
									<div className="col-md-8">
										{promotionId !== undefined ? (
											<button
												onClick={handleSubmit}
												class="btn btn-primary w-100 "
											>
												edit Promotions
											</button>
										) : (
											<button
												onClick={handleSubmit}
												class="btn btn-primary w-100 "
											>
												add Promotions
											</button>
										)}
									</div>
									<div className="col-md-4">
										<Link
											to={`/dashboard/list-promotions-basic/${id}`}
											className="btn btn-light btn-light-new w-100"
										>
											cancel
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default AddBasicDeal;
