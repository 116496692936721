export default function globalReducer(state, action) {
	switch (action.type) {
		case "setLoading":
			return { ...state, loading: action.payload };

		case "setLocale":
			localStorage.setItem("currentLocale", action.payload);
			window.location.reload();
			return { ...state, locale: action.payload };

		case "progress": {
			return {
				...state,
				progress: action.progress,
				step: action.step,
				current: action.current,
			};
		}

		case "setError":
			return {
				...state,
				isError: action.payload,
				ErrorMassage: { ...action.message },
			};
		// case "mode": {
		// 	return {
		// 		...state,
		// 		mode: action.mode,
		// 	};
		// }

		case "login":
			localStorage.setItem("isAuth", true);
			localStorage.setItem("token", action.token);
			// localStorage.setItem("userType", action.payload.user_type);
			debugger;
			localStorage.setItem("supplierName", action.supplierName);
			localStorage.setItem("userName", action.userName);

			localStorage.setItem("permissions", JSON.stringify(action?.permissions));
			localStorage.setItem("role", action?.role);
			return {
				...state,
				isAuth: action.isAuth,
				token: action.token,
				refreshToken: action.refreshToken,
				permissions: action?.permissions,
				role: action?.role,
				supplierName: action.supplierName,
				userName: action.userName,
				// userType: action.payload.user_type,
			};

		case "logout":
			localStorage.clear();
			return {
				...state,
				isAuth: false,
				token: null,
			};
		case "getPermissions":
			localStorage.setItem("role", action?.payload?.role);
			localStorage.setItem(
				"permissions",
				JSON.stringify(action?.payload?.permissions)
			);
			return {
				...state,
				permissions: action?.payload?.permissions,
				role: action?.payload?.role,
			};

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}
