import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import React from "react";
import Locale from "translations";

export default function FilterReservations({ search, filter, setFilter }) {
	const { reservations } = Locale;

	const customStyles = {
		control: () => ({
			// none of react-select's styles are passed to <Control />
			height: "33px",
		}),
		indicatorsContainer: () => ({
			visibility: "hidden",
		}),
	};

	const statusOptions = [
		{ value: "Pending", label: reservations.pending },
		{ value: "Approved", label: reservations.approved },
		{ value: "Paid", label: reservations.paid },
		{ value: "Rejected", label: reservations.rejected },
		{ value: "Canceled", label: reservations.cancelled },
		{ value: "Expired", label: reservations.expired },
	];

	return (
		<div className="row m-0 p-0 w-100 rounded">
			<div className="col">
				<TextField
					value={filter.file_number}
					label={reservations.bookingNumber}
					placeholder={reservations.bookingNumber}
					onChange={(e) => {
						setFilter({
							...filter,
							file_number: e.target.value,
						});
					}}
				/>
			</div>
			<div className="col">
				<DatePickerField
					date={filter.check_in}
					label={reservations.CheckIn}
					onChangeDate={(e) => {
						setFilter({
							...filter,
							check_in: e,
						});
					}}
				/>
			</div>
			<div className="col">
				<DatePickerField
					date={filter.check_out}
					label={reservations.CheckOut}
					onChangeDate={(e) => {
						setFilter({
							...filter,
							check_out: e,
						});
					}}
				/>
			</div>
			<div className="col">
				<SelectField
					value={filter.status}
					styles={customStyles}
					label={reservations.status}
					multi={false}
					placeholder={reservations.status}
					options={statusOptions}
					onChange={(e) => {
						setFilter({
							...filter,
							status: e,
						});
					}}
				/>
			</div>
			<div className="col-2 d-flex justify-content-start align-items-center ">
				<button type="button" class="btn btn-primary" onClick={search}>
					{reservations.search}
				</button>
				<span
					className="text-muted btn"
					onClick={() => {
						setFilter({
							file_number: "",
							check_in: null,
							check_out: null,
							status: null,
						});
						search();
					}}
				>
					{reservations.cancel} <i class="fas fa-retweet"></i>
				</span>
			</div>
		</div>
	);
}
