import Loader from "components/Loader";
import { useAuthState } from "context/auth";
import { useGlobalState } from "context/global";
import Services from "modules/auth/components/Services";
import SupplierDetails from "modules/auth/components/SupplierDetails";
import RegistrationLayout from "modules/auth/layout/RegistrationLayout";
import Confirmation from "modules/auth/pages/Confirmation";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { accountRegister } from "services/auth";
import Locale from "translations";
import {
	fetchCategories,
	fetchCountries,
	fetchServices,
} from "services/lookups";
import { store } from "react-notifications-component";
const locale = localStorage.getItem("currentLocale");

export default function Register() {
	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	const { locale } = useGlobalState();
	const authState = useAuthState();
	const [apiCalled, setApiCalled] = useState(false);
	const [lookups, setLookups] = useState({
		countries: [],
		catagories: [],
		services: [],
	});
	Locale.setLanguage(locale);
	let history = useHistory();
	// ----------------------------------------------------------------------------------------------------
	// Component Functions
	// ----------------------------------------------------------------------------------------------------

	const requestBody = {
		email: authState.supplierDetails?.email,
		supplier_name: authState.supplierDetails?.name,
		owner_name: authState.supplierDetails?.owner,
		phone_number:
			"+" +
			authState.supplierDetails?.phoneCode +
			authState.supplierDetails?.phone,
		password: authState.supplierDetails?.password,
		password_confirmation: authState.supplierDetails?.confirmPassword,
		country_id: authState.supplierDetails?.country.value,
		category_id: authState.supplierDetails?.category.value,
		services: authState.services?.map((service) => +service.id),
	};
	//
	// Register New Account
	const registerAccount = async () => {
		setApiCalled(true);
		// Register New Account
		const response = await accountRegister(requestBody);
		if (response.status === 201 || response.status === 200) {
			store.addNotification({
				title: "Info!",
				message: response.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			setApiCalled(false);
			history.push({
				pathname: "/register/success",
				state: { email: authState.supplierDetails?.email },
			});
		} else {
			setApiCalled(false);
		}
	};

	const fetchLookups = async () => {
		const [countries, services, catagories] = await Promise.all([
			fetchCountries(),
			fetchServices(),
			fetchCategories(),
		]);

		const formatCountries = countries?.data.map((t) => ({
			value: t.id,
			label: t.names[locale],
			code: t.country_code,
		}));
		const formatCatagories = catagories.map((t) => ({
			value: t.id,
			label: t.name,
		}));
		// const formatServices = services.data.map((t) => ({
		// 	value: t.id,
		// 	label: t.name
		// }));

		setLookups({
			lookups,
			countries: formatCountries,
			catagories: formatCatagories,
			services: services.data,
		});
	};
	useEffect(() => {
		fetchLookups();
	}, []);

	// Return View
	const currentStepView = () => {
		switch (authState.step) {
			case 1:
				return (
					<SupplierDetails
						countries={lookups.countries}
						catagories={lookups.catagories}
					/>
				);
			case 2:
				return (
					<Services
						services={lookups.services}
						catagories={lookups.catagories}
					/>
				);
			case 3:
				return (
					<Confirmation mainAction={registerAccount} isDisabled={apiCalled} />
				);
			default:
				return <SupplierDetails countries={lookups.countries} />;
		}
	};

	if (true) {
		return (
			<>
				<RegistrationLayout>{currentStepView()}</RegistrationLayout>
			</>
		);
	} else {
		return <Loader />;
	}
}
