import React from "react";
import walletAdd from "assets/images/wallet-add.svg";
import Model from "components/model";
import TextField from "components/shared/TextField";
import Locale from "translations";

export default function AddPopup({
	isOpen,
	toggle,
	popupInfo = null,
	setpopupInfo,
	addBalance,
}) {
	const { wallet, requests } = Locale;

	return (
		<Model
			isOpen={isOpen}
			title={"Add Balance"}
			toggle={() => {
				toggle();
			}}
			hasFooter={false}
			size="md"
			className="modal-network-center "
		>
			<div className="container px-5 py-4 ">
				<TextField
					type="text"
					placeholder={wallet.Amount}
					hasLabel={false}
					name="Amount"
					value={popupInfo.amount}
					onChange={(e) => {
						setpopupInfo({ ...popupInfo, amount: e.target.value });
					}}
				/>
				<div className="w-100 border-top-0  d-flex text-center justify-content-between   pt-2 my-2 ">
					<button className="btn rounded btn-gray  w-25" onClick={toggle}>
						{wallet.cancle}
					</button>
					<button className="btn rounded btn-primary w-65" onClick={addBalance}>
						{wallet.addBalance}
					</button>
				</div>
			</div>
		</Model>
	);
}
