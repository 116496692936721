import Locale from "translations";
import Logs from "./logs";

export default function ActivityLogs() {
	const { activity } = Locale;

	return (
		<div className="row justify-content-between m-0 p-0 px-3">
			<h3 className="main-title col">{activity.activityLogs}</h3>
			<div className="col-12">
				<Logs />
			</div>
		</div>
	);
}
