import DateRangeField from "components/shared/DateRangeField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import { formatAddPromotionsFree, PromotionsFormatFree } from "data/promotions";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { fetchCountries } from "services/lookups";
import {
	addPromotionsRoom,
	editPromotionsFree,
	getListRatePlanDp,
	getPromotionsFree,
	getRoomsDp,
} from "services/Promotions";

function AddRoomOrNight() {
	const history = useHistory();
	const { locale } = useGlobalState();
	const { id, promotionId } = useParams();
	const [errors, setErrors] = useState({});
	const [roomOrNight, setRoomOrNight] = useState({
		days: {
			saturday: 0,
			sunday: 0,
			monday: 0,
			tuesday: 0,
			wednesday: 0,
			thursday: 0,
			friday: 0,
		},
	});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	useEffect(() => {
		const AddRatePlan = async () => {
			if (isFormValid(errors)) {
				const data = formatAddPromotionsFree(roomOrNight);
				let response;
				if (promotionId !== undefined) {
					response = await editPromotionsFree(id, promotionId, data);
				} else {
					response = await addPromotionsRoom(id, data);
				}

				if (response.status === 201 || response.status === 200) {
					history.push(`/dashboard/list-promotions-Room/${id}`);

					store.addNotification({
						title: "Info!",
						message: response.statusText,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			}
		};
		AddRatePlan();
	}, [isErrorLoaded]);

	const [lookups, setLookups] = useState({
		countries: [],
		RatePlans: [],
	});
	const [toggle, setToggle] = useState({
		create: false,
		edit: false,
		spin: false,
		disableButton: false,
	});

	const handleSubmit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	const FreeTypeList = [
		{
			value: 1,
			label: "Night",
		},
		{
			value: 2,
			label: "Room",
		},
	];

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "promotion_name", value: roomOrNight.promotion_name },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "Free_type", value: roomOrNight.Free_type },
				{ required: true }
			),
			...validate(
				{ name: "free_count", value: roomOrNight.free_count },
				{ required: true }
			),
			...validate(
				{ name: "paid_count", value: roomOrNight.paid_count },
				{ required: true }
			),
			...validate(
				{ name: "promotion_apply", value: roomOrNight.promotion_apply },
				{ required: true }
			),
			...validate(
				{ name: "rooms", value: roomOrNight.rooms },
				{ required: true }
			),
		});
	};

	useEffect(() => {
		const getRooms = async () => {
			let RatePlan = [...lookups.RatePlans];
			let max = Math.max.apply(
				Math,
				RatePlan.map(function (rate) {
					return rate.minimum_length_of_stay;
				})
			);
			if (
				roomOrNight.promotion_apply !== undefined &&
				roomOrNight.promotion_apply.length > 0
			) {
				let list = roomOrNight.promotion_apply.map((res) => {
					return res.value;
				});
				const req = await getRoomsDp(id, list);
				let newRoom = req.data.map((t) => ({
					value: t.room_id,
					label: t.custom_name !== null ? t.custom_name : t.name?.name,
				}));
				setRoomOrNight({
					...roomOrNight,
					ListRatePlanIds: list,
					listRooms: newRoom,
					min_stay: max.toString(),
					min_stay_rules: max.toString(),
				});
			}
		};
		getRooms();
	}, [roomOrNight.promotion_apply]);

	useEffect(() => {
		const fetchPromotionsFree = async () => {
			if (promotionId !== undefined) {
				const Promotions = await getPromotionsFree(id, promotionId);
				const data = PromotionsFormatFree(Promotions.data);
				setRoomOrNight(data);
			}
		};
		fetchPromotionsFree();
	}, [promotionId]);

	useEffect(() => {
		async function fetchLookups() {
			const [countries, RatePlanList] = await Promise.all([
				fetchCountries(),
				getListRatePlanDp(id),
			]);

			const format = countries.data.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			const RatePlansFormat =
				RatePlanList.data.length > 0
					? RatePlanList.data.map((t) => ({
							value: t.id,
							label: t.name,
					  }))
					: [];
			setLookups({
				lookups,
				countries: format,
				RatePlans: RatePlansFormat,
			});
		}

		fetchLookups();
	}, []);

	const daysCheck = (e) => {
		let value = e.target.value;
		let checked = e.target.checked;
		let daysList = roomOrNight.days;
		if (checked) {
			daysList[value] = checked ? 1 : 0;
		} else {
			daysList[value] = 0;
		}

		setRoomOrNight({
			...roomOrNight,
			days: daysList,
		});
	};
	return (
		<>
			<div className="container">
				<div className="col-md-12 box-add-promotion">
					<div className="row">
						<div className="col-md-6 m-auto">
							<TextField
								label={"What do you want to name this promotion?"}
								placeholder={"What do you want to name this promotion?"}
								value={roomOrNight.promotion_name}
								name="promotion_name"
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(
											{
												name: "promotion_name",
												value: e.target.value,
											},
											{ required: true, min: 3 }
										),
									});
									setRoomOrNight({
										...roomOrNight,
										promotion_name: e.target.value,
									});
								}}
								onBlur={() =>
									setErrors({
										...errors,
										...validate(
											{
												name: "promotion_name",
												value: roomOrNight.promotion_name,
											},
											{ required: true, min: 3 }
										),
									})
								}
								min={3}
								color={
									errors?.promotion_name?.required ||
									errors?.promotion_name?.min
										? "danger"
										: ""
								}
								errors={errors?.promotion_name}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 m-auto">
							<SelectField
								label={"Free type "}
								value={roomOrNight.Free_type}
								name="Free_type"
								options={FreeTypeList}
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(
											{
												name: "Free_type",
												value: e,
											},
											{ required: true }
										),
									});
									setRoomOrNight({
										...roomOrNight,
										Free_type: e,
									});
								}}
								onBlur={() =>
									setErrors({
										...errors,
										...validate(
											{
												name: "Free_type",
												value: roomOrNight.Free_type,
											},
											{ required: true }
										),
									})
								}
								color={errors?.Free_type?.required ? "danger" : ""}
								errors={errors?.Free_type}
							/>
						</div>
					</div>

					<div className="row">
						<div className="col-md-6 m-auto">
							<div className="row m-0">
								<div className="col-md-6">
									<TextField
										label={"Free count"}
										placeholder={"Free count"}
										value={roomOrNight.free_count}
										name="free_count"
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(
													{
														name: "free_count",
														value: e.target.value,
													},
													{ required: true }
												),
											});
											setRoomOrNight({
												...roomOrNight,
												free_count: e.target.value,
											});
										}}
										onBlur={() =>
											setErrors({
												...errors,
												...validate(
													{
														name: "free_count",
														value: roomOrNight.free_count,
													},
													{ required: true }
												),
											})
										}
										color={errors?.free_count?.required ? "danger" : ""}
										errors={errors?.free_count}
									/>
								</div>
								<div className="col-md-6">
									<TextField
										label={"Paid count"}
										placeholder={"Paid count"}
										value={roomOrNight.paid_count}
										name="paid_count"
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(
													{
														name: "paid_count",
														value: e.target.value,
													},
													{ required: true }
												),
											});
											setRoomOrNight({
												...roomOrNight,
												paid_count: e.target.value,
											});
										}}
										onBlur={() =>
											setErrors({
												...errors,
												...validate(
													{
														name: "paid_count",
														value: roomOrNight.paid_count,
													},
													{ required: true }
												),
											})
										}
										color={errors?.paid_count?.required ? "danger" : ""}
										errors={errors?.paid_count}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-6 m-auto">
							<SelectField
								label={"Which rate plans will this promotion apply to?"}
								value={roomOrNight.promotion_apply}
								name="promotion_apply"
								options={lookups.RatePlans}
								multi={true}
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(
											{
												name: "promotion_apply",
												value: e,
											},
											{ required: true }
										),
									});
									setRoomOrNight({
										...roomOrNight,
										promotion_apply: e,
									});
								}}
								onBlur={() =>
									setErrors({
										...errors,
										...validate(
											{
												name: "promotion_apply",
												value: roomOrNight.promotion_apply,
											},
											{ required: true }
										),
									})
								}
								color={errors?.promotion_apply?.required ? "danger" : ""}
								errors={errors?.promotion_apply}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 m-auto">
							<SelectField
								label={"Which rooms?"}
								value={roomOrNight.rooms}
								name="rooms"
								multi={true}
								options={roomOrNight.listRooms}
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(
											{
												name: "rooms",
												value: e,
											},
											{ required: true }
										),
									});
									setRoomOrNight({
										...roomOrNight,
										rooms: e,
									});
								}}
								onBlur={() =>
									setErrors({
										...errors,
										...validate(
											{
												name: "rooms",
												value: roomOrNight.rooms,
											},
											{ required: true }
										),
									})
								}
								color={errors?.rooms?.required ? "danger" : ""}
								errors={errors?.rooms}
							/>
						</div>
					</div>

					<div className="row">
						<div className="col-md-6  m-auto">
							<DateRangeField
								label={"When can guests stay using this promotion? "}
								startDate={roomOrNight.promotion_duration_start_date}
								endDate={roomOrNight.promotion_duration_end_date}
								startDatePlaceholderText={
									"When can guests stay using this promotion?"
								}
								endDatePlaceholderText={
									"When can guests stay using this promotion?"
								}
								onDatesChange={({ startDate, endDate }) => {
									//
									setRoomOrNight({
										...roomOrNight,
										promotion_duration_start_date: moment(startDate)._d,
										promotion_duration_end_date: moment(endDate)._d,
									});
								}}
								disabled={toggle.create}
								readOnly={toggle.create}
							/>
						</div>
					</div>

					<div className="row">
						<div className="col-md-6 m-auto promotions-days">
							<p>
								Which day(s) of the week would you like to include in this
								promotion?
							</p>

							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									type="checkbox"
									id="Mon"
									value="monday"
									onChange={daysCheck}
									checked={roomOrNight.days?.monday}
								/>
								<label class="form-check-label" for="Mon">
									Mon
								</label>
							</div>
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									type="checkbox"
									id="Tue"
									value="tuesday"
									onChange={daysCheck}
									checked={roomOrNight.days?.tuesday}
								/>
								<label class="form-check-label" for="Tue">
									Tue
								</label>
							</div>
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									type="checkbox"
									id="Wed"
									value="wednesday"
									onChange={daysCheck}
									checked={roomOrNight.days?.wednesday}
								/>
								<label class="form-check-label" for="Wed">
									Wed
								</label>
							</div>
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									type="checkbox"
									id="Thu"
									value="thursday"
									onChange={daysCheck}
									checked={roomOrNight.days?.thursday}
								/>
								<label class="form-check-label" for="Thu">
									Thu
								</label>
							</div>
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									type="checkbox"
									id="Fri"
									value="friday"
									onChange={daysCheck}
									checked={roomOrNight.days?.friday}
								/>
								<label class="form-check-label" for="Fri">
									Fri
								</label>
							</div>
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									type="checkbox"
									id="Sat"
									value="saturday"
									onChange={daysCheck}
									checked={roomOrNight.days?.saturday}
								/>
								<label class="form-check-label" for="Sat">
									Sat
								</label>
							</div>
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									type="checkbox"
									id="Sun"
									onChange={daysCheck}
									value="sunday"
									checked={roomOrNight.days?.sunday}
								/>
								<label class="form-check-label" for="Sun">
									Sun
								</label>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-6 m-auto">
							<div className="col-md-6 offset-md-6 mt-3">
								<div className="row">
									<div className="col-md-8">
										{promotionId !== undefined ? (
											<button
												class="btn btn-primary w-100 "
												onClick={handleSubmit}
											>
												edit Promotions
											</button>
										) : (
											<button
												class="btn btn-primary w-100 "
												onClick={handleSubmit}
											>
												add Promotions
											</button>
										)}
									</div>
									<div className="col-md-4">
										<Link
											to={`/dashboard/list-promotions-Room/${id}`}
											className="btn btn-light btn-light-new w-100"
										>
											cancel
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default AddRoomOrNight;
