import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import React, { useEffect, useState } from "react";
import Locale from "translations";
import ViewRequestHeader from "./ViewRequestHeader";
import { Table } from "reactstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { createVisa, getToken, viewRequests } from "services/visa";
import { store } from "react-notifications-component";
import Model from "components/model";
import visaToken from "assets/images/visa.png";
import { exportToExcel, viewGroundRequests } from "services/ground";

export default function GroundViewRequest() {
	const { requests, backOffice } = Locale;
	const [searchVal, setSearchVal] = useState(null);
	const [ListOfChecked, setListOfChecked] = useState([]);
	const { status, id } = useParams();
	const [modalShow, setModalShow] = useState(false);
	const toggleModalShow = () => {
		setModalShow(!modalShow);
	};

	let history = useHistory();

	const { locale } = useGlobalState();
	const [passangers, setPassangers] = useState([]);
	const [header, setHeader] = useState({});

	const handelCheck = (passenger, flag) => {
		if (flag) {
			setListOfChecked([...ListOfChecked, passenger.id]);
		} else {
			let newListChecked = ListOfChecked.filter((id) => id != passenger.id);
			setListOfChecked(newListChecked);
		}
	};

	const selectAllItems = document.getElementsByClassName("select-all");
	const selectItems = document.getElementsByClassName("select-item");
	function selectAll(event) {
		const isSelectorChecked = event.target.checked;
		if (isSelectorChecked) {
			for (var item of selectItems) {
				item.checked = true;
			}
			setListOfChecked([
				...passangers.map((item) => {
					if (item.visa_number == null) {
						return item.id;
					}
				}),
			]);
		} else {
			for (let item of selectItems) {
				item.checked = false;
				setListOfChecked([]);
			}
		}
	}
	const fetchRequest = async () => {
		const response = await viewGroundRequests(id);
		if (response.status === 200 || response.status === 201) {
			setPassangers(response.data.data.passengers);
			setHeader(response.data.data);
		}
	};
	useEffect(() => {
		fetchRequest();
	}, []);

	const [loader, setLoader] = useState(false);
	let x;
	const allPassengers =
		passangers?.length > 0
			? passangers?.map((passenger) => {
					x = ListOfChecked.includes(passenger.id);

					return (
						<tr>
							<td> {passenger.passport_number}</td>
							<td>
								{locale === "en"
									? passenger.full_name_en
									: passenger.full_name_ar}
							</td>
							<td>{passenger.gender}</td>
							<td>{passenger.date_of_birth}</td>
							{/* <td>{passenger.age} </td> */}
							<td>
								{passenger.relationship ? passenger.relationship[locale] : ""}
							</td>
							<td>{passenger.national_id}</td>
							<td>{passenger.nationality}</td>

							<td>
								<Link to={`/ground-service/${id}/${passenger.id}`}>
									<i class="fas fa-angle-right text-dark"></i>
								</Link>
							</td>
						</tr>
					);
			  })
			: [];

	const exportToFile = async () => {
		const res = await exportToExcel(id);
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					//type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					type: res.data.type,
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.click();
				//window.open(objectUrl);
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	return (
		<div className="container-fluid px-2">
			<div className="py-2">
				<ViewRequestHeader header={header} />
			</div>

			<div className="mt-3 bg-white border rounded ">
				<header className=" p-3 border-bottom">
					<p className="m-0 font-weight-bold">
						{requests.Travelers} ({header.count_travellers}/{header.pax})
					</p>
				</header>

				<div className="d-flex   border-top px-3  align-items-center  justify-content-between visa-view-list ">
					<div className="col-4 p-0">
						<button className="btn btn-primary  m-1" onClick={exportToFile}>
							<i class="fas fa-file-excel mx-2"></i>
							{requests.ExportSelectedToExcel}
						</button>
					</div>

					<div className="col-6 m-0  justify-content-end d-flex align-items-center  ">
						{/* <button className="btn btn-primary  m-1">
							<i class="fas fa-file-excel mx-2"></i>
							{requests.ExportSelectedToExcel}
						</button> */}

						<div className=" w-50">
							<TextField
								hasLabel={false}
								placeholder={requests.search}
								value={searchVal}
								onChange={(e) => {
									setSearchVal(e.target.value);
								}}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="border">
				<div class="card-body p-0">
					<div class="table-responsive">
						<table className="table mb-0 f-15">
							<thead className="table-light">
								<tr>
									<th>{requests.PassportNumber}</th>
									<th>{requests.Name}</th>
									<th>{requests.Gender}</th>
									<th>{requests.Birthday}</th>
									{/* <th>{requests.Age}</th> */}
									<th>{requests.Relationship}</th>
									<th>{requests.NationalId}</th>
									<th>{requests.Nationality}</th>
									<th></th>
								</tr>
							</thead>
							<tbody>{allPassengers}</tbody>
						</table>
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-end my-4">
				<button
					className="btn btn-back mx-2 px-5"
					onClick={() => {
						history.push(`/ground-service`);
					}}
				>
					{requests.back}
				</button>
			</div>
		</div>
	);
}
