import React, { useState } from "react";
import LoginBanner from "modules/auth/components/LoginBanner";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import TextField from "components/shared/TextField";
import { Link, useHistory } from "react-router-dom";
import validate, { isFormValid } from "helpers/validate";
import { Spinner } from "reactstrap";
import Locale from "translations";
import { forgetPass } from "services/auth";

export default function ForgotPassword() {
	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	const { forgetPassword } = Locale;
	let history = useHistory();
	const [email, setEmail] = useState("");
	const [spin, setSpin] = useState(false);
	let location = useHistory();

	// ----------------------------------------------------------------------------------------------------
	// Component Functions
	// ----------------------------------------------------------------------------------------------------
	// Validate Email
	const [errors, setErrors] = useState({});

	const checkEmailErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "email", value: email },
				{ required: true, email: true }
			),
		});
	};
	// Forgot Password Handler
	const forgotPasswordHandler = async () => {
		checkEmailErrors();
		setSpin(true);
		let requestBody = {
			email: email,
		};
		if (isFormValid(errors)) {
			const response = await forgetPass(requestBody);
			if (response.status === 200) {
				setSpin(false);
				history.push({
					pathname: "/forgot-password-submitted",
					state: { email: email },
				});
			} else if (
				response.status === 422 &&
				response.data.errors.email[0].indexOf("activate") >= 0
			) {
				location.push("/activation/resend", {
					email: email,
					action: "resend",
				});
			}
		}
		setSpin(false);
	};

	// ----------------------------------------------------------------------------------------------------
	// Main Component Body UI
	// ----------------------------------------------------------------------------------------------------
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					{/* Left Banner */}
					<div className="col-md-6 p-0">
						<LoginBanner />
					</div>

					{/* Forget Password Form */}
					<div className="col-md-6 py-5 py-md-0">
						<div className="d-flex justify-content-center justify-content-md-start flex-md-row-reverse px-4 pt-5">
							<LanguageSwitcher />
						</div>

						<div className="d-flex align-items-center h-75 px-4 px-md-0 pt-5 pt-md-0">
							<div className="col-md-8 mx-auto text-center">
								<h1 className="text-title">{forgetPassword.resetPassword}</h1>
								<p className="text-headline">{forgetPassword.forgetMessage}</p>

								<div className="my-3">
									<TextField
										label={forgetPassword.emailAddress}
										value={email}
										placeholder={forgetPassword.emailPlaceholder}
										name="email"
										id="forgot-email"
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
													email: true,
												}),
											});
											setEmail(e.target.value);
										}}
										color={
											errors?.email?.required || errors?.email?.email
												? "danger"
												: ""
										}
										errors={errors?.email}
									/>
								</div>

								<button
									className="btn btn-primary w-100"
									onClick={forgotPasswordHandler}
									disabled={spin}
								>
									{spin ? <Spinner color="light" size="sm" /> : null}{" "}
									{forgetPassword.continue}
								</button>

								<div className="d-flex justify-content-between">
									<Link to="/login" className="my-1 text-dark-blue">
										<span className="icx icx-left-solid-arrow text-10"></span>{" "}
										{forgetPassword.backLogin}
									</Link>
									<Link to="/register" className="my-1 text-dark-blue">
										{forgetPassword.newUserSignup}
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
