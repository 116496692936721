import React from "react";
import Locale from "translations";

export default function Request({
	request,
	toggleView,
	acceptRequst,
	rejectRequst,
}) {
	const { wallet } = Locale;

	return (
		<tr>
			<td>{request?.companyName}</td>
			<td>{request?.receiptNumber} </td>
			<td>{request?.balance} </td>
			<td>{request?.created}</td>

			<td className="text-warning">{request?.status}</td>
			<td className=" " width={"22%"}>
				<i
					class="fas fa-eye mx-1 pointer f-18"
					onClick={() =>
						toggleView(request.payment_card_id, request.id, "request")
					}
				></i>
				<button
					type="button"
					class="btn btn-success mx-1 font-weight-bolder"
					// onClick={() => {
					//   acceptRequst(request.id, request.receipt_number);
					// }}
				>
					{wallet.confirm}
				</button>
				<button
					type="button"
					class="btn btn-danger mx-1 font-weight-bolder"
					// onClick={() => {
					//   rejectRequst(request.id, request.receipt_number);
					// }}
				>
					{wallet.reject}
				</button>
			</td>
		</tr>
	);
}
