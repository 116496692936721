import arFLag from "assets/images/ar-flag.svg";
import enFLag from "assets/images/en-flag.svg";
import { useGlobalDispatch, useGlobalState } from "context/global";
import React from "react";
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from "reactstrap";
import Locale from "./LanguageSwitcher.locale";
export default function LanguageSwitcher() {
	const { locale } = useGlobalState();
	Locale.setLanguage(locale);
	const dispatch = useGlobalDispatch();
	localStorage.setItem("currentLocale", locale);
	//
	return (
		<UncontrolledDropdown className="lang-dropdown">
			<DropdownToggle caret className="btn-light">
				<img
					className="img-lang px-2"
					src={locale === "ar" ? arFLag : enFLag}
					width="40"
					alt="lang"
				/>
				{Locale[locale]}
			</DropdownToggle>

			<DropdownMenu>
				<DropdownItem
					onClick={() => dispatch({ type: "setLocale", payload: "en" })}
				>
					<img className="img-lang" src={enFLag} width="20" alt="lang" />
					<span className={`${locale === "en" ? "ml-2" : "mr-2"}`}>
						English
					</span>
				</DropdownItem>
				<DropdownItem
					onClick={() => dispatch({ type: "setLocale", payload: "ar" })}
				>
					<img className="img-lang" src={arFLag} width="20" alt="lang" />
					<span className={`${locale === "en" ? "ml-2" : "mr-2"}`}>
						العربية
					</span>
				</DropdownItem>
			</DropdownMenu>
		</UncontrolledDropdown>
	);
}
