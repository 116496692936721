import React from "react";
import { Link, useParams } from "react-router-dom";
import BasicDeal from "./../../../assets/images/Basicdeal.png";
import night from "./../../../assets/images/night.png";

function Promotions() {
	const { id } = useParams();
	return (
		<>
			<div className="container promotions-type">
				<div className="col-md-12">
					<div className="row">
						<div className="col-md-1 icon-item">
							<img src={BasicDeal} alt="" srcset="" />
						</div>
						<div className="col-md-3 promotions-description">
							<p className="title-item">Basic deal </p>
							<p className="subtitle-item">Recommended 10% discount or more</p>
						</div>
						<div className="col-md-2 promotions-description text-center">
							<p className="subtitle-item">Any dates </p>
						</div>
						<div className="col-md-4 promotions-description text-center">
							<p className="subtitle-item">
								Customize a deal to suit your needs
							</p>
						</div>
						<div className="col-md-2 promotions-Link">
							<Link to={`/dashboard/list-promotions-basic/${id}`}>
								<i class="fas fa-chevron-right"></i>
							</Link>
						</div>
					</div>
				</div>
			</div>

			<div className="container promotions-type">
				<div className="col-md-12">
					<div className="row">
						<div className="col-md-1 icon-item">
							<img src={night} alt="" srcset="" />
						</div>
						<div className="col-md-3 promotions-description">
							<p className="title-item">Free nights / Room deal </p>
						</div>
						<div className="col-md-2 promotions-description text-center">
							<p className="subtitle-item">Any dates </p>
						</div>
						<div className="col-md-4 promotions-description text-center">
							<p className="subtitle-item">
								Encourage longer stays at your property
							</p>
						</div>
						<div className="col-md-2 promotions-Link">
							<Link to={`/dashboard/list-promotions-Room/${id}`}>
								<i class="fas fa-chevron-right"></i>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Promotions;
