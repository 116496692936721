import SelectField from "components/shared/SelectField";
import React, { useEffect, useRef, useState } from "react";
import { store } from "react-notifications-component";
import { Collapse } from "reactstrap";
import { fetchCountries } from "services/lookups";
import Locale from "translations";
import AddCustomRate from "./AddCustomRate";
import BulkEdit from "./BullkEdit";
import Cell from "./Cell";
import {
	addPriceRangeDays,
	deleteCustomPriceCalender,
	updateStatusPriceCalender,
} from "services/calendar";
import moment from "moment";
export default function CalendarRoom(props) {
	const { hotelLayout } = Locale;

	const [calendar, setCalendar] = useState({ ratePlan: [] });
	const [modal, setModal] = useState({
		isOpen: false,
		type: "edit",
		title: "",
	});

	const { filters, getViewCalendar } = props;
	//Collapse
	const [isOpen, setIsOpen] = useState(true);
	const toggleButton = () => setIsOpen(!isOpen);
	const [RangeDays, setRangeDays] = useState({ days: [] });

	const toggle = (roomId) => {
		setModal({
			isOpen: !modal.isOpen,
			title: hotelLayout.calendar.bulkEdit,
			id: roomId,
		});
	};

	const [customRatemodal, setCustomRatemodal] = useState({
		isOpen: false,
		type: "edit",
		title: "",
	});

	const ratesplans = props.room.standalone_property_rates.map((ratePlan) => {
		return (ratePlan = {
			label: ratePlan.name,
			value: ratePlan.id,
			showCountries: true,
			...ratePlan,
			countries: ratePlan?.property_rate_price_countries.map(
				(res) =>
				(res = {
					id: res?.id,
					nationality: res.nationality,
					residence: res.residence,
				})
			),
		});
	});

	const ratetoggle = (ratePlanId) => {
		setCustomRatemodal({
			isOpen: !customRatemodal.isOpen,
			title: hotelLayout.calendar.bulkEdit,
			ratePlanId: ratePlanId,
		});
	};

	// cells
	const [selected, setSelected] = React.useState();
	const [started, setStarted] = React.useState("");
	const [handle, setHandle] = React.useState();
	const [handleFirstDay, setHandleFirstDay] = React.useState();

	const handleMouseUp = (e) => {
		if (e.type === "mouseup" && selected && started) {
			setStarted(false);
		}
	};

	const handleMouseDown = (e) => {
		if (e.type === "mousedown" && selected && started) {
			setStarted(false);
		}
	};

	useEffect(() => {
		if (typeof document.onselectstart != "undefined") {
			document.onselectstart = new Function("return false");
		} else {
			document.onmousedown = new Function("return false");
			document.onmouseup = new Function("return true");
		}
	}, []);
	const [lookups, setLookups] = useState({
		countries: [],
	});
	const locale = localStorage.getItem("currentLocale") || "en";

	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchCountries();

			const format = res?.data.map((t) => ({
				...t,
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setLookups({
				...lookups,
				countries: format,
			});
		}

		fetchLookups();
	}, []);

	const addCustomRate = (data, retePlanId) => {
		let ratePlan = [...calendar.ratePlan];
		ratePlan.forEach((element) => {
			if (element.value === retePlanId) {
				element.countries.push({ ...data });
			}
		});
		setCalendar({ ...calendar, ratePlan: ratePlan });
	};

	const ChangePriceRangeDays = async () => {
		setStarted(false);

		let date_from = RangeDays.days[0];
		let date_to = RangeDays.days[RangeDays.days.length - 1];
		let response;
		if (moment(date_to).isAfter(date_from)) {
			response = await addPriceRangeDays(props.id, {
				room_id: props.room.id,
				count: RangeDays.count,
				date_from: date_from,
				date_to: date_to,
			});
		} else {
			response = await addPriceRangeDays(props.id, {
				room_id: props.room.id,
				count: RangeDays.count,
				date_from: date_to,
				date_to: date_from,
			});
		}

		setRangeDays({ days: [] });

		if (response.message) {
			getViewCalendar({ ...filters, id: props.id });
			store.addNotification({
				title: "Info!",
				message: response.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	useEffect(() => {
		if (RangeDays.days.length > 0) {
			const timeoutId = setTimeout(() => ChangePriceRangeDays(), 800);
			return () => clearTimeout(timeoutId);
		}
	}, [RangeDays]);

	const [heightRate, setHeightRate] = useState(0);
	const selectRef = useRef(null);

	useEffect(() => {
		if (selectRef?.current?.offsetHeight !== null) {
			setHeightRate(selectRef.current.offsetHeight);
		}
	}, [selectRef?.current?.offsetHeight, calendar?.ratePlan?.length]);

	const showCountries = (rate) => {
		let ratePlan = [...calendar.ratePlan];
		ratePlan.forEach((element) => {
			if (element.value === rate.value) {
				element.showCountries = !element.showCountries;
			}
		});
		setCalendar({ ...calendar, ratePlan: ratePlan });
	};

	const changeStatus = async (ratePlan, status) => {


		let x;
		x = await updateStatusPriceCalender(props.id, {
			room_id: props.room.id,
			property_rate_id: ratePlan.value,
			is_active: status,
		});
		if (x.message) {
			getViewCalendar({ ...filters, id: props.id });

			store.addNotification({
				title: "Info!",
				message: x.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	const deleteCountries = async (ratePlan, selected) => {

		let x;
		x = await deleteCustomPriceCalender(props.id, {
			room_id: props.room.id,
			property_rate_id: ratePlan.value,
			nationality: selected.nationality,
			residence: selected.residence,
		});
		if (x.message) {
			getViewCalendar({ ...filters, id: props.id });
			store.addNotification({
				title: "Info!",
				message: x.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	return (
		<>
			<BulkEdit
				ratesPlans={ratesplans}
				modal={modal}
				setModal={setModal}
				toggle={toggle}
				idRoom={props.room.id}
				getViewCalendar={getViewCalendar}
				filtersData={filters}
				countries={lookups.countries}
				size="lg"
			/>

			<AddCustomRate
				customRatemodal={customRatemodal}
				setCustomRatemodal={setCustomRatemodal}
				ratetoggle={ratetoggle}
				addCustomRate={addCustomRate}
				countries={lookups.countries}
			/>

			<div className="calendar-room">
				<div className="calendar-head">
					<h6>
						{props.room.custom_name !== null
							? props.room.custom_name + " ( " + props.room?.type?.name + " )"
							: props.room.name.name}
					</h6>
					<button
						className="btm btn-primary border-0 py-2 rounded font-12"
						onClick={() => toggle(props.room.id)}
						data-bs-toggle="modal"
						data-bs-target={"#myModal" + props.room.id}
					>
						{hotelLayout.calendar.bulkEdit}
					</button>
				</div>
				<div className="calendar-parent">
					<ul className="calendar-title">
						<li></li>
						<li>{hotelLayout.calendar.roomStatus}</li>
						<li>{hotelLayout.calendar.roomToSell}</li>
						<li>{hotelLayout.calendar.netBooked}</li>
						<li className="calendar-select" ref={selectRef}>
							<SelectField
								label={hotelLayout.calendar.ratePlan}
								value={calendar.ratePlan}
								name="ratePlan"
								id="ratePlan"
								multi={true}
								options={ratesplans}
								onChange={(e) => {
									setCalendar({ ...calendar, ratePlan: e });
								}}
							/>
						</li>
						{calendar.ratePlan && calendar.ratePlan?.length > 0
							? calendar.ratePlan.map((ratePlan) => (
								<li
									className={`shadow-sm product-build__product-collpase branch-collapse p-0 
											${+ratePlan.is_active ? "" : " disabled-calender"}`}
								>
									<div className="button-collapse bg-white border-0">
										<div className="p-2 d-flex justify-content-between align-items-center">
											<div>
												<p className="font-12 m-0" onClick={toggleButton}>
													{ratePlan.label}
												</p>
												<a
													href={() => false}
													className="text-primary font-12"
													onClick={() => ratetoggle(ratePlan.value)}
												>
													{hotelLayout.calendar.addCustomPrice}{" "}
												</a>
											</div>
											<div>
												{ratePlan.countries &&
													ratePlan.countries.length > 0 ? (
													<i
														onClick={() => showCountries(ratePlan)}
														className={`fas fa-fw pointer text-gray-300  ${ratePlan.showCountries !== true
																? "fa-chevron-right "
																: "fa-chevron-down"
															} `}
													></i>
												) : (
													""
												)}
												{+ratePlan.is_active ? (
													<span
														className="text-danger mx-2"
														onClick={() => {
															debugger;
															changeStatus(ratePlan, 0);
														}}
													>
														<i class="fa fa-ban" aria-hidden="true"></i>
													</span>
												) : (
													<span
														className="text-success mx-2"
														onClick={() => {
															debugger;
															changeStatus(ratePlan, 1);
														}}
													>
														<i class="fa fa-play"></i>
													</span>
												)}
											</div>
										</div>
									</div>

									<Collapse isOpen={ratePlan.showCountries}>
										{ratePlan.countries && ratePlan.countries.length > 0
											? ratePlan.countries.map((res) => {
												return (
													<>
														<div className="child-country d-flex">
															<span
																style={{
																	fontSize: " 14px",
																	color: "rgb(72, 68, 68)",
																	display: "flex",
																	alignItems: "center",
																	justifyContent: "space-around",
																	width: "35px",
																}}
															>
																<i
																	onClick={() =>
																		deleteCountries(ratePlan, res)
																	}
																	class="fas fa-trash-alt text-danger"
																></i>{" "}
																—
															</span>
															<table
																className=" w-100 text-center "
																style={{ borderCollapse: "separate" }}
															>
																<tr
																	style={{
																		height: "10px",
																		lineHeight: "14px",
																	}}
																>
																	<td
																		className="font-weight-bold text-capitalize"
																		style={{
																			borderRight: "2px solid",
																			color: "#484444",
																		}}
																	>
																		nationality
																	</td>
																	<td
																		className="font-weight-bold text-capitalize"
																		style={{
																			color: "#484444",
																		}}
																	>
																		residence
																	</td>
																</tr>
																<tr
																	style={{
																		height: "10px",
																		lineHeight: "14px",
																	}}
																>
																	<td style={{ borderRight: "2px solid" }}>
																		{typeof res?.nationality ===
																			"string" ? (
																			res?.nationality
																		) : res?.nationality?.nat_code ? (
																			res.nationality?.nat_code
																		) : (
																			<strong className="font-weight-bold">
																				{" "}
																				ALL
																			</strong>
																		)}
																	</td>
																	<td>
																		{typeof res?.residence === "string" ? (
																			res?.residence
																		) : res?.residence?.nat_code ? (
																			res.residence?.nat_code
																		) : (
																			<strong className="font-weight-bold">
																				ALL
																			</strong>
																		)}
																	</td>
																</tr>
															</table>
														</div>
													</>
												);
											})
											: ""}
									</Collapse>
								</li>
							))
							: ""}
					</ul>

					<div
						onMouseUp={handleMouseUp}
						onMouseDown={handleMouseDown}
						className="day-parent"
					>
						{props.days.map((cell, index) => {
							return (
								<Cell
									heightRate={heightRate}
									ratePlans={calendar.ratePlan}
									day={cell}
									handle={handle}
									setHandle={setHandle}
									selected={selected}
									setSelected={setSelected}
									started={started}
									setStarted={setStarted}
									setHandleFirstDay={setHandleFirstDay}
									handleFirstDay={handleFirstDay}
									setRangeDays={setRangeDays}
									RangeDays={RangeDays}
									id={index}
									hotelId={props.id}
									key={index + 100}
									room={props.room}
									room_availability_days={props.room.room_availability_days}
								/>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
}
