import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Locale from "translations";
import Amenity from "./Amenity";
import OtherAmenity from "./OtherAmenity";
import { viewProperties } from "services/property";
import SelectField from "components/shared/SelectField";
const locale = localStorage.getItem("currentLocale") || "en";

export default function Amenities() {
	const { amenities } = Locale;
	const { id } = useParams();

	const [allAmenities, setAllAmenities] = useState([]);

	const [roomTypes, setRoomTypes] = useState([]);
	useEffect(async () => {
		const res = await viewProperties(id);
		if (res.status === 200 || res.status === 201) {

			setAllAmenities(res.data.amenities);
		}
	}, []);
	return (
		<div className="container px-3 py-2">
			{allAmenities.length > 0 &&
				allAmenities.map((amenities, i) => {
					return (
						<>
							<div
								className="form-group form-check d-flex justify-content-between "
								key={i}
							>
								<div className="">
									<input
										type="checkbox"
										name="views"
										className="form-check-input"
										checked={true}
										disabled={true}
									/>
									<label
										className="form-check-label text-capitalize"
										htmlFor={`room-views-${i + 1}`}
									>
										{amenities.name}
									</label>
								</div>
								<div className="d-flex">
									<div className=" mx-5">
										<input
											type="checkbox"
											name="views"
											checked={amenities.is_has_all_rooms}
											className="mx-1"
											disabled={true}
										/>
										<label
											className="form-check-label text-capitalize"
											htmlFor={`room-views-${i + 1}`}
										>
											All Rooms
										</label>
									</div>
									<div>
										<input
											type="checkbox"
											name="views"
											className="form-check-input"
											checked={amenities.is_has_all_rooms === false}
											disabled={true}
										/>
										<label
											className="form-check-label text-capitalize"
											htmlFor={`room-views-${i + 1}`}
										>
											Some Rooms
										</label>
									</div>
								</div>
							</div>
							<div className="w-50">
								{!amenities.is_has_all_rooms ? (
									<SelectField
										multi={true}
										disabled={true}
										label={"SELECT WHERE THIS AMENITY IS AVAILABLE"}
										value={amenities?.rooms.map((item) => ({
											id: item.id,
											label: item.name,
										}))}
									/>
								) : null}
							</div>
						</>
					);
				})}
		</div>
	);
}
