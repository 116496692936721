const locale = localStorage.getItem("currentLocale") || "en";

export function formatVerifyHotel(info) {
	const { PropertyDetails, OwnerDetails, ManagerDetails } = info;
	return {
		// property_classification_name: PropertyDetails.property_name,
		// property_trade_name: PropertyDetails.trade_name,
		// address: PropertyDetails.address,
		// unit_number: PropertyDetails.unit_number,
		// zip_code: PropertyDetails.zip_code,
		country_id: PropertyDetails?.country?.value,
		city_id: PropertyDetails?.town_city?.value,
		documents: OwnerDetails.photos.map((photo) => {
			return { name: photo.name };
		}),
		owner_details: {
			owner_type_id: OwnerDetails.owns_property?.value,
			entity_full_name: OwnerDetails.full_name_Business,
			address: OwnerDetails.address_business,
			unit_number: OwnerDetails.unit_number,
			zip_code: OwnerDetails.zip_code,
			country_id: OwnerDetails.country.value,
			city_id: OwnerDetails.town_city.value,
		},
		beneficial_owners: OwnerDetails.OwnerNames,
		manager_type_id: ManagerDetails.owns_property.value,
		management_company: {
			full_name: ManagerDetails.full_name_Management,
			alternative_name: ManagerDetails.alternative_name_Business,
			address: ManagerDetails.address_management_business,
			unit_number: ManagerDetails.unit_number,
			country_id: ManagerDetails.country?.value,
			city_id: ManagerDetails.town_city?.value,
			zip_code: ManagerDetails.zip_code,
			managers: ManagerDetails.ManagerNames,
		},
		management_manager: {
			full_name: ManagerDetails.full_name_Management,
			birth_date: ManagerDetails.birthDate,
		},
	};
}
