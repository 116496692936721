import StatusBar from "components/shared/StatusBar";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Locale from "translations";
import { Modal, ModalFooter, ModalBody } from "reactstrap";
import SelectField from "components/shared/SelectField";

import TextField from "components/shared/TextField";
import Details from "./Details";
import EditedRooms from "./Edited-Rooms";
import RoomPricing from "./Room-Pricing";
import DatePickerField from "components/shared/DatePickerField";
import Checkbox from "components/shared/checkboxs";
import TextAreaField from "components/shared/TextAreaField";
import {
	askToApproved,
	askToPromo,
	askToReject,
	askToVoucher,
	SendVoucher,
	viewReservations,
} from "services/reservations";
import moment from "moment";
import { getNotification } from "helpers/makeNotifications";
import { Link } from "react-router-dom";

export default function ReservationsLayout() {
	const { reservations } = Locale;
	const [successfully, setSuccessfully] = useState(false);
	const { id, reservation_id } = useParams();
	const [isOpen, setIsOpen] = useState(false);
	const [selectedMethod, setSelectedMethod] = useState(2);
	const [data, setData] = useState({});
	const [selected, setSelected] = useState({});
	const [files, setFiles] = useState([]);

	/* three state for every step to hold there actions felids data  */

	const [approved, setApproved] = useState({
		type: null,
		expiration_date: null,
		amount: null,
	});

	/* promo code */
	const [deposite, setDeposite] = useState({
		code: null,
		expiration_date: null,
		notes: null,
	});

	const [voucher, setVoucher] = useState({
		expiration_date: null,
		notes: null,
	});

	/* modalType ==>  approved  , deposite  ,  voucher */
	const [modalType, setModalType] = useState();

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	useEffect(async () => {
		const res = await viewReservations(id, reservation_id);
		if (res) {
			setData(res);
			setModalType(
				res?.payment === null
					? "approved"
					: res?.payment?.type === "deposit"
					? "deposite"
					: "voucher" //  res?.payment?.type === full payment
			);
		}
	}, [successfully]);

	/* api for three actions */

	const ApprovedConfigrations = async () => {
		const res = await askToApproved(reservation_id, {
			amount: selectedMethod === 1 ? approved.amount : null,
			expiration_date: moment(approved.expiration_date).format("YYYY-MM-DD"),
			type: selectedMethod === 1 ? "deposit" : " full payment",
		});
		if (res.message) {
			getNotification(res.message);
			toggle();
			setSuccessfully(!successfully);
		}
	};

	const promoConfigrations = async () => {
		const res = await askToPromo(reservation_id, {
			...deposite,
			expiration_date: moment(deposite.expiration_date).format("YYYY-MM-DD"),
		});
		if (res.message) {
			getNotification(res.message);
			toggle();
			setSuccessfully(!successfully);
		}
	};

	const voucherConfigrations = async () => {
		const res = await SendVoucher(reservation_id, selected.id, {
			voucher_code: voucher.notes,
		});
		if (res.message) {
			getNotification(res.message);
			toggle();
			setSuccessfully(!successfully);
		}
	};

	const download = () => {
		data?.voucher_code?.files.map((item) => {
			window.open(`${item}`, "_blank");
		});
	};

	const copyCodeToClipboard = () => {
		const text = document.getElementById("text").innerText;
		var textField = document.createElement("textarea");
		textField.innerText = text;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand("copy");
		textField.remove();
	};

	const reject = async () => {
		const rejected = await askToReject(reservation_id);
		if (rejected?.status === 200 || rejected?.status === 201) {
			getNotification(rejected?.data?.message);
			toggle();
			setSuccessfully(!successfully);
		}
	};

	const allRoomsList =
		data?.rooms?.length > 0
			? data.rooms.map((room) => {
					debugger;
					return (
						<tr>
							<td>{room?.room_details?.name?.name}</td>
							{/* <td>{room?.room_details?.custom_name}</td> */}
							<td>{room?.room_details?.type?.name}</td>
							{/* 							<td>{room?.room_details?.custom_name}</td>
							<td>{room?.room_details?.custom_name}</td> */}
							<td>{room?.room_details?.view?.name}</td>
							<td>
								{room?.voucher_code === null ? (
									data?.payment?.type === "full payment" ? (
										<button
											type="button"
											class="btn btn-primary"
											onClick={() => {
												toggle();
												setSelected(room);
											}}
										>
											{reservations.sendVoucher}
										</button>
									) : (
										""
									)
								) : (
									<>
										<span className="text-success">
											{room?.voucher_code} <i class="fas fa-check"></i>
										</span>
									</>
								)}
							</td>
						</tr>
					);
			  })
			: [];

	return (
		<>
			{/* <div className="bar-header w-100">
				<div className="container">
					<header className="d-flex justify-content-start align-items-center ">
						<h3 className="bar-title p-3 font-weight-bold">
							Dar Al Eiman Royal Hotel
						</h3>
						<div className="py-3">
							<p className="px-2 bg-success  text-white rounded">
								{reservations.bookable}
							</p>
						</div>
					</header>
				</div>
			</div> */}
			<div className=" px-4">
				<div className="d-flex py-3">
					<NavLink
						exact={true}
						to={`/dashboard/${id}/reservation/${reservation_id}`}
						activeClassName="is-active-reservations"
						className="p-4 bar-title  t bg-white  rounded  font-weight-bold"
					>
						<i class="fas fa-hotel bar-title mx-1 active-icon"></i>
						{reservations.bookingDetails}
					</NavLink>
					{/* 			<NavLink
						exact={true}
						to="/dashboard/reservation/Rooming List"
						activeClassName="is-active-reservations"
						className="p-4 bar-title  t bg-white  rounded  font-weight-bold mx-2 border"
					>
						<i class="fas fa-list-alt bar-title mx-1 "></i>
						{reservations.roomingList}
					</NavLink> */}
				</div>
				{/* progress bar */}
				<StatusBar
					title={reservations.requestsStatus}
					status={[
						{
							status:
								data?.status != "pending" &&
								data?.status != "rejected" &&
								data?.status != "canceled" &&
								data.status
									? "active"
									: "inactive", // means data?.status = approved or paid
							name: reservations.approvedRequest,
						},
						{
							status:
								// data?.status === "completed" ||
								data?.status === "paid" || data?.voucher_code != null //means that its paid or promo code or voucher is exsit and its the last step
									? "active"
									: "inactive",
							name: reservations.payDeposit,
						},
						{
							//status: data?.voucher_code != null ? "active" : "inactive", // means that promo code or voucher is exsit and its the last step
							status: data?.status === "completed" ? "active" : "inactive",

							name:
								data?.payment?.type === "deposit"
									? reservations.promoCodeIssuance
									: reservations.Voucher,
							extraName:
								data?.payment?.type === "deposit" &&
								data?.voucher_code?.code ? (
									<p id="text">
										{data?.voucher_code?.code}
										<i
											class="far fa-clone text-primary mx-1 "
											style={{ cursor: "pointer" }}
											onClick={() => copyCodeToClipboard()}
										></i>
									</p>
								) : data?.payment?.type === "full payment" &&
								  data?.voucher_code?.files ? (
									<button
										type="button"
										class=" btn-link text-success"
										onClick={download}
									>
										{reservations.download} <i class="fas fa-download"></i>
									</button>
								) : null,
						},
					]}
					details={data}
					setSuccessfully={setSuccessfully}
				/>

				<Details details={data} />

				{/* 				<RoomPricing
					room={data?.room}
					notes={data?.room?.comment}
					setSuccessfully={setSuccessfully}
					status={data?.status}
				/>
				<EditedRooms
					roomsHistory={data?.rooms_history}
					checkDates={{ check_in: data?.check_in, check_out: data?.check_out }}
				/> */}

				{/* Room List */}

				<div className="w-100">
					{" "}
					<table class="table mb-0 f-13">
						<thead className="table-light">
							<tr className="">
								<th scope="col">{reservations.Name} </th>
								<th scope="col">{reservations.Roomtype}</th>
								{/* <th scope="col">{reservations.Gender}</th>
								<th scope="col">{reservations.RoomNo}</th>
								<th scope="col">{reservations.Meals}</th> */}
								<th scope="col">{reservations.RoomView}</th>
								<th scope="col">Voucher</th>
							</tr>
						</thead>
						<tbody>{allRoomsList}</tbody>
					</table>{" "}
				</div>

				{/* Modal Start and end of ui */}

				{/* there is three modla types named in code = ( modalType )  approved 	, deposite , voucher  ,
				note : approved ( have two methods to be selected named  in code = selectedMethod (deposit = 1 , full = 2 ) )
				 */}

				<div className="d-flex py-3 justify-content-end">
					<Link
						to={`/dashboard/${id}`}
						type="button"
						class="btn btn-secondary mx-2"
					>
						{reservations.back}
					</Link>

					{data?.payment == null &&
					data?.status != "canceled" &&
					data?.status != "rejected" ? (
						<button
							type="button"
							class="btn btn-primary"
							onClick={() => {
								toggle();
							}}
						>
							{data?.payment === null
								? reservations.ApproveAndSetDeposit
								: data?.payment?.type === "deposit"
								? reservations.sendPromoCode
								: reservations.sendVoucher}
						</button>
					) : null}
				</div>
			</div>

			{/*Modal titlies */}
			<Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: "650px" }}>
				<div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
					{data?.payment === null ? (
						<>
							<p className=" text-secondary font-weight-bold w-100">
								{reservations.SetPaymentConfirmation}
							</p>
							<button
								type="button"
								onClick={toggle}
								class="close"
								aria-label="Close"
							>
								<span aria-hidden="true">×</span>
							</button>
						</>
					) : data?.payment?.type === "deposit" ? (
						<>
							<p className=" text-secondary font-weight-bold w-100">
								{reservations.sendPromoCode}
							</p>
							<button
								type="button"
								onClick={toggle}
								class="close"
								aria-label="Close"
							>
								<span aria-hidden="true">×</span>
							</button>
						</>
					) : (
						<>
							<p className=" text-secondary font-weight-bold w-100">
								{reservations.sendVoucher}
							</p>
							<button
								type="button"
								onClick={toggle}
								class="close"
								aria-label="Close"
							>
								<span aria-hidden="true">×</span>
							</button>
						</>
					)}
				</div>
				{/* Modal fields */}
				<ModalBody className="  d-flex flex-column justify-content-center align-items-center ">
					{data?.payment === null ? (
						<div className="w-75">
							<div className="text-center">
								<h3 className="text-success font-weight-bold my-1">
									{reservations.totalPrice}
								</h3>
								<h3 className="font-weight-bold">{data?.price} SAR</h3>
							</div>
							<div className="w-100 text-center d-flex  justify-content-center  ">
								{/* <div className="Checkbox">
									<Checkbox
										labelClassName={"check-box-label "}
										type={"radio"}
										label={reservations.depositPayment}
										checked={selectedMethod === 1}
										onChange={() => {
											setSelectedMethod(1);
										}}
									/>
								</div> */}

								{/* <div className="Checkbox">
									<Checkbox
										checked={selectedMethod === 2}
										labelClassName="check-box-label "
										type={"radio"}
										label={reservations.fullPayment}
										onChange={() => {
											setSelectedMethod(2);
										}}
									/>
								</div> */}
							</div>

							{selectedMethod === 1 ? (
								<div className="w-100">
									<TextField
										label={reservations.depositAmount}
										onChange={(e) => {
											setApproved({ ...approved, amount: e.target.value });
										}}
										value={approved.amount}
									/>
								</div>
							) : null}
							<div className="w-100">
								<DatePickerField
									label={reservations.paymentExpire}
									minDate={moment(new Date()).subtract(1, "days")}
									date={approved.expiration_date}
									onChangeDate={(e) => {
										setApproved({
											...approved,
											expiration_date: e,
										});
									}}
								/>
							</div>
						</div>
					) : data?.payment?.type === "deposit" ? (
						<div className="w-75">
							<div>
								<TextField
									label={reservations.promoCode}
									value={deposite.code}
									onChange={(e) => {
										setDeposite({
											...deposite,
											code: e.target.value,
										});
									}}
								/>
							</div>
							<div>
								<DatePickerField
									label={reservations.promoCodeExpire}
									minDate={moment(new Date()).subtract(1, "days")}
									date={deposite.expiration_date}
									onChangeDate={(e) => {
										setDeposite({
											...deposite,
											expiration_date: e,
										});
									}}
								/>
							</div>
							<div>
								<TextAreaField
									label={reservations.notes}
									value={deposite.notes}
									onChange={(e) => {
										setDeposite({
											...deposite,
											notes: e.target.value,
										});
									}}
								/>
							</div>
						</div>
					) : (
						<>
							<div className="w-100">
								<TextField
									label={reservations.Voucher}
									value={voucher.Voucher}
									onChange={(e) => {
										setVoucher({
											...voucher,
											notes: e.target.value,
										});
									}}
								/>
							</div>

							<div className="w-100">
								{files.map((item, index) => {
									return (
										<div className="file">
											<p>
												<i class="fas fa-file-pdf mx-1"></i> {item.name}
											</p>
											<i
												class="fas fa-trash text-danger pointer"
												onClick={() => {
													let x = [...files];
													x.splice(index, 1);
													setFiles(x);
												}}
											></i>
										</div>
									);
								})}
							</div>
							{/* 					<div className="w-100">
								<DatePickerField
									label={reservations.promoCodeExpire}
									minDate={moment(new Date()).subtract(1, "days")}
									date={voucher.expiration_date}
									onChangeDate={(e) => {
										setVoucher({
											...voucher,
											expiration_date: e,
										});
									}}
								/>
							</div> */}
						</>
					)}
				</ModalBody>

				{/* Modal Actions */}
				<div className="pb-5 border-top-0  text-center px-4   p-0 ">
					{data?.payment === null ? (
						<button
							className="rounded btn btn-primary w-50 py-2 mx-1"
							onClick={ApprovedConfigrations}
							disabled={
								!approved.expiration_date ||
								!selectedMethod ||
								selectedMethod === 1
									? !approved.amount
									: false
							}
						>
							{reservations.setAndSave}
						</button>
					) : data?.payment?.type === "deposit" ? (
						<button
							className="rounded btn btn-primary w-50 py-2 mx-1 "
							onClick={promoConfigrations}
							disabled={!deposite.code || !deposite.expiration_date}
						>
							{reservations.setAndSave}
						</button>
					) : (
						<button
							className="rounded btn btn-primary w-50 py-2 mx-1 "
							onClick={voucherConfigrations}
						>
							{reservations.send}
						</button>
					)}

					{data?.status === "pending" ? (
						<button
							className="rounded btn btn-danger w-25 py-2 mx-1"
							onClick={reject}
						>
							{reservations.reject}
						</button>
					) : (
						<button
							className="rounded btn btn-secondary w-25 py-2 mx-1"
							onClick={toggle}
						>
							{reservations.back}
						</button>
					)}
				</div>
			</Modal>
		</>
	);
}
