import axios from "axios";

const statisticsUrl = process.env.REACT_APP_API_URL + "/api/statistics";

export const fetchDashboard = async () => {
	return await axios
		.get(`${statisticsUrl}`)
		.then((res) => res)
		.catch((err) => err.response);
};
