import axios from "axios";
import { cleanEmpty } from "services/general";
const basicUrl = process.env.REACT_APP_API_URL;

export const getListStandalone = async (HotelId) => {
	return await axios
		.get(`${basicUrl + "/api/properities/" + HotelId + "/rates/standalone"}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const getListRooms = async (HotelId) => {
	return await axios
		.get(`${basicUrl + "/api/properities/" + HotelId + "/rooms"}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const addRatePlan = async (HotelId, data) => {
	return await axios
		.post(
			`${basicUrl + "/api/properities/" + HotelId + "/rates"}`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err);
};

export const editRatePlan = async (HotelId, RatePlanId, data) => {
	return await axios
		.post(
			`${basicUrl + "/api/properities/" + HotelId + "/rates/" + RatePlanId}`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err);
};

export const getRatePlan = async (HotelId, RatePlanId) => {
	return await axios
		.get(`${basicUrl + "/api/properities/" + HotelId + "/rates/" + RatePlanId}`)
		.then((res) => res)
		.catch((err) => err);
};

export const getListRatePlan = async (HotelId, page) => {
	return await axios
		.get(`${basicUrl + "/api/properities/" + HotelId + "/rates?page=" + page}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const deleteRatePlan = async (HotelId, retaId) => {
	return await axios
		.delete(`${basicUrl + "/api/properities/" + HotelId + "/rates/" + retaId}`)
		.then((res) => res)
		.catch((err) => err.response);
};
