import Model from "components/model";
import { usePropertyState } from "context/property";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router";
import { deletePhoto, editPhoto } from "services/property";

function Photo({ photo, gallery, setGallery, setPhotos, photos }) {
	const { id } = useParams();
	// const dispatch = useGlobalDispatch();
	const { propertyDetails } = usePropertyState();

	// const [photo, setPhoto] = useState([]);
	const [checked, setChecked] = useState(false);
	const [rooms, setRooms] = useState([]);

	const [modal, setModal] = useState({
		isOpen: false,
		type: "",
		title: "",
	});

	const toggle = () => {
		setModal({
			isOpen: !modal.isOpen,
			type: "gallery",
			title: "Edit Photo",
		});
	};

	const removePhoto = async (photoId, e) => {
		if (photo.uploaded) {
			const res = await deletePhoto(id, {
				photo_id: photoId,
				_method: "delete",
			});
			if (res.status === 200) {
				setGallery(gallery.filter((img) => img.id !== photoId));
				store.addNotification({
					title: "info!",
					message: res.data.message,
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			}
		} else {
			setGallery([...gallery].filter((photo) => photo.id !== photoId));
			setPhotos([...photos].filter((photo) => photo.name !== e.fileName));
		}
	};

	const handleCheckbox = (event) => {
		if (event.target.checked === true) {
			setRooms([...rooms, Number(event.target.value)]);
		} else {
			setRooms([...rooms.filter((e) => e !== Number(event.target.value))]);
		}
	};

	const editRoomPhotos = async (e = checked) => {
		const data = {
			photo_id: photo.id,
			rooms,
			_method: "put",
			is_main: e ? "1" : "0",
		};
		const res = await editPhoto(id, data);
		// if (res.status === 200) {
		if (e == true) {
			let x = [...gallery];
			x = x.map((res) => {
				if (res.id == photo.id) {
					return { ...res, is_main: 1 };
				} else {
					return { ...res, is_main: 0 };
				}
			});

			setGallery(x);
		}

		// }
	};

	useEffect(() => {
		if (rooms.length > 0) {
			editRoomPhotos();
		}
	}, [rooms]);

	//

	return (
		<div>
			<Model
				isOpen={modal.isOpen}
				toggle={() => toggle(photo)}
				type={modal.type}
				title={modal.title}
				size="lg"
				className="customModal"
			>
				<div className="d-flex justify-content-between w-100">
					<div className="data-gallery col-md-4">
						{/* <div className="form-group form-check">
							<input
								type="checkbox"
								className="form-check-input"
								onChange={(e) => {
									setChecked(!checked);
								}}
								checked={checked ? true : false}
								id="included"
							/>
							<label
								className="form-check-label text-caption pointer text-blue-black font-weight-bold"
								for="included"
							>
								Include in your photo gallery
							</label>
							<p className="text-caption3">
								This will Also Be Included in Your Property Page
							</p>
						</div> */}

						<div className="form-group form-check mb-3 mt-2">
							<input
								type="checkbox"
								className="form-check-input"
								onChange={(e) => {
									setChecked(!checked);
									editRoomPhotos(e.target.checked);
								}}
								checked={+photo.is_main}
								id="included"
							/>
							<label
								className="form-check-label text-caption pointer text-blue-black font-weight-bold"
								for="included"
							>
								Make Main Photo
							</label>
						</div>

						<div>
							<p className="text-capitalize text-caption font-weight-bold">
								Indicate Of This Is A Room Photo:
							</p>
							{propertyDetails?.property?.rooms?.map((room, i) => {
								return (
									<div className="form-group form-check" key={room.id}>
										<input
											type="checkbox"
											className="form-check-input"
											onChange={handleCheckbox}
											value={room.id}
											defaultChecked={rooms.includes(room.id) ? true : false}
											id={"room1" + i}
										/>
										<label
											className="form-check-label text-caption3 pointer"
											for={"room1" + i}
										>
											{room.custom_name || room.name.name}
										</label>
									</div>
								);
							})}
						</div>
					</div>
					<div className="photo-gallery text-center col-md-8">
						<img src={photo.image} alt="gallery" className="img-fluid" />
						<div
							className="py-2 text-center bg-trash"
							onClick={() => removePhoto(photo.id, photo)}
						>
							<i className="fa fa-trash text-white pointer"></i>
						</div>
					</div>
				</div>
			</Model>

			<div className="gallery-item" style={{ position: "relative" }}>
				{+photo.is_main ? <span className="mainPhoto">Main Photo</span> : ""}
				<img src={photo.image} alt="gallery" className="img-fluid" />
				<ul className="bg-white d-flex justify-content-center py-1">
					{photo.uploaded && (
						<li
							className="mx-2 text-blue-black pointer"
							onClick={toggle}
							id="edit-photo"
						>
							<i className="fa fa-edit fa-fw"></i>
							Edit
						</li>
					)}
					<li
						className="mx-2 text-danger pointer"
						onClick={() => removePhoto(photo.id, photo)}
						id="delete-photo"
					>
						<i className="fa fa-trash fa-fw"></i>
						Delete
					</li>
				</ul>
			</div>
		</div>
	);
}

export default Photo;
