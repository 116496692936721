import uploadIcon from "assets/images/upload.svg";
import Loader from "components/Loader";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import {
	useVerifyHotelDispatch,
	useVerifyHotelState,
} from "context/verifyHotel";
import validate, { isFormValid } from "helpers/validate";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Link, useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import { fetchCities } from "services/lookups";
import {
	checkHotelClassificationName,
	editContract,
} from "services/verify-hotel";
import Locale from "translations";

export default function PropertyDetails(props) {
	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	const { propertyDetails, userDetails, commons } = Locale;
	const { locale } = useGlobalState();
	const { PropertyDetails } = useVerifyHotelState();
	const { id } = useParams();

	const dispatch = useVerifyHotelDispatch();

	const [propertyDetailsState, setPropertyDetailsState] = useState({
		property_name: PropertyDetails?.property_name ?? "", // PropertyDetails?.property_name
		trade_name: PropertyDetails?.trade_name ?? "",
		country: PropertyDetails?.country,
		address: PropertyDetails?.address ?? "",
		town_city: PropertyDetails?.town_city,
		unit_number: PropertyDetails?.unit_number ?? "",
		zip_code: PropertyDetails?.zip ?? PropertyDetails?.zip_code,
		photos: PropertyDetails?.photos ?? [],
		countryCode: null,
		spin: false,
	});

	useEffect(() => {
		if (props.canEdit) {
			setPropertyDetailsState({
				property_name: PropertyDetails?.classification_name ?? "",
				trade_name: PropertyDetails?.trade_name ?? "",
				country: PropertyDetails?.country?.id
					? {
						value: PropertyDetails?.country?.id,
						label:
							PropertyDetails?.country?.names[locale] ??
							PropertyDetails?.country,
					}
					: PropertyDetails?.country,

				address: PropertyDetails?.address ?? "",
				town_city: PropertyDetails?.city?.id
					? {
						value: PropertyDetails?.city?.id,
						label:
							PropertyDetails?.city.names[locale] ??
							PropertyDetails?.city.names.ar,
					}
					: PropertyDetails.city,
				unit_number: PropertyDetails?.unit_number ?? "",
				zip_code: PropertyDetails?.zip ?? "",
				photos: PropertyDetails?.photos ?? [],
				countryCode: null,
				spin: false,
			});
		}
	}, [PropertyDetails]);
	const [cities, setCities] = useState([]);

	const [loaderMessage] = useState(
		"Please be patient and wait, The page will load now."
	);
	const [errors, setErrors] = useState(PropertyDetails?.errors ?? {});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	// ----------------------------------------------------------------------------------------------------
	// Component Functions
	// ----------------------------------------------------------------------------------------------------

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "property_name", value: propertyDetailsState.property_name },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "trade_name", value: propertyDetailsState.trade_name },
				{ required: true }
			),
			...validate(
				{ name: "address", value: propertyDetailsState.address },
				{ required: true }
			),
			// ...validate(
			// 	{ name: "unit_number", value: propertyDetailsState.unit_number },
			// 	{ required: true }
			// ),
			...validate(
				{ name: "zip_code", value: propertyDetailsState.zip_code },
				{ required: true, min: 5, max: 5 }
			),
			...validate(
				{ name: "town_city", value: propertyDetailsState.town_city },
				{ required: true }
			),
			...validate(
				{ name: "country", value: propertyDetailsState.country },
				{ required: true }
			),
		});
	};

	// SUbmit Form
	const submitForm = () => {
		dispatch({
			type: "setPropertyDetails",
			payload: propertyDetailsState,
		});
		dispatch({ type: "setStep", progress: 50, step: 2 });
	};

	const handleUpload = (e) => {
		const files = e.target.files;
		if (files && files != undefined) {
			setPropertyDetailsState({
				...propertyDetailsState,
				photos: [...propertyDetailsState.photos, ...files],
			});
		}
	};

	const removePhoto = (photoName) => {
		setPropertyDetailsState({
			...propertyDetailsState,
			photos: propertyDetailsState.photos.filter(
				(photo) => photo.name !== photoName
			),
		});
	};

	const data = {
		property_classification_name: propertyDetailsState.property_name,
	};

	useEffect(() => {
		const submit = async () => {
			// && userRegister.lastStep === 1
			if (isFormValid(errors)) {
				// for check name hotel

				if (PropertyDetails?.classification_name) {
					//const data = formatPostBasicInfo(info);
					const data = {
						property_classification_name: PropertyDetails?.classification_name,
						property_trade_name: PropertyDetails?.trade_name,
						address: PropertyDetails?.address,
						unit_number: PropertyDetails?.unit_number,
						zip_code: PropertyDetails?.zip,
						country_id: PropertyDetails?.country?.id,
						city_id: PropertyDetails?.city.id,
						photos: PropertyDetails?.documents ?? [],
					};
					dispatch({ type: "setData", payload: data });

					submitForm();
				} else {
					const response = await checkHotelClassificationName(data);
					if (response.status === 200) {
						submitForm();
					}
				}
			} else {
				// dispatch({ type: "Progress", progress: 0, step: 1, lastStep: 1 });
			}
			setPropertyDetailsState({
				...propertyDetailsState,
				spin: false,
			});
		};
		submit();
	}, [isErrorLoaded]);

	const checkEmailAvailability = () => {
		setPropertyDetailsState({
			...propertyDetailsState,
			spin: true,
		});
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	// Fetch Cities
	useEffect(() => {
		async function fetchLookups() {
			if (propertyDetailsState.country?.value) {
				const res = await fetchCities(propertyDetailsState.country?.value);
				const formatted = res?.data.map((country) => ({
					value: country.id,
					label: country.names[locale],
				}));
				setCities(formatted);
			}
		}
		fetchLookups();
	}, [propertyDetailsState.country]);


	// ----------------------------------------------------------------------------------------------------
	// Main Component Body UI
	// ----------------------------------------------------------------------------------------------------
	if (true) {
		return (
			<>
				<div className="col-md-12 mt-2 mb-5">
					<h1 className="text-center text-title text-dark-blue mt-4 mb-3">
						{propertyDetails.propertyDetails}
					</h1>

					<div className="bg-white p-2 px-3 border radius-10 mt-3">
						<div className="row m-0">
							{/* Full Name */}
							<div className="col-12">
								<TextField
									type="text"
									label={propertyDetails.hotelNameAsClassificationCertificate}
									placeholder={
										propertyDetails.hotelNameAsClassificationCertificate
									}
									value={propertyDetailsState.property_name}
									name="property_name"
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
												min: 3,
											}),
										});
										setPropertyDetailsState({
											...propertyDetailsState,
											property_name: e.target.value,
										});
									}}
									min={3}
									color={
										errors?.property_name?.required ||
											errors?.property_name?.min
											? "danger"
											: ""
									}
									errors={errors?.property_name}
								/>
							</div>

							{/* Trade Name */}
							<div className="col-12">
								<TextField
									type="text"
									label={propertyDetails.tradeName}
									placeholder={propertyDetails.tradeName}
									value={propertyDetailsState.trade_name}
									name="trade_name"
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
											}),
										});
										setPropertyDetailsState({
											...propertyDetailsState,
											trade_name: e.target.value,
										});
									}}
									min={3}
									color={errors?.trade_name?.required ? "danger" : ""}
									errors={errors?.trade_name}
								/>
							</div>

							{/* address Street Name House */}
							<div className="col-12">
								<TextField
									type="text"
									label={propertyDetails.addressStreetNameHouse}
									placeholder={propertyDetails.addressStreetNameHouse}
									value={propertyDetailsState.address}
									name="address"
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
											}),
										});
										setPropertyDetailsState({
											...propertyDetailsState,
											address: e.target.value,
										});
									}}
									min={3}
									color={errors?.address?.required ? "danger" : ""}
									errors={errors?.address}
								/>
							</div>

							{/* Unit Number (Optional)  */}
							<div className="col-md-6 pl-md-2">
								<TextField
									label={propertyDetails.unitNumber}
									placeholder={propertyDetails.unitNumber}
									name="unit_number"
									value={propertyDetailsState.unit_number}
									type={"number"}
									onChange={(e) => {
										setPropertyDetailsState({
											...propertyDetailsState,
											unit_number: e.target.value,
										});
									}}
								/>
							</div>

							{/* Phone Number */}
							<div className="col-md-6 pl-md-2">
								<TextField
									label={propertyDetails.zipCode}
									placeholder={propertyDetails.zipCode}
									name="zip_code"
									type={"number"}
									value={propertyDetailsState.zip_code}
									min={5}
									max={5}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{ name: "zip_code", value: e.target.value },
												{
													required: true,
													min: 5,
													max: 5,
												}
											),
										});

										setPropertyDetailsState({
											...propertyDetailsState,
											zip_code: e.target.value,
										});
									}}
									color={
										errors?.zip_code?.required ||
											errors?.zip_code?.min ||
											errors?.zip_code?.max
											? "danger"
											: ""
									}
									errors={errors?.zip_code}
								/>
							</div>

							{/* Country */}
							<div className="col-md-12 pr-md-2">
								<SelectField
									label={userDetails.country}
									value={propertyDetailsState.country}
									name="country"
									options={props.lookups?.countries}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{ name: "country", value: e.value },
												{ required: true }
											),
										});
										setPropertyDetailsState({
											...propertyDetailsState,
											country: e,
											town_city: "",
										});
									}}
									onBlur={() =>
										setErrors({
											...errors,
											...validate(
												{
													name: "country",
													value: propertyDetailsState.country,
												},
												{ required: true }
											),
										})
									}
									color={errors?.country?.required ? "danger" : ""}
									errors={errors?.country}
								/>
							</div>

							{/* townCity */}
							<div className="col-md-12 pr-md-2">
								<SelectField
									label={propertyDetails.townCity}
									value={propertyDetailsState.town_city}
									name="town_city"
									options={cities}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{ name: "town_city", value: e.value },
												{ required: true }
											),
										});
										setPropertyDetailsState({
											...propertyDetailsState,
											town_city: e,
										});
									}}
									onBlur={() =>
										setErrors({
											...errors,
											...validate(
												{
													name: "town_city",
													value: propertyDetailsState.town_city,
												},
												{ required: true }
											),
										})
									}
									color={errors?.town_city?.required ? "danger" : ""}
									errors={errors?.town_city}
								/>
							</div>

							<div className="col-md-12">
								<p className="custom-label">
									{propertyDetails.officialDocuments}
								</p>
								<div className="bg-white p-3 text-center w-100  position-relative upload-images">
									<p className="text-center upload-icon">
										<img src={uploadIcon} alt="" srcset="" />
									</p>

									<label
										for="uploadFile"
										onChange={handleUpload}
										className="btn bg-blue-black text-white px-4"
									>
										<i className="far fa-images px-2 fa-lg"></i>
										Upload Photo
									</label>
									<input
										multiple="multiple"
										onChange={handleUpload}
										type="file"
										id="uploadFile"
										accept="image/*"
									/>
								</div>
							</div>

							<div className="col-md-12">
								{propertyDetailsState.photos.map((photo, i) => {
									return (
										<div className="col-md-12 item_box">
											{photo.name}
											<span
												onClick={() => removePhoto(photo.name)}
												className="span-delete"
											>
												<i class="fas fa-times"></i>
											</span>
										</div>
									);
								})}
							</div>
						</div>
					</div>

					<div className="row mt-3  m-0">
						<div className="col-md-6"></div>
						<div className="col-md-2">
							<Link to="/" className="btn btn-light btn-light-new w-100">
								<span className="icx icx-left-solid-arrow text-10"></span>{" "}
								{commons.back}
							</Link>
						</div>
						<div className="col-md-4 p-0">
							<button
								className="btn btn-primary w-100"
								onClick={() => checkEmailAvailability()}
								disabled={propertyDetailsState.spin}
							>
								{propertyDetailsState.spin ? (
									<Spinner color="light" size="sm" />
								) : null}{" "}
								{userDetails.next}
							</button>
						</div>
					</div>
				</div>
			</>
		);
	} else {
		return <Loader message={loaderMessage} />;
	}
}
