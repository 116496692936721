import React, { createContext, useContext, useReducer } from "react";
const TeamManagementReducerContext = createContext();
const TeamManagementReducerDispatchContext = createContext();
const initialState = {};

function TeamManagementReducer(state, action) {
	switch (action.type) {
		case "dashboardState": {
			return { ...state, dashboardState: action.payload };
		}

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function useTeamManagementState() {
	const context = useContext(TeamManagementReducerContext);
	if (context === undefined) {
		throw new Error(
			"useTeamManagementReducerState must be used within a TeamManagementReducerProvider"
		);
	}
	return context;
}

function useTeamManagementDispatch() {
	const context = useContext(TeamManagementReducerDispatchContext);
	if (context === undefined) {
		throw new Error(
			"useTeamManagementReducerDispatch must be used within a TeamManagementReducerProvider"
		);
	}
	return context;
}

function TeamManagementProvider({ children }) {
	const [state, dispatch] = useReducer(TeamManagementReducer, initialState);
	return (
		<TeamManagementReducerContext.Provider value={state}>
			<TeamManagementReducerDispatchContext.Provider value={dispatch}>
				{children}
			</TeamManagementReducerDispatchContext.Provider>
		</TeamManagementReducerContext.Provider>
	);
}

export {
	TeamManagementProvider,
	useTeamManagementState,
	useTeamManagementDispatch,
};
