import React from "react";
import emailImg from "assets/images/mailbox.svg";
import Locale from "translations";
import { Spinner } from "reactstrap";
import { useAuthState, useAuthDispatch } from "context/auth";

export default function Confirmation(props) {
	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	const { confirmation, commons, supplierDetails, services, login } = Locale;
	const authState = useAuthState();
	const dispatch = useAuthDispatch();

	// Back Button
	const back = () => {
		dispatch({ type: "Progress", progress: 50, step: 2 });
	};
	// ----------------------------------------------------------------------------------------------------
	// Component Functions
	// ----------------------------------------------------------------------------------------------------
	return (
		<div className="confirmation">
			<h1 className="text-center text-title text-dark-blue">
				{confirmation.title}
			</h1>

			<div className="bg-white px-3 radius-10 mt-3 pb-3">
				<div className="row">
					<div className="col-12 p-3">
						<h1 className="text-subtitle font-medium">
							{confirmation.subtitle1}
						</h1>
						<p className="font-light">{confirmation.details}</p>
					</div>
				</div>
			</div>
			<div className="bg-white px-3 radius-10 mt-3 pb-3">
				<div className="row">
					<div className="col-12 p-3">
						<h1 className="text-subtitle font-medium d-flex justify-content-between">
							<span>{supplierDetails.title}</span>
							<button
								id="editSupplierDetails"
								className="btn-link"
								onClick={() => {
									dispatch({ type: "Progress", progress: 0, step: 1 });
								}}
							>
								{confirmation.editSupplierDetails}
							</button>
						</h1>
					</div>
					<div className="col-6 px-3 py-2">{supplierDetails.name}</div>
					<div className="col-6 px-3 py-2">
						{authState.supplierDetails.name}
					</div>
					<div className="col-6 px-3 py-2">{supplierDetails.category}</div>
					<div className="col-6 px-3 py-2">
						{authState.supplierDetails.category.label}
					</div>
					<div className="col-6 px-3 py-2">{supplierDetails.owner}</div>
					<div className="col-6 px-3 py-2">
						{authState.supplierDetails.owner}
					</div>
					<div className="col-6 px-3 py-2">{login.email}</div>
					<div className="col-6 px-3 py-2">
						{authState.supplierDetails.email}
					</div>
					<div className="col-6 px-3 py-2">{supplierDetails.country}</div>
					<div className="col-6 px-3 py-2">
						{authState.supplierDetails.country.label}
					</div>
					<div className="col-6 px-3 py-2">{supplierDetails.phoneNumber}</div>
					<div className="col-6 px-3 py-2">
						{authState.supplierDetails.phone}
					</div>
				</div>
			</div>
			<div className="bg-white px-3 radius-10 mt-3 pb-3">
				<div className="row">
					<div className="col-12 p-3">
						<h1 className="text-subtitle font-medium d-flex justify-content-between">
							{services.title}
							<button
								id="editServices"
								className="btn-link"
								onClick={() => {
									dispatch({ type: "Progress", progress: 50, step: 2 });
								}}
							>
								{confirmation.editServices}
							</button>
						</h1>
					</div>
					{authState.services.map((service, i) => {
						return (
							<div key={service.name} className="row m-0 w-100">
								<div className="col-6 px-3 py-2">{service.name}</div>
								<div className="col-6 px-3 py-2">
									<i className="fas fa-check"></i>
								</div>
							</div>
						);
					})}
				</div>
			</div>

			<div className="row mt-3 m-0">
				<div className="col-md-2 offset-md-6">
					<button
						id="confirmation-back"
						className="btn btn-secondary w-100 text-white opacity-30"
						onClick={back}
						disabled={props.isDisabled}
					>
						{commons.back}
					</button>
				</div>
				<div className="col-md-4 p-0">
					<button
						id="confirmation-mainAction"
						className="btn btn-primary w-100"
						onClick={props.mainAction}
						disabled={props.isDisabled}
					>
						{props.isDisabled ? <Spinner color="light" size="sm" /> : null}
						{"  "}
						{commons.register}
					</button>
				</div>
			</div>
		</div>
	);
}
