import React from "react";
import Locale from "translations";
import Logo from "assets/images/safa_white_logo.svg";
import Coverimage from "assets/images/login_image.jpg";

export default function LoginBanner(props) {
	const { loginBanner } = Locale;

	return (
		<div
			className="login-banner text-center"
			style={{ background: `url(${Coverimage})` }}
		>
			<img className="login-banner__logo my-5" src={Logo} alt="logo" />
			<div className="text-light text-title">
				<h4 className="text-light">{loginBanner.title1}</h4>
				<h4 className="mt-2 text-light">{loginBanner.title2}</h4>
			</div>
		</div>
	);
}
