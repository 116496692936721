import React, { createContext, useContext, useReducer } from "react";
const HotelLayoutContext = createContext();
const HotelLayoutDispatchContext = createContext();
const initialState = {};

function HotelLayoutReducer(state, action) {
	switch (action.type) {
		case "dashboardState": {
			return { ...state, dashboardState: action.payload };
		}

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function useHotelLayoutState() {
	const context = useContext(HotelLayoutContext);
	if (context === undefined) {
		throw new Error(
			"useHotelLayoutState must be used within a HotelLayoutProvider"
		);
	}
	return context;
}

function useHotelLayoutDispatch() {
	const context = useContext(HotelLayoutDispatchContext);
	if (context === undefined) {
		throw new Error(
			"useHotelLayoutDispatch must be used within a HotelLayoutProvider"
		);
	}
	return context;
}

function HotelLayoutProvider({ children }) {
	const [state, dispatch] = useReducer(HotelLayoutReducer, initialState);
	return (
		<HotelLayoutContext.Provider value={state}>
			<HotelLayoutDispatchContext.Provider value={dispatch}>
				{children}
			</HotelLayoutDispatchContext.Provider>
		</HotelLayoutContext.Provider>
	);
}

export { HotelLayoutProvider, useHotelLayoutState, useHotelLayoutDispatch };
