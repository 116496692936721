const locale = localStorage.getItem("currentLocale") || "en";

const formatArray = (array) => {
	return array.map((item) => (item = { label: item.name, value: item.id }));
};

export function formatPostBasicInfo(info) {
	const {
		name,
		rating,
		description,
		country,
		city,
		area,
		address,
		postalCode,
		phone,
		secondPhone,
		email,
		officialEmail,
		latitude,
		longitude,
		distance,
		id,
	} = info;
	return {
		name,
		umrah_hotel_id: id,
		rate: rating.value,
		description,
		country_id: country.value,
		area: area,
		address,
		post_code: postalCode,
		primary_phone_number: `+${country.value}${phone}`,
		second_phone_number:
			secondPhone === "" || secondPhone === null
				? ""
				: `+${country.value}${secondPhone}`,
		city_id: city.value,
		reservation_email: email,
		official_email: officialEmail,
		latitude,
		longitude,
		lang_key: locale,
	};
}

export function formatGetBasicInfo(info) {
	const {
		name,
		description,
		location,
		primary_phone_number,
		second_phone_number,
		reservation_email,
		official_email,
		distance_from_haram,
	} = info.property;

	return {
		name,
		rating: { value: info.rate, label: info.rate },
		description,
		country: {
			value: location.country.id,
			label: location.country.names[locale],
		},
		city: { value: location.city.id, label: location.city.names[locale] },
		area: location.area_text,
		address: location.address,
		postalCode: location.post_code,
		phone: primary_phone_number,
		secondPhone: second_phone_number,
		email: reservation_email,
		officialEmail: official_email,
		latitude: location.latitude,
		longitude: location.longitude,
		lang: locale,
		distance: distance_from_haram,
	};
}

export function formatPostServices(service) {
	const {
		parkingAvailable,
		parkingType,
		parkingSite,
		parkingReservation,
		parkingPrice,
		languages,
		views,
		facilities,
	} = service;

	return {
		parking_availabity: parkingAvailable.value,
		parking_type: parkingType.value,
		parking_site: parkingSite.value,
		reservation_parking_space: parkingReservation.value,
		price: parkingPrice == "" ? null : parkingPrice,
		languages: languages.map((language) => (language = language.value)),
		views,
		facilities,
	};
}

export function formatGetServices(hotel) {
	const {
		parking_availabity,
		parking_type,
		parking_site,
		reservation_parking_space,
		price,
	} = hotel.service;
	const { languages, views, facilities } = hotel;
	return {
		parkingAvailable: { label: parking_availabity, value: parking_availabity },
		parkingType: { label: parking_type, value: parking_type },
		parkingSite: { label: parking_site, value: parking_site },
		parkingReservation: {
			label: reservation_parking_space,
			value: reservation_parking_space,
		},
		parkingPrice: price,
		languages: formatArray(languages),
		views: views.map((view) => (view = view.id)),
		facilities: facilities.map((facility) => (facility = facility.id)),
	};
}

export function formatPostRooms(rooms) {
	return rooms.map(
		(room) =>
			(room = {
				room_type_id: room.type.value,
				room_name_id: room.name.value,
				room_view_id: room.view.value,
				smooking_type: room.smokingPolicy.value,
				max_adults_with_children: room.max_adults_with_children,
				max_children: room.max_children,
				max_adults: room.max_adults,
				count: room.count,
				custom_name: room.customName,
				id: room.id,
			})
	);
}

export function formatGetRooms(rooms) {
	return rooms.map(
		(room) =>
			(room = {
				type: { value: room.type.id, label: room.type.name },
				name: { value: room?.name?.id, label: room?.name?.name },
				view: { value: room.view.id, label: room.view.name },
				smokingPolicy: {
					value: room.smoking_policy,
					label: room.smoking_policy,
				},
				max_adults_with_children: room.max_adults_with_children,
				max_children: room.max_children,
				max_adults: room.max_adults,
				count: room.count,
				customName: room.custom_name,
				id: room.id,
			})
	);
}

export function formatPostAmenities(amenities) {
	return amenities.map((amenity) => {
		if ("rooms" in amenity) {
			return (amenity = {
				amenity_id: amenity.id,
				rooms: amenity.rooms?.map((room) => (room = room.value)),
			});
		} else {
			return (amenity = {
				amenity_id: amenity.id,
			});
		}
	});
}

export function formatGetAmenities(amenities) {
	return amenities.map((amenity) => {
		if ("rooms" in amenity) {
			return (amenity = {
				id: amenity.id,
				is_has_all_rooms: amenity.is_has_all_rooms,
				rooms: amenity.rooms.map(
					(room) => (room = { value: room.id, label: room.name })
				),
			});
		} else {
			return (amenity = {
				id: amenity.id,
			});
		}
	});
}

export function formatPostPhotos(photos) {
	const formData = new FormData();
	for (let i = 0; i < photos.length; i++) {
		formData.append("photos[0]", photos[i]);
	}
	return formData;
}

export function formatGetPhotos(photos) {
	return;
}

export function formatPostPolicies(policies, taxes) {
	const {
		checkInFrom,
		checkInTo,
		checkOutFrom,
		checkOutTo,
		petsCharges,
		haveChildren,
		petsAllowed,
		tax_type,
		tax,
		currencies,
	} = policies;

	return {
		currencies:
			currencies && currencies.length > 0
				? currencies.map((res) => res.value)
				: [],
		check_in_from: checkInFrom,
		tax_type: tax_type == 1 ? "exclude" : "include",
		tax_name: tax,
		check_in_to: checkInTo,
		check_out_from: checkOutFrom,
		check_out_to: checkOutTo,
		pets_allowed: petsAllowed.value === "yes" ? 1 : 0,
		pets_charges: petsCharges.value,
		have_childrens: haveChildren === "yes" ? 1 : 0,
		taxes:
			tax_type == 2
				? []
				: taxes.map(
						(tax) =>
							(tax = {
								name: tax.name,
								rate: tax.rate,
								type: tax.type?.value,
								rates: tax.rates && tax.rates.length > 0 ? tax.rates : [],
							})
				  ),
	};
}

export function formatGetPolicies(policies, taxes, data) {
	const {
		check_in_from,
		check_in_to,
		check_out_from,
		check_out_to,
		pets_allowed,
		pets_charges,
		have_childrens,
		currencies,
	} = policies;

	return {
		checkInFrom: check_in_from,
		checkInTo: check_in_to,
		tax_type: taxes.length > 0 ? 1 : 0,
		checkOutFrom: check_out_from,
		checkOutTo: check_out_to,
		taxes: taxes.map(
			(tax) =>
				(tax = {
					name: tax.name,
					rate: tax.rate,
					type: { value: tax.type, label: tax.type },
					rates: tax.rates && tax.rates.length > 0 ? tax.rates : [],
				})
		),
		currencies:
			data.property.currencies && data.property.currencies.length > 0
				? data.property.currencies.map((res) => {
						return {
							...res,
							value: res.currency_code,
							label: res.currency_code,
							name: res.currency_code,
							id: res.currency_code,
						};
				  })
				: [],
		petsCharges: {
			label: pets_charges === "paid" ? "Paid" : "Free",
			value: pets_charges,
		},
		haveChildren: have_childrens,
		petsAllowed: {
			label: pets_allowed === 0 ? "Yes" : "No",
			value: pets_allowed,
		},
	};
}
