import SafaLogo from "assets/images/safa_logo.png";
import avatar from "assets/imagesLayout/users/avatar-1.jpg";
import { useGlobalDispatch } from "context/global";

import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import UserSettings from "components/UserSetting";
import { useGlobalState } from "context/global";
// import Dashboard from "modules/dashboard/Dashboard/Dashboard";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import NotificationsHeader from "./Notifications/NavIcon";
import SubNav from "./SubNav";
import { getPermissions } from "services/auth";
import ShowForPermission from "helpers/showForPermission";

export default function Header(props) {
	const { show } = props;
	const history = useHistory();
	const dispatch = useGlobalDispatch();
	const data = useGlobalState();
	useEffect(() => {
		async function fetchPermissions() {
			const res = await getPermissions();
			dispatch({ type: "getPermissions", payload: res?.data });
		}
		fetchPermissions();
	}, []);
	console.log(data, "data");
	return (
		<>
			{/* <div
				className="  shadow-sm d-flex justify-content-between"
				style={{ height: "60px", background: "#fbfaff" }}
			>
				<div>
					<Link
						className="navbar-brand"
						onClick={() => {
							show && setShow(false);
							history.push("/");
						}}
						// to="/"
					>
						<img className="img-fluid" src={SafaLogo} alt="Logo" width="70%" />
					</Link>
				</div>
				<SubNav />
				<div className="dropdowns d-flex align-items-center p-3">
					<NotificationsHeader />
					{isAuth ? <UserSettings /> : null}
				</div>
			</div> */}
			<header id="page-topbar">
				<div class="navbar-header">
					<div class="d-flex">
						<div
							class={`navbar-brand-box ${show ? "" : "navbar-brand-box-width"}`}
						>
							<a class="logo logo-dark">
								<span class={`logo-sm  ${show ? "hide-logo" : "show-logo"}`}>
									safa
								</span>
								<span class={`logo-lg  ${show ? "show-logo" : "hide-logo"}`}>
									<img src={SafaLogo} alt="Logo" height="24" />
								</span>
							</a>

							<a class="logo logo-light">
								{/* <img src={SafaLogo} width="100%" alt="Logo" height="24" /> */}
								<span class={`logo-sm  ${show ? "hide-logo" : "show-logo"}`}>
									safa
								</span>
								<span class={`logo-lg  ${show ? "show-logo" : "hide-logo"}`}>
									<img src={SafaLogo} alt="Logo" height="24" />{" "}
								</span>
							</a>
						</div>

						<button
							type="button"
							class={`btn btn-sm px-3
                            font-size-16 header-item
														${show ? "" : "vertical-menu-btn-hide"}`}
							id="vertical-menu-btn"
							onClick={props.toggleSideBar}
						>
							<i class="fa fa-fw fa-bars"></i>
						</button>
					</div>

					<div class="d-flex">
						{/* <div class="dropdown d-none d-sm-inline-block">
							<button
								type="button"
								class="btn header-item"
								id="mode-setting-btn"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
									class="feather feather-moon icon-lg layout-mode-dark"
								>
									<path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
								</svg>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
									class="feather feather-sun icon-lg layout-mode-light"
								>
									<circle cx="12" cy="12" r="5"></circle>
									<line x1="12" y1="1" x2="12" y2="3"></line>
									<line x1="12" y1="21" x2="12" y2="23"></line>
									<line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
									<line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
									<line x1="1" y1="12" x2="3" y2="12"></line>
									<line x1="21" y1="12" x2="23" y2="12"></line>
									<line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
									<line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
								</svg>
							</button>
						</div> */}

						{/* <div class="dropdown d-none d-lg-inline-block ms-1">
							<button
								type="button"
								class="btn header-item"
								data-bs-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
									class="feather feather-grid icon-lg"
								>
									<rect x="3" y="3" width="7" height="7"></rect>
									<rect x="14" y="3" width="7" height="7"></rect>
									<rect x="14" y="14" width="7" height="7"></rect>
									<rect x="3" y="14" width="7" height="7"></rect>
								</svg>
							</button>
							<div
								class="dropdown-menu dropdown-menu-lg
                                dropdown-menu-end"
							>
								<div class="p-2">
									<div class="row g-0">
										<div class="col">
											<a class="dropdown-icon-item" href="#">
												<img
													src="assets/images/brands/github.png"
													alt="Github"
												/>
												<span>GitHub</span>
											</a>
										</div>
										<div class="col">
											<a class="dropdown-icon-item" href="#">
												<img
													src="assets/images/brands/bitbucket.png"
													alt="bitbucket"
												/>
												<span>Bitbucket</span>
											</a>
										</div>
									
									</div>

									<div class="row g-0">
										<div class="col">
											<a class="dropdown-icon-item" href="#">
												<img
													src="assets/images/brands/dropbox.png"
													alt="dropbox"
												/>
												<span>Dropbox</span>
											</a>
										</div>
										<div class="col">
											<a class="dropdown-icon-item" href="#">
												<img
													src="assets/images/brands/mail_chimp.png"
													alt="mail_chimp"
												/>
												<span>Mail Chimp</span>
											</a>
										</div>
										<div class="col">
											<a class="dropdown-icon-item" href="#">
												<img src="assets/images/brands/slack.png" alt="slack" />
												<span>Slack</span>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div> */}

						<div class="dropdown d-inline-block">
							<button
								type="button"
								class="btn header-item
                                noti-icon position-relative"
								id="page-header-notifications-dropdown"
								data-bs-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
									class="feather feather-bell icon-lg"
								>
									<path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
									<path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
								</svg>
								{/* <span class="badge bg-danger rounded-pill">5</span> */}
							</button>
							<div
								class="dropdown-menu dropdown-menu-lg
                                dropdown-menu-end p-0"
								aria-labelledby="page-header-notifications-dropdown"
							>
								<div class="p-3">
									<div class="row align-items-center">
										<div class="col">
											<h6 class="m-0"> Notifications </h6>
										</div>
										<div class="col-auto">
											{/* <a
												href="#!"
												class="small text-reset
                                                text-decoration-underline"
											>
												Unread (3)
											</a> */}
										</div>
									</div>
								</div>
								<div data-simplebar="init">
									<div class="simplebar-wrapper">
										<div class="simplebar-height-auto-observer-wrapper">
											<div class="simplebar-height-auto-observer"></div>
										</div>
										<div class="simplebar-mask">
											<div class="simplebar-offset">
												<div class="simplebar-content-wrapper">
													{/* <div class="simplebar-content">
														<a
															href="#!"
															class="text-reset
                                        notification-item"
														>
															<div class="d-flex">
																<div class="flex-shrink-0 me-3">
																	<img
																		src="assets/images/users/avatar-3.jpg"
																		class="rounded-circle
                                                    avatar-sm"
																		alt="user-pic"
																	/>
																</div>
																<div class="flex-grow-1">
																	<h6 class="mb-1">James Lemire</h6>
																	<div
																		class="font-size-13
                                                    text-muted"
																	>
																		<p class="mb-1">
																			It will seem like simplified English.
																		</p>
																		<p class="mb-0">
																			<i
																				class="mdi
                                                            mdi-clock-outline"
																			></i>
																			<span>1 hours ago</span>
																		</p>
																	</div>
																</div>
															</div>
														</a>
														<a
															href="#!"
															class="text-reset
                                        notification-item"
														>
															<div class="d-flex">
																<div
																	class="flex-shrink-0 avatar-sm
                                                me-3"
																>
																	<span
																		class="avatar-title
                                                    bg-primary rounded-circle
                                                    font-size-16"
																	>
																		<i class="bx bx-cart"></i>
																	</span>
																</div>
																<div class="flex-grow-1">
																	<h6 class="mb-1">Your order is placed</h6>
																	<div
																		class="font-size-13
                                                    text-muted"
																	>
																		<p class="mb-1">
																			If several languages coalesce the grammar
																		</p>
																		<p class="mb-0">
																			<i
																				class="mdi
                                                            mdi-clock-outline"
																			></i>
																			<span>3 min ago</span>
																		</p>
																	</div>
																</div>
															</div>
														</a>
														<a
															href="#!"
															class="text-reset
                                        notification-item"
														>
															<div class="d-flex">
																<div
																	class="flex-shrink-0 avatar-sm
                                                me-3"
																>
																	<span
																		class="avatar-title
                                                    bg-success rounded-circle
                                                    font-size-16"
																	>
																		<i
																			class="bx
                                                        bx-badge-check"
																		></i>
																	</span>
																</div>
																<div class="flex-grow-1">
																	<h6 class="mb-1">Your item is shipped</h6>
																	<div
																		class="font-size-13
                                                    text-muted"
																	>
																		<p class="mb-1">
																			If several languages coalesce the grammar
																		</p>
																		<p class="mb-0">
																			<i
																				class="mdi
                                                            mdi-clock-outline"
																			></i>
																			<span>3 min ago</span>
																		</p>
																	</div>
																</div>
															</div>
														</a>

														<a
															href="#!"
															class="text-reset
                                        notification-item"
														>
															<div class="d-flex">
																<div class="flex-shrink-0 me-3">
																	<img
																		src="assets/images/users/avatar-6.jpg"
																		class="rounded-circle
                                                    avatar-sm"
																		alt="user-pic"
																	/>
																</div>
																<div class="flex-grow-1">
																	<h6 class="mb-1">Salena Layfield</h6>
																	<div
																		class="font-size-13
                                                    text-muted"
																	>
																		<p class="mb-1">
																			As a skeptical Cambridge friend of mine
																			occidental.
																		</p>
																		<p class="mb-0">
																			<i
																				class="mdi
                                                            mdi-clock-outline"
																			></i>
																			<span>1 hours ago</span>
																		</p>
																	</div>
																</div>
															</div>
														</a>
													</div> */}
												</div>
											</div>
										</div>
										<div class="simplebar-placeholder"></div>
									</div>
									{/* <div class="simplebar-track simplebar-horizontal">
										<div class="simplebar-scrollbar"></div>
									</div> */}
									{/* <div class="simplebar-track simplebar-vertical">
										<div class="simplebar-scrollbar"></div>
									</div> */}
								</div>
								{/* <div class="p-2 border-top d-grid">
									<a
										class="btn btn-sm btn-link font-size-14
                                        text-center"
										href="javascript:void(0)"
									>
										<i
											class="mdi mdi-arrow-right-circle
                                            me-1"
										></i>{" "}
										<span>View More..</span>
									</a>
								</div> */}
							</div>
						</div>

						{/* <div class="dropdown d-inline-block">
							<button
								type="button"
								class="btn header-item
                                right-bar-toggle me-2"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
									class="feather feather-settings icon-lg"
								>
									<circle cx="12" cy="12" r="3"></circle>
									<path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
								</svg>
							</button>
						</div> */}

						<div class="dropdown d-inline-block">
							<button
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
								type="button"
								class="btn header-item
                                bg-soft-light border-start border-end"
								id="page-header-user-dropdown"
								data-bs-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								{/* <img
									class="rounded-circle header-profile-user"
									src={avatar}
									alt="Header Avatar"
								/> */}
								<span
									style={{
										width: "30px",
										height: "30px",
										background: "gray",
										borderRadius: "19px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										color: "#fff",
									}}
								>
									{data.supplierName?.[0]}
								</span>
								<span className="d-inline-block">
									<span
										class=" d-xl-inline-block ms-1
                                    fw-medium"
									>
										{data.supplierName}
									</span>
									<span
										class=" d-xl-block ms-1
                                    "
									>
										{data.userName}
									</span>
								</span>
								<i
									class="mdi mdi-chevron-down d-none
                                    d-xl-inline-block"
								></i>
							</button>
							<div class="dropdown-menu dropdown-menu-end">
								{/* <a class="dropdown-item" href="#">
									<i
										class="mdi
                                        mdi-account-settings font-size-16
                                        align-middle me-1"
									></i>{" "}
									Profile
								</a> */}
								{/* <a class="dropdown-item" href="#">
									<i
										class="mdi
                                        mdi-credit-card-outline font-size-16
                                        align-middle me-1"
									></i>{" "}
									Billing
								</a> */}
								<Link to="/company-profile">
									<a class="dropdown-item" href="#">
										<i
											class="mdi
											mdi-account font-size-16
                                        align-middle me-1"
										></i>{" "}
										Profile
									</a>
								</Link>
								{/* <a class="dropdown-item" href="#">
									<i
										class="mdi
                                        mdi-lock font-size-16 align-middle
                                        me-1"
									></i>{" "}
									Lock screen
								</a> */}
								<div class="dropdown-divider"></div>
								<a
									onClick={() => {
										dispatch({ type: "logout" });
										history.push("/login");
									}}
									class="dropdown-item"
									href="#"
								>
									<i
										class="mdi
                                        mdi-logout font-size-16 align-middle
                                        me-1"
									></i>{" "}
									Logout
								</a>
							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	);
}
