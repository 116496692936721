import { useGlobalState } from "context/global";
import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { fetchHotels, fetchUmrahHotels } from "services/lookups";

export default function AsyncSelectField(props) {
	const { locale } = useGlobalState();

	const [Cities, setCities] = useState([]);

	const customStyles = {
		menu: (provided, state) => ({
			...provided,
			width: "95%",
			zIndex: 999999999,
		}),
		control: (e, state) => {
			return {
				...e,
				border: "none",
				color: state.isFocused ? "blue" : "red",
				boxShadow: "none",
				"&:hover": { border: "none" },
			};
		},
		container: (e) => {
			return {
				...e,
				top: "-1px;",
			};
		},
		valueContainer: (e) => {
			return {
				...e,
				top: "-5px;",
			};
		},
		hove: (e) => ({
			...e,
			border: "none",
		}),
		focus: (e) => ({
			...e,
			border: "none",
		}),
	};

	const filter = async (inputValue) => {
		let result = [];
		if (inputValue && inputValue.length > 2) {
			const countries = await fetchHotels(inputValue);

			result = countries.data.data.map((item) => {
				return {
					...item,
					id: item.hotel_id,
					name: item.name,
					value: item.hotel_id,
					label: item.name,
				};
			});
		}
		return result;
	};

	const promiseOptions = (inputValue) =>
		new Promise((resolve) => {
			setTimeout(() => {
				resolve(filter(inputValue));
			}, 1000);
		});

	return (
		<div className="my-2 input-group control-field + control-field__body">
			{props.hasLabel ? (
				<label
					className={`control-field__label ${
						props.color ? " control-field__label--" + props.color : ""
					}`}
				>
					{props.label}
				</label>
			) : null}
			<AsyncSelect
				className="form-control  form-control-custom-input border-0"
				styles={customStyles}
				cacheOptions
				defaultOptions={Cities}
				loadOptions={promiseOptions}
				onChange={props.onChange}
				value={props.value}
				placeholder={props.placeholder}
				onFocus={false}
			/>
		</div>
	);
}
