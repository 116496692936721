import Model from "components/model";
import { useGlobalDispatch } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { listProperties } from "services/property";
import Locale from "translations";
import "./style.scss";
const locale = localStorage.getItem("currentLocale") || "en";

function Home() {
	const dispatch = useGlobalDispatch();
	const history = useHistory();
	const inprogressTab = history.location.pathname.includes("inprogress");
	const verifyingTab = history.location.pathname.includes("verifying");
	const activeTab = history.location.pathname.includes("active");

	const [modalShow, setModalShow] = useState(false);
	const [rejectedData, setRejectedData] = useState({
		id: null,
		rejectedResone: null,
	});
	const toggleModalShow = (id) => {
		//setSeletedId(id);
		setModalShow(!modalShow);
	};

	const { home, reservations } = Locale;
	const [done, setDone] = useState(false);
	const [properties, setProperties] = useState({
		inProgress: [],
		completed: [],
		active: [],
	});

	useEffect(() => {
		if (
			properties.inProgress.length === 0 &&
			properties.completed.length === 0 &&
			properties.active.length === 0 &&
			!done
		) {
			async function fetchData() {
				const res = await listProperties();
				if (res.status === 200) {
					setProperties({
						...properties,
						inProgress: res.data.in_progress,
						completed: res.data.completed,
						active: res.data.active,
					});
				}
			}
			setDone(true);
			fetchData();
		}
	}, [properties]);

	const addProperty = () => {
		dispatch({ type: "progress", progress: 0, step: "1" });
		history.push("/property");
	};

	const calculateStep = (progress) => {
		switch (Math.floor(progress)) {
			case 16:
				return "2";
			case 33:
				return "3";
			case 50:
				return "4";
			case 66:
				return "5";
			case 83:
				return "6";
			default:
				return "1";
		}
	};

	const goToPropertyStep = (property) => {
		dispatch({
			type: "progress",
			progress: Math.floor(property.registeration_progress),
			step: calculateStep(property.registeration_progress),
		});
		// dispatch({ type: "mode", mode: "edit" });
		history.push({
			pathname: `/property/${property.id}`,
			state: { id: property.id },
		});
	};
	return (
		<>
			<div className="container-fluid home mb-5">
				{inprogressTab ? (
					<>
						<h2 className="mt-5 mb-2 text-capitalize">
							{home.header1} ({properties.inProgress.length})
						</h2>
						<div class="card-body p-0">
							<div class="table-responsive">
								<table className="table mb-0 f-15">
									<thead className="table-light">
										<tr className="">
											<th scope="col">{home.hotelName}</th>
											<th scope="col">{home.country}</th>
											<th scope="col">{home.city}</th>
											<th scope="col">{home.area}</th>
											<th scope="col">{home.createdAt}</th>
											<th scope="col">{home.registrationProgress}</th>
											<th scope="col">{home.status}</th>
											<th scope="col">{home.action}</th>
										</tr>
									</thead>
									<div className="my-2">
										<ShowForPermission permission="manage-property">
											<button
												onClick={addProperty}
												// to="/property"
												className="btn  btn-Primary add"
											>
												{home.addProperty}
											</button>
										</ShowForPermission>
									</div>
									<tbody>
										{properties.inProgress.length > 0 ? (
											properties.inProgress.map((property) => {
												return (
													<tr className=" text-black" key={property.id}>
														<td>{property.property_name}</td>
														<td>{property.country[locale]}</td>
														<td>{property.city[locale]}</td>
														<td>{property.area[locale]}</td>
														<td>{property.created_at}</td>
														<td>
															<div className="progress-wrapper d-flex">
																<div className="progress">
																	<div
																		className="progress-bar bg-danger"
																		role="progressbar"
																		style={{
																			width:
																				`${
																					property.registeration_progress / 100
																				}` *
																					100 +
																				`%`,
																		}}
																	></div>
																</div>
																<span>
																	{Math.floor(property.registeration_progress)}%
																</span>
															</div>
														</td>
														<th className="text-danger" scope="col">
															{property.status}
														</th>
														<td>
															<button
																className="btn-link"
																onClick={() => goToPropertyStep(property)}
															>
																{home.continue}
															</button>
														</td>
													</tr>
												);
											})
										) : (
											<tr>
												<td colSpan={8}>
													<div className="table-empty text-gray text-center py-3">
														<i className="fas fa-info-circle fa-lg"></i>
														{reservations.noResult}
													</div>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</div>
					</>
				) : verifyingTab ? (
					// properties.completed.length > 0 && (
					<>
						{" "}
						<h2 className="mt-5 mb-2 text-capitalize">
							{home.header2} ({properties.completed.length})
						</h2>
						<div class="card-body p-0">
							<div class="table-responsive">
								<table className="table mb-0 f-15">
									<thead className="table-light">
										<tr className="">
											<th scope="col">{home.hotelName}</th>
											<th scope="col">{home.country}</th>
											<th scope="col">{home.city}</th>
											<th scope="col">{home.area}</th>
											<th scope="col">{home.createdAt}</th>
											<th scope="col">{home.registrationProgress}</th>
											<th scope="col">{home.status}</th>

											<th scope="col">{home.property_status}</th>
											<th scope="col">{home.contract_status}</th>
											<th scope="col"></th>
										</tr>
									</thead>
									<tbody>
										{properties.completed.length > 0 ? (
											properties.completed.map((property) => {
												return (
													<tr className=" text-black" key={property.id}>
														<td>{property.property_name}</td>
														<td>{property.country[locale]}</td>
														<td>{property.city[locale]}</td>
														<td>{property.area[locale]}</td>
														<td>{property.created_at}</td>
														<td>
															<div className="progress-wrapper d-flex">
																<div className="progress">
																	<div
																		className="progress-bar bg-success"
																		role="progressbar"
																		style={{
																			width: "100%",
																			// `${property.registeration_progress / 100}` *
																			// 	100 +
																			// `%`
																		}}
																	></div>
																</div>
																<span>
																	{/* {Math.floor(property.registeration_progress)} */}
																	100%
																</span>
															</div>
														</td>
														<th style={{ color: "#e5ae78" }} scope="col">
															{property.status}
														</th>
														{/************property status*************** */}

														<td>
															{property.status === "Rejected" ? (
																<>
																	<span className="span-rej">
																		{" "}
																		{property.status}
																		<i
																			class=" fas fa-question-circle mx-1"
																			style={{
																				fontSize: "20px",
																				cursor: "pointer",
																			}}
																			onClick={() => {
																				setModalShow(!modalShow);
																				setRejectedData({
																					id: property.id,
																					rejectedResone:
																						property.rejection_reason,
																				});
																			}}
																		></i>
																	</span>
																	<br />
																</>
															) : (
																""
															)}
															{property.status === "Completed" ||
															property.status === "Rejected" ? (
																property.status === "Rejected" ? (
																	// <Link
																	// 	className="btn-link"
																	// 	to={`/verify-hotel/${property.id}`}
																	// >
																	// 	{home.askToVerify}
																	// </Link>
																	<button
																		className="btn btn-link"
																		onClick={() => goToPropertyStep(property)}
																	>
																		{"Ask to verify my hotel again"}
																	</button>
																) : property.contract_status !== "Rejected" &&
																  property.status === "Completed" ? (
																	<ShowForPermission permission="manage-property">
																		{" "}
																		<Link
																			className="btn-link"
																			//to={`/verify-hotel/${property.id}`}
																			to={{
																				pathname: `/verify-hotel/${property.id}`,
																				state: { canEdit: false },
																			}}
																		>
																			{home.askToVerify}
																		</Link>
																	</ShowForPermission>
																) : (
																	"Completed"
																)
															) : (
																<p className="d-inline ">pending</p>
															)}
														</td>
														{/************contract status*************** */}
														<td
															className={`${
																property.contract_status.toLowerCase() ==
																"pending"
																	? "text-warning"
																	: "text-success"
															}`}
														>
															{property.contract_status === "Rejected" ? ( //contract_status
																<>
																	<span className="span-rej">
																		{" "}
																		{property.contract_status}
																		<i
																			class=" fas fa-question-circle mx-1"
																			style={{
																				fontSize: "20px",
																				cursor: "pointer",
																			}}
																			onClick={() => {
																				setModalShow(!modalShow);
																				setRejectedData({
																					id: property.id,
																					rejectedResone:
																						property.contract_rejection_reason,
																				});
																			}}
																		></i>
																	</span>
																	<br />
																</>
															) : (
																""
															)}
															{property.contract_status === "Completed" ||
															property.contract_status === "Rejected" ? (
																property.contract_status === "Rejected" ? (
																	<Link
																		className="btn-link"
																		//to={`/verify-hotel/${property.id}`}
																		to={{
																			pathname: `/verify-hotel/${property.id}`,
																			state: { canEdit: true },
																		}}
																	>
																		{/* {home.askToVerify} */}
																		{home.sendContractAgain}
																	</Link>
																) : (
																	// <Link
																	// 	className="btn-link"
																	// 	//to={`/verify-hotel/${property.id}`}
																	// 	to={{
																	// 		pathname: `/verify-hotel/${property.id}`,
																	// 		state: { canEdit: false },
																	// 	}}
																	// >
																	// 	{home.askToVerify}
																	// </Link>
																	""
																)
															) : property.contract_status.toLowerCase() ==
															  "pending" ? (
																"pending"
															) : (
																property.contract_status
															)}
														</td>

														<td>
															<ShowForPermission permission="manage-property">
																<button
																	className="btn-link mx-2"
																	onClick={() => goToPropertyStep(property)}
																>
																	<i class="fas fa-edit"></i> Edit
																</button>
															</ShowForPermission>
														</td>
													</tr>
												);
											})
										) : (
											<tr>
												<td colSpan={9}>
													<div className="table-empty text-gray text-center py-3">
														<i className="fas fa-info-circle fa-lg"></i>
														{reservations.noResult}
													</div>
												</td>
											</tr>
										)}
									</tbody>
								</table>{" "}
							</div>
						</div>
					</>
				) : (
					// )
					// properties.active.length > 0 && (
					<>
						{" "}
						<h2 className="mt-5 mb-2 text-capitalize">
							{home.activeProperties} ({properties.active.length})
						</h2>
						<div class="card-body p-0">
							<div class="table-responsive">
								<table className="table mb-0 f-15">
									<thead className="table-light">
										<tr className="">
											<th scope="col">{home.hotelName}</th>
											<th scope="col">{home.country}</th>
											<th scope="col">{home.city}</th>
											<th scope="col">{home.area}</th>
											<th scope="col">{home.createdAt}</th>
											<th scope="col">{home.status}</th>
											<th scope="col">{home.sharingStatus}</th>
											<th scope="col">{home.action}</th>
										</tr>
									</thead>
									<tbody>
										{properties.active.length > 0 ? (
											properties.active.map((property) => {
												return (
													<tr className=" text-black" key={property.id}>
														<td>{property.property_name}</td>
														<td>{property.country[locale]}</td>
														<td>{property.city[locale]}</td>
														<td>{property.area[locale]}</td>
														<td>{property.created_at}</td>
														<td>
															<b
																className={
																	property.contract_status === "Verified"
																		? `text-primary`
																		: ""
																}
															>
																{property.contract_status}
															</b>
														</td>
														<td
															className={`${
																property.share_status
																	? "text-success "
																	: "text-danger"
															} font-weight-bold  `}
														>
															{property.share_status
																? home.shared
																: home.notShared}
														</td>
														<td>
															{property.registeration_progress < 100 ? (
																<button
																	className="btn-link"
																	onClick={() => goToPropertyStep(property)}
																>
																	Continue Adding Details
																</button>
															) : (
																<>
																	<Link
																		className="btn-link"
																		to={`/dashboard/${property.id}`}
																	>
																		Dashboard
																	</Link>
																	<ShowForPermission permission="manage-property">
																		<button
																			className="btn-link mx-4"
																			onClick={() => goToPropertyStep(property)}
																		>
																			<i class="fas fa-edit"></i> Edit
																		</button>
																	</ShowForPermission>
																</>
															)}
														</td>
													</tr>
												);
											})
										) : (
											<tr>
												<td colSpan={8}>
													<div className="table-empty text-gray text-center py-3">
														<i className="fas fa-info-circle fa-lg"></i>
														{reservations.noResult}
													</div>
												</td>
											</tr>
										)}
									</tbody>
								</table>{" "}
							</div>
						</div>
					</>
					// )
				)}

				{/******  Model Reject Reason ****** */}
				<Model
					isOpen={modalShow}
					title={"Info !"}
					toggle={() => {
						toggleModalShow(null);
					}}
					hasFooter={false}
					className="bulk-edit user-model"
				>
					<div className="py-5  user-model-content container">
						<div
							className="content d-flex align-items-center"
							style={{ height: "70px" }}
						>
							<h5>
								<span style={{ color: "#ff3200" }}>{home.rejectReason}</span> :{" "}
								{rejectedData.rejectedResone}
							</h5>
						</div>

						<div className="submit-btn d-flex justify-content-end footer">
							<button
								className="btn btn-secondary mx-1"
								onClick={() => {
									toggleModalShow(null);
								}}
							>
								{home.cancel}
							</button>
						</div>
					</div>
				</Model>
				{/****** Model ***** */}
			</div>
		</>
	);
}

export default Home;
