import React, { useEffect, useState } from "react";
import Locale from "translations";
import ReservationItem from "./ReservationItem";
import Stepper from "./Stepper";

import FilterReservations from "./FilterReservations";
import { useParams } from "react-router-dom";
import { getListReservations } from "services/reservations";
import moment from "moment";
import Pagination from "components/shared/Pagination";
import { getPropertyServices } from "services/property";
import { useHotelLayoutDispatch } from "context/hotelLayout";

export default function ReservationsList() {
	const { reservations } = Locale;
	const [page, setPage] = useState(null);
	const { id } = useParams();
	// const dispatch = useHotelLayoutDispatch();

	const [reservationsList, setReservationsList] = useState([]);
	const [meta, setMeta] = useState();

	const [searching, setSearching] = useState(false);
	const [filter, setFilter] = useState({
		file_number: "",
		check_in: null,
		check_out: null,
		status: "",
	});

	const search = () => {
		setSearching(!searching);
		setPage(null);
	};

	useEffect(async () => {
		// const data = await getPropertyServices(id);
		const res = await getListReservations(id, {
			...filter,
			check_in: filter.check_in
				? moment(filter.check_in).format("YYYY-MM-DD")
				: null,
			check_out: filter.check_out
				? moment(filter.check_out).format("YYYY-MM-DD")
				: null,
			status: filter?.status?.value ? filter.status.value : null,
			page: page,
		});
		if (res?.data) {
			setReservationsList(res.data);
			setMeta(res.meta);
		}
		// if (data?.status === 200) {
		// 	dispatch({ type: "dashboardState", payload: data.data });
		// }
	}, [searching, page]);

	const goTo = (page) => {
		setPage(page);
	};

	const listReservations =
		reservationsList.length > 0 ? (
			reservationsList?.map((item, index) => {
				return <ReservationItem reservation={item} key={index} />;
			})
		) : (
			<tr>
				<td colSpan="10" className="text-center my-3">
					<div className="product-build__product-no-data">
						<i className="fas fa-info-circle fa-lg"></i>{" "}
						<h4>{reservations.noResult}</h4>
					</div>
				</td>
			</tr>
		);

	return (
		<>
			<div className="dashboard  ">
				<div className="row m-0 p-0 ">
					<div className="col-12 d-flex flex-column align-items-end border mt-2 px-4 py-2 rounded  justify-content-start p-0">
						<div className="w-100">
							<FilterReservations
								search={search}
								filter={filter}
								setFilter={setFilter}
							/>
						</div>
						<div className="w-100">
							<table class="table mb-0 f-13">
								<thead className="table-light">
									<tr className="">
										<th scope="col">{reservations.bookingNumber}</th>
										<th scope="col">{reservations.vendor}</th>
										<th scope="col">{reservations.CheckIn}</th>
										<th scope="col">{reservations.CheckOut}</th>
										<th scope="col">{reservations.rooms}</th>
										<th scope="col">{reservations.bookedOn}</th>
										<th scope="col">{reservations.price}</th>
										<th scope="col">{reservations.status}</th>
										<th scope="col"></th>
									</tr>
								</thead>
								<tbody>{listReservations}</tbody>
							</table>{" "}
						</div>
					</div>
					<div className="col-12"> </div>
					<Pagination info={meta} goTo={goTo} />
				</div>
			</div>
		</>
	);
}
