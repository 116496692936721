import Header from "components/layout/Header";
import React from "react";
import RegistrationProgress from "../components/RegistrationProgress";

const RegistrationLayout = (props) => {
	return (
		<div className="h-100">
			{/* 	<Header /> */}

			<div className="row  align-items-center bg-gray-100 pt-5 py-5" {...props}>
				<div className="m-auto" style={{ maxWidth: "55%" }}>
					<RegistrationProgress className="m-auto" />
					<div className="mt-5">{props.children}</div>
				</div>
			</div>
		</div>
	);
};

export default RegistrationLayout;
