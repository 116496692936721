import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Locale from "translations";

export default function ReservationItem({ reservation }) {
	const { reservations } = Locale;
	const { id } = useParams();

	return (
		<tr className=" text-black">
			<td>{reservation.file_number}</td>
			<td>{reservation.company_name}</td>
			<td>{moment(reservation.check_in).format("DD/MM/YYYY")}</td>
			<td>{moment(reservation.check_out).format("DD/MM/YYYY")}</td>

			<td>{reservation?.rooms?.length}</td>
			<td>{reservation.created_at}</td>
			<td>{reservation.price} {reservation?.currency || ""}</td>
			<td
				className={`${
					reservation?.status.toLowerCase() === "pending"
						? "text-warning"
						: reservation?.status.toLowerCase() === "approved" ||
						  reservation?.status.toLowerCase() === "completed" ||
						  reservation?.status.toLowerCase() === "paid"
						? "text-success"
						: reservation?.status.toLowerCase() === "rejected" ||
						  reservation?.status.toLowerCase() === "canceled"
						? "text-danger"
						: "text-dark"
				}`}
			>
				{reservation.status}
			</td>
			<td className="">
				<Link to={`/dashboard/${id}/reservation/${reservation.id}`}>
					<i class="fas fa-chevron-right text-dark"></i>
				</Link>
			</td>
		</tr>
	);
}
