import Loader from "components/Loader";
import BirthdayPickerField from "components/shared/birthdayPickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import {
	useVerifyHotelDispatch,
	useVerifyHotelState,
} from "context/verifyHotel";
import validate, { isFormValid } from "helpers/validate";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import { fetchCities } from "services/lookups";
import Locale from "translations";
import OwnerNameInput from "./OwnerNameInput";

export default function ManagerDetails(props) {
	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	const { locale } = useGlobalState();
	const { propertyDetails, userDetails, commons } = Locale;
	const history = useHistory();
	const VerifyHotel = useVerifyHotelState();

	const { ManagerDetails, ManagerType } = useVerifyHotelState();
	const dispatch = useVerifyHotelDispatch();
	const [managerDetailsState, setManagerDetailsState] = useState({
		owns_property: ManagerDetails?.owns_property ?? "",
		birthDate: ManagerDetails?.birthDate ?? "",
		full_name_Management:
			ManagerDetails?.full_name ?? ManagerDetails?.full_name_Management,
		alternative_name_Business:
			ManagerDetails?.alternative_name ??
			ManagerDetails?.alternative_name_Business,
		address_management_business:
			ManagerDetails?.address ?? ManagerDetails?.address_management_business,
		town_city: ManagerDetails?.city?.id
			? {
				value: ManagerDetails?.city.id,
				label: ManagerDetails?.city.names[locale],
			}
			: ManagerDetails?.town_city, //ManagerDetails?.town_city ?? "",
		unit_number: ManagerDetails?.unit_number ?? "",
		zip_code: ManagerDetails?.zip_code ?? "",
		country: ManagerDetails?.country?.id
			? {
				value: ManagerDetails?.country?.id,
				label: ManagerDetails?.country?.names[locale],
			}
			: ManagerDetails?.country, //ManagerDetails?.country ?? "",
		ManagerNames: props?.canEdit
			? ManagerDetails?.managers?.map((own) => {
				return {
					email: own.email,
					first_name: own.first_name,
					last_name: own.last_name,
				};
			})
			: VerifyHotel?.ManagerDetails?.ManagerNames &&
				VerifyHotel?.ManagerDetails?.ManagerNames.length > 0
				? VerifyHotel?.ManagerDetails?.ManagerNames?.map((own) => {
					return {
						email: own.email,
						first_name: own.first_name,
						last_name: own.last_name,
					};
				})
				: [{}],

		countryCode: null,
		spin: false,
	});

	const [cities, setCities] = useState([]);
	const [loaderMessage] = useState(
		"Please be patient and wait, The page will load now."
	);
	const [errors, setErrors] = useState(ManagerDetails?.errors ?? {});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	// ----------------------------------------------------------------------------------------------------
	// Component Functions
	// ----------------------------------------------------------------------------------------------------

	const checkFormErrors = () => {
		if (managerDetailsState.owns_property.value === 1) {
			setErrors({
				...errors,
				...validate(
					{ name: "owns_property", value: managerDetailsState.owns_property },
					{ required: true }
				),
				...validate(
					{
						name: "full_name_Management",
						value: managerDetailsState.full_name_Management,
					},
					{ required: true }
				),
				// ...validate(
				// 	{ name: "unit_number", value: managerDetailsState.unit_number },
				// 	{ required: true }
				// ),
				...validate(
					{ name: "zip_code", value: managerDetailsState.zip_code },
					{ required: true, min: 5, max: 5 }
				),
				...validate(
					{
						name: "address_management_business",
						value: managerDetailsState.address_management_business,
					},
					{ required: true }
				),
				...validate(
					{ name: "town_city", value: managerDetailsState.town_city },
					{ required: true }
				),
				...validate(
					{ name: "country", value: managerDetailsState.country },
					{ required: true }
				),
			});
		} else {
			setErrors({
				...errors,
				...validate(
					{ name: "owns_property", value: managerDetailsState.owns_property },
					{ required: true }
				),
				...validate(
					{
						name: "full_name_Management",
						value: managerDetailsState.full_name_Management,
					},
					{ required: true }
				),
			});
		}
	};

	const addOwner = () => {
		let ManagerDetails = [...managerDetailsState.ManagerNames];
		ManagerDetails.push({});
		setManagerDetailsState({
			...managerDetailsState,
			ManagerNames: ManagerDetails,
		});
	};
	// SUbmit Form
	const submitForm = () => {
		dispatch({
			type: "setManagerDetails",
			payload: managerDetailsState,
		});
		dispatch({ type: "setStep", progress: 100, step: 3 });
	};

	const backStep = () => {
		dispatch({ type: "setStep", progress: 0, step: 1 });
		//history.push("/");
	};

	useEffect(() => {
		setManagerDetailsState({
			...managerDetailsState,
			spin: false,
		});
		if (ManagerDetails?.full_name) {
			dispatch({
				type: "setData",
				payload: {
					...managerDetailsState,
					country_id: ManagerDetails?.country?.id,
					city_id: ManagerDetails?.city.id,
				},
			});
		}

		const FetchForm = async () => {
			if (isFormValid(errors)) {
				if (true) {
					submitForm();
				}
			}
		};
		FetchForm();
	}, [isErrorLoaded]);

	const checkEmailAvailability = () => {
		setManagerDetailsState({
			...managerDetailsState,
			spin: true,
		});
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};
	const OwnsProperty = [
		{
			label: "Management Company",
			value: 1,
		},
		{
			label: "Property Management",
			value: 2,
		},
	];
	// Fetch Cities
	useEffect(() => {
		async function fetchLookups() {
			if (managerDetailsState.country?.value) {
				const res = await fetchCities(managerDetailsState.country?.value);
				const formatted = res?.data.map((country) => ({
					value: country.id,
					label: country.names[locale],
				}));
				setCities(formatted);
			}
		}

		fetchLookups();
	}, [managerDetailsState.country]);
	// ----------------------------------------------------------------------------------------------------
	// Main Component Body UI
	// ----------------------------------------------------------------------------------------------------
	if (true) {
		return (
			<>
				<div className="col-md-12 mt-2 mb-5">
					<h1 className="text-center text-title text-dark-blue mt-4 mb-3">
						{propertyDetails.managerDetails}
					</h1>

					<div className="bg-white p-2 px-3 border radius-10 mt-3 mb-4">
						<div className="row m-0">
							{/* WhoOwnsTheProperty */}
							<div className="col-md-12 pr-md-2">
								<SelectField
									label={propertyDetails.WhoManagesTheProperty}
									value={managerDetailsState.owns_property}
									name="owns_property"
									options={OwnsProperty}
									onChange={(e) => {
										setErrors({});

										setManagerDetailsState({
											...managerDetailsState,
											owns_property: e,
											full_name_Management: "",
										});
									}}
									onBlur={() =>
										setErrors({
											...errors,
											...validate(
												{
													name: "owns_property",
													value: managerDetailsState.owns_property,
												},
												{ required: true }
											),
										})
									}
									color={errors?.owns_property?.required ? "danger" : ""}
									errors={errors?.owns_property}
								/>
							</div>
							{/* Full name of business entity  */}
							{managerDetailsState.owns_property.value == 1 ? (
								<div className="w-100">
									<div className="col-12">
										<TextField
											type="text"
											label={propertyDetails.fullNameOfTheManagementCompany}
											placeholder={
												propertyDetails.fullNameOfTheManagementCompany
											}
											value={managerDetailsState.full_name_Management}
											name="full_name_Management"
											onChange={(e) => {
												setErrors({
													...errors,
													...validate(e.target, {
														required: true,
													}),
												});
												setManagerDetailsState({
													...managerDetailsState,
													full_name_Management: e.target.value,
												});
											}}
											min={3}
											color={
												errors?.full_name_Management?.required ? "danger" : ""
											}
											errors={errors?.full_name_Management}
										/>
									</div>

									{/* Trade Name */}
									<div className="col-12">
										<TextField
											type="text"
											label={
												propertyDetails.alternativeNameYouUseForOperatingYourBusiness
											}
											placeholder={
												propertyDetails.alternativeNameYouUseForOperatingYourBusiness
											}
											value={managerDetailsState.alternative_name_Business}
											name="alternative_name_Business"
											onChange={(e) => {
												setManagerDetailsState({
													...managerDetailsState,
													alternative_name_Business: e.target.value,
												});
											}}
											color={
												errors?.alternative_name_Business?.required
													? "danger"
													: ""
											}
											errors={errors?.alternative_name_Business}
										/>
									</div>

									<div className="col-md-12">
										<div className="border-bottom w-100 mt-2 mb-1"></div>
									</div>

									<div className="col-12">
										<TextField
											type="text"
											label={propertyDetails.addressManagementCompany}
											placeholder={propertyDetails.addressManagementCompany}
											value={managerDetailsState.address_management_business}
											name="address_management_business"
											onChange={(e) => {
												setErrors({
													...errors,
													...validate(e.target, {
														required: true,
													}),
												});
												setManagerDetailsState({
													...managerDetailsState,
													address_management_business: e.target.value,
												});
											}}
											color={
												errors?.address_management_business?.required
													? "danger"
													: ""
											}
											errors={errors?.address_management_business}
										/>
									</div>

									<div className="col-md-12 pl-3 pr-3">
										<div className="row">
											{/* Unit Number (Optional)  */}
											<div className="col-md-6 pl-md-2">
												<TextField
													label={propertyDetails.unitNumber}
													placeholder={propertyDetails.unitNumber}
													name="unit_number"
													type={"number"}
													value={managerDetailsState.unit_number}
													onChange={(e) => {
														setManagerDetailsState({
															...managerDetailsState,
															unit_number: e.target.value,
														});
													}}
												/>
											</div>
											<div className="col-md-6">
												<SelectField
													label={userDetails.country}
													value={managerDetailsState.country}
													name="country"
													options={props.lookups.countries}
													onChange={(e) => {
														setErrors({
															...errors,
															...validate(
																{ name: "country", value: e.value },
																{ required: true }
															),
														});
														setManagerDetailsState({
															...managerDetailsState,
															country: e,
															town_city: "",
														});
													}}
													onBlur={() =>
														setErrors({
															...errors,
															...validate(
																{
																	name: "country",
																	value: managerDetailsState.country,
																},
																{ required: true }
															),
														})
													}
													color={errors?.country?.required ? "danger" : ""}
													errors={errors?.country}
												/>
											</div>
											<div className="col-md-6">
												<SelectField
													label={propertyDetails.townCity}
													value={managerDetailsState.town_city}
													name="town_city"
													options={cities}
													onChange={(e) => {
														setErrors({
															...errors,
															...validate(
																{ name: "town_city", value: e.value },
																{ required: true }
															),
														});
														setManagerDetailsState({
															...managerDetailsState,
															town_city: e,
														});
													}}
													onBlur={() =>
														setErrors({
															...errors,
															...validate(
																{
																	name: "town_city",
																	value: managerDetailsState.town_city,
																},
																{ required: true }
															),
														})
													}
													color={errors?.town_city?.required ? "danger" : ""}
													errors={errors?.town_city}
												/>
											</div>
											{/* zip Code */}
											<div className="col-md-6 pl-md-2">
												<TextField
													label={propertyDetails.zipCode}
													placeholder={propertyDetails.zipCode}
													name="zip_code"
													type={"number"}
													value={managerDetailsState.zip_code}
													min={5}
													max={5}
													onChange={(e) => {
														setErrors({
															...errors,
															...validate(
																{ name: "zip_code", value: e.target.value },
																{
																	required: true,
																	min: 5,
																	max: 5,
																}
															),
														});
														setManagerDetailsState({
															...managerDetailsState,
															zip_code: e.target.value,
														});
													}}
													color={
														errors?.zip_code?.required ||
															errors?.zip_code?.min ||
															errors?.zip_code?.max
															? "danger"
															: ""
													}
													errors={errors?.zip_code}
												/>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="border-bottom w-100 mt-2 mb-1"></div>
										<p className="title-form">
											If the property management company operates under a
											different name (e.g. “trading as”) in relation to the
											accommodation, provide those details.)
										</p>
									</div>
									{managerDetailsState.ManagerNames?.map((owner, i) => {
										return (
											<OwnerNameInput
												key={owner.name}
												setErrors={setErrors}
												errors={errors}
												ownerDetailsState={managerDetailsState}
												setOwnerDetailsState={setManagerDetailsState}
												owner={owner}
												index={i}
											/>
										);
									})}

									<div className="col-md-12">
										<a className="link-add" onClick={addOwner}>
											<i class="fas fa-plus-circle"></i> Add another
										</a>
									</div>
								</div>
							) : (
								<>
									<div className="col-12">
										<TextField
											type="text"
											label={propertyDetails.providePropertyManagerFullName}
											placeholder={
												propertyDetails.providePropertyManagerFullName
											}
											value={managerDetailsState.full_name_Management}
											name="full_name_Management"
											onChange={(e) => {
												setErrors({
													...errors,
													...validate(e.target, {
														required: true,
													}),
												});
												setManagerDetailsState({
													...managerDetailsState,
													full_name_Management: e.target.value,
												});
											}}
											color={
												errors?.full_name_Management?.required ? "danger" : ""
											}
											errors={errors?.full_name_Management}
										/>
									</div>
									{/* <div className="col-4">
										<BirthdayPickerField
											label={propertyDetails.birthDate}
											value={managerDetailsState.birthDate}
											onChangeDate={(date) => {
												setManagerDetailsState({
													...managerDetailsState,
													birthDate: date,
												});
											}}
											maxDate={new Date()}
											format={"d-M-yyyy"}
											isOutsideRange={(day) => {
												return false;
											}}
										/>
									</div> */}
								</>
							)}
						</div>
					</div>

					<div className="row mt-3 justify-content-between m-0">
						<div className="col-md-6"></div>
						<div className="col-md-2">
							<button
								onClick={() => {
									backStep();
								}}
								className="btn btn-light btn-light-new w-100"
							>
								<span className="icx icx-left-solid-arrow text-10"></span>{" "}
								{commons.back}
							</button>
						</div>
						<div className="col-md-4 p-0">
							<button
								className="btn btn-primary w-100"
								onClick={() => checkEmailAvailability()}
								disabled={managerDetailsState.spin}
							>
								{managerDetailsState.spin ? (
									<Spinner color="light" size="sm" />
								) : null}{" "}
								{userDetails.next}
							</button>
						</div>
					</div>
				</div>
			</>
		);
	} else {
		return <Loader message={loaderMessage} />;
	}
}
