import DateRangeField from "components/shared/DateRangeField";
import SelectField from "components/shared/SelectField";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Locale from "translations";

export default function Filter({
	setFilters,
	filters,
	id,
	rooms,
	getViewCalendar,
}) {
	const { hotelLayout } = Locale;

	useEffect(() => {
		if (filters?.rooms?.length > 0) {
			const ViewCalendar = getViewCalendar({ ...filters, id });
		}
	}, [filters]);

	return (
		<>
			<div className="row m-0">
				<div className="col-md-4 p-0">
					<SelectField
						label={hotelLayout.calendar.view}
						placeholder={hotelLayout.calendar.allRooms}
						value={filters.rooms}
						name="rooms"
						id="rooms"
						multi={true}
						options={rooms}
						onChange={(e) => {
							setFilters({
								...filters,
								rooms: e,
							});
						}}
					/>
				</div>
				<div className="col-md-4">
					<DateRangeField
						label={hotelLayout.calendar.date}
						startDate={filters.dateStart}
						endDate={filters.dateEnd}
						onDatesChange={({ startDate, endDate }) =>
							setFilters({ ...filters, dateStart: startDate, dateEnd: endDate })
						}
					/>
				</div>
			</div>
		</>
	);
}
