import Model from "components/model";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import validate, { isFormValid } from "helpers/validate";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { NavLink } from "react-router-dom";

import { editUser, getListGroups, viewUser } from "services/TeamManagement";

import Locale from "../../translations";

export default function User(props) {
	const { user } = props;
	const { permissions, role } = useGlobalState();
	const { teamManagement } = Locale;

	/****API Edit user */
	const [errors, setErrors] = useState({});
	const [data, setData] = useState({});

	const locale = localStorage.getItem("currentLocale") || "en";
	const [modalShow, setModalShow] = useState(false);
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	const toggleModalShow = (id) => {
		//setSeletedId(id)
		setModalShow(!modalShow);
	};
	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{
					name: "supplier_name",
					value: data.supplier_name,
				},
				{
					required: true,
				}
			),
			// ...validate(
			// 	{
			// 		name: "owner_name",
			// 		value: data.owner_name,
			// 	},
			// 	{
			// 		required: true,
			// 	}
			// ),
			...validate(
				{
					name: "email",
					value: data.email,
				},
				{
					required: true,
				}
			),
			...validate(
				{
					name: "password",
					value: data.password,
				},
				{
					required: false,
				}
			),
			...validate(
				{
					name: "password_confirmation",
					value: data.password_confirmation,
				},
				{
					required: false,
					confirm: true,
				},
				data.password
			),
			...validate(
				{
					name: "password_not_match",
					value: data.password_not_match,
				},
				{
					required: false,
				}
			),
			...validate(
				{
					name: "phone_number",
					value: data.phone_number,
				},
				{
					required: true,
					number: true,
				}
			),
			...validate(
				{
					name: "group_id",
					value: data.group_id,
				},
				{
					required: false,
				}
			),
			...validate(
				{
					name: "country_id",
					value: data.country_id,
				},
				{
					required: true,
				}
			),
			// ...validate(
			// 	{
			// 		name: "category_id",
			// 		value: data.category_id,
			// 	},
			// 	{
			// 		required: true,
			// 	}
			// ),
			// ...validate(
			// 	{
			// 		name: "services",
			// 		value: data.services,
			// 	},
			// 	{
			// 		required: true,
			// 	}
			// ),
			// ...validate(
			// 	{
			// 		name: "is_admin",
			// 		value: data.is_admin,
			// 	},
			// 	{
			// 		required: true,
			// 	}
			// ),
		});
	};
	const handleForm = async () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};
	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setData({ ...data, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			setData({ ...data, [name]: value });
		}
	};

	useEffect(() => {
		if (modalShow) {
			async function fetchData() {
				const res = await viewUser(user.id);

				setData({
					...data,
					...res?.data,

					country_id: {
						label: res?.data?.country?.name, //s[locale]
						value: res?.data?.country?.id,
					},
					group_id: {
						label: res?.data?.team?.name,
						value: res?.data?.team?.id,
					},
					supplier_name: res?.data.name,
					// services: {
					// 	label: res?.data?.data?.services[0]?.name,
					// 	value: res?.data?.data?.services[0]?.id,
					// },
					// category_id: {
					// 	label: res?.data?.data?.category,
					// 	value: res?.data?.data?.category_id,
					// },
					// is_admin: {
					// 	label: res?.data?.data?.is_admin ? "Is Admin" : "Not Admin",
					// 	value: res?.data?.data?.is_admin,
					// },
				});
			}
			fetchData();
		}
	}, [modalShow]);
	useEffect(() => {
		const editUser1 = async () => {
			if (isFormValid(errors)) {
				const res = await editUser({
					...data,
					id: user.id,
					group_id: data.group_id?.value,
					country_id: data.country_id?.value,
					//services: [data.services?.value],
					//category_id: data.category_id?.value,
					//is_admin: data.is_admin?.value,
					is_admin: 0,
					phone_number: data.phone_number?.includes(data?.country_id?.value)
						? data.phone_number
						: "+" + data.country_id?.value + data?.phone_number,
					name: data?.supplier_name,
				});

				if (res?.status == 201 || res?.status == 200) {
					store.addNotification({
						title: "info!",
						message: res.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
					toggleModalShow(null);
					window.location.reload();
				}
			}
		};
		editUser1();
	}, [isErrorLoaded]);

	/****End Edit user */
	return (
		<>
			<tr>
				<td>{user.name}</td>
				<td>{user.email}</td>
				<td> {locale === "en" ? user.phone_number : user.phone_number} </td>
				<td>{user.country?.name}</td>
				<td>{user.team?.name}</td>

				<td>
					{(permissions && permissions.includes("manage-team")) ||
					role == "super-admin" ? (
						<>
							{user.status == "active" || user.status == "مفعل" ? (
								<button
									type="button"
									onClick={() => props.changeStatusUser(user.id, "deactivate")}
									className="btn btn-link text-white stopping mx-2 font-weight-bold btn btn-success"
									style={{ width: "75px" }}
									disabled={user?.team?.name == "super-admin"}
								>
									{teamManagement.active}
								</button>
							) : (
								<button
									type="button"
									onClick={() => props.changeStatusUser(user.id, "activate")}
									className="btn btn-link text-white mx-2 font-weight-bold btn btn-danger"
									style={{ width: "75px" }}
									disabled={user?.team?.name == "super-admin"}
								>
									{teamManagement.inactive}
								</button>
							)}
						</>
					) : (
						user.status
					)}
				</td>

				<td>
					{user?.team?.name != "super-admin" ? (
						<ShowForPermission permission="manage-team">
							<div className="actions">
								<span className="text-primary d-flex ">
									<button
										className="p-2 text-primary  btn btn-link  font-weight-bold"
										onClick={() => {
											setModalShow(!modalShow);
										}}
									>
										<i className="fas fa-edit" style={{ fontSize: "16px" }}></i>
									</button>

									<></>
								</span>
							</div>
						</ShowForPermission>
					) : null}
				</td>
			</tr>

			{/*************************** Model Edit */}
			<div>
				<Model
					isOpen={modalShow}
					title={teamManagement.editMember}
					toggle={() => {
						toggleModalShow(null);
					}}
					hasFooter={false}
					className="bulk-edit user-model"
				>
					<div className="py-3  user-model-content ">
						<div className="row">
							<div className="col-md-12 ">
								<TextField
									label={teamManagement.fullName}
									placeholder={teamManagement.enterFullName}
									name="supplier_name"
									onChange={(e) => {
										setData({
											...data,
											supplier_name: e.target.value,
										});
									}}
									color={errors?.supplier_name?.required ? "danger" : ""}
									errors={errors?.supplier_name}
									value={data?.supplier_name}
								/>
							</div>
							{/* <div className="col-md-6 ">
								<TextField
									label={teamManagement.ownerName}
									placeholder={teamManagement.ownerName}
									name="owner_name"
									onChange={(e) => {
										setData({
											...data,
											owner_name: e.target.value,
										});
									}}
									color={errors?.owner_name?.required ? "danger" : ""}
									errors={errors?.owner_name}
									value={data?.owner_name}
								/>
							</div> */}
							<div className="col-md-12 ">
								<TextField
									label={teamManagement.emailAddress}
									placeholder={teamManagement.enterEmailAddress}
									name="email"
									onChange={(e) => {
										setData({
											...data,
											email: e.target.value,
										});
									}}
									color={errors?.email?.required ? "danger" : ""}
									errors={errors?.email}
									value={data?.email}
								/>
							</div>
							<div className="col-md-6 ">
								<TextField
									type="password"
									label={teamManagement.password}
									placeholder={teamManagement.enterPassword}
									name="password"
									onChange={(e) => {
										setData({
											...data,
											password: e.target.value,
										});
									}}
									color={errors?.password?.required ? "danger" : ""}
									errors={errors?.password}
									value={data?.password}
								/>
							</div>
							<div className="col-md-6 ">
								<TextField
									type="password"
									label={teamManagement.password_confirmation}
									placeholder={teamManagement.enterConfirmPassword}
									name="password_confirmation"
									onChange={(e) => {
										setData({
											...data,
											password_confirmation: e.target.value,
										});
									}}
									color={
										errors?.password_confirmation?.required ||
										errors?.password_confirmation?.confirm
											? "danger"
											: ""
									}
									errors={errors?.password_confirmation}
									value={data?.password_confirmation}
								/>
							</div>

							<div className="col-md-6 ">
								<SelectField
									label={teamManagement.country}
									placeholder={teamManagement.enterCountry}
									name="country_id"
									errors={errors.country_id}
									color={errors.country_id?.required ? "danger" : ""}
									options={props.countries}
									onChange={(e) => {
										handleChange({ name: "country_id", value: e });

										setErrors({
											...errors,
											...validate(
												{ name: "country_id", value: e },
												{
													required: true,
												}
											),
										});
									}}
									value={data.country_id}
								/>
							</div>
							{locale == "en" ? (
								<div
									className="col-md-6 pl-md-2 phone-supplier"
									style={{ direction: "ltr" }}
								>
									<TextField
										label={teamManagement.phoneNumber}
										placeholder={teamManagement.enterPhoneNumber}
										name="phone_number"
										extraText={
											data?.country_id?.value
												? "+" + data?.country_id?.value
												: "---"
										}
										extraTextPosition="prepend"
										onChange={(e) => {
											setData({
												...data,
												phone_number: e.target.value,
											});
										}}
										color={errors?.phone_number?.required ? "danger" : ""}
										errors={errors?.phone_number}
										value={
											data.phone_number?.includes(data?.country_id?.value)
												? data.phone_number.replace(
														"+" + data?.country_id?.value,
														""
												  )
												: data.phone_number
										}
									/>
								</div>
							) : (
								<div
									className="col-md-6 pl-md-2 phone-supplier"
									style={{ direction: "ltr" }}
								>
									<TextField
										label={teamManagement.phoneNumber}
										placeholder={teamManagement.enterPhoneNumber}
										name="phone_number"
										extraText={
											data?.country_id?.value
												? "+" + data?.country_id?.value
												: "---"
										}
										extraTextPosition="prepend"
										onChange={(e) => {
											setData({
												...data,
												phone_number: e.target.value,
											});
										}}
										color={
											errors?.phone_number?.required ||
											errors?.phone_number?.number
												? "danger"
												: ""
										}
										errors={errors?.phone_number}
										value={
											data.phone_number?.includes(data?.country_id?.value)
												? data.phone_number.replace(data?.country_id?.value, "")
												: data.phone_number
										}
									/>
								</div>
							)}
							{/* <div className="col-md-6 ">
								<SelectField
									label={teamManagement.catagory}
									placeholder={teamManagement.enterCatagory}
									name="category_id"
									errors={errors.category_id}
									color={errors.category_id?.required ? "danger" : ""}
									options={props.catagories}
									onChange={(e) => {
										handleChange({ name: "category_id", value: e });

										setErrors({
											...errors,
											...validate(
												{ name: "category_id", value: e },
												{
													required: true,
												}
											),
										});
									}}
									value={data?.category_id}
								/>
								{
							</div> */}
							{/* <div className="col-md-6 ">
								<SelectField
									label={teamManagement.services}
									placeholder={teamManagement.enterServices}
									name="services"
									errors={errors.services}
									color={errors.services?.required ? "danger" : ""}
									options={props.services}
									onChange={(e) => {
										handleChange({ name: "services", value: e });

										setErrors({
											...errors,
											...validate(
												{ name: "services", value: e },
												{
													required: true,
												}
											),
										});
									}}
									value={data.services}
								/>
							</div> */}

							{/* <div className="col-md-6 ">
								<SelectField
									label={teamManagement.admin}
									placeholder={teamManagement.admin}
									name="is_admin"
									//multi={true}
									options={props.admins}
									onChange={(e) => {
										handleChange({ name: "is_admin", value: e });
										setErrors({
											...errors,
											...validate(
												{ name: "is_admin", value: e },
												{
													required: true,
												}
											),
										});
									}}
									value={data?.is_admin}
								/>
							</div> */}

							<div className="col-md-12 ">
								<SelectField
									disabled={data?.is_admin?.value == 1}
									label={teamManagement.team}
									placeholder={teamManagement.enterTeam}
									name="group_id"
									//multi={true}
									options={props.groups}
									onChange={(e) => {
										handleChange({ name: "group_id", value: e });
										setErrors({
											...errors,
											...validate(
												{ name: "group_id", value: e },
												{
													required: true,
												}
											),
										});
									}}
									value={data?.group_id}
								/>
							</div>
						</div>
						<div className=" d-flex justify-content-end ">
							<button className="btn btn-primary mx-1 " onClick={handleForm}>
								{teamManagement.edit}
							</button>
							<button
								className="btn btn-secondary mx-1"
								onClick={() => {
									toggleModalShow(null);
								}}
							>
								{" "}
								{teamManagement.cancel}
							</button>
						</div>
					</div>
				</Model>
			</div>
			{/********************* Model Edit  */}
		</>
	);
}
