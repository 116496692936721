import { useGlobalDispatch, useGlobalState } from "context/global";
import { usePropertyDispatch } from "context/property";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { getProperty } from "services/property";
import Locale from "translations";
import Amenities from "./Amenities";
import BasicInfo from "./BasicInfo";
import Photos from "./Photos";
import Polices from "./Polices";
import Rooms from "./Rooms";
import Services from "./Services";
import "./style.scss";

function Property() {
	const { state } = useLocation();
	const { step, current } = useGlobalState();

	const dispatch = usePropertyDispatch();
	const dispatchGlobal = useGlobalDispatch();
	const { property } = Locale;
	const [activeTab, setActiveTab] = useState(step);
	const toggle = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
			dispatchGlobal({ type: "progress", current: tab, step: step });
		}
	};

	const calculateStep = (progress) => {
		switch (Math.floor(progress)) {
			case 16:
				return "2";
			case 33:
				return "3";
			case 50:
				return "4";
			case 66:
				return "5";
			case 83:
				return "6";
			default:
				return "1";
		}
	};

	useEffect(() => {
		async function fetchProperty() {
			const res = await getProperty(state.id);
			if (res.status === 201 || res.status === 200) {
				dispatch({ type: "propertyDetails", payload: res.data });
				dispatchGlobal({
					type: "progress",
					progress: Math.floor(res.data.registeration_progress),
					step: calculateStep(res.data.registeration_progress),
					current: calculateStep(res.data.registeration_progress),
				});
			}
		}
		if (state?.id) {
			fetchProperty();
		} else {
			dispatchGlobal({
				type: "progress",
				progress: 0,
				step: "1",
				current: "1",
			});
		}
	}, [state?.id]);

	useEffect(() => {
		window.scrollTo(0, 0);
		if (current) {
			setActiveTab(current);
		} else {
			setActiveTab(step);
		}
	}, [current, step]);

	return (
		<div className="property">
			<div className="bg-white text-black mb-3">
				<div className="">
					<div className="row">
						<h1 className="p-3">{property.add}</h1>
					</div>
				</div>
			</div>
			<div className="">
				<div className="text-black">
					<div className="d-flex route-block">
						<Nav tabs>
							<NavItem>
								<NavLink
									className={`${activeTab === "1" ? "is-active" : ""} ${
										+step > 1 ? "done" : ""
									}`}
									onClick={() => {
										toggle("1");
									}}
								>
									{property.basicInfo}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={`${activeTab === "2" ? "is-active" : ""}  
									${+step > 2 ? "done" : ""}
									${+step < 2 ? "disabled" : ""}`}
									onClick={() => {
										toggle("2");
									}}
								>
									{property.services}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={`${activeTab === "3" ? "is-active" : ""}  
									${+step > 3 ? "done " : ""} 
									${+step < 3 ? "disabled" : ""}`}
									onClick={() => {
										toggle("3");
									}}
								>
									{property.rooms}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={`${activeTab === "4" ? "is-active" : ""} 
									${+step > 4 ? "done " : ""} ${+step < 4 ? "disabled" : ""}`}
									onClick={() => {
										toggle("4");
									}}
								>
									{property.amenities}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={`${activeTab === "5" ? "is-active" : ""} ${
										+step > 5 ? "done " : ""
									} ${+step < 5 ? "disabled" : ""}`}
									onClick={() => {
										toggle("5");
									}}
								>
									{property.photos}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={`${activeTab === "6" ? "is-active" : ""} ${
										+step > 6 ? "done " : ""
									} ${+step < 6 ? "disabled" : ""}`}
									onClick={() => {
										toggle("6");
									}}
								>
									{property.polices}
								</NavLink>
							</NavItem>
						</Nav>
					</div>
				</div>
			</div>
			<div
				className={` mt-3 ${
					activeTab == 3 ? "container-fluid px-2" : "container-fluid px-3"
				} `}
			>
				<TabContent activeTab={activeTab}>
					<TabPane tabId="1">
						<BasicInfo />
					</TabPane>
					<TabPane tabId="2">
						<Services />
					</TabPane>
					<TabPane tabId="3">{activeTab == 3 ? <Rooms /> : ""}</TabPane>
					<TabPane tabId="4">
						<Amenities />
					</TabPane>
					<TabPane tabId="5">
						<Photos />
					</TabPane>
					<TabPane tabId="6">
						<Polices />
					</TabPane>
				</TabContent>
			</div>
		</div>
	);
}

export default Property;
