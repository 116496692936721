import axios from "axios";

const lookupsUrl = process.env.REACT_APP_API_URL + "/api/lookups";

export const fetchCountries = async () => {
	return await axios
		.get(`${lookupsUrl}/countries`)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const fetchServices = async (id) => {
	return await axios
		.get(`${lookupsUrl}/services`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchCurrencies = async () => {
	return await axios
		.get(`${lookupsUrl}/currencies`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchCurrenciesproperty = async (id) => {
	return await axios
		.get(`${lookupsUrl}/currencies?property_id=` + id)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchCategories = async (id) => {
	return await axios
		.get(`${lookupsUrl}/categories`)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const fetchCities = async (id) => {
	return await axios
		.get(`${lookupsUrl}/cities?country_id=${id}`)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const fetchAreas = async (id) => {
	return await axios
		.get(`${lookupsUrl}/areas?city_id=${id}`)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const fetchLanguages = async (lang = "en") => {
	return await axios
		.get(`${lookupsUrl}/languages?lang_key=${lang}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchRoomViews = async (lang = "en") => {
	return await axios
		.get(`${lookupsUrl}/room-views?lang_key=${lang}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchFacilities = async (lang = "en") => {
	return await axios
		.get(`${lookupsUrl}/facilities?lang_key=${lang}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchRoomTypes = async (lang = "en") => {
	return await axios
		.get(`${lookupsUrl}/room-types?lang_key=${lang}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchRoomNames = async (lang = "en") => {
	return await axios
		.get(`${lookupsUrl}/room-names?lang_key=${lang}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchAmenities = async (lang = "en") => {
	return await axios
		.get(`${lookupsUrl}/amenities?lang_key=${lang}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchCommonRatePlan = async (lang = "en") => {
	return await axios
		.get(`${lookupsUrl}/common-rate-plan?lang_key=${lang}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchHotels = async (key) => {
	return await axios
		.get(`${lookupsUrl}/sbs-hotels?name=${key}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchUmrahHotels = async (key) => {
	return await axios
		.post(
			`${process.env.REACT_APP_API_URL}/api/v1/supplier/hotels/search-umrah-hotels`,
			{ name: key }
		)
		.then((res) => res)
		.catch((err) => err.response);
};
