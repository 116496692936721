import SelectField from "components/shared/SelectField";
import validate from "helpers/validate";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Card, CardBody, Collapse } from "reactstrap";
import { getListRooms } from "services/rateplan";
import Locale from "translations";
export default function ConfirmationTypes({
	ratePlan,
	setRatePlanState,
	setErrors,
	errors,
}) {
	const RatePlan = Locale;
	const ListConfirmationTypes = [
		{
			label: "Auto",
			value: 1,
		},
		{
			label: "manual",
			value: 2,
		},
	];

	return (
		<div className="row m-0">
			<div className="col-md-12 p-0 mt-3">
				<div
					className="rate-plan-header"
					onClick={() =>
						setRatePlanState({
							...ratePlan,
							isOpenConfirmationTypes: !ratePlan.isOpenConfirmationTypes,
						})
					}
				>
					{RatePlan.hotelLayout?.ratePlan?.ConfirmationTypes}

					<i
						className={`fas pos-arrow ${ratePlan.isOpenConfirmationTypes
								? "fa-chevron-up "
								: "fa-chevron-down"
							}`}
					></i>
				</div>

				<Collapse isOpen={ratePlan.isOpenConfirmationTypes}>
					<Card>
						<CardBody>
							<div className="col-md-12 pt-2">
								<div className="col-md-12">
									{ListConfirmationTypes.map((res) => {
										return (
											<div
												class="form-check form-check-inline"
												style={{ width: "200px" }}
											>
												<input
													class="form-check-input"
													type="radio"
													id={"inlineCheckbox1" + res.label}
													onChange={(e) => {
														setRatePlanState({
															...ratePlan,
															ConfirmationTypes: ListConfirmationTypes.filter(
																(res) => res?.value == e?.target?.value
															)[0],
														});
													}}
													checked={
														+res.value == +ratePlan?.ConfirmationTypes?.value
													}
													value={res.value}
												/>
												<label
													class="form-check-label"
													for={"inlineCheckbox1" + res.label}
												>
													{res.label}
												</label>
											</div>
										);
									})}
									{/* <SelectField
										label={"ConfirmationTypes"}
										value={ratePlan.ConfirmationTypes}
										name="ConfirmationTypes"
										options={ListConfirmationTypes}
										onChange={(e) => {
											setRatePlanState({
												...ratePlan,
												ConfirmationTypes: e,
											});
										}}
										onBlur={() =>
											setErrors({
												...errors,
												...validate(
													{
														name: "Meals",
														value: ratePlan.ConfirmationTypes,
													},
													{ required: true }
												),
											})
										}
										color={errors?.ConfirmationTypes?.required ? "danger" : ""}
										errors={errors?.ConfirmationTypes}
									/> */}
								</div>
							</div>
						</CardBody>
					</Card>
				</Collapse>
			</div>
		</div>
	);
}
