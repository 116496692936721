import axios from "axios";
import { cleanEmpty } from "services/general";
const propertyUrl = process.env.REACT_APP_API_URL + "/api/properities";

export const listProperties = async (data) => {
	return await axios
		.get(`${propertyUrl}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getProperty = async (id) => {
	return await axios
		.get(`${propertyUrl}/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addBasicInfo = async (data) => {
	return await axios
		.post(`${propertyUrl}/basic-info`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const editBasicInfo = async (id, data) => {
	return await axios
		.put(`${propertyUrl}/${id}/basic-info`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const addServices = async (id, data) => {
	return await axios
		.post(`${propertyUrl}/${id}/facilities-services`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const editServices = async (id, data) => {
	return await axios
		.put(`${propertyUrl}/${id}/facilities-services`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
export const getPropertyServices = async (id) => {
	return await axios
		.get(`${propertyUrl}/${id}/meta`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addRooms = async (id, data) => {
	return await axios
		.post(`${propertyUrl}/${id}/rooms`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const editRooms = async (id, data) => {
	return await axios
		.put(`${propertyUrl}/${id}/rooms`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const addAmenities = async (id, data) => {
	return await axios
		.post(`${propertyUrl}/${id}/room-amenities`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editAmenities = async (id, data) => {
	return await axios
		.put(`${propertyUrl}/${id}/room-amenities`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addPhotos = async (id, data) => {
	return await axios
		.post(`${propertyUrl}/${id}/photos`, data, {
			headers: {
				"content-type": "multipart/form-data",
			},
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const editPhoto = async (id, data) => {
	return await axios
		.put(`${propertyUrl}/${id}/photos`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const deletePhoto = async (id, data) => {
	return await axios
		.post(`${propertyUrl}/${id}/photos`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addPolicies = async (id, data) => {
	return await axios
		.post(`${propertyUrl}/${id}/policies`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const editPolicies = async (id, data) => {
	return await axios
		.put(`${propertyUrl}/${id}/policies`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewProperties = async (id, data) => {
	return await axios
		.get(`${propertyUrl}/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
