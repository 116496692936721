import AsyncSelectField from "components/shared/AsyncSelectField";
import SelectField from "components/shared/SelectField";
import TextAreaField from "components/shared/TextAreaField";
import TextField from "components/shared/TextField";
import { useGlobalDispatch } from "context/global";
import { usePropertyDispatch, usePropertyState } from "context/property";
import { formatGetBasicInfo, formatPostBasicInfo } from "data/property";
import validate, { isFormValid, validatePhone } from "helpers/validate";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FormGroup, Input, Label } from "reactstrap";
import { fetchAreas, fetchCities, fetchCountries } from "services/lookups";
import { addBasicInfo, editBasicInfo } from "services/property";
import Locale from "translations";
import CancelModal from "./CancelModal";

function BasicInfo() {
	const { id } = useParams();
	const { propertyDetails } = usePropertyState();
	const dispatch = useGlobalDispatch();
	const propertyDispatch = usePropertyDispatch();
	const history = useHistory();
	const locale = localStorage.getItem("currentLocale");
	const { property, commons, supplierDetails } = Locale;
	const [toggleValue, setToggleValue] = useState("english");
	const [canceled, setCanceled] = useState(false);
	const [info, setInfo] = useState({
		typeInput: "insert",
		name: "",
		rating: "",
		description: "",
		country: "",
		city: "",
		area: "",
		address: "",
		postalCode: "",
		phone: "",
		secondPhone: "",
		email: "",
		officialEmail: "",
		latitude: "",
		longitude: "",
		lang: "en",
		distance: "",
	});

	//

	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [countries, setCountries] = useState([]);
	const [cities, setCities] = useState([]);
	const [areas, setAreas] = useState([]);
	const [isSameEmail, setIsSameEmail] = useState(false);

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "name", value: info.name },
				{ required: true, min: 3 }
			),
			...validate({ name: "rating", value: info.rating }, { required: true }),
			...validate(
				{ name: "address", value: info.address },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "email", value: info.email },
				{ required: true, email: true }
			),
			...validate(
				{ name: "officialEmail", value: info.officialEmail },
				{ required: true, email: true }
			),
			...validate({ name: "country", value: info.country }, { required: true }),
			...validate({ name: "city", value: info.city }, { required: true }),
			...validate({ name: "area", value: info.area }, { required: true }),
			...validate(
				{
					name: "phone",
					value: validatePhone(
						info.phone,
						countries.find((country) => country.value === info.country.value)
							?.code
					),
				},
				{ required: true, phone: true }
			),
			...validate(
				{
					name: "secondPhone",
					value: validatePhone(
						info.secondPhone,
						countries.find((country) => country.value === info.country.value)
							?.code
					),
				},
				{
					phone: validatePhone(
						info.secondPhone,
						countries.find((country) => country.value === info.country.value)
							?.code
					),
				}
			),
			...validate(
				{ name: "latitude", value: info.latitude },
				{ required: true }
			),
			...validate(
				{ name: "longitude", value: info.longitude },
				{ required: true }
			),
			...validate(
				{ name: "postalCode", value: info.postalCode },
				{ required: true }
			),
			...validate(
				{ name: "description", value: info.description },
				{ required: true }
			),
		});
	};

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setInfo({ ...info, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			if (name === "country") {
				setCities([]);
				setInfo({
					...info,
					city: "",
					area: "",
					postalCode: "",
					phone: "",
					secondPhone: "",
					[name]: value,
				});
			} else setInfo({ ...info, [name]: value });
		}
	};

	const handleNameChange = (e) => {
		// const re = {
		// 	en: /^[A-Za-z][A-Za-z0-9 ]*$/,
		// 	ar: /^[\u0621-\u064A0-9 ]+$/,
		// };

		const value = e.target.value;
		const name = e.target.name;
		// if (value === "" || re["en"].test(value)) {
		// info.lang ==> en
		setInfo({ ...info, [name]: value });
		// }
	};

	// Fetch Countries
	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchCountries();
			const format = res.data.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		fetchLookups();
	}, []);

	// Fetch Cities
	useEffect(() => {
		async function fetchLookups() {
			if (info.country?.value) {
				const res = await fetchCities(info.country?.value);
				const formatted = res.data.map((country) => ({
					value: country.id,
					label: country.names[locale],
				}));
				setCities(formatted);
			}
		}
		fetchLookups();
	}, [info.country]);

	// Fetch Areas
	useEffect(() => {
		async function fetchLookups() {
			if (info.city?.value) {
				const res = await fetchAreas(info.city?.value);
				const formatted = res.data.map((city) => ({
					value: city.id,
					label: city.names[locale],
				}));
				setAreas(formatted);
			}
		}
		fetchLookups();
	}, [info.city]);

	// is same email
	useEffect(() => {
		if (isSameEmail) {
			setInfo({ ...info, officialEmail: info.email });
		} else if (info.email === "" && info.officialEmail === "") {
			setIsSameEmail(false);
		} else {
			setInfo({ ...info, officialEmail: info.officialEmail });
		}
	}, [isSameEmail, info.email]);

	useEffect(() => {
		if (id && propertyDetails) {
			const formatted = formatGetBasicInfo(propertyDetails);
			setInfo({
				...formatted,
				typeInput: propertyDetails?.hotel_mapping_id == null ? "insert" : "",
			});
		}
		// if (propertyDetails?.hotel_mapping_id == null) {
		// 	handleChange({ name: "typeInput", value: "insert" });
		// }
	}, [propertyDetails]);

	useEffect(async () => {
		if (isFormValid(errors)) {
			if (id && propertyDetails?.property?.name) {
				const data = formatPostBasicInfo(info);
				const res = await editBasicInfo(id, data);

				if (res?.status === 201 || res?.status === 200) {
					dispatch({ type: "progress", step: "2", current: "2" });
					propertyDispatch({ type: "propertyDetails", payload: res.data });
				}
			} else {
				const data = formatPostBasicInfo(info);
				const res = await addBasicInfo(data);
				if (res?.status === 201 || res?.status === 200) {
					history.push(`/property/${res.data.id}`);
					dispatch({
						type: "progress",
						step: "2",
						current: "2",
					});

					propertyDispatch({ type: "propertyDetails", payload: res.data });
				}
			}
		}
	}, [isErrorLoaded]);

	const submit = () => {
		checkFormErrors();

		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	const reset = () => {
		setCanceled(true);
	};
	//

	return (
		<>
			<CancelModal isOpen={canceled} toggle={setCanceled} />
			<div className="content-box">
				<FormGroup tag="fieldset" className="col-12 name-check">
					<FormGroup check>
						<Label htmlFor="basic-search" check>
							<Input
								type="radio"
								onChange={() => {
									handleChange({ name: "typeInput", value: "search" });
								}}
								name="propertyName"
								id="basic-search"
							/>{" "}
							Search
						</Label>
					</FormGroup>
					<FormGroup check>
						<Label htmlFor="basic-insert" check>
							<Input
								type="radio"
								name="propertyName"
								checked={info.typeInput == "insert"}
								onChange={() => {
									handleChange({ name: "typeInput", value: "insert" });
								}}
								id="basic-insert"
							/>{" "}
							Insert
						</Label>
					</FormGroup>
				</FormGroup>
				{/* name */}
				<div className="row m-0">
					{info.typeInput == "insert" ? (
						<div className="col-7">
							<TextField
								label={property.name}
								customLabel={property.customName}
								placeholder={property.namePlaceholder}
								value={info.name}
								name="name"
								id="basic-info"
								onChange={(e) => {
									handleNameChange(e);
									setErrors({
										...errors,
										...validate(e.target, {
											required: true,
											min: 3,
										}),
									});
								}}
								min={3}
								color={
									errors.name?.required || errors.name?.min ? "danger" : ""
								}
								errors={errors.name}
							/>
						</div>
					) : (
						<>
							<div className="col-6">
								<AsyncSelectField
									hasLabel={true}
									label={property.name}
									isSearchable={true}
									onChange={(e) => {
										setInfo({
											...info,
											name: e.name,
											id: e.hotel_id,
											description: e.description,
											rating: { label: e.rating, value: e.rating },
											city: { label: e.city, value: e.city_id },
											country: { label: e.country, value: e.country_id },
											area: e.area,
											address: e.address,
											email: e.email,
											phone: e.phone,
											latitude: e.latitude,
											longitude: e.longitude,
											postalCode: e.pobox,
										});
									}}
								/>
							</div>
							{/* name */}
							<div className="col-6">
								<TextField
									label={property.NewHotel}
									id="basic-areas"
									onChange={(e) => {
										handleChange({ name: "name", value: e.target.value });
									}}
									value={info.name}
								/>
							</div>
						</>
					)}

					{/* rating */}
					<div className="col-5">
						<SelectField
							label={property.rating}
							value={info.rating}
							options={[
								{ label: "1", value: 1 },
								{ label: "2", value: 2 },
								{ label: "3", value: 3 },
								{ label: "4", value: 4 },
								{ label: "5", value: 5 },
							]}
							placeholder={property.ratingPlaceholder}
							name="rating"
							id="basic-rating"
							onChange={(e) => {
								handleChange({ name: "rating", value: e });
								setErrors({
									...errors,
									...validate({ name: "rating", value: e }, { required: true }),
								});
							}}
							color={errors.rating?.required ? "danger" : ""}
							errors={errors.rating}
						/>
					</div>
					{/* description */}
					<div className="col-12">
						<TextAreaField
							label={property.description}
							placeholder={property.descriptionPlaceholder}
							rows={5}
							value={info.description}
							name="description"
							id="basic-description"
							onChange={(e) => {
								handleChange(e);
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
									}),
								});
							}}
							color={errors.description?.required ? "danger" : ""}
						/>
					</div>
				</div>
			</div>
			<div className="header-box my-3">{property.located}</div>
			<div className="row m-0">
				{/* Country */}
				<div className="col-6">
					<SelectField
						label={commons.country}
						placeholder={commons.countryPlaceholder}
						errors={errors.country}
						color={errors.country?.required ? "danger" : ""}
						options={countries}
						id="basic-countries"
						onChange={(e) => {
							handleChange({ name: "country", value: e });
							setErrors({
								...errors,
								...validate(
									{ name: "country", value: e },
									{
										required: true,
									}
								),
							});
						}}
						value={info.country}
					/>
				</div>
				{/* City */}
				<div className="col-6">
					<SelectField
						label={commons.city}
						placeholder={commons.cityPlaceholder}
						errors={errors.city}
						color={errors.city?.required ? "danger" : ""}
						options={cities}
						id="basic-cities"
						onChange={(e) => {
							handleChange({ name: "city", value: e });
							setErrors({
								...errors,
								...validate(
									{ name: "city", value: e },
									{
										required: true,
									}
								),
							});
						}}
						value={info.city}
					/>
				</div>
				{/* Area */}
				<div className="col-6">
					<TextField
						label={commons.area}
						errors={errors.area}
						color={errors.area?.required ? "danger" : ""}
						options={areas}
						id="basic-areas"
						onChange={(e) => {
							handleChange({ name: "area", value: e.target.value });
							setErrors({
								...errors,
								...validate(
									{ name: "area", value: e.target.value },
									{
										required: true,
									}
								),
							});
						}}
						value={info.area}
					/>
				</div>
				{/* address */}
				<div className="col-6">
					<TextField
						label={commons.address}
						placeholder={commons.addressPlaceholder}
						value={info.address}
						name="address"
						id="basic-address"
						onChange={(e) => {
							handleChange(e);
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									min: 3,
								}),
							});
						}}
						min={3}
						color={
							errors.address?.required || errors.address?.min ? "danger" : ""
						}
						errors={errors.address}
					/>
				</div>
				{/* distance */}
				{/* {info.country?.value === 966 && (
					<div className="col-6">
						<TextField
							label={commons.distance}
							placeholder={commons.distancePlaceholder}
							value={info.distance}
							name="distance"
							id="basic-distance"
							onChange={(e) => {
								handleChange(e);
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										number: true,
									}),
								});
							}}
							color={
								errors.distance?.required || errors.distance?.number
									? "danger"
									: ""
							}
							errors={errors.distance}
						/>
					</div>
				)} */}

				{/* postalCode */}
				<div className="col-6">
					<TextField
						label={commons.postalCode}
						placeholder={commons.postalCodePlaceholder}
						value={info.postalCode}
						name="postalCode"
						id="basic-postalCode"
						onChange={(e) => {
							handleChange(e);
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									number: true,
								}),
							});
						}}
						min={3}
						color={
							errors.postalCode?.required || errors.postalCode?.number
								? "danger"
								: ""
						}
						errors={errors.postalCode}
					/>
				</div>
				{/* Phone Number */}
				<div className="col-md-6 pl-md-2">
					<TextField
						label={supplierDetails.phoneNumber}
						placeholder={supplierDetails.phoneNumberPlaceholder}
						name="phone"
						id="basic-phone"
						value={info.phone}
						extraText={info.country.value ? "+" + info.country.value : "---"}
						extraTextPosition="prepend"
						onChange={(e) => {
							handleChange(e);
							setErrors({
								...errors,
								...validate(
									{
										name: "phone",
										value: validatePhone(
											e.target.value,
											countries.find(
												(country) => country.value === info.country.value
											)?.code
										),
									},
									{
										required: true,
										phone: true,
									}
								),
							});
						}}
						errors={errors.phone}
						color={
							errors?.phone?.required || errors?.phone?.phone ? "danger" : ""
						}
					/>
				</div>
				{/* Phone Number */}
				<div className="col-md-6 pl-md-2">
					<TextField
						label={supplierDetails.phoneNumber}
						placeholder={supplierDetails.phoneNumberPlaceholder}
						name="secondPhone"
						id="basic-secondPhone"
						value={info.secondPhone}
						extraText={info.country.value ? "+" + info.country.value : "---"}
						extraTextPosition="prepend"
						onChange={(e) => {
							handleChange(e);
							setErrors({
								...errors,
								...validate(
									{
										name: "secondPhone",
										value: validatePhone(
											e.target.value,
											countries.find(
												(country) => country.value === info.country.value
											).code
										),
									},
									{
										phone: true,
									}
								),
							});
						}}
						errors={errors.secondPhone}
						color={
							errors.secondPhone?.required || errors.secondPhone?.phone
								? "danger"
								: ""
						}
					/>
				</div>
				{/*  Reservations Email address  */}
				<div className="col-6">
					<TextField
						label={supplierDetails.reservationsEmail}
						placeholder={supplierDetails.emailPlaceholder}
						type="text"
						name="email"
						id="basic-email"
						errors={errors.email}
						color={
							errors.email?.required || errors.email?.email ? "danger" : ""
						}
						onChange={(e) => {
							handleChange(e);
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									email: true,
								}),
							});
						}}
						value={info.email}
					/>
				</div>
				{/*  Business Email */}
				<div className="col-4">
					<TextField
						label={supplierDetails.officialEmail}
						placeholder={supplierDetails.emailPlaceholder}
						type="text"
						name="officialEmail"
						id="basic-officialEmail"
						errors={errors.officialEmail}
						color={
							errors.officialEmail?.required || errors.officialEmail?.email
								? "danger"
								: ""
						}
						onChange={(e) => {
							handleChange(e);
							// if (!isSameEmail) {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									email: true,
								}),
							});
							// }
						}}
						value={info.officialEmail}
						disabled={isSameEmail}
					/>
				</div>
				{/* isSame */}
				<div className="col-2 d-flex align-items-center">
					<div className="form-group form-check">
						<input
							id="basic-info-officialEmail"
							type="checkbox"
							className="form-check-input"
							onChange={(e) => {
								setIsSameEmail(!isSameEmail);
								setErrors({
									...errors,
									...validate(
										{ name: "officialEmail", value: info.officialEmail },
										{
											required: false,
											email: false,
										}
									),
								});
							}}
							defaultChecked={isSameEmail}
						/>
						<label className="form-check-label text-capitalize">
							{property.isSame}
						</label>
					</div>
				</div>
			</div>
			<div className="header-box my-3">{property.location}</div>
			<div className="col-6">
				<TextField
					label={property.xCord}
					placeholder={property.xCordPlaceholder}
					value={info.latitude}
					name="latitude"
					id="basic-latitude"
					onChange={(e) => {
						handleChange(e);
						setErrors({
							...errors,
							...validate(e.target, {
								required: true,
							}),
						});
					}}
					color={
						errors?.latitude?.required || errors?.latitude?.number
							? "danger"
							: ""
					}
					errors={errors?.latitude}
				/>
				<TextField
					label={property.yCord}
					placeholder={property.yCordPlaceholder}
					value={info.longitude}
					name="longitude"
					id="basic-longitude"
					onChange={(e) => {
						handleChange(e);
						setErrors({
							...errors,
							...validate(e.target, {
								required: true,
							}),
						});
					}}
					color={
						errors?.longitude?.required || errors?.longitude?.number
							? "danger"
							: ""
					}
					errors={errors?.longitude}
				/>
			</div>
			<div className="col-6">
				<div className="map"></div>
			</div>
			<div className="row my-5 mx-0 justify-content-start flex-nowrap w-25">
				<button onClick={reset} className="btn mx-2 cancel" id="basic-cancel">
					cancel
				</button>
				<button
					className="btn btn-primary px-5"
					id="basic-submit"
					onClick={submit}
				>
					continue
				</button>
			</div>
		</>
	);
}

export default BasicInfo;
