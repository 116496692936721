import React, { createContext, useContext, useReducer } from "react";
const NetworkReducerContext = createContext();
const NetworkReducerDispatchContext = createContext();
const initialState = {};

function NetworkReducer(state, action) {
	switch (action.type) {
		case "dashboardState": {
			return { ...state, dashboardState: action.payload };
		}

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function useNetworkState() {
	const context = useContext(NetworkReducerContext);
	if (context === undefined) {
		throw new Error(
			"useNetworkReducerState must be used within a NetworkReducerProvider"
		);
	}
	return context;
}

function useNetworkDispatch() {
	const context = useContext(NetworkReducerDispatchContext);
	if (context === undefined) {
		throw new Error(
			"useNetworkReducerDispatch must be used within a NetworkReducerProvider"
		);
	}
	return context;
}

function NetworkProvider({ children }) {
	const [state, dispatch] = useReducer(NetworkReducer, initialState);
	return (
		<NetworkReducerContext.Provider value={state}>
			<NetworkReducerDispatchContext.Provider value={dispatch}>
				{children}
			</NetworkReducerDispatchContext.Provider>
		</NetworkReducerContext.Provider>
	);
}

export { NetworkProvider, useNetworkState, useNetworkDispatch };
