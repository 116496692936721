import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Locale from "translations";
import Request from "./Request";
import Transaction from "./Transaction";
import WalletTable from "./WalletTable";
import Pagination from "components/shared/Pagination";
import Model from "components/model";
import TextField from "components/shared/TextField";
import walletAdd from "assets/images/wallet-add.svg";
import ViewPopup from "../../modal/viewPopup";
import AddPopup from "../../modal/addPopup";

export default function WalletNetwork() {
	const { wallet, requests } = Locale;
	const [Wallet, setWallet] = useState({
		id: 1,
		type: "credit20",
		currency: "EGP",
		ref_num: "511021",
		amount: "15000",
		limit: "84771",
	});

	const [transactions, setTransactions] = useState([
		{
			transaction_number: "5151",
			transaction_type: "100",
			amount: "80",
			action_type: "888",
			created_at: "22-05-2001",
			status: "approved",
		},
	]);
	const [requestes, setRequestes] = useState([
		{
			id: 1,
			companyName: "Company Name",
			receiptNumber: "#DFG12456",
			balance: "2500 EGP",
			created: "22-05-2001",
			status: "Pending",
		},
	]);
	const [selectType, setSelectType] = useState("transactions");
	const { company_id } = useParams();
	const [meta, setMeta] = useState(null);
	const [page, setPage] = useState(1);

	const [isOpen, setIsOpen] = useState(false);
	const [isOpenView, setIsOpenView] = useState(false);
	const [popupInfo, setpopupInfo] = useState({});

	const toggle = (wallet_id = null, currency = null, account = null, type) => {
		setpopupInfo({
			wallet_id,
			currency,
			account,
			type,
		});
		setIsOpen(!isOpen);
	};

	const toggleView = (
		wallet_id = null,
		amount = null,
		transaction_number = null
	) => {
		setpopupInfo({
			wallet_id,
			amount,
			transaction_number,
		});
		setIsOpenView(!isOpenView);
	};

	useEffect(() => {
		const getDeals = async () => {
			// if (selectType === "requests" ) {
			// 	const res = await listWalletsRequests({
			// 		payment_card_id: Wallet.id,
			// 		company_id,
			// 		page,
			// 		status: "pending",
			// 	});
			// 	if (res?.status === 200) {
			// 		setRequestes(res.data.data);
			// 		setMeta(res?.data?.meta);
			// 	}
			// } else {
			// 	const res = await listWalletsTransactions({
			// 		payment_card_id: Wallet.id,
			// 		company_id,
			// 		page,
			// 	});
			// 	if (res?.status === 200) {
			// 		setTransactions(res.data.data);
			// 		setMeta(res?.data?.meta);
			// 	}
			// }
		};
		getDeals();
	}, [selectType, page]);

	//to use two button confirm and reject
	const acceptRequst = async (id, receipt_number) => {
		// const res = await acceptRequests(id, { receipt_number });
		setSelectType("transactions");
	};

	const rejectRequst = async (id, receipt_number) => {
		// const res = await rejectRequests(id, { receipt_number });
		setSelectType("transactions");
	};

	const goTo = (page) => {
		setPage(page);
	};

	const allTransactions = useMemo(() => {
		return transactions?.length > 0 ? (
			transactions.map((transaction) => (
				<Transaction
					key={transaction.id}
					transaction={transaction}
					isOpenView={isOpenView}
					toggleView={toggleView}
				/>
			))
		) : (
			<tr>
				<td colSpan={6} className="CompanyHead-title text-gray text-center">
					{/* {backOffice.noResult} */}
					No Result
				</td>
			</tr>
		);
	}, [transactions]);

	const allRequestes = useMemo(() => {
		return requestes?.length > 0 ? (
			requestes.map((request) => (
				<Request
					key={request.id}
					request={request}
					acceptRequst={acceptRequst}
					rejectRequst={rejectRequst}
					isOpenView={isOpenView}
					toggleView={toggleView}
				/>
			))
		) : (
			<tr>
				<td colSpan={6} className="CompanyHead-title text-gray text-center">
					No Result
				</td>
			</tr>
		);
	}, [requestes]);

	const addBalance = async () => {
		let data = {
			transaction_type: "add",
			amount: popupInfo.amount,
			payment_card_id: popupInfo.wallet_id,
			company_id,
		};

		//const res = await addBalances(data);
		// if (res.status === 200) {
		//   getNotification(res?.data?.message);
		//   toggle();
		// }
	};
	return (
		<div className="container mt-4 collapse-border network-wallet">
			<div className="row p-0 m-0 justify-content-between ">
				<div className="col-2 bg-wallet-name font-weight-bolder d-flex align-items-center justify-content-center ">
					{Wallet?.currency + " Wallet"}
				</div>

				<div className="col-2 p-2">
					<p className="m-0 font-weight-bolder"> {wallet?.AccountNo}</p>
					<p className="m-0"> {Wallet?.ref_num}</p>
				</div>

				<div className="col-2 p-2">
					<p className="m-0 font-weight-bolder">{wallet?.amount}</p>
					<p className="m-0">
						{Wallet?.amount} {Wallet?.currency}
					</p>
				</div>

				<div className={`col-2 d-flex align-items-center`}>
					<button
						className="companies-btn btn w-100 rounded  f-15 pointer"
						onClick={() => {
							toggle(Wallet.id, Wallet.currency, Wallet.ref_num);
						}}
					>
						{wallet?.AddBalance}
					</button>
				</div>
			</div>
			<div className="border-top px-3">
				<div className=" d-flex justify-content-center mt-4 mb-2 ">
					<div className="d-flex justify-content-between  rounded-pill shadow-sm tab-network-wallet">
						<span
							className={` p-2 rounded-pill px-5 pointer font-weight-bolder text-uppercase ${selectType === "transactions" ? "bg-wallet-name" : ""
								}`}
							onClick={() => {
								setSelectType("transactions");
							}}
						>
							{wallet.Transactions}
						</span>

						<span
							className={` p-2 rounded-pill px-5 pointer  font-weight-bolder text-uppercase ${selectType === "requests" ? "bg-wallet-name" : ""
								}`}
							onClick={() => {
								setSelectType("requests");
							}}
						>
							{wallet.Requests}
						</span>
					</div>
				</div>

				<WalletTable
					thead={
						selectType !== "requests"
							? [
								wallet.TransactionsID,
								wallet.Type,
								wallet.Balance,
								wallet.Method,
								wallet.Created,
								wallet.Status,
								"",
							]
							: [
								requests.companyName,
								requests.receiptNumber,
								requests.Balance,
								requests.created,
								requests.status,
								requests.actions,
							]
					}
					tbody={selectType === "requests" ? allRequestes : allTransactions}
				/>
			</div>
			<Pagination info={meta} goTo={goTo} items={10} />

			{/**start modal view  transactian*/}
			<ViewPopup
				isOpen={isOpenView}
				toggle={toggleView}
				popupInfo={popupInfo}
			/>
			{/**end modal view  transactian*/}

			{/**start modal add balance*/}
			<AddPopup
				isOpen={isOpen}
				toggle={toggle}
				popupInfo={popupInfo}
				setpopupInfo={setpopupInfo}
				addBalance={addBalance}
			/>
			{/**end modal add balance*/}
		</div>
	);
}
