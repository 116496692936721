import React, { useEffect, useState } from "react";
import Locale from "translations";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import validate from "helpers/validate";
const locale = localStorage.getItem("currentLocale");

function Room({ room }) {
	const { rooms } = Locale;
	const [disabled, setDisabled] = useState(true);
	return (
		<>
			<td>
				<TextField
					label={rooms.selectType}
					placeholder={rooms.selectType}
					hasLabel={false}
					value={room.type.name}
					disabled
					name="type"
					id="room-type"
				/>
			</td>
			{/* <td>
				<SelectField
					label={rooms.selectName}
					placeholder={rooms.selectName}
					onChange={(e) => {}}
					options={lookups.names}
					hasLabel={false}
					value={room.name}
					name="name"
					id="room-name"
					onChange={(e) => {
						handleChange({ name: "name", value: e });
						setErrors({
							...errors,
							...validate({ name: "name", value: e }, { required: true }),
						});
					}}
					color={errors.name?.required ? "danger" : ""}
					errors={errors.name}
				/>
			</td> */}
			<td>
				<div className="position-relative">
					<TextField
						label={rooms.selectName}
						placeholder={rooms.selectName}
						value={room.custom_name}
						name="customName"
						id="room-customName-input"
						disabled
						hasLabel={false}
					/>
				</div>
			</td>
			<td>
				<TextField
					label={rooms.selectView}
					placeholder={rooms.selectView}
					onChange={(e) => {}}
					hasLabel={false}
					value={room.view.name}
					disabled
					name="view"
					id="room-view"
				/>
			</td>
			<td>
				<TextField
					label={rooms.selectSmokingPolicy}
					placeholder={rooms.selectSmokingPolicy}
					hasLabel={false}
					value={room.smoking_policy}
					disabled
					name="smokingPolicy"
					id="room-smokingPolicy"
				/>
			</td>
			<td className="fixRoom">
				<div className="w-100">
					<TextField
						label={rooms.count}
						placeholder={rooms.count}
						disabled
						value={room.count}
						name="count"
						id="room-count"
						hasLabel={false}
					/>
				</div>
			</td>
			<td className="fixRoom">
				<div className="w-100">
					<TextField
						label={rooms.MaxAdults}
						placeholder={rooms.MaxAdults}
						disabled
						value={room.max_adults}
						name="max_adults"
						id="room-count"
						hasLabel={false}
					/>
				</div>
			</td>
			<td className="fixRoom">
				<div className="w-100">
					{" "}
					<TextField
						label={rooms.MaxChildrens}
						placeholder={rooms.MaxChildrens}
						value={room.max_children}
						disabled
						name="max_children"
						id="room-count"
						hasLabel={false}
					/>
				</div>
			</td>
			<td className="fixRoom">
				<div className="w-100">
					{" "}
					<TextField
						label={rooms.MaxAdultWithChild}
						placeholder={rooms.MaxAdultWithChild}
						disabled
						value={room.max_adults_with_children}
						name="max_adults_with_children"
						id="room-count"
						hasLabel={false}
					/>
				</div>
			</td>
		</>
	);
}

export default Room;
