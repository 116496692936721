import moment from "moment";
import React, { useEffect, useState } from "react";
import Filter from "./Filter";
import { fetchCountries } from "services/lookups";
import Pagination from "components/shared/Pagination";
import TableContent from "./TableContent";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Locale from "translations";
import walletAdd from "assets/images/wallet-add.svg";
import Model from "components/model";
import ViewPopup from "../modal/viewPopup";
export default function BankRequest() {
	const { requests, wallet } = Locale;
	const { status } = useParams();
	const [networkList, setNetworkList] = useState([
		{
			id: 1,
			companyName: "Company Name",
			receiptNumber: "#DFG12456",
			balance: "2500 EGP",
			created: "22-05-2001",
			status: "Pending",
		},
	]);
	const [countries, setCountries] = useState([]);
	const locale = localStorage.getItem("currentLocale") || "en";
	const [meta, setMeta] = useState();
	const [filter, setFilter] = useState({
		id: "",
		destination_id: null,
		visa_type_id: null,
		request_date: null,
		page: null,
	});
	const [visaType, setvisaType] = useState([]);
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => {
		setIsOpen(!isOpen);
	};
	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchCountries();
			const format = res?.data.map((t) => ({
				value: t.id,
				label: t.names[locale],
			}));
			setCountries(format);
		}
		fetchLookups();
	}, []);

	useEffect(() => {
		const fetcNetworkList = async () => {
			// const response = await listWaitingVisaRequests({
			// 	...filter,
			// 	destination_id: filter.destination_id?.value,
			// 	visa_type_id: filter.visa_type_id?.value,
			// 	request_date: filter.request_date
			// 		? moment(filter.request_date).format("YYYY-MM-DD")
			// 		: "",
			// 	status: "waiting",
			// });
			// if (response.status === 200 || response.status === 201) {
			// 	setNetworkList(response.data.data);
			// 	setMeta(response.data.meta);
			// }
		};

		fetcNetworkList();
	}, [filter]);

	const listRequests =
		networkList.length > 0 ? (
			networkList?.map((item, index) => {
				return <TableContent data={item} key={index} toggle={toggle} />;
			})
		) : (
			<tr>
				<td colSpan="10" className="text-center my-3">
					<div className="product-build__product-no-data">
						<i className="fas fa-info-circle fa-lg"></i>{" "}
						<h4>{wallet.noResult}</h4>
					</div>
				</td>
			</tr>
		);

	const goTo = (page) => {
		setFilter({ ...filter, page: page });
	};
	return (
		<div>
			<div className="row m-0 p-0 ">
				<div className="col-12 d-flex flex-column align-items-end  justify-content-start">
					<Filter
						filter={filter}
						setFilter={setFilter}
						countries={countries}
						visaType={visaType}
					/>
				</div>
				<div className="w-100">
					<div class="card-body p-0">
						<div class="table-responsive">
							<table className="table mb-0 f-15">
								<thead className="table-light">
									<tr className="">
										<th>{requests.companyName}</th>
										<th>{requests.receiptNumber}</th>
										<th>{requests.Balance}</th>
										<th>{requests.created}</th>
										<th>{requests.status}</th>
										<th>{requests.actions}</th>
									</tr>
								</thead>
								<tbody>{listRequests}</tbody>
							</table>{" "}
						</div>
					</div>
				</div>
				<div className="col-12"> </div>
				<Pagination info={meta} goTo={goTo} />
			</div>
			<ViewPopup isOpen={isOpen} toggle={toggle} />
		</div>
	);
}
