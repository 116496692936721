import Dashboard from "modules/HotelLayout/HomePage/Dashboard";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Translation from "translations";

function SubNav() {
	const { commons, teamManagement, reservations } = Translation;
	const { pathname } = useLocation();
	const [show, setShow] = useState(false);

	const showToggle = () => {
		setShow(!show);
	};
	return (
		<div className="bg-white text-black">
			<div className="container">
				<div className="d-flex route-block">
					<div>
						{/* <NavLink to="/" exact={true} activeClassName="is-active">
							{commons.homePage}
						</NavLink>
						<NavLink
							to="/team-management/users"
							exact={true}
							activeClassName="is-active"
						>
							{teamManagement.teamMangement}
						</NavLink> */}
						<NavLink to="/" exact={true} activeClassName="is-active">
							{/* {commons.homePage} */}
							Property
						</NavLink>
						<NavLink
							to="/manage-visas/waiting"
							exact={true}
							activeClassName="is-active"
						>
							Manage Visas
						</NavLink>
						<NavLink
							to="/transportation"
							exact={true}
							activeClassName="is-active"
						>
							Transportation
						</NavLink>
						<NavLink
							to="/ground-service"
							exact={true}
							activeClassName="is-active"
						>
							Ground Service
						</NavLink>
						<NavLink to="/my-network" exact={true} activeClassName="is-active">
							My Network
						</NavLink>
						{pathname !== "/login" ? (
							<div className="nav-item main-menu py-1">
								{/* <div
									className="nav-link btn btn-primary  m-auto"
									onClick={showToggle}
									style={{ width: "135px" }}
								>
									<i class="fas fa-bars" style={{ fontSize: "18px" }}></i>
									<span className="text-headline px-2">{"Menu"}</span>
								</div> */}
							</div>
						) : null}
						<Dashboard show={show} setShow={setShow} />

						{/* 			<NavLink
							to="/reservations"
							exact={true}
							activeClassName="is-active"
						>
							{reservations.reservations}
						</NavLink> */}
					</div>
				</div>
			</div>
		</div>
	);
}

export default SubNav;
