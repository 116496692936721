import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import React, { useEffect, useState } from "react";
import Locale from "translations";
import ViewRequestHeader from "./ViewRequestHeader";
import { Table } from "reactstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { createVisa, getToken, viewRequests } from "services/visa";
import { store } from "react-notifications-component";
import Model from "components/model";
import visaToken from "assets/images/visa.png";

export default function ViewRequest() {
	const { requests, backOffice } = Locale;
	const [searchVal, setSearchVal] = useState(null);
	const [ListOfChecked, setListOfChecked] = useState([]);
	const { status, id } = useParams();
	const [modalShow, setModalShow] = useState(false);
	const toggleModalShow = () => {
		setModalShow(!modalShow);
	};

	let history = useHistory();

	const { locale } = useGlobalState();
	const [passangers, setPassangers] = useState([]);
	const [header, setHeader] = useState({});

	const handelCheck = (passenger, flag) => {
		if (flag) {
			setListOfChecked([...ListOfChecked, passenger.id]);
		} else {
			let newListChecked = ListOfChecked.filter((id) => id != passenger.id);
			setListOfChecked(newListChecked);
		}
	};

	const selectAllItems = document.getElementsByClassName("select-all");
	const selectItems = document.getElementsByClassName("select-item");
	function selectAll(event) {
		const isSelectorChecked = event.target.checked;
		if (isSelectorChecked) {
			for (var item of selectItems) {
				item.checked = true;
			}
			setListOfChecked([
				...passangers.map((item) => {
					if (item.visa_number == null) {
						return item.id;
					}
				}),
			]);
		} else {
			for (let item of selectItems) {
				item.checked = false;
				setListOfChecked([]);
			}
		}
	}
	const fetchRequest = async () => {
		const response = await viewRequests(id);
		if (response.status === 200 || response.status === 201) {
			setPassangers(response.data.data.passengers);
			setHeader(response.data.data);
		}
	};
	useEffect(() => {
		fetchRequest();
	}, []);

	const [loader, setLoader] = useState(false);
	let x;
	const allPassengers =
		passangers?.length > 0
			? passangers?.map((passenger) => {
					x = ListOfChecked.includes(passenger.id);

					return (
						<tr>
							{status != "issued" ? (
								<td>
									<input
										type="checkbox"
										checked={ListOfChecked.includes(passenger.id)}
										className="select-item mx-1 insert-group-checkbox visa-checkbox"
										disabled={passenger?.visa_number}
										onChange={(e) => {
											handelCheck(passenger, e.target.checked);
										}}
									/>
								</td>
							) : (
								""
							)}
							<td> {passenger.passport_number}</td>
							<td>
								{locale === "en"
									? passenger.full_name_en
									: passenger.full_name_ar}
							</td>
							<td>{passenger.gender}</td>
							<td>{passenger.date_of_birth}</td>
							<td>{passenger.age} Years</td>
							<td>
								{passenger.relationship ? passenger.relationship[locale] : ""}
							</td>
							<td>{passenger.national_id}</td>
							<td>{passenger.nationality}</td>
							<td className="text-center">
								{!loader ? (
									passenger?.visa_number ? (
										<i class="fas fa-check text-success"></i>
									) : (
										"-"
									)
								) : x ? (
									<div class="spinner-border spinner-border-visa" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								) : passenger?.visa_number ? (
									<i class="fas fa-check text-success"></i>
								) : (
									"-"
								)}
							</td>
							<td>
								<Link to={`/manage-visas/${status}/${id}/${passenger.id}`}>
									<i class="fas fa-angle-right text-dark"></i>
								</Link>
							</td>
						</tr>
					);
			  })
			: [];

	{
		/*
			safa_key: "100000",
			email: "Botros1@safavisa.com",
			pwd: "123",
*/
	}
	const tokenSafaVisa = localStorage.getItem("VisaToken");

	const Verify = async () => {
		// let requestBody = {
		// 	safa_key: "9999",
		// 	email: "h.jabban@safavisa.com",
		// 	pwd: "2133549",
		// };

		if (tokenSafaVisa !== null) {
			setLoader(true);
			if (ListOfChecked?.length > 0) {
				//const response = await getToken(requestBody);
				const res = await createVisa(id, {
					token: tokenSafaVisa,
					//response?.data?.data?.token,
					passengers: [...ListOfChecked],
				});
				if (res?.status === 200 || res?.status === 201) {
					setLoader(false);
					store.addNotification({
						title: "Info!",
						message: `${res?.data?.msg ?? res?.data?.msg}`,
						type: "info",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
						onRemoval: () => {
							fetchRequest();
							setListOfChecked([]);
							selectAllItems[0].checked = false;
						},
					});
				}
			} else {
				store.addNotification({
					title: "Info!",
					message: `Select Item!`,
					type: "info",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 2000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			}
		} else {
			toggleModalShow();
		}
	};

	return (
		<div className="container-fluid px-2">
			<div className="py-2">
				<ViewRequestHeader header={header} />
			</div>

			<div className="mt-3 bg-white border rounded ">
				<header className=" p-3 border-bottom">
					<p className="m-0 font-weight-bold">
						{requests.Travelers} ({header.count_travellers}/{header.pax})
					</p>
				</header>

				<div className="d-flex   border-top px-3  align-items-center  justify-content-between visa-view-list ">
					<div className="col-4 p-0">
						{status != "issued" ? (
							<button className="btn btn-primary m-1" onClick={() => Verify()}>
								<i class="fas fa-check-circle"></i> {requests.visaIssued}
							</button>
						) : (
							""
						)}
					</div>

					<div className="col-6 m-0  justify-content-end d-flex align-items-center  ">
						{/* <button className="btn btn-primary  m-1">
							<i class="fas fa-file-excel mx-2"></i>
							{requests.ExportSelectedToExcel}
						</button> */}

						<div className=" w-50">
							<TextField
								hasLabel={false}
								placeholder={requests.search}
								value={searchVal}
								onChange={(e) => {
									setSearchVal(e.target.value);
								}}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="border">
				<div class="card-body p-0">
					<div class="table-responsive">
						<table className="table mb-0 f-13">
							<thead className="table-light">
								<tr>
									{status != "issued" ? (
										<th>
											<input
												type="checkbox"
												name="selectItem"
												className="select-all mx-1"
												onChange={selectAll}
											/>
										</th>
									) : (
										""
									)}

									<th>{requests.PassportNumber}</th>
									<th>{requests.Name}</th>
									<th>{requests.Gender}</th>
									<th>{requests.Birthday}</th>
									<th>{requests.Age}</th>
									<th>{requests.Relationship}</th>
									<th>{requests.NationalId}</th>
									<th>{requests.Nationality}</th>
									<th>Issued Visa</th>
									<th></th>
								</tr>
							</thead>
							<tbody>{allPassengers}</tbody>
						</table>
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-end my-4">
				<button
					className="btn btn-back mx-2 px-5"
					onClick={() => {
						history.push(`/manage-visas/${status}`);
					}}
				>
					{requests.back}
				</button>
			</div>
			{/**Modal */}
			<div>
				<Model
					isOpen={modalShow}
					title={"Note"}
					toggle={() => {
						toggleModalShow();
					}}
					hasFooter={false}
					//className="bulk-edit user-model"
					size="md"
				>
					<div className="container py-4">
						<div className="d-flex flex-column justify-content-center align-items-center  ">
							<div>
								<img src={visaToken} alt="" />
							</div>
							<p className="m-0 my-3">You must login with safa visa account</p>
							<div className="text-center w-100">
								<button
									className="btn btn-primary  w-75"
									onClick={() => {
										history.push("/company-profile");
									}}
								>
									LOGIN
								</button>
							</div>
						</div>
					</div>
				</Model>

				{/******* MOdel ***** */}
			</div>
			{/**End Modal */}
		</div>
	);
}
