import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { listPromotionsRoom } from "services/Promotions";
import Locale from "translations";
const locale = localStorage.getItem("currentLocale") || "en";

function ListPromotionsRoomOrNight() {
	const { id } = useParams();
	const { home, hotelLayout } = Locale;
	const [properties, setProperties] = useState({
		completed: []
	});

	useEffect(() => {
		async function fetchData() {
			const res = await listPromotionsRoom(id);
			if (res.status === 200) {
				setProperties({
					...properties,
					completed: res.data.data
				});
			}
		}
		fetchData();
	}, []);

	return (
		<>
			<div className="container list-rate">
				<div className="col-md-12 p-0">
					<div className="row">
						<div className="col-md-10 p-15">
							<p className="title">{hotelLayout.promotions.promotions}</p>
							<p className="sub-title">
								{hotelLayout.promotions.improveOccupancyRange}
							</p>
						</div>
						<div className="col-md-2">
							<Link
								to={`/dashboard/add-Room-Night/${id}`}
								className="btn btn-primary btn-add"
							>
								add new Promotions
							</Link>
						</div>
					</div>
				</div>

				{properties.completed.length > 0 && (
					<>
						{" "}
						<table className="table my-2 mt-3">
							<thead className="table-light">
								<tr className="bg-navy text-white">
									<th scope="col">Name</th>
									<th scope="col">Bookable period</th>
									<th scope="col">Status</th>
									<th scope="col">Paid room nights</th>
									<th scope="col">Free room nights</th>
									<th scope="col">{home.action}</th>
								</tr>
							</thead>
							<tbody>
								{properties.completed.length > 0 &&
									properties.completed.map((property) => {
										return (
											<tr className=" text-black" key={property.id}>
												<td>{property.promotion_name}</td>
												<td>
													{property.stay_from} {" => "}
													{property.stay_to}
												</td>
												<td>{property.status}</td>
												<td>{property.paid_count}</td>
												<td>{property.free_count}</td>

												<td>
													<Link
														className="btn-link-edit"
														to={`/dashboard/add-Room-Night/${id}/${property.id}`}
													>
														<i class="far fa-edit"></i>
													</Link>
													<Link className="btn-link-trash" to={`/`}>
														<i class="fas fa-stop-circle"></i>
													</Link>
													<Link className="btn-link-trash" to={`/`}>
														<i class="fas fa-trash"></i>
													</Link>
												</td>
											</tr>
										);
									})}
							</tbody>
						</table>{" "}
					</>
				)}
			</div>
		</>
	);
}

export default ListPromotionsRoomOrNight;
