import React, { useState } from "react";
import Locale from "translations";
import walletBlue from "assets/images/walletBlue.svg";
import Model from "components/model";

export default function TableContent({ data, toggle }) {
	const { reservations, wallet } = Locale;

	return (
		<tr className=" text-black">
			<td>{data.companyName}</td>
			<td>{data.receiptNumber}</td>
			<td>{data.balance}</td>
			<td>{data.created}</td>
			<td>{data.status}</td>

			<td className="text-primary " width={"20%"}>
				<div className="action d-flex">
					<i
						className="fas fa-eye mt-2 text-dark pointer f-18"
						onClick={() => {
							toggle();
						}}
					></i>
					<button className="btn btn-success font-bold mx-2">
						{wallet.confirm}
					</button>
					<button className="btn btn-danger font-bold">{wallet.reject}</button>
				</div>
			</td>
		</tr>
	);
}
