import { useHotelLayoutDispatch } from "context/hotelLayout";
import Dashboard from "modules/HotelLayout/HomePage/Dashboard";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useParams, Link } from "react-router-dom";
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from "reactstrap";
import Locale from "translations";
import { getPropertyServices } from "./../../services/property";

function DashboardSubNav() {
	const { hotelLayout } = Locale;
	const { id, reservation_id } = useParams();
	const dispatch = useHotelLayoutDispatch();

	const [dashboardData, setDashboardData] = useState({});

	const [dropdownOpenRates, setDropdownOpenRates] = useState(false);
	const toggleRates = () => setDropdownOpenRates(!dropdownOpenRates);

	const [dropdownOpenProperty, setDropdownOpenProperty] = useState(false);
	const toggleProperty = () => setDropdownOpenProperty(!dropdownOpenProperty);

	const [dropdownOpenFinance, setDropdownOpenFinance] = useState(false);
	const toggleFinance = () => setDropdownOpenFinance(!dropdownOpenFinance);

	useEffect(() => {
		const getDashboardSubNav = async () => {
			if (id !== undefined) {
				const data = await getPropertyServices(id);
				if (data.status === 200) {
					setDashboardData(data.data);

					dispatch({ type: "dashboardState", payload: data.data });
				}
			}
		};
		getDashboardSubNav();
	}, [id]);
	const { pathname } = useLocation();
	const [show, setShow] = useState(false);

	const showToggle = () => {
		setShow(!show);
	};
	return (
		<>
			<div className="bg-white text-black">
				<div class="topnav position-relative m-0">
					<div class="container-fluid">
						<nav class="navbar navbar-light navbar-expand-lg topnav-menu">
							<div class="collapse navbar-collapse" id="topnav-menu-content">
								<ul class="navbar-nav">
									<li class="nav-item dropdown">
										<Link
											className="nav-link dropdown-toggle arrow-none"
											to={`/dashboard/${id}`}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
												class="feather feather-home"
											>
												<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
												<polyline points="9 22 9 12 15 12 15 22"></polyline>
											</svg>
											<span>{hotelLayout.dashboard.dashboard}</span>
										</Link>
									</li>

									<li class="nav-item dropdown">
										<span
											class="nav-link dropdown-toggle arrow-none"
											id="topnav-pages"
											role="button"
										>
											<span data-key="t-apps">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													stroke-width="2"
													stroke-linecap="round"
													stroke-linejoin="round"
													class="feather feather-briefcase"
												>
													<rect
														x="2"
														y="7"
														width="20"
														height="14"
														rx="2"
														ry="2"
													></rect>
													<path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
												</svg>
												<span>
													{hotelLayout.dashboard.rates} &amp;{" "}
													{hotelLayout.dashboard.availability}
												</span>
											</span>{" "}
											<div class="arrow-down"></div>
										</span>

										<div class="dropdown-menu" aria-labelledby="topnav-pages">
											<Link
												to={`/dashboard/list-Rate-Plan/${id}`}
												className="dropdown-item"
											>
												{hotelLayout.dashboard.ratePlan}
											</Link>
											<Link to={`/calendar/${id}`} className="dropdown-item">
												{hotelLayout.dashboard.calendar}
											</Link>
										</div>
									</li>

									<li class="nav-item dropdown">
										<Link
											to={`/dashboard/reservations-list/${id}`}
											exact={true}
											id="topnav-pages"
											className="nav-link dropdown-toggle arrow-none"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
												class="feather feather-grid"
											>
												<rect x="3" y="3" width="7" height="7"></rect>
												<rect x="14" y="3" width="7" height="7"></rect>
												<rect x="14" y="14" width="7" height="7"></rect>
												<rect x="3" y="14" width="7" height="7"></rect>
											</svg>
											{hotelLayout.dashboard.reservation}
										</Link>
									</li>

									<li class="nav-item dropdown">
										<span
											class="nav-link dropdown-toggle arrow-none"
											id="topnav-pages"
											role="button"
										>
											<span data-key="t-apps">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													stroke-width="2"
													stroke-linecap="round"
													stroke-linejoin="round"
													class="feather feather-box"
												>
													<path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
													<polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
													<line x1="12" y1="22.08" x2="12" y2="12"></line>
												</svg>
												{hotelLayout.dashboard.property}
											</span>{" "}
											<div class="arrow-down"></div>
										</span>
										<div class="dropdown-menu" aria-labelledby="topnav-pages">
											<Link
												to={`/dashboard/${id}/view-property/basic`}
												className="dropdown-item"
											>
												View Property
											</Link>
										</div>
									</li>

									<li class="nav-item dropdown">
										<span
											class="nav-link dropdown-toggle arrow-none"
											id="topnav-pages"
											role="button"
										>
											<span data-key="t-apps">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													stroke-width="2"
													stroke-linecap="round"
													stroke-linejoin="round"
													class="feather feather-file-text"
												>
													<path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
													<polyline points="14 2 14 8 20 8"></polyline>
													<line x1="16" y1="13" x2="8" y2="13"></line>
													<line x1="16" y1="17" x2="8" y2="17"></line>
													<polyline points="10 9 9 9 8 9"></polyline>
												</svg>
												{hotelLayout.dashboard.finance}
											</span>{" "}
											<div class="arrow-down"></div>
										</span>
										<div class="dropdown-menu" aria-labelledby="topnav-pages">
											<Link
												to={`/dashboard/${id}/view-property/basic`}
												className="dropdown-item"
											>
												Another Action
											</Link>
										</div>
									</li>
								</ul>
							</div>
						</nav>
					</div>
				</div>

				{/* <div className="d-flex route-block">
					<div>
						<Link
							to={`/dashboard/${id}`}
							exact={true}
							activeClassName="is-active"
						>
							{hotelLayout.dashboard.dashboard}
						</Link>
					</div>

					<div>
						<Dropdown isOpen={dropdownOpenRates} toggle={toggleRates}>
							<DropdownToggle nav caret>
								{hotelLayout.dashboard.rates} &amp;{" "}
								{hotelLayout.dashboard.availability}
							</DropdownToggle>
							<DropdownMenu>
								<DropdownItem>
									<Link to={`/calendar/${id}`} className="p-0">
										{hotelLayout.dashboard.calendar}
									</Link>
								</DropdownItem>
								<DropdownItem divider />
								<DropdownItem>
									<Link
										to={`/dashboard/list-Rate-Plan/${id}`}
										className="p-0"
									>
										{hotelLayout.dashboard.ratePlan}
									</Link>
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</div>
		
					<div>
						<Link
							to={`/dashboard/reservations-list/${id}`}
							exact={true}
							activeClassName="is-active"
						>
							{hotelLayout.dashboard.reservation}
						</Link>
					</div>
					<div>
						<Dropdown isOpen={dropdownOpenProperty} toggle={toggleProperty}>
							<DropdownToggle nav caret>
								{hotelLayout.dashboard.property}
							</DropdownToggle>
							<DropdownMenu>
								<DropdownItem>Header</DropdownItem>
								<DropdownItem divider />
								<DropdownItem className="p-0">
									<Link to={`/dashboard/${id}/view-property/basic`}>
										View Property
									</Link>
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</div>
					<div>
						<Dropdown isOpen={dropdownOpenFinance} toggle={toggleFinance}>
							<DropdownToggle nav caret>
								{hotelLayout.dashboard.finance}
							</DropdownToggle>
							<DropdownMenu>
								<DropdownItem>Header</DropdownItem>
								<DropdownItem divider />
								<DropdownItem>Another Action</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</div>
				</div>  */}
			</div>

			<div className="hotel-box">
				<div className="">
					<div
						className="head px-2"
						style={{ justifyContent: "space-between" }}
					>
						<h5 className="title d-flex m-0">
							{dashboardData.name} <div className="bookable my-1">Bookable</div>
						</h5>

						<div className="text-transform-DU">
							<i
								class="fas fa-calendar-alt mx-1"
								style={{ fontSize: "16px", color: "#324356" }}
							></i>{" "}
							contract duration : {dashboardData?.contract?.duration_from} to{" "}
							{"  "}
							{dashboardData?.contract?.duration_to}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default DashboardSubNav;
