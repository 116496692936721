import React from "react";
import Locale from "translations";

export default function Pagination({ info, goTo }) {
	const { commons } = Locale;

	return (
		<>
			{info?.total > info?.per_page ? (
				<div className="d-flex justify-content-between align-items-center my-2 mr-2 ml-2">
					<div className="text-blue-black font-weight-bold text-caption">
						{commons.show} {info?.from} {commons.to} {info?.to} {commons.of}{" "}
						{info?.total} {commons.entries}
					</div>
					<nav aria-label="Page navigation example">
						<ul className="pagination my-4 row">
							{info?.links?.map((page) => {
								let string = page?.url?.lastIndexOf("page=");
								let goToPage = page?.url?.substring(string + 5);
								return (
									<li
										key={`page-${page.label} `}
										className={`${
											page.active ? "page-item active" : "page-item"
										}   ${page.url == null ? "disabled-li" : ""}`}
										onClick={() =>
											goToPage != undefined ? goTo(goToPage) : false
										}
									>
										<span
											style={{ cursor: "pointer" }}
											dangerouslySetInnerHTML={{ __html: page.label }}
											className={`page-link ${
												page.url === null ? "false" : ""
											}`}
										></span>
									</li>
								);
							})}
						</ul>
					</nav>
				</div>
			) : null}
		</>
	);
}

// import React from "react";
// import Locale from "translations";

// export default function Pagination(props) {
// 	const { commons } = Locale;
// 	return (
// 		<>
// 			{props.info?.total > 10 ? (
// 				<div className="d-flex justify-content-between align-items-center mb-2 mr-2 ml-2">
// 					<div className="text-body">
// 						{commons.show} {props.info?.from} {commons.of} {props.info?.total}{" "}
// 						{commons.entries}
// 					</div>
// 					<nav aria-label="Page navigation example">
// 						<ul className="pagination">
// 							{props.info?.links?.map((page) => {
// 								let string;
// 								string = page.url?.charAt(page.url.length - 1);
// 								return (
// 									<li
// 										key={`page-${page.label}`}
// 										className={page.active ? "page-item active" : "page-item"}
// 										onClick={() => {
// 											props.goTo(string);
// 										}}
// 									>
// 										<span
// 											style={{ cursor: "pointer" }}
// 											dangerouslySetInnerHTML={{ __html: page.label }}
// 											className={`page-link ${
// 												page.url === null ? "false" : ""
// 											}`}
// 										></span>
// 									</li>
// 								);
// 							})}
// 						</ul>
// 					</nav>
// 				</div>
// 			) : null}
// 		</>
// 	);
// }
