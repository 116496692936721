import classnames from "classnames";
import { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Locale from "translations";

import companyBlack from "./../../assets/images/inventory/companyBlack.svg";
import companyColored from "./../../assets/images/inventory/companyColored.svg";
import safaBlack from "./../../assets/images/inventory/safaBlack.svg";
import safaColored from "./../../assets/images/inventory/safaColored.svg";
import { Link } from "react-router-dom";
import ViewCompany from "./ViewCompany";
import ViewSafa from "./ViewSafa";
import ViewUser from "./ViewUser";

export default function CompanyAccountsView() {
	const [activeTab, setActiveTab] = useState("1");

	const { messages, companyDetails } = Locale;
	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	return (
		<>
			<div className="team-management-tabs container mt-10  interest bg-white p-0">
				<div className="tab-icons d-flex justify-content-between">
					<Nav tabs className="w-100">
						<NavItem>
							<NavLink
								className={` d-flex align-items-center justify-content-center border-0  p-0 ${classnames(
									{
										active: activeTab === "1",
									}
								)}`}
								onClick={() => {
									toggle("1");
								}}
								// disabled
							>
								<div className="product-build-list-item ">
									<img
										src={activeTab === "1" ? companyColored : companyBlack}
										alt=""
									/>
									<span className="text mx-1">Supplier Profile</span>
								</div>
							</NavLink>
						</NavItem>

						<NavItem>
							<NavLink
								className={` d-flex align-items-center justify-content-center border-0  p-0 ${classnames(
									{
										active: activeTab === "3",
									}
								)}`}
								onClick={() => {
									toggle("3");
								}}
								// disabled
							>
								<div className="product-build-list-item ">
									<img
										src={activeTab === "3" ? companyColored : companyBlack}
										alt=""
									/>
									<span className="text mx-1">User Profile </span>
								</div>
							</NavLink>
						</NavItem>

						{/* <NavItem className="">
							<NavLink
								className={` d-flex align-items-center justify-content-center border-0  p-0 ${classnames(
									{
										active: activeTab === "2",
									}
								)}`}
								onClick={() => {
									toggle("2");
								}}
							>
								<div className="product-build-list-item">
									<img
										src={activeTab === "2" ? safaColored : safaBlack}
										alt=""
										className="mx-1"
									/>

									<span className="text mx-1">{messages?.safa}</span>
								</div>
							</NavLink>
						</NavItem> */}
					</Nav>
					{activeTab === "1" ? (
						<div className="w-25 d-flex align-items-center flex-column justify-content-center px-2">
							{/* <Link className="btn bg-nxt w-100 " to={"/edit-company-profile"}>
								{companyDetails?.editProfile}
							</Link> */}
						</div>
					) : null}
				</div>

				<TabContent activeTab={activeTab} style={{ minHeight: "100%" }}>
					<TabPane tabId="1">
						{activeTab === "1" ? <ViewCompany /> : null}
					</TabPane>

					<TabPane tabId="3">{activeTab === "3" ? <ViewUser /> : null}</TabPane>

					<TabPane tabId="2">{activeTab === "2" ? <ViewSafa /> : null}</TabPane>
				</TabContent>
			</div>
		</>
	);
}
