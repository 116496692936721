import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import TimePickerField from "components/shared/TimePickerField";
import { usePropertyDispatch, usePropertyState } from "context/property";
import { formatGetPolicies, formatPostPolicies } from "data/property";
import validate, { isFormValid } from "helpers/validate";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useParams } from "react-router";
import { FormGroup, Input, Label } from "reactstrap";
import { fetchCountries, fetchCurrencies } from "services/lookups";
import { addPolicies, editPolicies } from "services/property";
import Locale from "translations";
import CancelModal from "./CancelModal";
import { Tax } from "./Tax";

function Polices() {
	const { id } = useParams();
	const { polices } = Locale;
	const { propertyDetails } = usePropertyState();
	const propertyDispatch = usePropertyDispatch();
	const history = useHistory();
	const [canceled, setCanceled] = useState(false);
	const [currencyList, setCurrencyList] = useState([]);

	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [taxes, setTaxes] = useState([
		{
			type: "",
			name: "",
			rate: "",
		},
	]);

	const [allPolices, setAllPolices] = useState({
		checkInFrom: "",
		checkInTo: "",
		checkOutFrom: "",
		checkOutTo: "",
		petsCharges: "",
		haveChildren: "no",
		petsAllowed: "",
		tax_type: 1,
		tax: "",
	});

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "checkInFrom", value: allPolices.checkInFrom },
				{ required: true }
			),

			...validate(
				{ name: "checkOutFrom", value: allPolices.checkOutFrom },
				{ required: true }
			),

			...validate(
				{ name: "haveChildren", value: allPolices.haveChildren },
				{ required: false }
			),
			...validate(
				{ name: "petsAllowed", value: allPolices.petsAllowed },
				{ required: true }
			),
		});
	};

	const setTax = (i, name, value) => {
		debugger;
		let taxesClone = [...taxes];
		taxesClone[i] = {
			...taxesClone[i],
			[name]: value,
		};
		setTaxes(taxesClone);
	};

	// fetch Currencies
	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchCurrencies();

			const format = res?.data?.data.map((t) => ({
				value: t.currency_code,
				label: t.currency_code,
			}));
			setCurrencyList(format);
		}
		fetchLookups();
	}, []);

	const deleteTax = (index) => {
		let taxesClone = [...taxes];
		taxesClone.splice(index, 1);
		setTaxes(taxesClone);
	};

	const addTax = () => {
		setTaxes([
			...taxes,
			{
				type: "",
				name: "",
				rate: "",
			},
		]);
	};

	useEffect(() => {
		if (id && propertyDetails) {
			const { arrivalPolicy, otherPolicies, taxPolicies } =
				propertyDetails.property;
			if (arrivalPolicy && otherPolicies && taxPolicies) {
				const polices = {
					...arrivalPolicy,
					...otherPolicies,
				};
				const formatted = formatGetPolicies(
					polices,
					taxPolicies,
					propertyDetails
				);
				setTaxes(formatted.taxes);
				setAllPolices(formatted);
			}
		}
	}, [propertyDetails]);

	const isTaxesValid = () => {
		let isValid = true;
		taxes.forEach((tax) => {
			const { type, name, rate } = tax;
			if (Object.values({ type, name, rate }).some((v) => v === "")) {
				isValid = false;
			}
		});
		return isValid;
	};

	useEffect(async () => {
		if (isFormValid(errors)) {
			if (id && propertyDetails?.property?.arrivalPolicy) {
				//
				debugger;
				const data = formatPostPolicies(allPolices, taxes);
				const res = await editPolicies(id, data);

				if (res.status === 201 || res.status === 200) {
					// dispatch({ type: "progress", step: "6" });
					propertyDispatch({ type: "propertyDetails", payload: res.data });
					store.addNotification({
						title: "info!",
						message: res.data.status,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
					history.push("/properties/verifying");
				}
			} else {
				debugger;
				const data = formatPostPolicies(allPolices, taxes);
				const res = await addPolicies(id, data);
				if (res.status === 201 || res.status === 200) {
					// dispatch({
					// 	type: "progress",
					// 	step: "6",
					// 	current: res.data.property_id,
					// });
					propertyDispatch({ type: "propertyDetails", payload: res.data });
					store.addNotification({
						title: "info!",
						message: res.data.status,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
					history.push("/properties/verifying");
				}
			}
		}
	}, [isErrorLoaded]);

	const submit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	const reset = () => {
		setCanceled(true);
	};

	return (
		<div>
			<CancelModal isOpen={canceled} toggle={setCanceled} />
			<div className="header-box my-3">{polices.arrivalPolicy}</div>
			<div className="row m-0">
				<div className="col-4">
					<TimePickerField
						label={polices.checkIn}
						fromValue={allPolices.checkInFrom}
						toValue={allPolices.checkInTo}
						id="polices-check-in"
						onFromChange={(value) => {
							setAllPolices({ ...allPolices, checkInFrom: value });
							setErrors({
								...errors,
								...validate(
									{ name: "checkInFrom", value: value },
									{ required: true }
								),
							});
						}}
						name="checkOut"
						color={errors.checkInFrom?.required ? "danger" : ""}
						errors={errors.checkInFrom}
					/>
				</div>
				<div className="col-4">
					<TimePickerField
						label={polices.checkOut}
						fromValue={allPolices.checkOutFrom}
						toValue={allPolices.checkOutTo}
						id="polices-check-out"
						onFromChange={(value) => {
							setAllPolices({ ...allPolices, checkOutFrom: value });
							setErrors({
								...errors,
								...validate(
									{ name: "checkOutFrom", value: value },
									{ required: true }
								),
							});
						}}
						name="checkOut"
						color={errors.checkOutFrom?.required ? "danger" : ""}
						errors={errors.checkOutFrom}
					/>
				</div>
			</div>

			<div className="header-box my-3">{polices.price}</div>
			<div className="row m-0">
				<div className="col-4">
					<SelectField
						label={"currency"}
						placeholder={"currency"}
						name="petsAllowed"
						id="petsAllowed"
						value={allPolices.currencies}
						multi={true}
						onChange={(e) => {
							setAllPolices({ ...allPolices, currencies: e });
							setErrors({
								...errors,
								...validate(
									{ name: "currencies", value: e },
									{ required: true }
								),
							});
						}}
						options={currencyList}
						color={errors.currencies?.required ? "danger" : ""}
						errors={errors.currencies}
					/>
				</div>
			</div>

			<div className="header-box my-3">{polices.tax}</div>

			<div class="form-check form-check-inline pl-2 pr-2">
				<input
					class="form-check-input"
					type="radio"
					name="payment_policy456"
					id="Meals1"
					value="1"
					checked={allPolices.tax_type == 1}
					onChange={(e) => {
						setAllPolices({ ...allPolices, tax_type: 1 });
					}}
				/>
				<label class="form-check-label" for="Meals1">
					exclude
				</label>
			</div>
			<div class="form-check form-check-inline pl-2 pr-2">
				<input
					class="form-check-input"
					type="radio"
					name="payment_policy456"
					id="Meals2"
					value="2"
					checked={allPolices.tax_type == 2}
					onChange={(e) => {
						setAllPolices({ ...allPolices, tax_type: 2 });
						setTaxes([
							{
								type: "",
								name: "",
								rate: "",
							},
						]);
					}}
				/>
				<label class="form-check-label" for="Meals2">
					include{" "}
				</label>
			</div>

			{allPolices.tax_type == 1 && taxes.length > 0 ? (
				<>
					{taxes.map((tax, i) => {
						return (
							<>
								<div className="row m-0">
									<Tax
										deleteTax={deleteTax}
										index={i}
										key={i}
										tax={tax}
										currencys={allPolices.currencies}
										setTax={setTax}
									/>
								</div>
							</>
						);
					})}
					<div className="text-primary mx-3 pointer">
						<i className="fas fa-plus-circle"></i>
						<button
							className="px-1 font14 btn-link text-primary"
							onClick={addTax}
							id="add-tax"
						>
							Add Tax
						</button>
					</div>
				</>
			) : (
				<div className="col-4">
					<TextField
						label={polices.taxName}
						placeholder={polices.taxName}
						value={allPolices.tax}
						name="name"
						id="tax-1"
						onChange={(e) => {
							setAllPolices({ ...allPolices, tax: e.target.value });
						}}
					/>
				</div>
			)}

			<div className="header-box my-3">{polices.children}</div>
			<p>{polices.accommodateChildren}</p>
			<FormGroup tag="fieldset" className="col-12 name-check">
				<FormGroup
					check
					onClick={() => setAllPolices({ ...allPolices, haveChildren: "yes" })}
				>
					<Label check>
						<Input
							type="radio"
							defaultChecked={allPolices.haveChildren === "yes"}
							name="children"
							id="children-yes"
						/>
						Yes
					</Label>
				</FormGroup>
				<FormGroup
					check
					onClick={() => setAllPolices({ ...allPolices, haveChildren: "no" })}
				>
					<Label check>
						<Input
							type="radio"
							defaultChecked={allPolices.haveChildren === "no"}
							name="children"
							id="children-no"
						/>
						No
					</Label>
				</FormGroup>
			</FormGroup>
			<div className="header-box my-3">{polices.pets}</div>
			<p>{polices.petsDetails}</p>
			<div className="row m-0">
				<div className="col-4">
					<SelectField
						label={polices.allowPets}
						placeholder={polices.allowPets}
						name="petsAllowed"
						id="petsAllowed"
						value={allPolices.petsAllowed}
						onChange={(e) => {
							setAllPolices({ ...allPolices, petsAllowed: e });
							setErrors({
								...errors,
								...validate(
									{ name: "petsAllowed", value: e },
									{ required: true }
								),
							});
						}}
						options={[
							{ label: "No", value: "no" },
							{ label: "Yes", value: "yes" },
						]}
						color={errors.petsAllowed?.required ? "danger" : ""}
						errors={errors.petsAllowed}
					/>
				</div>
				{allPolices?.petsAllowed?.value == "yes" ? (
					<div className="col-4">
						<SelectField
							label={polices.chargesForPets}
							placeholder={polices.chargesForPets}
							name="petsCharges"
							id="petsCharges"
							options={[
								{ label: "Free", value: "free" },
								{ label: "Paid", value: "paid" },
							]}
							value={allPolices.petsCharges}
							onChange={(e) => {
								setAllPolices({ ...allPolices, petsCharges: e });
							}}
							color={errors.petsCharges?.required ? "danger" : ""}
							errors={errors.petsCharges}
						/>
					</div>
				) : (
					""
				)}
			</div>
			<div className="row my-5 mx-0 justify-content-start flex-nowrap w-25">
				<button
					className="btn mx-2 cancel"
					onClick={reset}
					id="policies-cancel"
				>
					cancel
				</button>
				<button
					className="btn btn-primary px-5"
					onClick={submit}
					id="policies-continue"
				>
					continue
				</button>
			</div>
		</div>
	);
}

export default Polices;
