import axios from "axios";

const basicUrl = process.env.REACT_APP_API_URL;

export const fetchCalendar = async (HotelId, date_from, date_to, rooms) => {
	return await axios
		.get(`${basicUrl + "/api/properities/" + HotelId + "/calender"}`, {
			params: { date_from: date_from, date_to: date_to, rooms: rooms },
		})
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const addAvailabilityRoom = async (HotelId, data) => {
	return await axios
		.post(
			`${basicUrl + "/api/properities/" + HotelId + "/set-availability-room"}`,
			data
		)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const addPriceRoom = async (HotelId, data) => {
	return await axios
		.post(
			`${
				basicUrl + "/api/properities/" + HotelId + "/set-property-rate-price"
			}`,
			data
		)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const addPriceCountry = async (HotelId, data) => {
	return await axios
		.post(
			`${
				basicUrl +
				"/api/properities/" +
				HotelId +
				"/set-property-rate-price-country"
			}`,
			data
		)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const addPriceRangeDays = async (HotelId, data) => {
	return await axios
		.post(
			`${
				basicUrl +
				"/api/properities/" +
				HotelId +
				"/set-pluck-availability-room"
			}`,
			data
		)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const addPluckRoomCalender = async (HotelId, data) => {
	return await axios
		.post(
			`${
				basicUrl + "/api/properities/" + HotelId + "/set-pluck-room-calender"
			}`,
			data
		)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const deleteCustomPriceCalender = async (HotelId, data) => {
	return await axios
		.delete(
			`${
				basicUrl +
				"/api/properities/" +
				HotelId +
				"/delete-custom-price-calender"
			}`,
			{ params: { ...data } }
		)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const updateStatusPriceCalender = async (HotelId, data) => {
	return await axios
		.put(
			`${
				basicUrl +
				"/api/properities/" +
				HotelId +
				"/update-base-price-calender-status"
			}`,
			{ ...data }
		)
		.then((res) => res.data)
		.catch((err) => err.response);
};
