// import React from "react";
// import Modal from "react-modal";
import { Modal, ModalBody } from "reactstrap";
import Locale from "translations";

export default function ConfirmModal(props) {
	const { backOffice, userDetails } = Locale;

	return (
		<>
			<Modal isOpen={props.IsOpen} toggle={props.toggle}>
				<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0 py-3">
					<h4 className="py-4 font-weight-bolder">{props.message}</h4>
				</ModalBody>
				<div className="w-100 pb-5 border-top-0  text-center    p-0 ">
					<button
						className="btn btn-danger rounded font-weight-bolder mx-1   w-25"
						onClick={props.onConfirm}
					>
						Delete <i class="fas fa-trash text-white mx-2"></i>
					</button>
					<button
						className="btn btn-dark rounded font-weight-bolder   mx-1  w-25"
						onClick={props.toggle}
					>
						{userDetails.cancel}
					</button>
				</div>
			</Modal>
		</>
	);
}
