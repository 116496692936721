import React from "react";
import { useAuthState } from "context/auth";
import { Progress } from "reactstrap";

export default function RegistrationProgress(props) {
	const progressBar = useAuthState();
	return (
		<div className="main-bar mt-5 " style={{ maxWidth: "650px" }}>
			<Progress
				className="main-bar__progress mx-3"
				color="primary"
				value={progressBar.progress}
			/>

			<div className="main-bar__box">
				<div
					className={`main-bar__item ${
						progressBar.progress >= 50 ? "active" : ""
					} ${progressBar.progress === 0 ? "current" : ""}`}
				>
					<span className="icx icx-building"></span>
				</div>
				<div
					className={`main-bar__item ${
						progressBar.progress > 50 ? "active" : ""
					} ${progressBar.progress === 50 ? "current" : ""}`}
				>
					<span className="icx icx-desktop"></span>
				</div>
				<div
					className={`main-bar__item ${
						progressBar.progress > 100 ? "active" : ""
					} ${progressBar.progress === 100 ? "current" : ""}`}
				>
					<span className="icx icx-shield"></span>
				</div>
			</div>
		</div>
	);
}
