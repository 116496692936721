import React from "react";
import { Redirect, Route } from "react-router-dom";
import Header from "components/layout/Header";
import { VerifyHotelProvider } from "context/verifyHotel";
import SideBar from "./layout/SideBar";
import Layout from "./layout/Layout";

// React Component
export function VerifyHotelRouter({ component: Component, ...props }) {
	const isAuth = localStorage.getItem("isAuth");
	if (isAuth) {
		return (
			<Route
				{...props}
				render={(matchProps) => (
					<>
						<Layout>
							<VerifyHotelProvider>
								<Component {...matchProps} />
							</VerifyHotelProvider>
						</Layout>
					</>
				)}
			/>
		);
	} else {
		return <Redirect to="/login" />;
	}
}
