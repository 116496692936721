import { useGlobalDispatch } from "context/global";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom";
import { fetchAmenities, fetchRoomTypes } from "services/lookups";
import { addAmenities, editAmenities } from "services/property";
import { formatPostAmenities, formatGetAmenities } from "data/property";
import Locale from "translations";
import Amenity from "./Amenity";
import OtherAmenity from "./OtherAmenity";
import { usePropertyDispatch, usePropertyState } from "context/property";
import CancelModal from "./CancelModal";
const locale = localStorage.getItem("currentLocale") || "en";

function Amenities() {
	const { id } = useParams();
	const { amenities } = Locale;
	const [lookups, setLookups] = useState({});
	const [allAmenities, setAllAmenities] = useState([]);
	const [roomTypes, setRoomTypes] = useState([]);
	const dispatch = useGlobalDispatch();
	const { propertyDetails } = usePropertyState();
	const propertyDispatch = usePropertyDispatch();
	const [canceled, setCanceled] = useState(false);

	const fetchLookups = async () => {
		const [amenities] = await Promise.all([fetchAmenities(locale)]);
		const { most_requested, ...rest } = amenities.data;
		setLookups({
			mostRequested: most_requested,
			rest,
		});
	};

	useEffect(() => {
		fetchLookups();
		if (propertyDetails?.property?.rooms) {
			setRoomTypes(
				propertyDetails?.property?.rooms.map(
					(room) =>
					(room = {
						label: room.custom_name || room.name.name,
						value: room.id,
					})
				)
			);
		}
	}, [propertyDetails]);

	const isAmenitiesValid = () => {
		let isValid = true;
		allAmenities.forEach((item) => {
			const { is_has_all_rooms } = item;
			if (!is_has_all_rooms && !item.rooms) {
				isValid = false;
			}
		});
		return isValid;
	};

	useEffect(() => {
		if (id && propertyDetails?.amenities) {
			//
			const formatted = formatGetAmenities(propertyDetails.amenities);
			setAllAmenities(formatted);
		}
	}, [propertyDetails]);

	const submit = async () => {
		if (isAmenitiesValid()) {
			if (id && propertyDetails?.amenities?.length > 0) {
				const data = formatPostAmenities(allAmenities);
				const res = await editAmenities(id, { amenities: data });
				if (res.status === 201 || res.status === 200) {
					dispatch({ type: "progress", step: "5", current: "5" });
					propertyDispatch({ type: "propertyDetails", payload: res.data });
				}
			} else {
				const data = formatPostAmenities(allAmenities);
				const res = await addAmenities(id, { amenities: data });
				if (res.status === 201 || res.status === 200) {
					dispatch({ type: "progress", step: "5", current: "5" });
					propertyDispatch({ type: "propertyDetails", payload: res.data });
				}
			}
		} else {
			store.addNotification({
				title: "warning!",
				message: "Please fill all fields",
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	const reset = () => {
		setCanceled(true);
	};

	//

	return (
		<div>
			<CancelModal isOpen={canceled} toggle={setCanceled} />

			<div className="header-box my-3">{amenities.mostRequested}</div>
			<div className="most">
				{lookups.mostRequested &&
					lookups.mostRequested.map((amenity, i) => {
						return (
							<Amenity
								allAmenities={allAmenities}
								setAllAmenities={setAllAmenities}
								amenity={amenity}
								key={i}
								roomTypes={roomTypes}
								index={i}
							/>
						);
					})}
			</div>
			<div className="shadow-sm product-build__product-collpase branch-collapse mt-3">
				{lookups.rest &&
					Object.entries(lookups.rest).length > 0 &&
					Object.entries(lookups.rest).map(([key, amenity], i) => {
						return (
							<OtherAmenity
								allAmenities={allAmenities}
								setAllAmenities={setAllAmenities}
								name={key}
								category={amenity}
								key={i}
								roomTypes={roomTypes}
								index={i}
							/>
						);
					})}
			</div>
			<div className="row my-5 mx-0 justify-content-start flex-nowrap w-25">
				<button
					className="btn mx-2 cancel"
					onClick={reset}
					id="amenities-cancel"
				>
					cancel
				</button>
				<button
					className="btn btn-primary px-5"
					onClick={submit}
					id="amenities-submit"
				>
					continue
				</button>
			</div>
		</div>
	);
}

export default Amenities;
