import React, { createContext, useContext, useReducer } from "react";
const TransportationReducerContext = createContext();
const TransportationReducerDispatchContext = createContext();
const initialState = {};

function TransportationReducer(state, action) {
	switch (action.type) {
		case "dashboardState": {
			return { ...state, dashboardState: action.payload };
		}

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function useTransportationState() {
	const context = useContext(TransportationReducerContext);
	if (context === undefined) {
		throw new Error(
			"useTransportationReducerState must be used within a TransportationReducerProvider"
		);
	}
	return context;
}

function useTransportationDispatch() {
	const context = useContext(TransportationReducerDispatchContext);
	if (context === undefined) {
		throw new Error(
			"useTransportationReducerDispatch must be used within a TransportationReducerProvider"
		);
	}
	return context;
}

function TransportationProvider({ children }) {
	const [state, dispatch] = useReducer(TransportationReducer, initialState);
	return (
		<TransportationReducerContext.Provider value={state}>
			<TransportationReducerDispatchContext.Provider value={dispatch}>
				{children}
			</TransportationReducerDispatchContext.Provider>
		</TransportationReducerContext.Provider>
	);
}

export {
	TransportationProvider,
	useTransportationState,
	useTransportationDispatch,
};
