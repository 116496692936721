import { useGlobalState } from "context/global";
import {
	useVerifyHotelDispatch,
	useVerifyHotelState,
} from "context/verifyHotel";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchCountries } from "services/lookups";
import { getContract } from "services/verify-hotel";
import Confirmation from "./Confirmation";
import ManagerDetails from "./ManagerDetails";
import OwnerDetails from "./OwnerDetails";
import PropertyDetails from "./PropertyDetails";
import VerifyProgress from "./VerifyProgress";

function Verify(props) {
	const { id } = useParams();
	const { locale } = useGlobalState();
	const [lookups, setLookups] = useState({
		countries: [],
		cities: [],
	});
	const dispatch = useVerifyHotelDispatch();
	const { step } = useVerifyHotelState();
	const { canEdit } = props?.location?.state;
	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchCountries();
			const format = res?.data?.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setLookups({
				lookups,
				countries: format,
			});
		}

		fetchLookups();
		dispatch({ type: "setStep", progress: 0, step: 1 });
	}, []);

	// Return View
	const currentStepView = () => {
		switch (step) {
			case 6:
				return <PropertyDetails lookups={lookups} canEdit={canEdit} />;
			case 1:
				return <OwnerDetails lookups={lookups} canEdit={canEdit} />;
			case 2:
				return <ManagerDetails lookups={lookups} canEdit={canEdit} />;
			case 3:
				return <Confirmation canEdit={canEdit} />;
			default:
				return (
					<PropertyDetails
					// countries={countries}
					// isCountriesLoaded={isCountriesLoaded}
					/>
				);
		}
	};
	//getContract
	useEffect(() => {
		if (canEdit) {
			async function fetchProperty() {
				const res = await getContract(id);

				if (res.status === 200 || res.status === 201) {
					dispatch({
						type: "setPropertyDetails",
						payload: res?.data?.property_details,
					});
					dispatch({
						type: "setOwnerDetails",
						payload: res?.data?.property_details?.owner_details,
					});
					dispatch({
						type: "setManagerDetails",
						payload: res?.data?.property_details?.manageable,
					});
					dispatch({
						type: "setManagerType",
						payload: res?.data?.property_details?.manageable_type,
					});
				}
			}
			fetchProperty();
		}
	}, []);

	return (
		<>
			<div className="container-fluid px-2 home">
				<VerifyProgress />
				{currentStepView()}
			</div>
		</>
	);
}

export default Verify;
