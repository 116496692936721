import axios from "axios";
// const authURL = process.env.API_URL;
const authURL =
	"https://key01.safavisa.com/auth/realms/SBS/protocol/openid-connect/token";
const userURL = process.env.REACT_APP_API_URL + "/api/users";
const permissionsURL = process.env.REACT_APP_API_URL + "/api";

export const emailAvailability = async (data) => {
	return await axios
		.post(`${userURL}/unique-email`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const nameAvailability = async (data) => {
	return await axios
		.post(`${userURL}/unique-name`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const accountRegister = async (data) => {
	return await axios
		.post(userURL, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const forgetPass = async (data) => {
	return await axios
		.post(`${userURL}/forgot-password`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const activeAccount = async (data) => {
	return await axios
		.post(`${userURL}/activate`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const reactiveAccount = async (data) => {
	return await axios
		.post(`${userURL}/resend-activate-token`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const verifyEmail = async (data) => {
	return await axios
		.post(`${userURL}/active-email`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const LoginEmail = async (data) => {
	return await axios
		.post(`${userURL}/login`, data)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const userLogin = async (data, config) => {
	return await axios
		.post(authURL, data, config)
		.then((res) => res)
		.catch((err) => err.response);
};

export const passReset = async (data) => {
	return await axios
		.post(`${userURL}/reset-password`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getPermissions = async () => {
	return await axios
		.get(`${permissionsURL}/my-permissions`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getSupplierProfile = async () => {
	return await axios
		.get(`${permissionsURL}/supplier-profile`)
		.then((res) => res.data)
		.catch((err) => err.response);
};
export const getUserProfile = async () => {
	return await axios
		.get(`${permissionsURL}/my-profile`)
		.then((res) => res.data)
		.catch((err) => err.response);
};
