import SelectField from "components/shared/SelectField";
import validate from "helpers/validate";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Card, CardBody, Collapse } from "reactstrap";
import { fetchCommonRatePlan } from "services/lookups";
import Locale from "translations";

export default function Meals({
	ratePlan,
	setRatePlanState,
	errors,
	setErrors,
	MealsPlan,
}) {
	const RatePlan = Locale;
	const { rateId } = useParams();
	const [MealsList, setMealsList] = useState([]);
	const Buffet = [
		{
			label: "East Asia",
			value: 1,
		},
		{
			label: "Continental",
			value: 2,
		},
	];

	useEffect(() => {
		const fetchLookup = async () => {
			const res = await fetchCommonRatePlan();
			if (res.status == 200) {
				setMealsList(
					res.data.meals.map((res) => ({
						label: res.name,
						value: res.id,
					}))
				);
			}
		};
		fetchLookup();
	}, []);

	useEffect(() => {
		if (
			rateId &&
			ratePlan.Meals?.length > 0 &&
			ratePlan.Buffet?.length > 0 &&
			ratePlan.Buffet[0].hasOwnProperty("name")
		) {
			let newMeals =
				ratePlan.Meals?.length > 0
					? ratePlan.Meals?.map((t) => ({
						value: t.id,
						label: t.name,
					}))
					: [];

			let list = [];
			let listTwo = [];

			ratePlan.exclude_meals.forEach((element) => {
				if (element.buffet.id === Buffet[0].value) {
					list = [
						...list,
						{
							value: element.meal.id,
							label: element.meal.name,
							price: element.price,
						},
					];
				} else if (element.buffet.id === Buffet[1].value) {
					listTwo = [
						...listTwo,
						{
							value: element.meal.id,
							label: element.meal.name,
							price: element.price,
						},
					];
				}
			});
			let Buffeta = [];

			if (list.length > 0) {
				Buffeta = [
					{
						label: "East Asia",
						value: 1,
						meal: list,
					},
				];
			}
			if (listTwo.length > 0) {
				Buffeta = [
					...Buffeta,
					{
						label: "Continental",
						value: 2,
						meal: listTwo,
					},
				];
			}

			setRatePlanState({
				...ratePlan,
				Meals: newMeals,
				Buffet: Buffeta,
			});
		}
	}, [rateId, ratePlan, setRatePlanState]);

	useEffect(() => {
		if (!ratePlan.Buffet) {
			setRatePlanState({
				...ratePlan,
				Meals: [],
			});
		}
	}, [ratePlan.Buffet]);

	const inputMeals = (e) => {
		let BuffetNew = [...ratePlan.Buffet];
		BuffetNew.forEach((res) => {
			if (e && e.length > 0 && BuffetNew && BuffetNew.length > 0) {
				e.forEach((element) => {
					BuffetNew.forEach((ele) => {
						ele?.meal?.forEach((elem) => {
							if (element.value === elem.value) {
								element.price = elem.price;
							}
						});
					});
				});
			}
			if (e) {
				res.meal = e.map((res) => {
					return { ...res, price: res.price ? res.price : 0 };
				});
			}
		});

		setRatePlanState({
			...ratePlan,
			Meals: e,
			Buffet: BuffetNew,
		});
	};

	useEffect(() => {
		if (ratePlan.Buffet?.length > 0 && ratePlan.Meals?.length > 0) {
			inputMeals(ratePlan.Meals);
		}
	}, [ratePlan.Meals?.length]);

	useEffect(() => {
		if (ratePlan.Buffet?.length > 0 && ratePlan.Buffet.length > 0) {
			let newPrice = [...ratePlan.Buffet];
			let listPrice = [];
			newPrice.forEach((element) => {
				if (element.meal && element.meal.length > 0) {
					element.meal.forEach((ele) => {
						listPrice = [
							...listPrice,
							{
								buffet_id: element.value,
								buffet_name: element.label,
								price: ele.price,
								meal_id: ele.value,
								meal_name: ele.label,
							},
						];
					});
				}
			});
			setRatePlanState({
				...ratePlan,
				listPrice: listPrice,
			});
		}
	}, [ratePlan.Buffet, ratePlan.Meals?.length]);

	const inputTable = (e, Meals, Buffet) => {
		let value = e.target.value;
		let BuffetNew = [...ratePlan.Buffet];
		BuffetNew.forEach((res) => {
			if (res.value === Buffet.value) {
				res.meal.forEach((ele) => {
					if (ele.value === Meals.value) {
						ele.price = value;
					}
				});
			}
		});
		setRatePlanState({
			...ratePlan,
			Buffet: BuffetNew,
		});
	};

	return (
		<div className="row m-0">
			<div
				className={`col-md-12 p-0 mt-3 ${errors?.meal?.required ? "danger-checkbox" : ""
					}`}
			>
				<div
					className="rate-plan-header"
					onClick={() =>
						setRatePlanState({
							...ratePlan,
							isOpenMeals: !ratePlan.isOpenMeals,
						})
					}
				>
					{RatePlan.hotelLayout?.ratePlan?.meals}
					<i
						className={`fas pos-arrow ${ratePlan.isOpenMeals ? "fa-chevron-up " : "fa-chevron-down"
							}`}
					></i>
				</div>

				<Collapse isOpen={ratePlan.isOpenMeals}>
					<Card>
						<CardBody>
							<div className="col-md-12 pt-2">
								<div class="form-check form-check-inline pl-2 pr-2">
									<input
										class="form-check-input"
										type="radio"
										name="payment_policy456"
										id="Meals1"
										value="1"
										checked={ratePlan.meal == "1"}
										onChange={(e) => {
											setRatePlanState({
												...ratePlan,
												meal: e.target.value,
												Buffet: [],
												Meals: [],
											});
											setErrors({
												...errors,
												...validate(
													{
														name: "meal",
														value: e.target.value,
													},
													{ required: true }
												),
											});
										}}
									/>
									<label class="form-check-label" for="Meals1">
										No Meals
									</label>
								</div>
								<div class="form-check form-check-inline pl-2 pr-2">
									<input
										class="form-check-input"
										type="radio"
										name="payment_policy456"
										id="Meals2"
										value="2"
										checked={ratePlan.meal == "2"}
										onChange={(e) => {
											setRatePlanState({
												...ratePlan,
												meal: e.target.value,
												Buffet: [],
												Meals: [],
											});
											setErrors({
												...errors,
												...validate(
													{
														name: "meal",
														value: e.target.value,
													},
													{ required: true }
												),
											});
										}}
									/>
									<label class="form-check-label" for="Meals2">
										Include
									</label>
								</div>
								{/* <div class="form-check form-check-inline pl-2 pr-2">
									<input
										class="form-check-input"
										type="radio"
										name="payment_policy456"
										id="Meals3"
										value="3"
										checked={ratePlan.meal == "3"}
										onChange={(e) => {
											setRatePlanState({
												...ratePlan,
												meal: e.target.value,
												Buffet: [],
												Meals: [],
											});
											setErrors({
												...errors,
												...validate(
													{
														name: "meal",
														value: e.target.value,
													},
													{ required: true }
												),
											});
										}}
									/>
									<label class="form-check-label" for="Meals3">
										exclude
									</label>
								</div> */}
								{errors?.meal?.required ? (
									<div className="col-md-12 text-danger danger-massage">
										should choose Meals type
									</div>
								) : (
									""
								)}
								{ratePlan.meal === "3" ? (
									<>
										<div className="row">
											<div className="col-md-4">
												<SelectField
													label={"Buffet"}
													value={ratePlan.Buffet}
													name="Buffet"
													multi={true}
													options={Buffet}
													onChange={(e) => {
														setErrors({
															...errors,
															...validate(
																{
																	name: "Buffet",
																	value: e,
																},
																{ required: true }
															),
														});

														setRatePlanState({
															...ratePlan,
															Buffet: e,
														});
													}}
													onBlur={() =>
														setErrors({
															...errors,
															...validate(
																{
																	name: "Buffet",
																	value: ratePlan.Buffet,
																},
																{ required: true }
															),
														})
													}
													color={errors?.Buffet?.required ? "danger" : ""}
													errors={errors?.Buffet}
												/>
											</div>
											<div className="col-md-4">
												<SelectField
													label={"Meals"}
													value={ratePlan.Meals}
													name="Meals"
													multi={true}
													disabled={
														ratePlan.Buffet === null ||
														ratePlan.Buffet.length === 0
													}
													options={MealsList}
													onChange={(e) => {
														setErrors({
															...errors,
															...validate(
																{
																	name: "Meals",
																	value: e,
																},
																{ required: true }
															),
														});
														inputMeals(e);
													}}
													onBlur={() =>
														setErrors({
															...errors,
															...validate(
																{
																	name: "Meals",
																	value: ratePlan.Meals,
																},
																{ required: true }
															),
														})
													}
													color={errors?.Meals?.required ? "danger" : ""}
													errors={errors?.Meals}
												/>
											</div>
											<div className="col-md-4">
												<SelectField
													label={"Meals Plan"}
													value={ratePlan.meals_plan}
													name="meals_plan"
													options={MealsPlan}
													onChange={(e) => {
														setErrors({
															...errors,
															...validate(
																{
																	name: "meals_plan",
																	value: e,
																},
																{ required: true }
															),
														});
														setRatePlanState({
															...ratePlan,
															meals_plan: e,
														});
													}}
													onBlur={() =>
														setErrors({
															...errors,
															...validate(
																{
																	name: "meals_plan",
																	value: ratePlan.meals_plan,
																},
																{ required: true }
															),
														})
													}
													color={errors?.meals_plan?.required ? "danger" : ""}
													errors={errors?.meals_plan}
												/>
											</div>

											{ratePlan?.Buffet?.length > 0 &&
												ratePlan?.Meals?.length > 0 ? (
												<div className="col-md-5">
													<div className="item-room">
														<table className=" table new-table">
															<tr>
																<th className="w-120">Buffet / Meals</th>
																{ratePlan?.Meals?.map((res, index) => {
																	return <th>{res.label}</th>;
																})}
															</tr>

															{ratePlan?.Buffet?.map((res, index) => {
																return (
																	<tr key={res.label}>
																		<td className="label">{res.label}</td>
																		{res?.meal?.length > 0
																			? res?.meal?.map((ele, i) => {
																				return (
																					<td key={ele.label}>
																						<input
																							className="input-price"
																							value={ele.price}
																							placeholder="0"
																							type={Number}
																							onChange={(e) =>
																								inputTable(e, ele, res)
																							}
																						/>
																					</td>
																				);
																			})
																			: ""}
																	</tr>
																);
															})}
														</table>
													</div>
												</div>
											) : (
												""
											)}
										</div>
									</>
								) : ratePlan.meal === "2" ? (
									<div className="row">
										{/* <div className="col-md-4">
											<SelectField
												label={"Buffet"}
												value={ratePlan.Buffet}
												name="Buffet"
												options={Buffet}
												onChange={(e) => {
													setErrors({
														...errors,
														...validate(
															{
																name: "Buffet",
																value: e,
															},
															{ required: true }
														),
													});
													setRatePlanState({
														...ratePlan,
														Buffet: e,
													});
												}}
												onBlur={() =>
													setErrors({
														...errors,
														...validate(
															{
																name: "Buffet",
																value: ratePlan.Buffet,
															},
															{ required: true }
														),
													})
												}
												color={errors?.Buffet?.required ? "danger" : ""}
												errors={errors?.Buffet}
											/>
										</div> */}
										<div className="col-md-4">
											<SelectField
												label={"Meals"}
												value={ratePlan.Meals}
												name="Meals"
												options={MealsList}
												onChange={(e) => {
													setErrors({
														...errors,
														...validate(
															{
																name: "Meals",
																value: e,
															},
															{ required: true }
														),
													});
													setRatePlanState({
														...ratePlan,
														Meals: e,
													});
												}}
												onBlur={() =>
													setErrors({
														...errors,
														...validate(
															{
																name: "Buffet",
																value: ratePlan.Meals,
															},
															{ required: true }
														),
													})
												}
												color={errors?.Meals?.required ? "danger" : ""}
												errors={errors?.Meals}
											/>
										</div>
									</div>
								) : (
									""
								)}
							</div>
						</CardBody>
					</Card>
				</Collapse>
			</div>
		</div>
	);
}
