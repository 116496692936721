import React from "react";
import walletAdd from "assets/images/wallet-add.svg";
import Model from "components/model";
import Locale from "translations";

export default function ViewPopup({ isOpen, toggle, popupInfo = null }) {
	const { wallet } = Locale;

	return (
		<Model
			isOpen={isOpen}
			title={"View TRANSACTIONS"}
			toggle={() => {
				toggle();
			}}
			hasFooter={false}
			size="md"
			className="modal-network-center"
		>
			<div className="container px-5 mb-3">
				<div className="recharge-img pt-3 pb-2 text-center">
					<img src={walletAdd} alt="Wallet" />
				</div>

				<div className="px-3  my-4 sent-request-content">
					<div className="d-flex justify-content-between ">
						<p className="font-bold">{wallet.receiptNumber}</p>
						<p>{"TR_164415476206624"}</p>
					</div>

					<div className="d-flex justify-content-between ">
						<p className="font-bold"> {wallet.companyName}</p>
						<p>{"Company Name"}</p>
					</div>
					<div className="d-flex justify-content-between ">
						<p className="font-bold"> {wallet.requestDate} </p>
						<p>{" 2022-02-06 01:39 PM"}</p>
					</div>

					<div className="d-flex justify-content-between ">
						<p className="font-bold"> {wallet.Amount}</p>
						<p>
							{"1200 EGP"}
							{/* {popupInfo?.amount} */}
						</p>
					</div>
				</div>
			</div>
		</Model>
	);
}
