import visa from "assets/images/visa.svg";
import hotels from "assets/images/hotels.svg";
import transportation from "assets/images/transportion2.svg";
import Group from "assets/images/Group.svg";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useAuthDispatch, useAuthState } from "context/auth";
import validate, { isFormValid } from "helpers/validate";
import React, { useEffect, useState } from "react";
import Locale from "translations";

export default function Services({ services: servicesLookups, catagories }) {
	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	const { services: servicesLocale, commons } = Locale;
	const { supplierDetails } = Locale;

	const authState = useAuthState();
	//
	// const [servicesLookups, setServicesLookups] = useState([]);
	const [services, setServices] = useState(authState.services ?? []);
	const dispatch = useAuthDispatch();
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [Done, setDone] = useState(false);

	// ----------------------------------------------------------------------------------------------------
	// Component Functions
	// ----------------------------------------------------------------------------------------------------
	const [supplier, setSupplier] = useState({
		category: authState.supplierDetails?.category ?? "",
		owner: authState.supplierDetails?.owner ?? "",
	});
	// Store Image in Redux And Continue
	const submit = () => {
		dispatch({
			type: "services",
			payload: services,
		});
		dispatch({
			type: "supplierDetails",
			payload: {
				category: supplier.category,
				owner: supplier.owner,
				errors: errors,
			},
		});
		dispatch({ type: "Progress", progress: 100, step: 3 });
	};

	const checkFormErrors = () => {
		const ids = services.map((service) => +service.id);
		if (ids.includes(1)) {
			setErrors({
				...errors,
				...validate(
					{ name: "category", value: supplier.category },
					{ required: true }
				),
				...validate(
					{ name: "owner", value: supplier.owner },
					{ required: true, min: 3 }
				),
			});
		} else {
			setErrors({
				...errors,
				...validate(
					{ name: "category", value: supplier.category },
					{ required: false }
				),
				...validate(
					{ name: "owner", value: supplier.owner },
					{ required: false }
				),
			});
		}
	};

	useEffect(() => {
		const checkUniqueness = async () => {
			if (isFormValid(errors) && Done) {
				submit();
			}
			setSupplier({
				...supplier,
				spin: false,
			});
		};
		checkUniqueness();
	}, [isErrorLoaded]);

	const checkAvailability = () => {
		setSupplier({
			...supplier,
			spin: true,
		});
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};
	const [errors, setErrors] = useState(authState.supplierDetails?.errors ?? {});

	const renderImage = (id) => {
		return id === 1
			? hotels
			: id === 2
			? visa
			: id === 3
			? transportation
			: Group;
	};

	// Back Button
	const back = () => {
		dispatch({
			type: "services",
			payload: services,
		});
		dispatch({ type: "Progress", progress: 50, step: 1 });
	};

	const handleService = (e) => {
		const { id, name } = e.target;
		if (services.map((service) => service.id).includes(id)) {
			const servicesClone = [...services];
			const idx = servicesClone.indexOf(name);
			servicesClone.splice(idx, 1);
			setServices(servicesClone);
		} else {
			setServices([...services, { id, name }]);
		}
	};

	// ----------------------------------------------------------------------------------------------------
	// Main Component Body UI
	// ----------------------------------------------------------------------------------------------------
	return (
		<>
			<h1 className="text-center text-title text-dark-blue">
				{servicesLocale.title}
			</h1>

			<div className="bg-white services p-2 px-3 radius-10 mt-3">
				<div className="d-flex m-3">
					{/* ['hotels','transportation', 'airline'] */}
					{servicesLookups.map((service, i) => {
						const ids = services.map((service) => +service.id);

						return (
							<div
								className={`item ${
									ids.includes(service.id) ? "selected" : ""
								} `}
							>
								<div className="icon text-center pb-4">
									<img
										className="img-fluid"
										src={renderImage(service.id)}
										alt={service.name}
										style={{ width: "80%" }}
									/>
								</div>
								<div className="title pb-4">{service.name}</div>
								<button
									id={service.id}
									name={service.name}
									onClick={(e) => handleService(e)}
									className="select"
									// id={`service-select-${i + 1}`}
								>
									{commons.select}
								</button>
								{service.id == 1 && ids.includes(service.id) ? (
									<div className="w-100">
										<div className="col-md-12 fixSelect">
											<SelectField
												label={supplierDetails.category}
												value={supplier.category}
												name="category"
												id="supplier-category"
												options={catagories}
												onChange={(e) => {
													setErrors({
														...errors,
														...validate(
															{ name: "category", value: e.value },
															{ required: true }
														),
													});

													setSupplier({
														...supplier,
														category: e,
													});
												}}
												onBlur={() =>
													setErrors({
														...errors,
														...validate(
															{ name: "category", value: supplier.category },
															{ required: true }
														),
													})
												}
												color={errors?.category?.required ? "danger" : ""}
												errors={errors?.category}
											/>
										</div>
										{/* Owner name */}
										<div className="col-12">
											<TextField
												type="text"
												label={supplierDetails.owner}
												placeholder={supplierDetails.ownerPlaceholder}
												value={supplier.owner}
												name="owner"
												id="owner-name"
												onChange={(e) => {
													setErrors({
														...errors,
														...validate(e.target, {
															required: true,
															min: 3,
														}),
													});
													setSupplier({
														...supplier,
														owner: e.target.value,
													});
												}}
												min={3}
												color={
													errors?.owner?.required || errors?.owner?.min
														? "danger"
														: ""
												}
												errors={errors?.owner}
											/>
										</div>
									</div>
								) : (
									""
								)}
							</div>
						);
					})}
					{/* <div
						className={`item ${services.includes("hotels") ? "selected" : ""}`}
					>
						<div className="icon pb-3">
							<img scr={hotels} alt="hotels icon" />
						</div>
						<div className="title pb-2">Hotels</div>
						<button name="hotels" onClick={handleService} className="select">
							{commons.select}
						</button>
					</div>
					<div
						className={`item disabled ${
							services.includes("transportation") ? "selected" : ""
						}`}
					>
						<div className="icon pb-3">
							<img scr={transportation} alt="transportation icon" />
						</div>
						<div className="title pb-2">Transportation</div>
						<div
							name="transportation"
							// onClick={handleService}
							className="select"
						>
							{commons.select}
						</div>
					</div>
					<div
						className={`item disabled ${
							services.includes("airline") ? "selected" : ""
						}`}
					>
						<div className="icon pb-3">
							<img scr={airline} alt="airline icon" />
						</div>
						<div className="title pb-2">Airline</div>
						<div
							name="airline"
							// onClick={handleService}
							className="select"
						>
							{commons.select}
						</div>
					</div> */}
				</div>
			</div>

			<div className="row mt-3 m-0">
				<div className="col-md-2 offset-md-6">
					<button
						className="btn btn-secondary w-100 text-white opacity-30"
						onClick={back}
						id="supplier-back-2"
					>
						{commons.back}
					</button>
				</div>
				<div className="col-md-4 p-0">
					<button
						className="btn btn-primary w-100"
						disabled={services.length === 0}
						onClick={() => {
							checkAvailability();
							setDone(true);
						}}
						id="service-submit"
					>
						{commons.next}
					</button>
				</div>
			</div>
		</>
	);
}
