import React from "react";
import { Redirect, Route } from "react-router-dom";
import Header from "components/layout/Header";
import { PropertyProvider } from "context/property";
import SideBar from "./layout/SideBar";
import Layout from "./layout/Layout";

// React Component
export function PrivateRoute({ component: Component, ...props }) {
	const isAuth = localStorage.getItem("isAuth");
	if (isAuth) {
		return (
			<Route
				{...props}
				render={(matchProps) => (
					<>
						<Layout>
							<PropertyProvider>
								{/* <Header /> */}
								<Component {...matchProps} />
							</PropertyProvider>
						</Layout>
					</>
				)}
			/>
		);
	} else {
		return <Redirect to="/login" />;
	}
}
