import SelectField from "components/shared/SelectField";
import TextAreaField from "components/shared/TextAreaField";
import validate from "helpers/validate";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Card, CardBody, Collapse } from "reactstrap";
import { getListRooms } from "services/rateplan";
import Locale from "translations";
export default function Notes({
	ratePlan,
	setRatePlanState,
	setErrors,
	errors,
}) {
	const RatePlan = Locale;
	const ListConfirmationTypes = [
		{
			label: "Auto confirmation",
			value: 1,
		},
		{
			label: "manual confirmation",
			value: 2,
		},
	];
	return (
		<div className="row m-0">
			<div className="col-md-12 p-0 mt-3">
				<div
					className="rate-plan-header"
					onClick={() =>
						setRatePlanState({
							...ratePlan,
							isOpenNotes: !ratePlan.isOpenNotes,
						})
					}
				>
					{RatePlan.hotelLayout?.ratePlan?.Notes}

					<i
						className={`fas pos-arrow ${
							ratePlan.isOpenNotes ? "fa-chevron-up " : "fa-chevron-down"
						}`}
					></i>
				</div>

				<Collapse isOpen={ratePlan.isOpenNotes}>
					<Card>
						<CardBody>
							<div className="col-md-12 pt-2">
								<TextAreaField
									label={RatePlan.hotelLayout?.ratePlan?.Notes}
									placeholder={RatePlan.hotelLayout?.ratePlan?.TypeNotes}
									value={ratePlan.notes}
									name="notes"
									onChange={(e) => {
										setRatePlanState({
											...ratePlan,
											notes: e.target.value,
										});
									}}
									color={errors?.notes?.required ? "danger" : ""}
									errors={errors?.notes}
								/>
							</div>
						</CardBody>
					</Card>
				</Collapse>
			</div>
		</div>
	);
}
