import React, { useState } from "react";

import Locale from "../../translations";
import { Table } from "reactstrap";
import User from "./user";

import { store } from "react-notifications-component";
import { changeStatus } from "services/TeamManagement";
import Pagination from "components/shared/Pagination";

//import { store } from "react-notifications-component";

export default function Users(props) {
	const { teamManagement } = Locale;
	const { users } = props;


	/** */
	const [modalShow, setModalShow] = useState(false);

	const goTo = async (page) => {
		props.setfilter({ ...props.filter, page: page }); //page.length < 3 ? Number(page):page
	};
	/***Api */
	const changeStatusUser = async (id, status) => {
		const res = await changeStatus({ id }, status);
		if (res.status === 200) {
			store.addNotification({
				title: "info!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			// const usersData = await ListUser();
			// if (usersData.status === 200) {
			//   setUsers(usersData.data);
			// }

			window.location.reload();
		}
	};

	/***Api */
	const AllUsers =
		users && users?.length > 0
			? users?.map((user, index) => (
				<User
					user={user}
					index={index}
					changeStatusUser={changeStatusUser}
					modalShow={modalShow}
					setModalShow={setModalShow}
					countries={props.countries}
					services={props.services}
					catagories={props.catagories}
					groups={props.groups}
					admins={props.admins}
				/>
			))
			: "";

	return (
		<div className="table-responsive px-3  p-0">
			<table className=" table">
				<thead className="table-light">
					<tr>
						<th>{teamManagement.name}</th>
						<th>{teamManagement.email}</th>
						<th>{teamManagement.mobile}</th>
						<th>{teamManagement.countrys}</th>
						<th>{teamManagement.teams}</th>
						<th>{teamManagement.status}</th>
						<th>{teamManagement.tools}</th>
					</tr>
				</thead>

				<tbody className="table-stripe ">
					{AllUsers?.length > 0 ? (
						AllUsers
					) : (
						<tr>
							<td colSpan="10" className="text-center my-3">
								<div className="product-build__product-no-data">
									<i className="fas fa-info-circle fa-lg"></i>{" "}
									<h4>{teamManagement.noResult}</h4>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>
			<Pagination info={props.meta} goTo={goTo} items={10} />
		</div>
	);
}
