import axios from "axios";
import { cleanEmpty } from "services/general";
const teamUrl = process.env.REACT_APP_API_URL + "/api";

export const getListUser = async (filter) => {
	return await axios
		.get(`${teamUrl + "/manage-users/users"}`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const changeStatus = async (id, status) => {
	return await axios
		.post(`${teamUrl}/manage-users/${status}`, id)
		.then((res) => res)
		.catch((err) => err.response);
};
export const addUser = async (data) => {
	return await axios
		.post(`${teamUrl}/manage-users/users`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getListGroups = async () => {
	return await axios
		.get(`${teamUrl + "/groups"}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewUser = async (id) => {
	return await axios
		.get(`${teamUrl}/manage-users/users/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const editUser = async (data) => {
	return await axios
		.post(`${teamUrl}/manage-users/update-user`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getGroups = async (filter) => {
	return await axios
		.get(`${teamUrl + "/groups"}`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const getGroupPermissions = async () => {
	return await axios
		.get(`${teamUrl + "/groups/permissions"}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const addTeam = async (data) => {
	return await axios
		.post(`${teamUrl}/groups`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editGroup = async (id, data) => {
	return await axios
		.put(`${teamUrl}/groups/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const getOneGroup = async (id) => {
	return await axios
		.get(`${teamUrl}/groups/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const deleteGroup = async (data) => {
	return await axios
		.post(`${teamUrl}/groups/delete`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
