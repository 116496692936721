import React from "react";
import TextField from "components/shared/TextField";
import SelectField from "components/shared/SelectField";

import Locale from ".../../translations";

export default function Filter({ filter, setfilter, users, setUsers }) {
	const { teamManagement } = Locale;
	const statusOptions = [
		{ value: 1, label: `${teamManagement.active}`, title: "active" },
		{ value: 0, label: `${teamManagement.inactive}`, title: "inactive" },
	];
	return (
		<>
			<div
				className="row no-gutters list_header p-2  team-filter"
				style={{ width: "80% " }}
			>
				<div className="col mr-2 ">
					<TextField
						label={teamManagement.search}
						placeholder={teamManagement.search}
						name="search"
						value={filter?.q}
						onChange={(e) => {
							setfilter({
								...filter,
								q: e.target.value,
							});
						}}
					/>
				</div>
				<div className="col mr-2 ">
					<TextField
						label={teamManagement.emailAddress}
						placeholder={teamManagement.enterEmailAddress}
						value={filter?.email}
						onChange={(e) => {
							setfilter({
								...filter,
								email: e.target.value,
							});
						}}
					/>
				</div>

				<div className="col mr-2 status-filter-user ">
					<SelectField
						label={teamManagement.status}
						placeholder={teamManagement.status}
						options={statusOptions}
						value={filter?.status}
						onChange={(e) => {
							setfilter({
								...filter,
								status: e,
							});
						}}
					/>
				</div>

				<div className="col pt-3">
					<button
						className="btn text-muted  "
						onClick={() =>
							setfilter({
								q: "",
								email: "",
								status: "",
								page: 1,
							})
						}
					>
						{" "}
						<i className="fas fa-retweet"></i> {teamManagement.restFilter}
					</button>
				</div>
			</div>
		</>
	);
}
