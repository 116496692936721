import axios from "axios";
import { cleanEmpty } from "./general";

const transportationURL = process.env.REACT_APP_API_URL + "/api";

export const listTransportaionRequests = async (params) => {
	return await axios
		.get(`${transportationURL}/transportation`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewRequests = async (id) => {
	return await axios
		.get(`${transportationURL}/transportation/view/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewPassenger = async (id) => {
	return await axios
		.get(`${transportationURL}/transportation/view-passenger/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const exportToExcel = async (id) => {
	return await axios
		.get(`${transportationURL}/transportation/export-passengers/${id}`, {
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};
