import React from "react";
//import { Table } from "reactstrap";
import Locale from "translations";

export default function WalletTable(props) {
	const { backOffice } = Locale;

	const allThead = props.thead.map((item, index) => {
		return (
			<th key={index} className="CompanyHead-title  ">
				{item}
			</th>
		);
	});

	return (
		<div className="w-100">
			<div class="card-body p-0">
				<div class="table-responsive">
					<table className="table mb-0 f-15">
						<thead className="">
							<tr className="">{allThead}</tr>
						</thead>
						<tbody>{props.tbody}</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}
