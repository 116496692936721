import { useState } from "react";
import Header from "./Header";
import SideBar from "./SideBar";

export default function Layout(props) {
	// const { dashboard, marketPlace, inventory } = Locale;
	const [show, setShow] = useState(true);
	const toggleSideBar = () => setShow((current) => !current);
	return (
		<div
			className={`main-content page-content ${show ? "" : "expand-content"}`}
		>
			<Header show={show} toggleSideBar={toggleSideBar} />
			<SideBar show={show} setShow={setShow} />
			{props.children}
		</div>
	);
}
